import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { auth } from '../config/firebaseConfig';

const Fichas = () => {
  const [nombremunicipio, setNombremunicipio] = useState('');
  const [resultados, setResultados] = useState([]);
  const [municipiosAsociados, setMunicipiosAsociados] = useState([]);


  // Obtener el usuario actualmente autenticado
  const usuarioActual = auth.currentUser;

  const buscarMunicipiosAsociados = useCallback(async () => {
    try {
      if (usuarioActual) {
        // Acceder a la colección usuariomunicipios para obtener los municipios asociados al usuario
        const municipiosAsociadosResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/UsuarioMunicipios?uid=${usuarioActual.uid}`);
        const municipiosAsociados = municipiosAsociadosResponse.data;
        
        // Obtener un array de municipios separados
        const municipiosSeparados = municipiosAsociados
        .map(item => (typeof item.municipio === 'string' ? item.municipio.split(',') : item.municipio))
        .flat()

        // Actualizar el estado con los municipios asociados
        setMunicipiosAsociados(municipiosSeparados);
      }
    } catch (error) {
      console.error('Error al buscar municipios asociados:', error);
    }
  }, [usuarioActual]);


  const buscarDatosAll = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/formdatamun?nombremunicipio=${nombremunicipio}`);
      const data = response.data;
      setResultados(data);
    } catch (error) {
      console.error('Error al buscar datos:', error);
    }
  }, [nombremunicipio]);

  useEffect(() => {
    buscarMunicipiosAsociados();
  }, [buscarMunicipiosAsociados]);

  useEffect(() => {
    buscarDatosAll();
  }, [buscarDatosAll]);


  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();

    // Hoja principal con los datos de la ficha
    const wsPrincipal = XLSX.utils.json_to_sheet(resultados.map(item => ({
      ...item,
      observesicercadelaviviendahay: item.observesicercadelaviviendahay.join('; '), // Convertir array a cadenas
      senaledelossiguientesambiilu: item.senaledelossiguientesambiilu.join('; '),
      senaledelossiguientesambiven: item.senaledelossiguientesambiven.join('; '),
      senalelosusosdelossiguienlm: item.senalelosusosdelossiguienlm.join('; '),
      senalelosusosdelossiguienlp: item.senalelosusosdelossiguienlp.join('; '),
      senalelosusosdelossiguienlr: item.senalelosusosdelossiguienlr.join('; '),
      alinteriordelaviviendase: item.alinteriordelaviviendase.join('; '),
      desdelaviviendasepuedeacc: item.desdelaviviendasepuedeacc.join('; '),
      comosedesplazangeneralmen: item.comosedesplazangeneralmen.join('; '),
      cualeselementosdesegurida: item.cualeselementosdesegurida.join('; '),
      sieltiempodedesplazamienttra: item.sieltiempodedesplazamienttra.join('; '),
      sieltiempodedesplazamientest: item.sieltiempodedesplazamientest.join('; '),
      sieltiempodedesplazamientotr: item.sieltiempodedesplazamientotr.join('; '),
      tipoaccidenteprimerainfa: item.tipoaccidenteprimerainfa.join('; '),
      tipoaccidenteinfancia: item.tipoaccidenteinfancia.join('; '),
      tipoaccidenteadolescenci: item.tipoaccidenteadolescenci.join('; '),
      tipoaccidentejuventud: item.tipoaccidentejuventud.join('; '),
      tipoaccidenteadultez: item.tipoaccidenteadultez.join('; '),
      tipoaccidentepersonamayo: item.tipoaccidentepersonamayo.join('; '),
      seidentificanalgunosdelos: item.seidentificanalgunosdelos.join('; '),
      cualeslaprincipalfuentede: item.cualeslaprincipalfuentede.join('; '),
      secuentacontanquedealmace: item.secuentacontanquedealmace.join('; '),
      serealizaalgunodelossigui: item.serealizaalgunodelossigui.join('; '),
      cualeslafrecuenciadelavad: item.cualeslafrecuenciadelavad.join('; '),
      quesistemadedisposiciondeex: item.quesistemadedisposiciondeex.join('; '),
      quesistemadedisposiciondeag: item.quesistemadedisposiciondeag.join('; '),
      losresiduossolidosdelaviv: item.losresiduossolidosdelaviv.join('; '),
      comoserealizaladisposicioso: item.comoserealizaladisposicioso.join('; '),
      comoserealizaladisposiciope: item.comoserealizaladisposiciope.join('; '),
      quepracticasdereduccionde: item.quepracticasdereduccionde.join('; '),
      quepracticasdeaprovechami: item.quepracticasdeaprovechami.join('; '),
      comoserealizalalimpiezadepi: item.comoserealizalalimpiezadepi.join('; '),
      comoserealizalalimpiezadepa: item.comoserealizalalimpiezadepa.join('; '),
      comoserealizalalimpiezadesu: item.comoserealizalalimpiezadesu.join('; '),
      secompartenimplementosdehpi: item.secompartenimplementosdehpi.join('; '),
      secompartenimplementosdehpa: item.secompartenimplementosdehpa.join('; '),
      secompartenimplementosdehte: item.secompartenimplementosdehte.join('; '),
      secompartenimplementosdehta: item.secompartenimplementosdehta.join('; '),
      secompartenimplementosdehco: item.secompartenimplementosdehco.join('; '),
      secompartenimplementosdehsa: item.secompartenimplementosdehsa.join('; '),
      secompartenimplementosdehcob: item.secompartenimplementosdehcob.join('; '),
      secompartenimplementosdehba: item.secompartenimplementosdehba.join('; '),
      secompartenimplementosdehcoc: item.secompartenimplementosdehcoc.join('; '),
      secompartenimplementosdehot: item.secompartenimplementosdehot.join('; '),
      enquesituacionessepractic: item.enquesituacionessepractic.join('; '),
      cualfuentedeenergiaocombu: item.cualfuentedeenergiaocombu.join('; '),
      senalelasfuentesfrecuente: item.senalelasfuentesfrecuente.join('; '),
      cualesdelassiguientesprac: item.cualesdelassiguientesprac.join('; '),
      quetipodeestufaseempleaen: item.quetipodeestufaseempleaen.join('; '),
      quemedidasseimplementancu: item.quemedidasseimplementancu.join('; '),
      cualeslafinalidaddetenere: item.cualeslafinalidaddetenere.join('; '),
      losanimalesdomesticosesta: item.losanimalesdomesticosesta.join('; '),
      cualesdelossiguientesvect: item.cualesdelossiguientesvect.join('; '),
      seobservacercadelaviviend: item.seobservacercadelaviviend.join('; '),
      quemedidasseponenenpracti: item.quemedidasseponenenpracti.join('; '),
      seobservanalgunosdelossig: item.seobservanalgunosdelossig.join('; '),
      quemedidasseponenenpract: item.quemedidasseponenenpract.join('; '),
      dondeseadquierenlosproduc: item.dondeseadquierenlosproduc.join('; '),
      comoserealizaladisposicioen: item.comoserealizaladisposicioen.join('; '),
      comoserealizaladisposiciopel: item.comoserealizaladisposiciopel.join('; '),
    })), {
      header: [
        '_id',
      ],
    });
    XLSX.utils.book_append_sheet(wb, wsPrincipal, 'Formulario_Principal');

    // Hoja para los datos de los miembros de la familia
    const wsMiembros = XLSX.utils.json_to_sheet(resultados.flatMap(item => item.grupospersonas.map(miembro => ({
      _id: item._id, // Agrega el campo _id aquí
      ...miembro,
      antecedentepersonalsm: miembro.antecedentepersonalsm ? miembro.antecedentepersonalsm.join('; ') : '',
      enfermedadactualsm: miembro.enfermedadactualsm ? miembro.enfermedadactualsm.join('; ') : '',
      antecedentefamiliarprimer: miembro.antecedentefamiliarprimer ? miembro.antecedentefamiliarprimer.join('; ') : '',
      sintomasrecientessincausa: miembro.sintomasrecientessincausa ? miembro.sintomasrecientessincausa.join('; ') : '',
    }))), {
      header: [
        '_id',
        'persona',
      ],
    });
    XLSX.utils.book_append_sheet(wb, wsMiembros, 'Miembros_Familia');

    XLSX.writeFile(wb, 'resultados_fichas.xlsx');
  };

  return (
    <div className="container">
      <div className="mb-4">
        <h4 className="text-center">Lista de fichas cargadas</h4>
      </div>
      <div className="mb-4">
        <label className="form-label">Seleccione el Municipio</label>
        <select className="form-select" value={nombremunicipio} onChange={(e) => setNombremunicipio(e.target.value)}>
          <option selected></option>
          {municipiosAsociados.map((municipio, index) => (
            <option key={index} value={municipio}>
              {municipio}
          </option>
          ))}
        </select>
      </div>
      <div className="mb-4">
        <button className="btn btn-primary" onClick={exportToExcel}>Exportar a Excel</button>
      </div>
      {resultados.length === 0 ? (
        <p className="text-danger">Municipio sin información en la base de datos</p>
      ) : (
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Número de ficha</th>
              <th>Nombre municipio</th>
              <th>Apellido Familiar</th>
              <th>Fecha Visita</th>
              <th>ESE</th>
              <th>Observaciones</th>
              <th>Miembros Familia</th>
              <th>Modificar</th>
              {/* Agrega encabezados para otros campos si es necesario */}
            </tr>
          </thead>
          <tbody>
            {resultados.map((item) => (
              <tr key={item._id}>
                <td>{item.numeroficha}</td>
                <td>{item.nombremunicipio}</td>
                <td>{item.apellidofamiliar}</td>
                <td>{item.fechavisita}</td>
                <td>{item.nombreese}</td>
                <td>{item.observesicercadelaviviendahay.join(', ')}</td>
                <td>
                  {item.grupospersonas.map((grupo, index) => (
                    <span key={index}>
                      <Link to={`/persona/${item._id}/${grupo.ndedocumentodeidentidad}`} class="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">
                        {grupo.ndedocumentodeidentidad} - {grupo.nombresyapellidos}
                      </Link>
                      {index < item.grupospersonas.length - 1 && '; '}
                    </span>
                  ))}
                  {/* Agrega el enlace para agregar un nuevo miembro de la familia */}
                  <br></br><Link to={`/agregar-miembro/${item._id}`}> 
                    <button type="button" className="btn btn-outline-primary btn-sm">Agregar Miembro</button>
                  </Link>
                </td>
                <td><Link to={`/datos/${item._id}`} class="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">{item._id}</Link></td>
                {/* Agrega celdas para otros campos si es necesario */}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Fichas;