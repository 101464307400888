import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, signInWithEmailAndPassword } from '../config/firebaseConfig';
import apsImage from '../media/aps.png'; // Importa la imagen

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      // Inicio de sesión exitoso
      console.log('Usuario ha iniciado sesión con éxito');
      navigate('/aps');
    } catch (error) {
      // Manejar errores de inicio de sesión
      //console.error('Error al iniciar sesión:', error.message);
      alert('Error al iniciar sesión: ' + error.message);
    }
  };

  return (
    <div class="mb-3 text-center">
      <img src={apsImage} alt="APS" className="img-fluid img-thumbnail" width="300"/>
      <h2>Ingreso APS</h2>
      <div class="mb-3 form-check">
        <label class="form-label" for="email">Email</label>
        <input
          type="email"
          id="email"
          class="form-control"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div class="mb-3 form-check">
        <label class="form-label" for="password">Contraseña</label>
        <input
          type="password"
          id="password"
          class="form-control"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <button type="submit" class="btn btn-primary" onClick={handleLogin}>Iniciar sesión</button>
    </div>
  );
}

export default Login;