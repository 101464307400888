import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

const Pif = () => {
  const [numeroficha, setNumeroficha] = useState('');
  const [nombremunicipio, setNombremunicipio] = useState('');
  const [resultados, setResultados] = useState([]);

  const buscarDatos = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/formdata?numeroficha=${numeroficha}&nombremunicipio=${nombremunicipio}`);
      const data = response.data;
      setResultados(data);
    } catch (error) {
      console.error('Error al buscar datos:', error);
    }
  }, [numeroficha, nombremunicipio]);

  useEffect(() => {
    buscarDatos();
  }, [buscarDatos]);

  return (
    <div className="container">
      <div className="mb-4">
        <h4 className="text-center">Plan de intervención Familiar</h4>
      </div>
      <div>
        <p>Busque familia a intervenir:</p>
      </div>
      <div className="mb-4">
        <label className="form-label">Digite N° de la ficha:</label>
        <input
          type="text"
          className="form-control"
          value={numeroficha}
          onChange={(e) => setNumeroficha(e.target.value)}
          placeholder="Número de ficha"
        />
      </div>
      <div className="mb-4">
        <label className="form-label">Seleccione el Municipio</label>
        <select className="form-select" value={nombremunicipio} onChange={(e) => setNombremunicipio(e.target.value)}>
          <option selected></option>
          <option value="Albania">Albania</option>
          <option value="Belén de los Andaquíes">Belén de los Andaquíes</option>
          <option value="Cartagena del Chairá">Cartagena del Chairá</option>
          <option value="Curillo">Curillo</option>
          <option value="El Doncello">El Doncello</option>
          <option value="El Paujil">El Paujil</option>
          <option value="Florencia">Florencia</option>
          <option value="La Montañita">La Montañita</option>
          <option value="Morelia">Morelia</option>
          <option value="Puerto Milán">Puerto Milán</option>
          <option value="Puerto Rico">Puerto Rico</option>
          <option value="San José del Fragua">San José del Fragua</option>
          <option value="San Vicente del Caguán">San Vicente del Caguán</option>
          <option value="Solano">Solano</option>
          <option value="Solita">Solita</option>
          <option value="Valparaíso">Valparaíso</option>
        </select>
      </div>
      {resultados.length === 0 ? (
        <p className="text-danger">La ficha y municipio no están registrados en la base de datos</p>
      ) : (
        resultados.map((item) => (
          <div key={item._id}>
            <p>Número de ficha: {item.numeroficha}</p>
            <p>Nombre municipio: {item.nombremunicipio}</p>
            <p>Apellido Familiar: {item.apellidofamiliar}</p>
            <p>Fecha Visita: {item.fechavisita}</p>
            <p>Observaciones: {item.observesicercadelaviviendahay.join(', ')}</p>
            {/* Otros campos a mostrar */}
          </div>
        ))
      )}
    </div>
  );
};

export default Pif;