const Aps = () => {

  return (
    <div className="container">
      <div className="mb-4">
        <h4 className="text-center">Estado general de las caracterizaciones</h4>
      </div>
      <div>
        <iframe title="aps_dashboard" width="1140" height="541.25" src="https://app.powerbi.com/reportEmbed?reportId=0a9fd48b-924e-46ed-9c3f-d02151d5fa63&autoAuth=true&ctid=899789dc-202f-44b4-8472-a6d40f9eb440" frameborder="0" allowFullScreen="true"></iframe>
      </div>
    </div>
  );
};

export default Aps;