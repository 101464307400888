import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

function EditarDatos() {
  const navigate = useNavigate();
  const { _id } = useParams();
  const [dato, setDato] = useState(null); // Inicializado como nulo para manejar la carga inicial
  const [edicion, setEdicion] = useState(false);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/datos?_id=${_id}`)
      .then((response) => {
        setDato(response.data[0]);
      })
      .catch((error) => {
        console.error('Error al cargar el dato:', error);
      });
  }, [_id]);
    
  const handleGuardar = () => {
    //axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/datos?_id=${_id}`, dato)
    axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/datos/${_id}`, dato)
      .then((response) => {
        setDato(response.data);
        setEdicion(false);
        alert('Datos editados');
        navigate('/fichas');
      })
      .catch((error) => {
        console.error('Error al guardar el dato:', error);
      });
  };
  const handleRadioChange = (event) => {
    const { name, value } = event.target;
    setDato((prevDato) => ({
      ...prevDato,
      [name]: value,
    }));
  };
  
  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    if (type === 'checkbox') { // Manejo especial para checkboxes
      if (checked) {
        setDato((prevCampos) => ({
          ...prevCampos,
          [name]: [...prevCampos[name], value], // Actualizamos el array correspondiente
        }));
      } else {
        setDato((prevCampos) => ({
          ...prevCampos,
          [name]: prevCampos[name].filter((item) => item !== value), // Removemos el valor del array correspondiente
        }));
      }
    } else {
      setDato((prevCampos) => ({
        ...prevCampos,
        [name]: value,
      }));

      const currentShowDivotrainstitucion = showDivotrainstitucion;

      // Mostrar u ocultar el div basado en la selección del select
      if (name === 'nombreese' && value === 'OTRA INSTITUCIÓN') {
        setShowDivotrainstitucion(true);
      } else {
        setShowDivotrainstitucion(false);
      }

      // Mostrar u ocultar el div basado en la selección del select
      if (name === 'enelultimoanoalgunaperson' && value === 'Si') {
        setShowDivtipoaccidente(true);
      } else {
        setShowDivtipoaccidente(false);
      }

      // Restaurar el estado actual
      if (currentShowDivotrainstitucion) {
        setShowDivotrainstitucion(true);
      }

    }
  };
  
  const [otrosobs, setOtrosObs] = useState('');
  const [otrosalinteriordelaviviendase, setOtrosAlinteriordelaviviendase] = useState('');
  const [otrosdesdelaviviendasepuedeacc, setOtrosDesdelaviviendasepuedeacc] = useState('');
  const [otroscomosedesplazangeneralmen, setOtrosComosedesplazangeneralmen] = useState('');
  const [otroscualeselementosdesegurida, setOtrosCualeselementosdesegurida] = useState('');
  const [otrosseidentificanalgunosdelos, setOtrosSeidentificanalgunosdelos] = useState('');
  const [otroscualeslaprincipalfuentede, setOtrosCualeslaprincipalfuentede] = useState('');
  const [otrosserealizaalgunodelossigui, setOtrosSerealizaalgunodelossigui] = useState('');
  const [otroscualeslafrecuenciadelavad, setOtrosCualeslafrecuenciadelavad] = useState('');
  const [otrosquesistemadedisposiciondeex, setOtrosQuesistemadedisposiciondeex] = useState('');
  const [otrosquesistemadedisposiciondeag, setOtrosQuesistemadedisposiciondeag] = useState('');
  const [otroslosresiduossolidosdelaviv, setOtrosLosresiduossolidosdelaviv] = useState('');
  const [otroscomoserealizaladisposicioso, setOtrosComoserealizaladisposicioso] = useState('');
  const [otroscomoserealizaladisposiciope, setOtrosComoserealizaladisposiciope] = useState('');
  const [otrosquepracticasdereduccionde, setOtrosQuepracticasdereduccionde] = useState('');
  const [otrosquepracticasdeaprovechami, setOtrosQuepracticasdeaprovechami] = useState('');
  const [otroscomoserealizalalimpiezadepi, setOtrosComoserealizalalimpiezadepi] = useState('');
  const [otroscomoserealizalalimpiezadepa, setOtrosComoserealizalalimpiezadepa] = useState('');
  const [otroscomoserealizalalimpiezadesu, setOtrosComoserealizalalimpiezadesu] = useState('');
  const [otroscualfuentedeenergiaocombu, setOtrosCualfuentedeenergiaocombu] = useState('');
  const [otrossenalelasfuentesfrecuente, setOtrosSenalelasfuentesfrecuente] = useState('');
  const [otrosquemedidasseimplementancu, setOtrosQuemedidasseimplementancu] = useState('');
  const [otroscualesdelossiguientesvect, setOtrosCualesdelossiguientesvect] = useState('');
  const [otrosquemedidasseponenenpracti, setOtrosQuemedidasseponenenpracti] = useState('');
  const [otrosseobservanalgunosdelossig, setOtrosSeobservanalgunosdelossig] = useState('');
  const [otrosquemedidasseponenenpract, setOtrosQuemedidasseponenenpract] = useState('');
  const [otroscomoserealizaladisposicioen, setOtrosComoserealizaladisposicioen] = useState('');
  const [otroscomoserealizaladisposiciopel, setOtrosComoserealizaladisposiciopel] = useState('');
  const [showDivtipoaccidente, setShowDivtipoaccidente] = useState(false); // Estado para controlar la visibilidad del div
  const [showDivotrainstitucion, setShowDivotrainstitucion] = useState(false); // Estado para controlar la visibilidad del div

  const handleInputChangeAdd = (event, setter) => {
    const { value } = event.target;
    setter(value);
  };

  const handleInputBlur = (setter, arrayName) => {
    if (setter && !dato[arrayName].includes(setter)) {
      setDato((prevCampos) => ({
        ...prevCampos,
        [arrayName]: [...prevCampos[arrayName], setter],
      }));
    }
  };

  const otrosobsarr = [
    "Cultivos",
    "Apriscos",
    "Porquerizas",
    "Galpones",
    "Terrenos baldíos",
    "Presencia de Plagas: roedores, cucarachas, zancudos, moscas, etc",
    "Industrias Contaminantes",
    "Malos olores",
    "Sitios satélites de disposición de excretas",
    "Rellenos sanitarios/botaderos",
    "Ruido o sonidos desagradables",
    "Contaminación visual",
    "Río o quebrada",
    "Planta de tratamiento de agua residual",
    "Extracción minera",
    "Canales de agua lluvia",
    "Vías de tráfico vehícular",
  ];
  const otrosalinteriordelaviviendasearr = [
    "No"
  ]
  const otrosdesdelaviviendasepuedeaccarr= [
    "Medios de transporte (Buses, autos, camiones, lanchas, et)",
    "Centros sociales, culturales y/o recreacionales",
    "Parques, y áreas deportivas",
    "Iglesias, templos, espacios para cultos religiosos",
    "Instituciones educativas",
    "Servicios de salud",
  ]

  const otroscomosedesplazangeneralmenarr = [
    "Vehículo particular",
    "Servicio público de transporte masivo (Articulados, metro, servicios de alimentación)",
    "Servicio público - Bus, buseta, colectivo",
    "Servicio público - Taxi",
    "Motocicleta",
    "Bicicleta",
    "Bicitaxi",
    "Semovientes",
    "Maquinaria agrícola o camión",
    "Tarabita, cable aereo artesanal",
    "Canoa, lancha, chalupa, piragua",
    "Caminando",
  ]

  const otroscualeselementosdeseguridaarr = [
    "Casco",
    "Cinturon de seguridad",
    "Sistema de Retención Infantil",
    "Chaleco reflectivo",
    "Chaleco Salva Vidas",
    "Ninguno",
  ]

  const otrosseidentificanalgunosdelosarr = [
    "Objetos cortantes o punzantes al alcance de los niños",
    "Sustancias químicas al alcance de los niños",
    "Medicamentos al alcance de los niños",
    "Velas, velones, incienso encendidos en la vivienda",
    "Conexiones eléctricas en mal estado o sobrecargadas",
    "Botones, canicas entre otros objetos pequeños o con piezas que puedan desmontarse, al alcance de los niños",
    "Pasillos obstruidos con juguetes, sillas u otros objetos",
    "Superficies resbaladizas, suelos con agua, grasas, aceites, entre otros",
    "Tanques o recipientes de almacenamiento de agua sin tapa",
    "Escaleras sin protección",
  ]
  
  const otroscualeslaprincipalfuentedearr = [
    "Acueducto administrado por empresa prestadora (ESP)",
    "Agua embotellada o en bolsa",
    "Acueducto veredal o comunitario",
    "Pila pública",
    "Carro tanque",
    "Abasto con distribución comunitaria",
    "Pozo con bomba",
    "Pozo sin bomba, aljibe, jagüey o barreno",
    "Laguna o jagüey",
    "Río, quebrada, manantial o nacimiento",
    "Aguas lluvias",
    "Aguatero",
  ]

  const otrosserealizaalgunodelossiguiarr = [
    "Asentamiento de solidos suspendidos o clarificación",
    "Filtración",
    "Desinfección con cloro o con otras sustancias",
    "Hervido",
    "Ninguno",
  ]

  const otroscualeslafrecuenciadelavadarr = [
    "Despues de cada uso",
    "Semestral",
    "No realiza lavado",
  ]

  const otrosquesistemadedisposiciondeexarr = [
    "Sanitario conectado al alcantarillado",
    "Sanitario y letrina",
    "Sanitario conectado a pozo séptico",
    "Sanitario ecológico seco",
    "Sanitario sin conexión",
    "Sanitario con disposición a fuente hídrica",
    "Campo abierto",
  ]

  const otrosquesistemadedisposiciondeagarr = [
    "Pozo séptico",
    "Campo de oxidación",
    "Biofiltro",
    "Fuente hídrica",
    "Campo abierto",
  ]

  const otroslosresiduossolidosdelavivarr = [
    "Recipientes con tapa",
    "Recipientes sin tapa",
    "Directamente al suelo",
    "Bolsas plásticas",
  ]

  const otroscomoserealizaladisposiciosoarr = [
    "Recolección por parte del servicio de aseo distrital o municipal",
    "Enterramiento",
    "Quema a campo abierto",
    "Disposición en fuentes de agua cercana",
    "Disposición a campo abierto",
  ]

  const otroscomoserealizaladisposiciopearr = [
    "Recolectados por empresas especializadas",
    "Sitios autorizados para su recolección",
    "Centros de acopio",
    "Enterramiento",
    "Quema a campo abierto",
    "Disposición en fuentes de agua cercana",
    "Disposición a campo abierto",
    "En conjunto con los residuos ordinarios de la vivienda",
  ]

  const otrosquepracticasdereducciondearr = [
    "Se evita el uso de productos de un solo uso como botellas, vajillas y cubiertos desechables, entre otros",
    "Se reutilizan materiales que hayan cumplido su primer ciclo, como el papel y el cartón",
    "Eliminación o reutilización de aparatos electricos a través de las empresas especializadas, como computadores, cartuchos de impresoras, enttre otros",
    "Se evita el uso de ambientadores o artificiales o aerosoles",
    "Se compra a grnael usando los propios envases o maletas para empacar",
    "Ninguna",
  ]

  const otrosquepracticasdeaprovechamiarr = [
    "Compostaje",
    "Lombricultivo",
    "Biocombustible",
    "Biofertilizante",
    "Ninguna",
  ]

  const otroscomoserealizalalimpiezadepiarr = [
    "Barrido",
    "Trapeado",
    "Barrido y trapeado",
    "Barrido, trapeado y desinfección",
  ]

  const otroscomoserealizalalimpiezadepaarr = [
    "Barrido",
    "Trapeado",
    "Barrido y trapeado",
    "Barrido, trapeado y desinfección",
  ]

  const otroscomoserealizalalimpiezadesuarr = [
    "Retiro de polvo",
    "Retiro de polvo y limpieza con agua y jabón",
    "Retiro de polvo, limpieza con agua y jabón y desinfección",
  ]

  const otroscualfuentedeenergiaocombuarr = [
    "Electricidad",
    "Gas natural",
    "Gas Liquado del petroleo (gas propano)",
    "Leña, madera o carbón de leña",
    "Petróleo, gasolina, kerosén, alcohol",
    "Carbón mineral",
    "Materiales de desecho",
  ]

  const otrossenalelasfuentesfrecuentearr = [
    "Cigarrillo o tabaco",
    "Leña",
    "Carbón",
    "Quema de basura",
    "No Aplica",
  ]

  const otrosquemedidasseimplementancuarr = [
    "Uso de tapabocas",
    "Lavado frecuente de manos",
    "Cubrir rostro al toser y estornudar",
    "Ninguno",
  ]

  const otroscualesdelossiguientesvectarr = [
    "Zancudos",
    "Cucarachas",
    "Moscas",
    "Mosquitos",
    "Pulgas",
    "Piojos",
    "Garrapatas",
    "Roedores",
    "Chinches",
    "Triatominos (Pito)",
    "Ninguno",
  ]

  const otrosquemedidasseponenenpractiarr = [
    "Manejo higiénico de los residuos sólidos al interior de la vivienda",
    "Evitar el uso de fuentes de luz blanca o brillante en ambientes oscuros dentro de la vivienda",
    "Manejo higiénico de los residuos sólidos en el entorno de la vivienda",
    "Recolección de inservibles",
    "Uso de toldillos",
    "Uso de angeos y trampas caseras",
    "Ordenamiento e higiene",
    "Limpieza de malezas",
    "Drenaje de zonas encharcadas",
    "Protección de depósitos de agua de consumo mientras no estén en uso",
    "Retiro de recipientes que usualmente contienen o acumulan agua",
    "Reemplazo de techos en paja por techo de zinc",
    "Reparaciones para eliminar sitios húmedos o pocos iluminados",
    "Uso de pantalones y camisas manga larga de colores claro",
  ]

  const otrosseobservanalgunosdelossigarr = [
    "Arañas",
    "Escorpiones o Alacranes",
    "Serpientes o víboras",
    "Abejas",
    "Avispas",
    "Orugas",
    "Ninguno",
  ]

  const otrosquemedidasseponenenpractarr = [
    "Sacudir la ropa y los zapatos antes de colocarselos",
    "No meter las manos en huecos de los árboles",
    "Tener precaución al levantar piedras o rocas para no entrar en contacto con animales venenosos o ponzoñosos",
    "Orden e higiene en las viviendas y el peridomicilio",
    "No caminar junto al corte del monte en los caminos de herradura",
    "No caminar descalzo en el campo, usar botas de caña alta",
    "Evitar que los niños y niñas jueguen en zonas boscosas",
    "Transitar si es posible en compañía de perros",
    "Evitar deambular de noche",
    "No molestar panales de abejas o avispas",
  ]

  const otroscomoserealizaladisposicioenarr = [
    "Se separan de los residuos ordinarios en la vivienda para posterior aprovechamiento",
    "En conjunto con los residuos ordinarios de la vivienda",
    "Quema a campo abierto",
    "Disposición en fuentes de agua cercana",
    "Disposición a campo abierto",
    "Enterramiento",
    "Se utilizan para contener bebidad, alimentos y agua y para otros usos en el hogar",
  ]

  const otroscomoserealizaladisposiciopelarr = [
    "Recolectados por empresas especializadas",
    "Sitios autorizados para su recolección",
    "Centros de acopio",
    "Enterramiento",
    "Quema a campo abierto",
    "Disposición en fuentes de agua cercana",
    "Disposición a campo abierto",
    "En conjunto con los residuos ordinarios de la vivienda",
    "Se utilizan para contener bebidad, alimentos y agua y para otros usos en el hogar",
  ]

  return (
    <div class="container">
      {dato ? (
        edicion ? (
          <div>
            <h5>Editar Ficha: {dato.numeroficha} - {dato.nombremunicipio} - {dato.nombreese}</h5>
            <form>
              <div class="m-2 mt-4 border border-primary-subtle"><h4 class="text"> 1. DATOS VIVIENDA</h4>
                <div class="row">
                  <div class="col-12 col-md-6 col-lg-4"> 
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <div>
                        <label class="form-label">Plan Interveciones</label>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="radio"
                              class="form-check-input"
                              name="planintervenciones"
                              value="Municipal"
                              checked={dato.planintervenciones === 'Municipal'}
                              onChange={handleRadioChange}
                            />
                            Municipal
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="radio"
                              class="form-check-input"
                              name="planintervenciones"
                              value="Departamental"
                              checked={dato.planintervenciones === 'Departamental'}
                              onChange={handleRadioChange}
                            />
                          Departamental
                          </label>
                        </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="radio"
                        class="form-check-input"
                        name="planintervenciones"
                        value="APS Recursos Ministerio"
                        checked={dato.planintervenciones === 'APS Recursos Ministerio'}
                        onChange={handleRadioChange}
                      />
                    APS Recursos Ministerio
                    </label>
                  </div>
                        {!dato.planintervenciones && <div className="text-danger">Campo obligatorio</div>}
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">  
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">N° de la ficha:</label>
                      <input
                        type="text"
                        name='numeroficha'
                        className={`form-control ${!dato.numeroficha ? 'is-invalid' : ''}`}
                        value={dato.numeroficha}
                        onChange={handleInputChange}
                      />
                      {!dato.numeroficha && <div className="invalid-feedback">Campo obligatorio</div>}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4"> 
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Nombre de la ESE:</label>
                      <select className={`form-select ${!dato.nombreese ? 'is-invalid' : ''}`} name='nombreese' value={dato.nombreese} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="ESE HOSPITAL SAN RAFAEL">ESE HOSPITAL SAN RAFAEL</option>
                        <option value="ESE RAFAEL TOVAR POVEDA">ESE RAFAEL TOVAR POVEDA</option>
                        <option value="ESE HOSPITAL DEPARTAMENTAL MARIA INMACULADA">ESE HOSPITAL DEPARTAMENTAL MARIA INMACULADA</option>
                        <option value="ESE SOR TERESA ADELE">ESE SOR TERESA ADELE</option>
                        <option value="ESE FABIO JARAMILLO LONDOÑO">ESE FABIO JARAMILLO LONDOÑO</option>                   
                        <option value="ESE HOSPITAL MALVINAS">ESE HOSPITAL MALVINAS</option> 
                        <option value="OTRA INSTITUCIÓN">OTRA INSTITUCIÓN</option>
                      </select>
                      {!dato.nombreese && <div className="invalid-feedback">Campo obligatorio</div>}
                    </div>
                  </div>
                  {showDivotrainstitucion && (
                  <div class="col-12 col-md-6 col-lg-4"> 
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Nombre de la OTRA IPS o Fundación:</label>
                      <input
                        type="text"
                        name='otrainstitucion'
                        className={`form-control`}
                        value={dato.otrainstitucion}
                        onChange={handleInputChange}
                      />
                      {/* {!fichaFamiliar.otrainstitucion && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  )}
                  <div class="col-12 col-md-6 col-lg-4"> 
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Fecha de la visita:</label>
                      <input
                        type="date"
                        name='fechavisita'
                        className={`form-control ${!dato.fechavisita ? 'is-invalid' : ''}`}
                        //value={dato.fechavisita}
                        value={dato.fechavisita ? dato.fechavisita.split('T')[0] : ''}
                        onChange={handleInputChange}
                      />
                      {!dato.fechavisita && <div className="invalid-feedback">Campo obligatorio</div>}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4"> 
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Hora de inicio:</label>
                      <input
                        type="time"
                        name='horainicio'
                        className={`form-control ${!dato.horainicio ? 'is-invalid' : ''}`}
                        value={dato.horainicio}
                        onChange={handleInputChange}
                      />
                      {!dato.horainicio && <div className="invalid-feedback">Campo obligatorio</div>}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4"> 
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Apellido Familiar:</label>
                      <input
                        type="text"
                        name='apellidofamiliar'
                        className={`form-control ${!dato.apellidofamiliar ? 'is-invalid' : ''}`}
                        value={dato.apellidofamiliar}
                        onChange={handleInputChange}
                      />
                      {!dato.apellidofamiliar && <div className="invalid-feedback">Campo obligatorio</div>}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4"> 
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Nombre y apellido de la Persona que Atiende la Visita:</label>
                      <input
                        type="text"
                        name='nombreyapellidoatiende'
                        className={`form-control ${!dato.nombreyapellidoatiende ? 'is-invalid' : ''}`}
                        value={dato.nombreyapellidoatiende}
                        onChange={handleInputChange}
                      />
                      {!dato.nombreyapellidoatiende && <div className="invalid-feedback">Campo obligatorio</div>}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4"> 
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <div>
                        <label class="form-label">Tipo Documento</label>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="radio"
                              class="form-check-input"
                              name="tipodocumento"
                              value="RC"
                              checked={dato.tipodocumento === 'RC'}
                              onChange={handleRadioChange}
                            />
                            1. RC
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="radio"
                              class="form-check-input"
                              name="tipodocumento"
                              value="TI"
                              checked={dato.tipodocumento === 'TI'}
                              onChange={handleRadioChange}
                            />
                          2. TI
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="radio"
                              class="form-check-input"
                              name="tipodocumento"
                              value="CC"
                              checked={dato.tipodocumento === 'CC'}
                              onChange={handleRadioChange}
                            />
                          3. CC
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="radio"
                              class="form-check-input"
                              name="tipodocumento"
                              value="ASI"
                              checked={dato.tipodocumento === 'ASI'}
                              onChange={handleRadioChange}
                            />
                          4. ASI
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="radio"
                              class="form-check-input"
                              name="tipodocumento"
                              value="MSI"
                              checked={dato.tipodocumento === 'MSI'}
                              onChange={handleRadioChange}
                            />
                          5. MSI
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="radio"
                              class="form-check-input"
                              name="tipodocumento"
                              value="CE"
                              checked={dato.tipodocumento === 'CE'}
                              onChange={handleRadioChange}
                            />
                          6. CE
                          </label>
                        </div>
                        {!dato.tipodocumento && <div className="text-danger">Campo obligatorio</div>}
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4"> 
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Número identificación:</label>
                      <input
                        type="number"
                        name='numerodocumentoatiende'
                        className={`form-control ${!dato.numerodocumentoatiende ? 'is-invalid' : ''}`}
                        value={dato.numerodocumentoatiende}
                        onChange={handleInputChange}
                      />
                      {!dato.numerodocumentoatiende && <div className="invalid-feedback">Campo obligatorio</div>}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4"> 
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Nombre del Municipio</label>
                      <select className={`form-select ${!dato.nombremunicipio ? 'is-invalid' : ''}`} name='nombremunicipio' value={dato.nombremunicipio} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Albania">Albania</option>
                        <option value="Belén de los Andaquíes">Belén de los Andaquíes</option>
                        <option value="Cartagena del Chairá">Cartagena del Chairá</option>
                        <option value="Curillo">Curillo</option>
                        <option value="El Doncello">El Doncello</option>
                        <option value="El Paujil">El Paujil</option>
                        <option value="Florencia">Florencia</option>
                        <option value="La Montañita">La Montañita</option>
                        <option value="Morelia">Morelia</option>
                        <option value="Milán">Milán</option>
                        <option value="Puerto Rico">Puerto Rico</option>
                        <option value="San José del Fragua">San José del Fragua</option>
                        <option value="San Vicente del Caguán">San Vicente del Caguán</option>
                        <option value="Solano">Solano</option>
                        <option value="Solita">Solita</option>
                        <option value="Valparaíso">Valparaíso</option>
                      </select>
                      {!dato.nombremunicipio && <div className="invalid-feedback">Campo obligatorio</div>}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4"> 
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Nombre del territorio donde hace presencia el equipo</label>
                      <input
                        type="text"
                        name='nombredelterritoriodondeh'
                        className={`form-control ${!dato.nombredelterritoriodondeh ? 'is-invalid' : ''}`}
                        value={dato.nombredelterritoriodondeh}
                        onChange={handleInputChange}
                      />
                      {!dato.nombredelterritoriodondeh && <div className="invalid-feedback">Campo obligatorio</div>}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4"> 
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Nombre del microterritorio donde hace presencia el equipo</label>
                      <input
                        type="text"
                        name='nombredelmicroterritoriod'
                        className={`form-control ${!dato.nombredelmicroterritoriod ? 'is-invalid' : ''}`}
                        value={dato.nombredelmicroterritoriod}
                        onChange={handleInputChange}
                      />
                      {!dato.nombredelmicroterritoriod && <div className="invalid-feedback">Campo obligatorio</div>}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4"> 
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Dirección de la vivienda:</label>
                      <input
                        type="text"
                        name='direccionvivienda'
                        className={`form-control ${!dato.direccionvivienda ? 'is-invalid' : ''}`}
                        value={dato.direccionvivienda}
                        onChange={handleInputChange}
                      />
                      {!dato.direccionvivienda && <div className="invalid-feedback">Campo obligatorio</div>}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4"> 
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <div>
                        <label class="form-label">Zona</label>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="radio"
                              class="form-check-input"
                              name="zona"
                              value="Urbano"
                              checked={dato.zona === 'Urbano'}
                              onChange={handleRadioChange}
                            />
                            Urbano
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="radio"
                              class="form-check-input"
                              name="zona"
                              value="Rural"
                              checked={dato.zona === 'Rural'}
                              onChange={handleRadioChange}
                            />
                          Rural
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="radio"
                              class="form-check-input"
                              name="zona"
                              value="Rural Disperso"
                              checked={dato.zona === 'Rural Disperso'}
                              onChange={handleRadioChange}
                            />
                          Rural Disperso
                          </label>
                        </div>
                        {!dato.zona && <div className="text-danger">Campo obligatorio</div>}
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4"> 
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Zona geográfica</label>
                      <select className={`form-select ${!dato.zonageografica ? 'is-invalid' : ''}`} name='zonageografica' value={dato.zonageografica} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Corregimiento">Corregimiento</option>
                        <option value="vereda">vereda</option>
                        <option value="Inspección">Inspección</option>
                        <option value="caserio">caserio</option>
                        <option value="Resguardo Indigena">Resguardo Indigena</option>
                        <option value="Territorio Colectivo de comunidades Negras.">Territorio Colectivo de comunidades Negras.</option>
                        <option value="Comuna">Florencia</option>
                        <option value="barrio">barrio</option>
                      </select>
                      {!dato.zonageografica && <div className="invalid-feedback">Campo obligatorio</div>}
                    </div>
                  </div>
                </div>
              </div>
              <div class="m-2 mt-4 border border-primary-subtle"><h4 class="text"> 1.2 DATOS VIVIENDA</h4>
                <div class="row">
                  <div class="col-12 col-md-6 col-lg-4"> 
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Tipo de Vivienda</label>
                      <select className={`form-select ${!dato.tipodevivienda ? 'is-invalid' : ''}`} name='tipodevivienda' value={dato.tipodevivienda} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Casa">Casa</option>
                        <option value="Casa Indígena">Casa Indígena</option>
                        <option value="Apartamento">Apartamento</option>
                        <option value="Cuarto de inquilinato">Cuarto de inquilinato</option>
                        <option value="Tipocuarto">Tipocuarto</option>
                        <option value="Otro tipo de vivienda (contenedor, carpa, embarcación, vagón, refugio natural, cueva, puente, etc .)">Otro tipo de vivienda (contenedor, carpa, embarcación, vagón, refugio natural, cueva, puente, etc .)</option>
                      </select>
                      {!dato.tipodevivienda && <div className="invalid-feedback">Campo obligatorio</div>}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4"> 
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Teléfono/Celular:</label>
                      <input
                        type="number"
                        name='telefonocelular'
                        className={`form-control ${!dato.telefonocelular ? 'is-invalid' : ''}`}
                        value={dato.telefonocelular}
                        onChange={handleInputChange}
                      />
                      {!dato.telefonocelular && <div className="invalid-feedback">Campo obligatorio</div>}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4"> 
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">¿A qué estrato pertenece esta vivienda? (Solicite recibo deservicio público, si lo tiene):</label>
                      <input
                        type="number"
                        name='estratovivienda'
                        className={`form-control ${!dato.estratovivienda ? 'is-invalid' : ''}`}
                        value={dato.estratovivienda}
                        onChange={handleInputChange}
                      />
                      {!dato.estratovivienda && <div className="invalid-feedback">Campo obligatorio</div>}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4"> 
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">N° de integrantes de la familia:</label>
                      <input
                        type="number"
                        name='numerointegrantesfamilia'
                        className={`form-control ${!dato.numerointegrantesfamilia ? 'is-invalid' : ''}`}
                        value={dato.numerointegrantesfamilia}
                        onChange={handleInputChange}
                      />
                      {!dato.numerointegrantesfamilia && <div className="invalid-feedback">Campo obligatorio</div>}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4"> 
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Familia</label>
                      <select className={`form-select ${!dato.familia ? 'is-invalid' : ''}`} name='familia' value={dato.familia} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Nueva">Nueva</option>
                        <option value="Actualizada">Actualizada</option>
                        <option value="No ubicada">No ubicada</option>
                        <option value="Cerrada">Cerrada</option>
                      </select>
                      {!dato.familia && <div className="invalid-feedback">Campo obligatorio</div>}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4"> 
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Estado de Registro</label>
                      <select className={`form-select ${!dato.estadoregistro ? 'is-invalid' : ''}`} name='estadoregistro' value={dato.estadoregistro} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Completa">Completa</option>
                        <option value="En proceso">En proceso</option>
                      </select>
                      {!dato.estadoregistro && <div className="invalid-feedback">Campo obligatorio</div>}
                    </div>
                  </div>
                </div>
              </div>
              <div class="m-2 mt-4 border border-primary-subtle"><h4 class="text"> 2.1. HABITABILIDAD</h4>       
                <div class="row">
                  <div class="col-12 col-md-6 col-lg-4"> 
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <div>
                        <label class="form-label">Observe si cerca de la vivienda hay: (puede señalar varias opciones)</label>
                        {dato.observesicercadelaviviendahay.map((valor, index) => (
                          !otrosobsarr.includes(valor) && (
                            <div class="form-check" key={index}>
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="observesicercadelaviviendahay"
                                  value={valor}
                                  checked={dato.observesicercadelaviviendahay.includes(valor)}
                                  onChange={handleInputChange}
                                />
                                {valor}
                              </label>
                            </div>
                          )
                        ))}
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="observesicercadelaviviendahay"
                              value="Cultivos"
                              checked={dato.observesicercadelaviviendahay.includes('Cultivos')}
                              onChange={handleInputChange}
                            />
                            Cultivos
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="observesicercadelaviviendahay"
                              value="Apriscos"
                              checked={dato.observesicercadelaviviendahay.includes('Apriscos')}
                              onChange={handleInputChange}
                            />
                          Apriscos
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="observesicercadelaviviendahay"
                              value="Porquerizas"
                              checked={dato.observesicercadelaviviendahay.includes('Porquerizas')}
                              onChange={handleInputChange}
                            />
                          Porquerizas
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="observesicercadelaviviendahay"
                              value="Galpones"
                              checked={dato.observesicercadelaviviendahay.includes('Galpones')}
                              onChange={handleInputChange}
                            />
                          Galpones
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="observesicercadelaviviendahay"
                              value="Terrenos baldíos"
                              checked={dato.observesicercadelaviviendahay.includes('Terrenos baldíos')}
                              onChange={handleInputChange}
                            />
                          Terrenos baldíos
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="observesicercadelaviviendahay"
                              value="Presencia de Plagas: roedores, cucarachas, zancudos, moscas, etc"
                              checked={dato.observesicercadelaviviendahay.includes('Presencia de Plagas: roedores, cucarachas, zancudos, moscas, etc')}
                              onChange={handleInputChange}
                            />
                          Presencia de Plagas: roedores, cucarachas, zancudos, moscas, etc
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="observesicercadelaviviendahay"
                              value="Industrias Contaminantes"
                              checked={dato.observesicercadelaviviendahay.includes('Industrias Contaminantes')}
                              onChange={handleInputChange}
                            />
                          Industrias Contaminantes
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="observesicercadelaviviendahay"
                              value="Malos olores"
                              checked={dato.observesicercadelaviviendahay.includes('Malos olores')}
                              onChange={handleInputChange}
                            />
                          Malos olores
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="observesicercadelaviviendahay"
                              value="Sitios satélites de disposición de excretas"
                              checked={dato.observesicercadelaviviendahay.includes('Sitios satélites de disposición de excretas')}
                              onChange={handleInputChange}
                            />
                          Sitios satélites de disposición de excretas
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="observesicercadelaviviendahay"
                              value="Rellenos sanitarios/botaderos"
                              checked={dato.observesicercadelaviviendahay.includes('Rellenos sanitarios/botaderos')}
                              onChange={handleInputChange}
                            />
                          Rellenos sanitarios/botaderos
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="observesicercadelaviviendahay"
                              value="Ruido o sonidos desagradables"
                              checked={dato.observesicercadelaviviendahay.includes('Ruido o sonidos desagradables')}
                              onChange={handleInputChange}
                            />
                          Ruido o sonidos desagradables
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="observesicercadelaviviendahay"
                              value="Contaminación visual"
                              checked={dato.observesicercadelaviviendahay.includes('Contaminación visual')}
                              onChange={handleInputChange}
                            />
                          Contaminación visual
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="observesicercadelaviviendahay"
                              value="Río o quebrada"
                              checked={dato.observesicercadelaviviendahay.includes('Río o quebrada')}
                              onChange={handleInputChange}
                            />
                          Río o quebrada
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="observesicercadelaviviendahay"
                              value="Planta de tratamiento de agua residual"
                              checked={dato.observesicercadelaviviendahay.includes('Planta de tratamiento de agua residual')}
                              onChange={handleInputChange}
                            />
                          Planta de tratamiento de agua residual
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="observesicercadelaviviendahay"
                              value="Extracción minera"
                              checked={dato.observesicercadelaviviendahay.includes('Extracción minera')}
                              onChange={handleInputChange}
                            />
                          Extracción minera
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="observesicercadelaviviendahay"
                              value="Canales de agua lluvia"
                              checked={dato.observesicercadelaviviendahay.includes('Canales de agua lluvia')}
                              onChange={handleInputChange}
                            />
                          Canales de agua lluvia
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="observesicercadelaviviendahay"
                              value="Vías de tráfico vehícular"
                              checked={dato.observesicercadelaviviendahay.includes('Vías de tráfico vehícular')}
                              onChange={handleInputChange}
                            />
                          Vías de tráfico vehícular
                          </label>
                        </div>
                        <div class="input-group">
                          <div class="input-group-text">
                              <input
                                type="checkbox"
                                class="form-check-input mt-0"
                                name="observesicercadelaviviendahay"
                                value={otrosobs}
                                checked={dato.observesicercadelaviviendahay.includes(otrosobs)}
                                onChange={handleInputChange}
                                //onChange={(e) => dato.observesicercadelaviviendahay(e.target.checked)}
                              />
                            </div>
                            <input type="text" class="form-control" aria-label="Text input with checkbox" 
                              placeholder="Si aplica, digite aqui otros"
                              name="otrosobs"
                              value={otrosobs}
                              onChange={(event) => handleInputChangeAdd(event, setOtrosObs)}
                              onBlur={() => handleInputBlur(otrosobs, 'observesicercadelaviviendahay')}
                              //onChange={handleInputChange}
                              //onBlur={handleInputBlur}
                              />
                        </div>
                        {!dato.observesicercadelaviviendahay.length && <div className="text-danger">Campo obligatorio</div>}
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4"> 
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">¿Cuál es el material predominante del piso de la vivienda? (señale una sola opción)</label>
                      <select className={`form-select ${!dato.cualeselmaterialpredominapiso ? 'is-invalid' : ''}`} name='cualeselmaterialpredominapiso' value={dato.cualeselmaterialpredominapiso} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Tierra, arena, barro">Tierra, arena, barro</option>
                        <option value="Cemento, gravilla">Cemento, gravilla</option>
                        <option value="Madera burda, tabla, tablón, otro vegetal">Madera burda, tabla, tablón, otro vegetal</option>
                        <option value="Liso e impermeable (baldosa, ladrillo, tableta, granito, mármol)">Liso e impermeable (baldosa, ladrillo, tableta, granito, mármol)</option>
                        <option value="Madera pulida o lacada, parqué, laminado">Madera pulida o lacada, parqué, laminado</option>
                        <option value="Alfombra o tapete de pared a pared">Alfombra o tapete de pared a pared</option>
                        <option value="Material plástico (vinilo, otro material sintético)">Material plástico (vinilo, otro material sintético)</option>
                        <option value="Lámina">Lámina</option>
                        <option value="Otro">Otro</option>
                      </select>
                      {!dato.cualeselmaterialpredominapiso && <div className="invalid-feedback">Campo obligatorio</div>}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4"> 
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">¿Cuál es el material predominante de las paredes? (señale una sola opción)</label>
                      <select className={`form-select ${!dato.cualeselmaterialpredominaparedes ? 'is-invalid' : ''}`} name='cualeselmaterialpredominaparedes' value={dato.cualeselmaterialpredominaparedes} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Impermeable (cemento, bloque, ladrillo, piedra, concreto vaciado) ">Impermeable (cemento, bloque, ladrillo, piedra, concreto vaciado) </option>
                        <option value="Bahareque/Barro, tapia pisada, esterilla, caña, otro tipo de material vegetal">Bahareque/Barro, tapia pisada, esterilla, caña, otro tipo de material vegetal</option>
                        <option value="Madera pulida, Madera burda (tabla, tablón), Guadua">Madera pulida, Madera burda (tabla, tablón), Guadua</option>
                        <option value="Tapia pisada, bahareque, adobe">Tapia pisada, bahareque, adobe</option>
                        <option value="Materiales de desecho (zinc, tela, cartón, latas, plásticos, otros)">Materiales de desecho (zinc, tela, cartón, latas, plásticos, otros)</option>
                        <option value="No tiene">No tiene</option>
                        <option value="Otro">Otro</option>
                      </select>
                      {!dato.cualeselmaterialpredominaparedes && <div className="invalid-feedback">Campo obligatorio</div>}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4"> 
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">¿Cuál es el material predominante del techo? (señale una sola opción)</label>
                      <select className={`form-select ${!dato.cualeselmaterialpredominatecho ? 'is-invalid' : ''}`} name='cualeselmaterialpredominatecho' value={dato.cualeselmaterialpredominatecho} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Concreto">Concreto</option>
                        <option value="Tejas de barro">Tejas de barro</option>
                        <option value="Fibrocemento">Fibrocemento</option>
                        <option value="Zinc">Zinc</option>
                        <option value="Palma o paja">Palma o paja</option>
                        <option value="Plástico">Plástico</option>
                        <option value="Desechos (cartón, lata, tela, sacos, etc)">Desechos (cartón, lata, tela, sacos, etc)</option>
                        <option value="Otros">Otros</option>
                      </select>
                      {!dato.cualeselmaterialpredominatecho && <div className="invalid-feedback">Campo obligatorio</div>}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4"> 
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Las paredes estan:</label>
                      <select className={`form-select ${!dato.lasparedesestan ? 'is-invalid' : ''}`} name='lasparedesestan' value={dato.lasparedesestan} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="En buen estado">En buen estado</option>
                        <option value="Con descascaramiento, humedad o cualquier otro tipo de deterioro">Con descascaramiento, humedad o cualquier otro tipo de deterioro</option>
                      </select>
                      {!dato.lasparedesestan && <div className="invalid-feedback">Campo obligatorio</div>}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4"> 
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Cual es el estado en general del techo? Señale una opción</label>
                      <select className={`form-select ${!dato.cualeselestadoengeneralde ? 'is-invalid' : ''}`} name='cualeselestadoengeneralde' value={dato.cualeselestadoengeneralde} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Buen estado">Buen estado</option>
                        <option value="Presenta agrietamiento, goteras u otro tipo de fisuras">Presenta agrietamiento, goteras u otro tipo de fisuras</option>
                      </select>
                      {!dato.cualeselestadoengeneralde && <div className="invalid-feedback">Campo obligatorio</div>}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4"> 
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">La cocina se encuentra separada de otros espacios de la vivienda (dormitorios, sala, comedor, baño)</label>
                      <select className={`form-select ${!dato.lacocinaseencuentrasepara ? 'is-invalid' : ''}`} name='lacocinaseencuentrasepara' value={dato.lacocinaseencuentrasepara} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Si">Si</option>
                        <option value="No">No</option>
                      </select>
                      {!dato.lacocinaseencuentrasepara && <div className="invalid-feedback">Campo obligatorio</div>}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4"> 
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">El baño se encuentra separado de otros espacios de la vivienda (dormitorios, sala, comedor)</label>
                      <select className={`form-select ${!dato.elbanoseencuentraseparado ? 'is-invalid' : ''}`} name='elbanoseencuentraseparado' value={dato.elbanoseencuentraseparado} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Si">Si</option>
                        <option value="No">No</option>
                      </select>
                      {!dato.elbanoseencuentraseparado && <div className="invalid-feedback">Campo obligatorio</div>}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4"> 
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Los dormitorios donde duermen adultos y niños estan separados físicamente?</label>
                      <select className={`form-select ${!dato.losdormitoriosdondeduerme ? 'is-invalid' : ''}`} name='losdormitoriosdondeduerme' value={dato.losdormitoriosdondeduerme} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Si">Si</option>
                        <option value="No">No</option>
                      </select>
                      {!dato.losdormitoriosdondeduerme && <div className="invalid-feedback">Campo obligatorio</div>}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4"> 
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">¿De cuántos cuartos o piezas dormitorio (incluyendo comedor), dispone este hogar? Respuesta Numerica:</label>
                      <input
                        type="number"
                        name='decuantoscuartosopiezasdo'
                        className={`form-control ${!dato.decuantoscuartosopiezasdo ? 'is-invalid' : ''}`}
                        value={dato.decuantoscuartosopiezasdo}
                        onChange={handleInputChange}
                      />
                      {!dato.decuantoscuartosopiezasdo && <div className="invalid-feedback">Campo obligatorio</div>}
                    </div>  
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">  
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <div>
                        <label class="form-label">Señale de los siguientes ambientes de la vivienda, cuales tiene iluminación natural</label>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="senaledelossiguientesambiilu"
                              value="Cocina"
                              checked={dato.senaledelossiguientesambiilu.includes('Cocina')}
                              onChange={handleInputChange}
                            />
                            Cocina
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="senaledelossiguientesambiilu"
                              value="Dormitorio adultos"
                              checked={dato.senaledelossiguientesambiilu.includes('Dormitorio adultos')}
                              onChange={handleInputChange}
                            />
                          Dormitorio adultos
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="senaledelossiguientesambiilu"
                              value="Dormitorio niños"
                              checked={dato.senaledelossiguientesambiilu.includes('Dormitorio niños')}
                              onChange={handleInputChange}
                            />
                          Dormitorio niños
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="senaledelossiguientesambiilu"
                              value="Sala / Comedor"
                              checked={dato.senaledelossiguientesambiilu.includes('Sala / Comedor')}
                              onChange={handleInputChange}
                            />
                          Sala / Comedor
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="senaledelossiguientesambiilu"
                              value="Sanitario"
                              checked={dato.senaledelossiguientesambiilu.includes('Sanitario')}
                              onChange={handleInputChange}
                            />
                          Sanitario
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="senaledelossiguientesambiilu"
                              value="Zona de lavado de ropas"
                              checked={dato.senaledelossiguientesambiilu.includes('Zona de lavado de ropas')}
                              onChange={handleInputChange}
                            />
                          Zona de lavado de ropas
                          </label>
                        </div>
                        {!dato.senaledelossiguientesambiilu.length && <div className="text-danger">Campo obligatorio</div>}
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4"> 
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Se facilita la circulación del aire en la vivienda a través de la ventilación natural o artificial</label>
                      <select className={`form-select ${!dato.sefacilitalacirculacionde ? 'is-invalid' : ''}`} name='sefacilitalacirculacionde' value={dato.sefacilitalacirculacionde} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Siempre">Siempre</option>
                        <option value="Algunas Veces">Algunas Veces</option>
                        <option value="Nunca">Nunca</option>
                      </select>
                      {!dato.sefacilitalacirculacionde && <div className="invalid-feedback">Campo obligatorio</div>}
                    </div> 
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">   
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <div>
                        <label class="form-label">Señale de los siguientes ambientes de la vivienda, cuales tiene ventilación natural (ventanas que se abren regularmente)</label>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="senaledelossiguientesambiven"
                              value="Cocina"
                              checked={dato.senaledelossiguientesambiven.includes('Cocina')}
                              onChange={handleInputChange}
                            />
                            Cocina
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="senaledelossiguientesambiven"
                              value="Dormitorio adultos"
                              checked={dato.senaledelossiguientesambiven.includes('Dormitorio adultos')}
                              onChange={handleInputChange}
                            />
                          Dormitorio adultos
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="senaledelossiguientesambiven"
                              value="Dormitorio niños"
                              checked={dato.senaledelossiguientesambiven.includes('Dormitorio niños')}
                              onChange={handleInputChange}
                            />
                          Dormitorio niños
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="senaledelossiguientesambiven"
                              value="Sala / Comedor"
                              checked={dato.senaledelossiguientesambiven.includes('Sala / Comedor')}
                              onChange={handleInputChange}
                            />
                          Sala / Comedor
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="senaledelossiguientesambiven"
                              value="Sanitario"
                              checked={dato.senaledelossiguientesambiven.includes('Sanitario')}
                              onChange={handleInputChange}
                            />
                          Sanitario
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="senaledelossiguientesambiven"
                              value="Zona de lavado de ropas"
                              checked={dato.senaledelossiguientesambiven.includes('Zona de lavado de ropas')}
                              onChange={handleInputChange}
                            />
                          Zona de lavado de ropas
                          </label>
                        </div>
                        {!dato.senaledelossiguientesambiven.length && <div className="text-danger">Campo obligatorio</div>}
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-8"> 
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <div class="row align-items-start">
                        <div class="col-md-4">
                          <div>
                            <label class="form-label">Señale los usos de los siguiente elementos en la vivienda: <b>[Lavamanos]</b></label>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="senalelosusosdelossiguienlm"
                                  value="Lavado de Manos"
                                  checked={dato.senalelosusosdelossiguienlm.includes('Lavado de Manos')}
                                  onChange={handleInputChange}
                                />
                                Lavado de Manos
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="senalelosusosdelossiguienlm"
                                  value="Lavado de Ropa"
                                  checked={dato.senalelosusosdelossiguienlm.includes('Lavado de Ropa')}
                                  onChange={handleInputChange}
                                />
                              Lavado de Ropa
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="senalelosusosdelossiguienlm"
                                  value="Lavado de utencillos"
                                  checked={dato.senalelosusosdelossiguienlm.includes('Lavado de utencillos')}
                                  onChange={handleInputChange}
                                />
                              Lavado de utencillos
                              </label>
                            </div>
                            {/* {!dato.senalelosusosdelossiguienlm.length && <div className="text-danger">Campo obligatorio</div>} */}
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div>
                            <label class="form-label">Señale los usos de los siguiente elementos en la vivienda: <b>[Lavaplatos]</b></label>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="senalelosusosdelossiguienlp"
                                  value="Lavado de Manos"
                                  checked={dato.senalelosusosdelossiguienlp.includes('Lavado de Manos')}
                                  onChange={handleInputChange}
                                />
                                Lavado de Manos
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="senalelosusosdelossiguienlp"
                                  value="Lavado de Ropa"
                                  checked={dato.senalelosusosdelossiguienlp.includes('Lavado de Ropa')}
                                  onChange={handleInputChange}
                                />
                              Lavado de Ropa
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="senalelosusosdelossiguienlp"
                                  value="Lavado de utencillos"
                                  checked={dato.senalelosusosdelossiguienlp.includes('Lavado de utencillos')}
                                  onChange={handleInputChange}
                                />
                              Lavado de utencillos
                              </label>
                            </div>
                            {/* {!dato.senalelosusosdelossiguienlp.length && <div className="text-danger">Campo obligatorio</div>} */}
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div>
                            <label class="form-label">Señale los usos de los siguiente elementos en la vivienda: <b>[Lavado de Ropas]</b></label>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="senalelosusosdelossiguienlr"
                                  value="Lavado de Manos"
                                  checked={dato.senalelosusosdelossiguienlr.includes('Lavado de Manos')}
                                  onChange={handleInputChange}
                                />
                                Lavado de Manos
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="senalelosusosdelossiguienlr"
                                  value="Lavado de Ropa"
                                  checked={dato.senalelosusosdelossiguienlr.includes('Lavado de Ropa')}
                                  onChange={handleInputChange}
                                />
                              Lavado de Ropa
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="senalelosusosdelossiguienlr"
                                  value="Lavado de utencillos"
                                  checked={dato.senalelosusosdelossiguienlr.includes('Lavado de utencillos')}
                                  onChange={handleInputChange}
                                />
                              Lavado de utencillos
                              </label>
                            </div>
                            {/* {!dato.senalelosusosdelossiguienlr.length && <div className="text-danger">Campo obligatorio</div>} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">          
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">¿Qué tipo de alumbrado utilizan en la vivienda? (señale una sola opción, la más predominante)</label>
                      <select className={`form-select ${!dato.quetipodealumbradoutiliza ? 'is-invalid' : ''}`} name='quetipodealumbradoutiliza' value={dato.quetipodealumbradoutiliza} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Luz eléctrica">Luz eléctrica</option>
                        <option value="Kerosén, petróleo, gasolina">Kerosén, petróleo, gasolina</option>
                        <option value="Velas">Velas</option>
                        <option value="Energia solar">Energia solar</option>
                        <option value="Planta de electricidad">Planta de electricidad</option>
                        <option value="Otros">Otros</option>
                      </select>
                      {!dato.quetipodealumbradoutiliza && <div className="invalid-feedback">Campo obligatorio</div>}
                    </div> 
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">          
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <div>
                        <label class="form-label">¿Al interior de la vivienda se realiza alguna actividad económica?</label>
                        {dato.alinteriordelaviviendase.map((valor, index) => (
                          !otrosalinteriordelaviviendasearr.includes(valor) && (
                            <div class="form-check" key={index}>
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="alinteriordelaviviendase"
                                  value={valor}
                                  checked={dato.alinteriordelaviviendase.includes(valor)}
                                  onChange={handleInputChange}
                                />
                                {valor}
                              </label>
                            </div>
                          )
                        ))}
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="alinteriordelaviviendase"
                              value="No"
                              checked={dato.alinteriordelaviviendase.includes('No')}
                              onChange={handleInputChange}
                            />
                            No
                          </label>
                        </div>
                        <div class="input-group">
                          <div class="input-group-text">
                              <input
                                type="checkbox"
                                class="form-check-input mt-0"
                                name="alinteriordelaviviendase"
                                value={otrosalinteriordelaviviendase}
                                checked={dato.alinteriordelaviviendase.includes(otrosalinteriordelaviviendase)}
                                onChange={handleInputChange}
                                //onChange={(e) => dato.alinteriordelaviviendase(e.target.checked)}
                              />
                            </div>
                            <input type="text" class="form-control" aria-label="Text input with checkbox" 
                              placeholder="Si, digite aqui las actividades"
                              value={otrosalinteriordelaviviendase}
                              name="otrosalinteriordelaviviendase"
                              //onChange={handleInputChange}
                              //onBlur={handleInputBlur}
                              onChange={(event) => handleInputChangeAdd(event, setOtrosAlinteriordelaviviendase)}
                              onBlur={() => handleInputBlur(otrosalinteriordelaviviendase, 'alinteriordelaviviendase')}
                              />
                        </div>
                        {!dato.alinteriordelaviviendase.length && <div className="text-danger">Campo obligatorio</div>}
                      </div>
                    </div> 
                  </div>
                  <div class="col-12 col-md-6 col-lg-4"> 
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Si realiza una actividad económica, el area de trabajo esta separada y es independiente de las  otras áreas de la vivienda?</label>
                      <select className={`form-select ${!dato.sirealizaunaactividadecon ? 'is-invalid' : ''}`} name='sirealizaunaactividadecon' value={dato.sirealizaunaactividadecon} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Si">Si</option>
                        <option value="No">No</option>
                      </select>
                      {!dato.sirealizaunaactividadecon && <div className="invalid-feedback">Campo obligatorio</div>}
                    </div>  
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">         
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Algun miembro de la familia se ha visto afectado por la actividad económica que se realiza en la vivienda?</label>
                      <select className={`form-select ${!dato.algunmiembrodelafamiliase ? 'is-invalid' : ''}`} name='algunmiembrodelafamiliase' value={dato.algunmiembrodelafamiliase} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Si">Si</option>
                        <option value="No">No</option>
                      </select>
                      {!dato.algunmiembrodelafamiliase && <div className="invalid-feedback">Campo obligatorio</div>}
                    </div> 
                  </div>
                  <div class="col-12 col-md-6 col-lg-4"> 
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <div>
                        <label class="form-label">Desde la vivienda se puede acceder fácilmente a: (puede señalar varias opciones)</label>
                        {dato.desdelaviviendasepuedeacc.map((valor, index) => (
                          !otrosdesdelaviviendasepuedeaccarr.includes(valor) && (
                            <div class="form-check" key={index}>
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="desdelaviviendasepuedeacc"
                                  value={valor}
                                  checked={dato.desdelaviviendasepuedeacc.includes(valor)}
                                  onChange={handleInputChange}
                                />
                                {valor}
                              </label>
                            </div>
                          )
                        ))}
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="desdelaviviendasepuedeacc"
                              value="Medios de transporte (Buses, autos, camiones, lanchas, et)"
                              checked={dato.desdelaviviendasepuedeacc.includes('Medios de transporte (Buses, autos, camiones, lanchas, et)')}
                              onChange={handleInputChange}
                            />
                            Medios de transporte (Buses, autos, camiones, lanchas, et)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="desdelaviviendasepuedeacc"
                              value="Centros sociales, culturales y/o recreacionales"
                              checked={dato.desdelaviviendasepuedeacc.includes('Centros sociales, culturales y/o recreacionales')}
                              onChange={handleInputChange}
                            />
                            Centros sociales, culturales y/o recreacionales
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="desdelaviviendasepuedeacc"
                              value="Parques, y áreas deportivas"
                              checked={dato.desdelaviviendasepuedeacc.includes('Parques, y áreas deportivas')}
                              onChange={handleInputChange}
                            />
                            Parques, y áreas deportivas
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="desdelaviviendasepuedeacc"
                              value="Iglesias, templos, espacios para cultos religiosos"
                              checked={dato.desdelaviviendasepuedeacc.includes('Iglesias, templos, espacios para cultos religiosos')}
                              onChange={handleInputChange}
                            />
                            Iglesias, templos, espacios para cultos religiosos
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="desdelaviviendasepuedeacc"
                              value="Instituciones educativas"
                              checked={dato.desdelaviviendasepuedeacc.includes('Instituciones educativas')}
                              onChange={handleInputChange}
                            />
                            Instituciones educativas
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="desdelaviviendasepuedeacc"
                              value="Servicios de salud"
                              checked={dato.desdelaviviendasepuedeacc.includes('Servicios de salud')}
                              onChange={handleInputChange}
                            />
                            Servicios de salud
                          </label>
                        </div>
                        <div class="input-group">
                          <div class="input-group-text">
                              <input
                                type="checkbox"
                                class="form-check-input mt-0"
                                name="desdelaviviendasepuedeacc"
                                value={otrosdesdelaviviendasepuedeacc}
                                checked={dato.desdelaviviendasepuedeacc.includes(otrosdesdelaviviendasepuedeacc)}
                                onChange={handleInputChange}
                                //onChange={(e) => dato.desdelaviviendasepuedeacc(e.target.checked)}
                              />
                            </div>
                            <input type="text" class="form-control" aria-label="Text input with checkbox" 
                              placeholder="Digite otras opciones de ser necesario"
                              value={otrosdesdelaviviendasepuedeacc}
                              name="otrosdesdelaviviendasepuedeacc"
                              //onChange={handleInputChange}
                              //onBlur={handleInputBlur}
                              onChange={(event) => handleInputChangeAdd(event, setOtrosDesdelaviviendasepuedeacc)}
                              onBlur={() => handleInputBlur(otrosdesdelaviviendasepuedeacc, 'desdelaviviendasepuedeacc')}
                              />
                        </div>
                        {!dato.desdelaviviendasepuedeacc.length && <div className="text-danger">Campo obligatorio</div>}
                      </div>
                    </div> 
                  </div>
                </div>
              </div>
              <div class="m-2 mt-4 border border-primary-subtle"><h4 class="text"> 2.2. MOVILIDAD</h4>       
                <div class="row">
                <div class="col-12 col-md-6 col-lg-6">
                    <div class="mb-4 p-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <div>
                        <label class="form-label">¿Como se desplazan generalmente los miembros de la familia hacia los lugares de trabajo, estudio, entre otros?</label>
                        {dato.comosedesplazangeneralmen.map((valor, index) => (
                          !otroscomosedesplazangeneralmenarr.includes(valor) && (
                            <div class="form-check" key={index}>
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="comosedesplazangeneralmen"
                                  value={valor}
                                  checked={dato.comosedesplazangeneralmen.includes(valor)}
                                  onChange={handleInputChange}
                                />
                                {valor}
                              </label>
                            </div>
                          )
                        ))}
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="comosedesplazangeneralmen"
                              value="Vehículo particular"
                              checked={dato.comosedesplazangeneralmen.includes('Vehículo particular')}
                              onChange={handleInputChange}
                            />
                            Vehículo particular
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="comosedesplazangeneralmen"
                              value="Servicio público de transporte masivo (Articulados, metro, servicios de alimentación)"
                              checked={dato.comosedesplazangeneralmen.includes('Servicio público de transporte masivo (Articulados, metro, servicios de alimentación)')}
                              onChange={handleInputChange}
                            />
                            Servicio público de transporte masivo (Articulados, metro, servicios de alimentación)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="comosedesplazangeneralmen"
                              value="Servicio público - Bus, buseta, colectivo"
                              checked={dato.comosedesplazangeneralmen.includes('Servicio público - Bus, buseta, colectivo')}
                              onChange={handleInputChange}
                            />
                            Servicio público - Bus, buseta, colectivo
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="comosedesplazangeneralmen"
                              value="Servicio público - Taxi"
                              checked={dato.comosedesplazangeneralmen.includes('Servicio público - Taxi')}
                              onChange={handleInputChange}
                            />
                            Servicio público - Taxi
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="comosedesplazangeneralmen"
                              value="Motocicleta"
                              checked={dato.comosedesplazangeneralmen.includes('Motocicleta')}
                              onChange={handleInputChange}
                            />
                            Motocicleta
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="comosedesplazangeneralmen"
                              value="Bicicleta"
                              checked={dato.comosedesplazangeneralmen.includes('Bicicleta')}
                              onChange={handleInputChange}
                            />
                            Bicicleta
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="comosedesplazangeneralmen"
                              value="Bicitaxi"
                              checked={dato.comosedesplazangeneralmen.includes('Bicitaxi')}
                              onChange={handleInputChange}
                            />
                            Bicitaxi
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="comosedesplazangeneralmen"
                              value="Semovientes"
                              checked={dato.comosedesplazangeneralmen.includes('Semovientes')}
                              onChange={handleInputChange}
                            />
                            Semovientes
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="comosedesplazangeneralmen"
                              value="Maquinaria agrícola o camión"
                              checked={dato.comosedesplazangeneralmen.includes('Maquinaria agrícola o camión')}
                              onChange={handleInputChange}
                            />
                            Maquinaria agrícola o camión
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="comosedesplazangeneralmen"
                              value="Tarabita, cable aereo artesanal"
                              checked={dato.comosedesplazangeneralmen.includes('Tarabita, cable aereo artesanal')}
                              onChange={handleInputChange}
                            />
                            Tarabita, cable aereo artesanal
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="comosedesplazangeneralmen"
                              value="Canoa, lancha, chalupa, piragua"
                              checked={dato.comosedesplazangeneralmen.includes('Canoa, lancha, chalupa, piragua')}
                              onChange={handleInputChange}
                            />
                            Canoa, lancha, chalupa, piragua
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="comosedesplazangeneralmen"
                              value="Caminando"
                              checked={dato.comosedesplazangeneralmen.includes('Caminando')}
                              onChange={handleInputChange}
                            />
                            Caminando
                          </label>
                        </div>
                        <div class="input-group">
                          <div class="input-group-text">
                              <input
                                type="checkbox"
                                class="form-check-input mt-0"
                                name="comosedesplazangeneralmen"
                                value={otroscomosedesplazangeneralmen}
                                checked={dato.comosedesplazangeneralmen.includes(otroscomosedesplazangeneralmen)}
                                onChange={handleInputChange}
                                //onChange={(e) => dato.comosedesplazangeneralmen(e.target.checked)}
                              />
                            </div>
                            <input type="text" class="form-control" aria-label="Text input with checkbox" 
                              placeholder="Digite otras opciones de ser necesario"
                              value={otroscomosedesplazangeneralmen}
                              name="otroscomosedesplazangeneralmen"
                              //onChange={handleInputChange}
                              //onBlur={handleInputBlur}
                              onChange={(event) => handleInputChange(event, setOtrosComosedesplazangeneralmen)}
                              onBlur={() => handleInputBlur(otroscomosedesplazangeneralmen, 'comosedesplazangeneralmen')}
                              />
                        </div>
                        {!dato.comosedesplazangeneralmen.length && <div className="text-danger">Campo obligatorio</div>}
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-6">
                    <div class="mb-4 p-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <div>
                        <label class="form-label">¿Cuales elementos de seguridad utilizan los miembros de la familia para su medio de transporte?</label>
                        {dato.cualeselementosdesegurida.map((valor, index) => (
                          !otroscualeselementosdeseguridaarr.includes(valor) && (
                            <div class="form-check" key={index}>
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="cualeselementosdesegurida"
                                  value={valor}
                                  checked={dato.cualeselementosdesegurida.includes(valor)}
                                  onChange={handleInputChange}
                                />
                                {valor}
                              </label>
                            </div>
                          )
                        ))}
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="cualeselementosdesegurida"
                              value="Casco"
                              checked={dato.cualeselementosdesegurida.includes('Casco')}
                              onChange={handleInputChange}
                            />
                            Casco
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="cualeselementosdesegurida"
                              value="Cinturon de seguridad"
                              checked={dato.cualeselementosdesegurida.includes('Cinturon de seguridad')}
                              onChange={handleInputChange}
                            />
                            Cinturon de seguridad
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="cualeselementosdesegurida"
                              value="Sistema de Retención Infantil"
                              checked={dato.cualeselementosdesegurida.includes('Sistema de Retención Infantil')}
                              onChange={handleInputChange}
                            />
                            Sistema de Retención Infantil
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="cualeselementosdesegurida"
                              value="Chaleco reflectivo"
                              checked={dato.cualeselementosdesegurida.includes('Chaleco reflectivo')}
                              onChange={handleInputChange}
                            />
                            Chaleco reflectivo
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="cualeselementosdesegurida"
                              value="Chaleco Salva Vidas"
                              checked={dato.cualeselementosdesegurida.includes('Chaleco Salva Vidas')}
                              onChange={handleInputChange}
                            />
                            Chaleco Salva Vidas
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="cualeselementosdesegurida"
                              value="Ninguno"
                              checked={dato.cualeselementosdesegurida.includes('Ninguno')}
                              onChange={handleInputChange}
                            />
                            Ninguno
                          </label>
                        </div>
                        <div class="input-group">
                          <div class="input-group-text">
                              <input
                                type="checkbox"
                                class="form-check-input mt-0"
                                name="cualeselementosdesegurida"
                                value={otroscualeselementosdesegurida}
                                checked={dato.cualeselementosdesegurida.includes(otroscualeselementosdesegurida)}
                                onChange={handleInputChange}
                                //onChange={(e) => dato.cualeselementosdesegurida(e.target.checked)}
                              />
                            </div>
                            <input type="text" class="form-control" aria-label="Text input with checkbox" 
                              placeholder="Digite otras opciones de ser necesario"
                              value={otroscualeselementosdesegurida}
                              name="otroscualeselementosdesegurida"
                              //onChange={handleInputChange}
                              //onBlur={handleInputBlur}
                              onChange={(event) => handleInputChange(event, setOtrosCualeselementosdesegurida)}
                              onBlur={() => handleInputBlur(otroscualeselementosdesegurida, 'cualeselementosdesegurida')}
                              />
                        </div>
                        {!dato.cualeselementosdesegurida.length && <div className="text-danger">Campo obligatorio</div>}
                      </div>
                    </div>          
                  </div>
                  <div class="col-12">
                    <div class="mb-4 p-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <div class="row align-items-start">
                        <div class="col-md-4">
                          <div>
                            <label class="form-label">Si el tiempo de desplazamiento entre el hogar y los entornos educativos o laborales es mayor a 30 minutos, señale con X la razón por la cual el tiempo se extiende de esa manera: <b>[TRABAJO]</b></label>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="sieltiempodedesplazamienttra"
                                  value="Estado de la infraestructura vial"
                                  checked={dato.sieltiempodedesplazamienttra.includes('Estado de la infraestructura vial')}
                                  onChange={handleInputChange}
                                />
                                Estado de la infraestructura vial
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="sieltiempodedesplazamienttra"
                                  value="Congestión vial"
                                  checked={dato.sieltiempodedesplazamienttra.includes('Congestión vial')}
                                  onChange={handleInputChange}
                                />
                              Congestión vial
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="sieltiempodedesplazamienttra"
                                  value="Disponibilidad de medios de transporte"
                                  checked={dato.sieltiempodedesplazamienttra.includes('Disponibilidad de medios de transporte')}
                                  onChange={handleInputChange}
                                />
                              Disponibilidad de medios de transporte
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="sieltiempodedesplazamienttra"
                                  value="Distancias a recorrer"
                                  checked={dato.sieltiempodedesplazamienttra.includes('Distancias a recorrer')}
                                  onChange={handleInputChange}
                                />
                              Distancias a recorrer
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="sieltiempodedesplazamienttra"
                                  value="Disponibilidad económica"
                                  checked={dato.sieltiempodedesplazamienttra.includes('Disponibilidad económica')}
                                  onChange={handleInputChange}
                                />
                              Disponibilidad económica
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="sieltiempodedesplazamienttra"
                                  value="Otros"
                                  checked={dato.sieltiempodedesplazamienttra.includes('Otros')}
                                  onChange={handleInputChange}
                                />
                              Otros
                              </label>
                            </div>
                            {/* {!dato.sieltiempodedesplazamienttra.length && <div className="text-danger">Campo obligatorio</div>} */}
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div>
                            <label class="form-label">Si el tiempo de desplazamiento entre el hogar y los entornos educativos o laborales es mayor a 30 minutos, señale con X la razón por la cual el tiempo se extiende de esa manera: <b>[ESTUDIO]</b></label>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="sieltiempodedesplazamientest"
                                  value="Estado de la infraestructura vial"
                                  checked={dato.sieltiempodedesplazamientest.includes('Estado de la infraestructura vial')}
                                  onChange={handleInputChange}
                                />
                                Estado de la infraestructura vial
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="sieltiempodedesplazamientest"
                                  value="Congestión vial"
                                  checked={dato.sieltiempodedesplazamientest.includes('Congestión vial')}
                                  onChange={handleInputChange}
                                />
                              Congestión vial
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="sieltiempodedesplazamientest"
                                  value="Disponibilidad de medios de transporte"
                                  checked={dato.sieltiempodedesplazamientest.includes('Disponibilidad de medios de transporte')}
                                  onChange={handleInputChange}
                                />
                              Disponibilidad de medios de transporte
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="sieltiempodedesplazamientest"
                                  value="Distancias a recorrer"
                                  checked={dato.sieltiempodedesplazamientest.includes('Distancias a recorrer')}
                                  onChange={handleInputChange}
                                />
                              Distancias a recorrer
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="sieltiempodedesplazamientest"
                                  value="Disponibilidad económica"
                                  checked={dato.sieltiempodedesplazamientest.includes('Disponibilidad económica')}
                                  onChange={handleInputChange}
                                />
                              Disponibilidad económica
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="sieltiempodedesplazamientest"
                                  value="Otros"
                                  checked={dato.sieltiempodedesplazamientest.includes('Otros')}
                                  onChange={handleInputChange}
                                />
                              Otros
                              </label>
                            </div>
                            {/* {!dato.sieltiempodedesplazamientest.length && <div className="text-danger">Campo obligatorio</div>} */}
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div>
                            <label class="form-label">Si el tiempo de desplazamiento entre el hogar y los entornos educativos o laborales es mayor a 30 minutos, señale con X la razón por la cual el tiempo se extiende de esa manera: <b>[OTRAS ACTIVIDADES]</b></label>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="sieltiempodedesplazamientotr"
                                  value="Estado de la infraestructura vial"
                                  checked={dato.sieltiempodedesplazamientotr.includes('Estado de la infraestructura vial')}
                                  onChange={handleInputChange}
                                />
                                Estado de la infraestructura vial
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="sieltiempodedesplazamientotr"
                                  value="Congestión vial"
                                  checked={dato.sieltiempodedesplazamientotr.includes('Congestión vial')}
                                  onChange={handleInputChange}
                                />
                              Congestión vial
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="sieltiempodedesplazamientotr"
                                  value="Disponibilidad de medios de transporte"
                                  checked={dato.sieltiempodedesplazamientotr.includes('Disponibilidad de medios de transporte')}
                                  onChange={handleInputChange}
                                />
                              Disponibilidad de medios de transporte
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="sieltiempodedesplazamientotr"
                                  value="Distancias a recorrer"
                                  checked={dato.sieltiempodedesplazamientotr.includes('Distancias a recorrer')}
                                  onChange={handleInputChange}
                                />
                              Distancias a recorrer
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="sieltiempodedesplazamientotr"
                                  value="Disponibilidad económica"
                                  checked={dato.sieltiempodedesplazamientotr.includes('Disponibilidad económica')}
                                  onChange={handleInputChange}
                                />
                              Disponibilidad económica
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="sieltiempodedesplazamientotr"
                                  value="Otros"
                                  checked={dato.sieltiempodedesplazamientotr.includes('Otros')}
                                  onChange={handleInputChange}
                                />
                              Otros
                              </label>
                            </div>
                            {/* {!dato.sieltiempodedesplazamientotr.length && <div className="text-danger">Campo obligatorio</div>} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="m-2 mt-4 border border-primary-subtle"><h4 class="text"> 2.3. ACCIDENTES EN EL HOGAR</h4>
                <div class="mb-4 p-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                  <label class="form-label">¿ En el último año alguna persona de la familia acudió a un servicio de urgencia por un accidente doméstico?</label>
                  <select className={`form-select ${!dato.enelultimoanoalgunaperson ? 'is-invalid' : ''}`} name='enelultimoanoalgunaperson' value={dato.enelultimoanoalgunaperson} onChange={handleInputChange}>
                    <option selected></option>
                    <option value="Si">Si</option>
                    <option value="No">No</option>
                  </select>
                  {!dato.enelultimoanoalgunaperson && <div className="invalid-feedback">Campo obligatorio</div>}
                </div>
                {showDivtipoaccidente && (
                <div class="mb-4 p-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                  <div class="row align-items-start">
                    <div class="col-md-4">
                      <div>
                        <label class="form-label">Primera infancia (0-5 años) </label>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidenteprimerainfa"
                              value="Heridas objetos cortopunzantes"
                              checked={dato.tipoaccidenteprimerainfa.includes('Heridas objetos cortopunzantes')}
                              onChange={handleInputChange}
                            />
                            Heridas objetos cortopunzantes
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidenteprimerainfa"
                              value="Intoxicación"
                              checked={dato.tipoaccidenteprimerainfa.includes('Intoxicación')}
                              onChange={handleInputChange}
                            />
                          Intoxicación
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidenteprimerainfa"
                              value="Quemadura"
                              checked={dato.tipoaccidenteprimerainfa.includes('Quemadura')}
                              onChange={handleInputChange}
                            />
                          Quemadura
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidenteprimerainfa"
                              value="Electrocución"
                              checked={dato.tipoaccidenteprimerainfa.includes('Electrocución')}
                              onChange={handleInputChange}
                            />
                          Electrocución
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidenteprimerainfa"
                              value="Asfixia respiratoria"
                              checked={dato.tipoaccidenteprimerainfa.includes('Asfixia respiratoria')}
                              onChange={handleInputChange}
                            />
                          Asfixia respiratoria
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidenteprimerainfa"
                              value="Golpes y atrapamientos"
                              checked={dato.tipoaccidenteprimerainfa.includes('Golpes y atrapamientos')}
                              onChange={handleInputChange}
                            />
                          Golpes y atrapamientos
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidenteprimerainfa"
                              value="Caída"
                              checked={dato.tipoaccidenteprimerainfa.includes('Caída')}
                              onChange={handleInputChange}
                            />
                          Caída
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidenteprimerainfa"
                              value="Ahogamientos o sumersiones"
                              checked={dato.tipoaccidenteprimerainfa.includes('Ahogamientos o sumersiones')}
                              onChange={handleInputChange}
                            />
                          Ahogamientos o sumersiones
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidenteprimerainfa"
                              value="Agresión animal"
                              checked={dato.tipoaccidenteprimerainfa.includes('Agresión animal')}
                              onChange={handleInputChange}
                            />
                          Agresión animal
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidenteprimerainfa"
                              value="Caída de alturas"
                              checked={dato.tipoaccidenteprimerainfa.includes('Caída de alturas')}
                              onChange={handleInputChange}
                            />
                          Caída de alturas
                          </label>
                        </div>
                        {/* {!dato.tipoaccidenteprimerainfa.length && <div className="text-danger">Campo obligatorio</div>} */}
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div>
                        <label class="form-label">Infancia (6 - 11 años)</label>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidenteinfancia"
                              value="Heridas objetos cortopunzantes"
                              checked={dato.tipoaccidenteinfancia.includes('Heridas objetos cortopunzantes')}
                              onChange={handleInputChange}
                            />
                            Heridas objetos cortopunzantes
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidenteinfancia"
                              value="Intoxicación"
                              checked={dato.tipoaccidenteinfancia.includes('Intoxicación')}
                              onChange={handleInputChange}
                            />
                          Intoxicación
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidenteinfancia"
                              value="Quemadura"
                              checked={dato.tipoaccidenteinfancia.includes('Quemadura')}
                              onChange={handleInputChange}
                            />
                          Quemadura
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidenteinfancia"
                              value="Electrocución"
                              checked={dato.tipoaccidenteinfancia.includes('Electrocución')}
                              onChange={handleInputChange}
                            />
                          Electrocución
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidenteinfancia"
                              value="Asfixia respiratoria"
                              checked={dato.tipoaccidenteinfancia.includes('Asfixia respiratoria')}
                              onChange={handleInputChange}
                            />
                          Asfixia respiratoria
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidenteinfancia"
                              value="Golpes y atrapamientos"
                              checked={dato.tipoaccidenteinfancia.includes('Golpes y atrapamientos')}
                              onChange={handleInputChange}
                            />
                          Golpes y atrapamientos
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidenteinfancia"
                              value="Caída"
                              checked={dato.tipoaccidenteinfancia.includes('Caída')}
                              onChange={handleInputChange}
                            />
                          Caída
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidenteinfancia"
                              value="Ahogamientos o sumersiones"
                              checked={dato.tipoaccidenteinfancia.includes('Ahogamientos o sumersiones')}
                              onChange={handleInputChange}
                            />
                          Ahogamientos o sumersiones
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidenteinfancia"
                              value="Agresión animal"
                              checked={dato.tipoaccidenteinfancia.includes('Agresión animal')}
                              onChange={handleInputChange}
                            />
                          Agresión animal
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidenteinfancia"
                              value="Caída de alturas"
                              checked={dato.tipoaccidenteinfancia.includes('Caída de alturas')}
                              onChange={handleInputChange}
                            />
                          Caída de alturas
                          </label>
                        </div>
                        {/* {!dato.tipoaccidenteinfancia.length && <div className="text-danger">Campo obligatorio</div>} */}
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div>
                        <label class="form-label">Adolescencia (12-18 años)</label>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidenteadolescenci"
                              value="Heridas objetos cortopunzantes"
                              checked={dato.tipoaccidenteadolescenci.includes('Heridas objetos cortopunzantes')}
                              onChange={handleInputChange}
                            />
                            Heridas objetos cortopunzantes
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidenteadolescenci"
                              value="Intoxicación"
                              checked={dato.tipoaccidenteadolescenci.includes('Intoxicación')}
                              onChange={handleInputChange}
                            />
                          Intoxicación
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidenteadolescenci"
                              value="Quemadura"
                              checked={dato.tipoaccidenteadolescenci.includes('Quemadura')}
                              onChange={handleInputChange}
                            />
                          Quemadura
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidenteadolescenci"
                              value="Electrocución"
                              checked={dato.tipoaccidenteadolescenci.includes('Electrocución')}
                              onChange={handleInputChange}
                            />
                          Electrocución
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidenteadolescenci"
                              value="Asfixia respiratoria"
                              checked={dato.tipoaccidenteadolescenci.includes('Asfixia respiratoria')}
                              onChange={handleInputChange}
                            />
                          Asfixia respiratoria
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidenteadolescenci"
                              value="Golpes y atrapamientos"
                              checked={dato.tipoaccidenteadolescenci.includes('Golpes y atrapamientos')}
                              onChange={handleInputChange}
                            />
                          Golpes y atrapamientos
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidenteadolescenci"
                              value="Caída"
                              checked={dato.tipoaccidenteadolescenci.includes('Caída')}
                              onChange={handleInputChange}
                            />
                          Caída
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidenteadolescenci"
                              value="Ahogamientos o sumersiones"
                              checked={dato.tipoaccidenteadolescenci.includes('Ahogamientos o sumersiones')}
                              onChange={handleInputChange}
                            />
                          Ahogamientos o sumersiones
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidenteadolescenci"
                              value="Agresión animal"
                              checked={dato.tipoaccidenteadolescenci.includes('Agresión animal')}
                              onChange={handleInputChange}
                            />
                          Agresión animal
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidenteadolescenci"
                              value="Caída de alturas"
                              checked={dato.tipoaccidenteadolescenci.includes('Caída de alturas')}
                              onChange={handleInputChange}
                            />
                          Caída de alturas
                          </label>
                        </div>
                        {/* {!dato.tipoaccidenteadolescenci.length && <div className="text-danger">Campo obligatorio</div>} */}
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-md-4">
                      <div>
                        <label class="form-label">Juventud (14 - 26 años)</label>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidentejuventud"
                              value="Heridas objetos cortopunzantes"
                              checked={dato.tipoaccidentejuventud.includes('Heridas objetos cortopunzantes')}
                              onChange={handleInputChange}
                            />
                            Heridas objetos cortopunzantes
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidentejuventud"
                              value="Intoxicación"
                              checked={dato.tipoaccidentejuventud.includes('Intoxicación')}
                              onChange={handleInputChange}
                            />
                          Intoxicación
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidentejuventud"
                              value="Quemadura"
                              checked={dato.tipoaccidentejuventud.includes('Quemadura')}
                              onChange={handleInputChange}
                            />
                          Quemadura
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidentejuventud"
                              value="Electrocución"
                              checked={dato.tipoaccidentejuventud.includes('Electrocución')}
                              onChange={handleInputChange}
                            />
                          Electrocución
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidentejuventud"
                              value="Asfixia respiratoria"
                              checked={dato.tipoaccidentejuventud.includes('Asfixia respiratoria')}
                              onChange={handleInputChange}
                            />
                          Asfixia respiratoria
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidentejuventud"
                              value="Golpes y atrapamientos"
                              checked={dato.tipoaccidentejuventud.includes('Golpes y atrapamientos')}
                              onChange={handleInputChange}
                            />
                          Golpes y atrapamientos
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidentejuventud"
                              value="Caída"
                              checked={dato.tipoaccidentejuventud.includes('Caída')}
                              onChange={handleInputChange}
                            />
                          Caída
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidentejuventud"
                              value="Ahogamientos o sumersiones"
                              checked={dato.tipoaccidentejuventud.includes('Ahogamientos o sumersiones')}
                              onChange={handleInputChange}
                            />
                          Ahogamientos o sumersiones
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidentejuventud"
                              value="Agresión animal"
                              checked={dato.tipoaccidentejuventud.includes('Agresión animal')}
                              onChange={handleInputChange}
                            />
                          Agresión animal
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidentejuventud"
                              value="Caída de alturas"
                              checked={dato.tipoaccidentejuventud.includes('Caída de alturas')}
                              onChange={handleInputChange}
                            />
                          Caída de alturas
                          </label>
                        </div>
                        {/* {!dato.tipoaccidentejuventud.length && <div className="text-danger">Campo obligatorio</div>} */}
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div>
                        <label class="form-label">Adultez (27- 59 años)</label>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidenteadultez"
                              value="Heridas objetos cortopunzantes"
                              checked={dato.tipoaccidenteadultez.includes('Heridas objetos cortopunzantes')}
                              onChange={handleInputChange}
                            />
                            Heridas objetos cortopunzantes
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidenteadultez"
                              value="Intoxicación"
                              checked={dato.tipoaccidenteadultez.includes('Intoxicación')}
                              onChange={handleInputChange}
                            />
                          Intoxicación
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidenteadultez"
                              value="Quemadura"
                              checked={dato.tipoaccidenteadultez.includes('Quemadura')}
                              onChange={handleInputChange}
                            />
                          Quemadura
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidenteadultez"
                              value="Electrocución"
                              checked={dato.tipoaccidenteadultez.includes('Electrocución')}
                              onChange={handleInputChange}
                            />
                          Electrocución
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidenteadultez"
                              value="Asfixia respiratoria"
                              checked={dato.tipoaccidenteadultez.includes('Asfixia respiratoria')}
                              onChange={handleInputChange}
                            />
                          Asfixia respiratoria
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidenteadultez"
                              value="Golpes y atrapamientos"
                              checked={dato.tipoaccidenteadultez.includes('Golpes y atrapamientos')}
                              onChange={handleInputChange}
                            />
                          Golpes y atrapamientos
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidenteadultez"
                              value="Caída"
                              checked={dato.tipoaccidenteadultez.includes('Caída')}
                              onChange={handleInputChange}
                            />
                          Caída
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidenteadultez"
                              value="Ahogamientos o sumersiones"
                              checked={dato.tipoaccidenteadultez.includes('Ahogamientos o sumersiones')}
                              onChange={handleInputChange}
                            />
                          Ahogamientos o sumersiones
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidenteadultez"
                              value="Agresión animal"
                              checked={dato.tipoaccidenteadultez.includes('Agresión animal')}
                              onChange={handleInputChange}
                            />
                          Agresión animal
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidenteadultez"
                              value="Caída de alturas"
                              checked={dato.tipoaccidenteadultez.includes('Caída de alturas')}
                              onChange={handleInputChange}
                            />
                          Caída de alturas
                          </label>
                        </div>
                        {/* {!dato.tipoaccidenteadultez.length && <div className="text-danger">Campo obligatorio</div>} */}
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div>
                        <label class="form-label">Persona Mayor (60 años o mas)</label>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidentepersonamayo"
                              value="Heridas objetos cortopunzantes"
                              checked={dato.tipoaccidentepersonamayo.includes('Heridas objetos cortopunzantes')}
                              onChange={handleInputChange}
                            />
                            Heridas objetos cortopunzantes
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidentepersonamayo"
                              value="Intoxicación"
                              checked={dato.tipoaccidentepersonamayo.includes('Intoxicación')}
                              onChange={handleInputChange}
                            />
                          Intoxicación
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidentepersonamayo"
                              value="Quemadura"
                              checked={dato.tipoaccidentepersonamayo.includes('Quemadura')}
                              onChange={handleInputChange}
                            />
                          Quemadura
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidentepersonamayo"
                              value="Electrocución"
                              checked={dato.tipoaccidentepersonamayo.includes('Electrocución')}
                              onChange={handleInputChange}
                            />
                          Electrocución
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidentepersonamayo"
                              value="Asfixia respiratoria"
                              checked={dato.tipoaccidentepersonamayo.includes('Asfixia respiratoria')}
                              onChange={handleInputChange}
                            />
                          Asfixia respiratoria
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidentepersonamayo"
                              value="Golpes y atrapamientos"
                              checked={dato.tipoaccidentepersonamayo.includes('Golpes y atrapamientos')}
                              onChange={handleInputChange}
                            />
                          Golpes y atrapamientos
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidentepersonamayo"
                              value="Caída"
                              checked={dato.tipoaccidentepersonamayo.includes('Caída')}
                              onChange={handleInputChange}
                            />
                          Caída
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidentepersonamayo"
                              value="Ahogamientos o sumersiones"
                              checked={dato.tipoaccidentepersonamayo.includes('Ahogamientos o sumersiones')}
                              onChange={handleInputChange}
                            />
                          Ahogamientos o sumersiones
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidentepersonamayo"
                              value="Agresión animal"
                              checked={dato.tipoaccidentepersonamayo.includes('Agresión animal')}
                              onChange={handleInputChange}
                            />
                          Agresión animal
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="tipoaccidentepersonamayo"
                              value="Caída de alturas"
                              checked={dato.tipoaccidentepersonamayo.includes('Caída de alturas')}
                              onChange={handleInputChange}
                            />
                          Caída de alturas
                          </label>
                        </div>
                        {/* {!dato.tipoaccidentepersonamayo.length && <div className="text-danger">Campo obligatorio</div>} */}
                      </div>
                    </div>
                  </div>
                </div> 
                )} 
                <div class="mb-4 p-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                  <div>
                    <label class="form-label">Se identifican algunos de los siguientes escenarios de riesgo de accidente en la vivienda (Señale con x)</label>
                      {dato.seidentificanalgunosdelos.map((valor, index) => (
                        !otrosseidentificanalgunosdelosarr.includes(valor) && (
                          <div class="form-check" key={index}>
                            <label class="form-check-label">
                              <input
                                type="checkbox"
                                class="form-check-input"
                                name="seidentificanalgunosdelos"
                                value={valor}
                                checked={dato.seidentificanalgunosdelos.includes(valor)}
                                onChange={handleInputChange}
                              />
                              {valor}
                            </label>
                          </div>
                        )
                      ))}
                    <div class="form-check">
                      <label class="form-check-label">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          name="seidentificanalgunosdelos"
                          value="Objetos cortantes o punzantes al alcance de los niños"
                          checked={dato.seidentificanalgunosdelos.includes('Objetos cortantes o punzantes al alcance de los niños')}
                          onChange={handleInputChange}
                        />
                        Objetos cortantes o punzantes al alcance de los niños
                      </label>
                    </div>
                    <div class="form-check">
                      <label class="form-check-label">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          name="seidentificanalgunosdelos"
                          value="Sustancias químicas al alcance de los niños"
                          checked={dato.seidentificanalgunosdelos.includes('Sustancias químicas al alcance de los niños')}
                          onChange={handleInputChange}
                        />
                        Sustancias químicas al alcance de los niños
                      </label>
                    </div>
                    <div class="form-check">
                      <label class="form-check-label">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          name="seidentificanalgunosdelos"
                          value="Medicamentos al alcance de los niños"
                          checked={dato.seidentificanalgunosdelos.includes('Medicamentos al alcance de los niños')}
                          onChange={handleInputChange}
                        />
                        Medicamentos al alcance de los niños
                      </label>
                    </div>
                    <div class="form-check">
                      <label class="form-check-label">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          name="seidentificanalgunosdelos"
                          value="Velas, velones, incienso encendidos en la vivienda"
                          checked={dato.seidentificanalgunosdelos.includes('Velas, velones, incienso encendidos en la vivienda')}
                          onChange={handleInputChange}
                        />
                        Velas, velones, incienso encendidos en la vivienda
                      </label>
                    </div>
                    <div class="form-check">
                      <label class="form-check-label">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          name="seidentificanalgunosdelos"
                          value="Conexiones eléctricas en mal estado o sobrecargadas"
                          checked={dato.seidentificanalgunosdelos.includes('Conexiones eléctricas en mal estado o sobrecargadas')}
                          onChange={handleInputChange}
                        />
                        Conexiones eléctricas en mal estado o sobrecargadas
                      </label>
                    </div>
                    <div class="form-check">
                      <label class="form-check-label">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          name="seidentificanalgunosdelos"
                          value="Botones, canicas entre otros objetos pequeños o con piezas que puedan desmontarse, al alcance de los niños"
                          checked={dato.seidentificanalgunosdelos.includes('Botones, canicas entre otros objetos pequeños o con piezas que puedan desmontarse, al alcance de los niños')}
                          onChange={handleInputChange}
                        />
                        Botones, canicas entre otros objetos pequeños o con piezas que puedan desmontarse, al alcance de los niños
                      </label>
                    </div>
                    <div class="form-check">
                      <label class="form-check-label">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          name="seidentificanalgunosdelos"
                          value="Pasillos obstruidos con juguetes, sillas u otros objetos"
                          checked={dato.seidentificanalgunosdelos.includes('Pasillos obstruidos con juguetes, sillas u otros objetos')}
                          onChange={handleInputChange}
                        />
                        Pasillos obstruidos con juguetes, sillas u otros objetos
                      </label>
                    </div>
                    <div class="form-check">
                      <label class="form-check-label">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          name="seidentificanalgunosdelos"
                          value="Superficies resbaladizas, suelos con agua, grasas, aceites, entre otros"
                          checked={dato.seidentificanalgunosdelos.includes('Superficies resbaladizas, suelos con agua, grasas, aceites, entre otros')}
                          onChange={handleInputChange}
                        />
                        Superficies resbaladizas, suelos con agua, grasas, aceites, entre otros
                      </label>
                    </div>
                    <div class="form-check">
                      <label class="form-check-label">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          name="seidentificanalgunosdelos"
                          value="Tanques o recipientes de almacenamiento de agua sin tapa"
                          checked={dato.seidentificanalgunosdelos.includes('Tanques o recipientes de almacenamiento de agua sin tapa')}
                          onChange={handleInputChange}
                        />
                        Tanques o recipientes de almacenamiento de agua sin tapa
                      </label>
                    </div>
                    <div class="form-check">
                      <label class="form-check-label">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          name="seidentificanalgunosdelos"
                          value="Escaleras sin protección"
                          checked={dato.seidentificanalgunosdelos.includes('Escaleras sin protección')}
                          onChange={handleInputChange}
                        />
                        Escaleras sin protección
                      </label>
                    </div>
                    <div class="input-group">
                      <div class="input-group-text">
                          <input
                            type="checkbox"
                            class="form-check-input mt-0"
                            name="seidentificanalgunosdelos"
                            value={otrosseidentificanalgunosdelos}
                            checked={dato.seidentificanalgunosdelos.includes(otrosseidentificanalgunosdelos)}
                            onChange={handleInputChange}
                            //onChange={(e) => dato.seidentificanalgunosdelos(e.target.checked)}
                          />
                        </div>
                        <input type="text" class="form-control" aria-label="Text input with checkbox" 
                          placeholder="Digite otras opciones de ser necesario"
                          value={otrosseidentificanalgunosdelos}
                          name="otrosseidentificanalgunosdelos"
                          //onChange={handleInputChange}
                          //onBlur={handleInputBlur}
                          onChange={(event) => handleInputChange(event, setOtrosSeidentificanalgunosdelos)}
                          onBlur={() => handleInputBlur(otrosseidentificanalgunosdelos, 'seidentificanalgunosdelos')}
                          />
                    </div>
                    {!dato.seidentificanalgunosdelos.length && <div className="text-danger">Campo obligatorio</div>}
                  </div>
                </div>   
              </div>
              <div class="m-2 mt-4 border border-primary-subtle"><h4 class="text"> 2.4. AGUA Y SANEAMIENTO BASICO</h4>       
                <div class="row">
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <div>
                        <label class="form-label">¿Cual es la principal fuente de abastecimiento de agua para consumo humano en la vivienda?</label>
                          {dato.cualeslaprincipalfuentede.map((valor, index) => (
                            !otroscualeslaprincipalfuentedearr.includes(valor) && (
                              <div class="form-check" key={index}>
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name="cualeslaprincipalfuentede"
                                    value={valor}
                                    checked={dato.cualeslaprincipalfuentede.includes(valor)}
                                    onChange={handleInputChange}
                                  />
                                  {valor}
                                </label>
                              </div>
                            )
                          ))}
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="cualeslaprincipalfuentede"
                              value="Acueducto administrado por empresa prestadora (ESP)"
                              checked={dato.cualeslaprincipalfuentede.includes('Acueducto administrado por empresa prestadora (ESP)')}
                              onChange={handleInputChange}
                            />
                            Acueducto administrado por empresa prestadora (ESP)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="cualeslaprincipalfuentede"
                              value="Agua embotellada o en bolsa"
                              checked={dato.cualeslaprincipalfuentede.includes('Agua embotellada o en bolsa')}
                              onChange={handleInputChange}
                            />
                            Agua embotellada o en bolsa
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="cualeslaprincipalfuentede"
                              value="Acueducto veredal o comunitario"
                              checked={dato.cualeslaprincipalfuentede.includes('Acueducto veredal o comunitario')}
                              onChange={handleInputChange}
                            />
                            Acueducto veredal o comunitario
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="cualeslaprincipalfuentede"
                              value="Pila pública"
                              checked={dato.cualeslaprincipalfuentede.includes('Pila pública')}
                              onChange={handleInputChange}
                            />
                            Pila pública
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="cualeslaprincipalfuentede"
                              value="Carro tanque"
                              checked={dato.cualeslaprincipalfuentede.includes('Carro tanque')}
                              onChange={handleInputChange}
                            />
                            Carro tanque
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="cualeslaprincipalfuentede"
                              value="Abasto con distribución comunitaria"
                              checked={dato.cualeslaprincipalfuentede.includes('Abasto con distribución comunitaria')}
                              onChange={handleInputChange}
                            />
                            Abasto con distribución comunitaria
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="cualeslaprincipalfuentede"
                              value="Pozo con bomba"
                              checked={dato.cualeslaprincipalfuentede.includes('Pozo con bomba')}
                              onChange={handleInputChange}
                            />
                            Pozo con bomba
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="cualeslaprincipalfuentede"
                              value="Pozo sin bomba, aljibe, jagüey o barreno"
                              checked={dato.cualeslaprincipalfuentede.includes('Pozo sin bomba, aljibe, jagüey o barreno')}
                              onChange={handleInputChange}
                            />
                            Pozo sin bomba, aljibe, jagüey o barreno
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="cualeslaprincipalfuentede"
                              value="Laguna o jagüey"
                              checked={dato.cualeslaprincipalfuentede.includes('Laguna o jagüey')}
                              onChange={handleInputChange}
                            />
                            Laguna o jagüey
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="cualeslaprincipalfuentede"
                              value="Río, quebrada, manantial o nacimiento"
                              checked={dato.cualeslaprincipalfuentede.includes('Río, quebrada, manantial o nacimiento')}
                              onChange={handleInputChange}
                            />
                            Río, quebrada, manantial o nacimiento
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="cualeslaprincipalfuentede"
                              value="Aguas lluvias"
                              checked={dato.cualeslaprincipalfuentede.includes('Aguas lluvias')}
                              onChange={handleInputChange}
                            />
                            Aguas lluvias
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="cualeslaprincipalfuentede"
                              value="Aguatero"
                              checked={dato.cualeslaprincipalfuentede.includes('Aguatero')}
                              onChange={handleInputChange}
                            />
                            Aguatero
                          </label>
                        </div>
                        <div class="input-group">
                          <div class="input-group-text">
                              <input
                                type="checkbox"
                                class="form-check-input mt-0"
                                name="cualeslaprincipalfuentede"
                                value={otroscualeslaprincipalfuentede}
                                checked={dato.cualeslaprincipalfuentede.includes(otroscualeslaprincipalfuentede)}
                                onChange={handleInputChange}
                                //onChange={(e) => dato.cualeslaprincipalfuentede(e.target.checked)}
                              />
                            </div>
                            <input type="text" class="form-control" aria-label="Text input with checkbox" 
                              placeholder="Digite otras opciones de ser necesario"
                              value={otroscualeslaprincipalfuentede}
                              name="otroscualeslaprincipalfuentede"
                              //onChange={handleInputChange}
                              //onBlur={handleInputBlur}
                              onChange={(event) => handleInputChange(event, setOtrosCualeslaprincipalfuentede)}
                              onBlur={() => handleInputBlur(otroscualeslaprincipalfuentede, 'cualeslaprincipalfuentede')}
                              />
                        </div>
                        {!dato.cualeslaprincipalfuentede.length && <div className="text-danger">Campo obligatorio</div>}
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">        
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">¿Cuántas horas al día cuenta con suministro de agua?</label>
                      <select className={`form-select ${!dato.cuantashorasaldiacuentaco ? 'is-invalid' : ''}`} name='cuantashorasaldiacuentaco' value={dato.cuantashorasaldiacuentaco} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="24 horas">24 horas</option>
                        <option value="Entre 12 a 23 horas">Entre 12 a 23 horas</option>
                        <option value="Entre 4 y 12 horas">Entre 4 y 12 horas</option>
                        <option value="Menor a 4 horas">Menor a 4 horas</option>
                        <option value="Se presentan días sin suministro">Se presentan días sin suministro</option>
                      </select>
                      {!dato.cuantashorasaldiacuentaco && <div className="invalid-feedback">Campo obligatorio</div>}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <div>
                        <label class="form-label">Se cuenta con tanque de almacenamiento de agua</label>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="secuentacontanquedealmace"
                              value="Aereo"
                              checked={dato.secuentacontanquedealmace.includes('Aereo')}
                              onChange={handleInputChange}
                            />
                            Aereo
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="secuentacontanquedealmace"
                              value="Superficial"
                              checked={dato.secuentacontanquedealmace.includes('Superficial')}
                              onChange={handleInputChange}
                            />
                            Superficial
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="secuentacontanquedealmace"
                              value="Subterráneo"
                              checked={dato.secuentacontanquedealmace.includes('Subterráneo')}
                              onChange={handleInputChange}
                            />
                            Subterráneo
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="secuentacontanquedealmace"
                              value="No tiene tanque de almancenamiento"
                              checked={dato.secuentacontanquedealmace.includes('No tiene tanque de almancenamiento')}
                              onChange={handleInputChange}
                            />
                            No tiene tanque de almancenamiento
                          </label>
                        </div>
                        {!dato.secuentacontanquedealmace.length && <div className="text-danger">Campo obligatorio</div>}
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <div>
                        <label class="form-label">Se realiza alguno de los siguientes tipos de tratamiento casero al agua antes del consumo</label>
                          {dato.serealizaalgunodelossigui.map((valor, index) => (
                            !otrosserealizaalgunodelossiguiarr.includes(valor) && (
                              <div class="form-check" key={index}>
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name="serealizaalgunodelossigui"
                                    value={valor}
                                    checked={dato.serealizaalgunodelossigui.includes(valor)}
                                    onChange={handleInputChange}
                                  />
                                  {valor}
                                </label>
                              </div>
                            )
                          ))}
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="serealizaalgunodelossigui"
                              value="Asentamiento de solidos suspendidos o clarificación"
                              checked={dato.serealizaalgunodelossigui.includes('Asentamiento de solidos suspendidos o clarificación')}
                              onChange={handleInputChange}
                            />
                            Asentamiento de solidos suspendidos o clarificación
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="serealizaalgunodelossigui"
                              value="Filtración"
                              checked={dato.serealizaalgunodelossigui.includes('Filtración')}
                              onChange={handleInputChange}
                            />
                            Filtración
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="serealizaalgunodelossigui"
                              value="Desinfección con cloro o con otras sustancias"
                              checked={dato.serealizaalgunodelossigui.includes('Desinfección con cloro o con otras sustancias')}
                              onChange={handleInputChange}
                            />
                            Desinfección con cloro o con otras sustancias
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="serealizaalgunodelossigui"
                              value="Hervido"
                              checked={dato.serealizaalgunodelossigui.includes('Hervido')}
                              onChange={handleInputChange}
                            />
                            Hervido
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="serealizaalgunodelossigui"
                              value="Ninguno"
                              checked={dato.serealizaalgunodelossigui.includes('Ninguno')}
                              onChange={handleInputChange}
                            />
                            Ninguno
                          </label>
                        </div>
                        <div class="input-group">
                          <div class="input-group-text">
                              <input
                                type="checkbox"
                                class="form-check-input mt-0"
                                name="serealizaalgunodelossigui"
                                value={otrosserealizaalgunodelossigui}
                                checked={dato.serealizaalgunodelossigui.includes(otrosserealizaalgunodelossigui)}
                                onChange={handleInputChange}
                                //onChange={(e) => dato.serealizaalgunodelossigui(e.target.checked)}
                              />
                            </div>
                            <input type="text" class="form-control" aria-label="Text input with checkbox" 
                              placeholder="Digite otras opciones de ser necesario"
                              value={otrosserealizaalgunodelossigui}
                              name="otrosserealizaalgunodelossigui"
                              //onChange={handleInputChange}
                              //onBlur={handleInputBlur}
                              onChange={(event) => handleInputChange(event, setOtrosSerealizaalgunodelossigui)}
                              onBlur={() => handleInputBlur(otrosserealizaalgunodelossigui, 'serealizaalgunodelossigui')}
                              />
                        </div>
                        {!dato.serealizaalgunodelossigui.length && <div className="text-danger">Campo obligatorio</div>}
                      </div>
                    </div>    
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">              
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">¿Como se realiza el almacenamiento de agua para consumo humano en la vivienda?</label>
                      <select className={`form-select ${!dato.comoserealizaelalmacenami ? 'is-invalid' : ''}`} name='comoserealizaelalmacenami' value={dato.comoserealizaelalmacenami} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Aereo">Aereo</option>
                        <option value="Superficial">Superficial</option>
                        <option value="Subterráneo">Subterráneo</option>
                        <option value="No tiene tanque de almancenamiento">No tiene tanque de almancenamiento</option>
                      </select>
                      {!dato.comoserealizaelalmacenami && <div className="invalid-feedback">Campo obligatorio</div>}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <div>
                        <label class="form-label">¿Cual es la frecuencia de lavado del tanque de almacenamiento de agua para consumo humano en  la vivienda?</label>
                          {dato.cualeslafrecuenciadelavad.map((valor, index) => (
                            !otroscualeslafrecuenciadelavadarr.includes(valor) && (
                              <div class="form-check" key={index}>
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name="cualeslafrecuenciadelavad"
                                    value={valor}
                                    checked={dato.cualeslafrecuenciadelavad.includes(valor)}
                                    onChange={handleInputChange}
                                  />
                                  {valor}
                                </label>
                              </div>
                            )
                          ))}
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="cualeslafrecuenciadelavad"
                              value="Despues de cada uso"
                              checked={dato.cualeslafrecuenciadelavad.includes('Despues de cada uso')}
                              onChange={handleInputChange}
                            />
                            Despues de cada uso
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="cualeslafrecuenciadelavad"
                              value="Semestral"
                              checked={dato.cualeslafrecuenciadelavad.includes('Semestral')}
                              onChange={handleInputChange}
                            />
                            Semestral
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="cualeslafrecuenciadelavad"
                              value="No realiza lavado"
                              checked={dato.cualeslafrecuenciadelavad.includes('No realiza lavado')}
                              onChange={handleInputChange}
                            />
                            No realiza lavado
                          </label>
                        </div>
                        <div class="input-group">
                          <div class="input-group-text">
                              <input
                                type="checkbox"
                                class="form-check-input mt-0"
                                name="cualeslafrecuenciadelavad"
                                value={otroscualeslafrecuenciadelavad}
                                checked={dato.cualeslafrecuenciadelavad.includes(otroscualeslafrecuenciadelavad)}
                                onChange={handleInputChange}
                                //onChange={(e) => dato.cualeslafrecuenciadelavad(e.target.checked)}
                              />
                            </div>
                            <input type="text" class="form-control" aria-label="Text input with checkbox" 
                              placeholder="Digite otras opciones de ser necesario"
                              value={otroscualeslafrecuenciadelavad}
                              name="otroscualeslafrecuenciadelavad"
                              //onChange={handleInputChange}
                              //onBlur={handleInputBlur}
                              onChange={(event) => handleInputChange(event, setOtrosCualeslafrecuenciadelavad)}
                              onBlur={() => handleInputBlur(otroscualeslafrecuenciadelavad, 'cualeslafrecuenciadelavad')}
                              />
                        </div>
                        {!dato.cualeslafrecuenciadelavad.length && <div className="text-danger">Campo obligatorio</div>}
                      </div>
                    </div>  
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">                
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">La distancia del tanque subterráneo de almacenamiento en relación al pozo séptico o punto de entrega al alcantarillado es:</label>
                      <select className={`form-select ${!dato.ladistanciadeltanquesubte ? 'is-invalid' : ''}`} name='ladistanciadeltanquesubte' value={dato.ladistanciadeltanquesubte} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Mas de 10 metros">Mas de 10 metros</option>
                        <option value="Entre 5 y 9 metros">Entre 5 y 9 metros</option>
                        <option value="Menor a 5 metros">Menor a 5 metros</option>
                      </select>
                      {!dato.ladistanciadeltanquesubte && <div className="invalid-feedback">Campo obligatorio</div>}
                    </div> 
                  </div>
                </div>     
              </div>
              <div class="m-2 mt-4 border border-primary-subtle"><h4 class="text"> 2.5.	SANEAMIENTO BASICO</h4>            
                <div class="row">
                  <div class="col-12 col-md-6 col-lg-4">  
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <div>
                        <label class="form-label">¿Que sistema de disposición de excretas tiene la vivienda?</label>
                          {dato.quesistemadedisposiciondeex.map((valor, index) => (
                            !otrosquesistemadedisposiciondeexarr.includes(valor) && (
                              <div class="form-check" key={index}>
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name="quesistemadedisposiciondeex"
                                    value={valor}
                                    checked={dato.quesistemadedisposiciondeex.includes(valor)}
                                    onChange={handleInputChange}
                                  />
                                  {valor}
                                </label>
                              </div>
                            )
                          ))}
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="quesistemadedisposiciondeex"
                              value="Sanitario conectado al alcantarillado"
                              checked={dato.quesistemadedisposiciondeex.includes('Sanitario conectado al alcantarillado')}
                              onChange={handleInputChange}
                            />
                            Sanitario conectado al alcantarillado
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="quesistemadedisposiciondeex"
                              value="Sanitario y letrina"
                              checked={dato.quesistemadedisposiciondeex.includes('Sanitario y letrina')}
                              onChange={handleInputChange}
                            />
                            Sanitario y letrina
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="quesistemadedisposiciondeex"
                              value="Sanitario conectado a pozo séptico"
                              checked={dato.quesistemadedisposiciondeex.includes('Sanitario conectado a pozo séptico')}
                              onChange={handleInputChange}
                            />
                            Sanitario conectado a pozo séptico
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="quesistemadedisposiciondeex"
                              value="Sanitario ecológico seco"
                              checked={dato.quesistemadedisposiciondeex.includes('Sanitario ecológico seco')}
                              onChange={handleInputChange}
                            />
                            Sanitario ecológico seco
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="quesistemadedisposiciondeex"
                              value="Sanitario sin conexión"
                              checked={dato.quesistemadedisposiciondeex.includes('Sanitario sin conexión')}
                              onChange={handleInputChange}
                            />
                            Sanitario sin conexión
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="quesistemadedisposiciondeex"
                              value="Sanitario con disposición a fuente hídrica"
                              checked={dato.quesistemadedisposiciondeex.includes('Sanitario con disposición a fuente hídrica')}
                              onChange={handleInputChange}
                            />
                            Sanitario con disposición a fuente hídrica
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="quesistemadedisposiciondeex"
                              value="Campo abierto"
                              checked={dato.quesistemadedisposiciondeex.includes('Campo abierto')}
                              onChange={handleInputChange}
                            />
                            Campo abierto
                          </label>
                        </div>
                        <div class="input-group">
                          <div class="input-group-text">
                              <input
                                type="checkbox"
                                class="form-check-input mt-0"
                                name="quesistemadedisposiciondeex"
                                value={otrosquesistemadedisposiciondeex}
                                checked={dato.quesistemadedisposiciondeex.includes(otrosquesistemadedisposiciondeex)}
                                onChange={handleInputChange}
                                //onChange={(e) => dato.quesistemadedisposiciondeex(e.target.checked)}
                              />
                            </div>
                            <input type="text" class="form-control" aria-label="Text input with checkbox" 
                              placeholder="Digite otras opciones de ser necesario"
                              value={otrosquesistemadedisposiciondeex}
                              name="otrosquesistemadedisposiciondeex"
                              //onChange={handleInputChange}
                              //onBlur={handleInputBlur}
                              onChange={(event) => handleInputChange(event, setOtrosQuesistemadedisposiciondeex)}
                              onBlur={() => handleInputBlur(otrosquesistemadedisposiciondeex, 'quesistemadedisposiciondeex')}
                              />
                        </div>
                        {!dato.quesistemadedisposiciondeex.length && <div className="text-danger">Campo obligatorio</div>}
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <div>
                        <label class="form-label">¿Que sistema de disposición de aguas residuales domesticas tiene la vivienda?</label>
                          {dato.quesistemadedisposiciondeag.map((valor, index) => (
                            !otrosquesistemadedisposiciondeagarr.includes(valor) && (
                              <div class="form-check" key={index}>
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name="quesistemadedisposiciondeag"
                                    value={valor}
                                    checked={dato.quesistemadedisposiciondeag.includes(valor)}
                                    onChange={handleInputChange}
                                  />
                                  {valor}
                                </label>
                              </div>
                            )
                          ))}
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="quesistemadedisposiciondeag"
                              value="Pozo séptico"
                              checked={dato.quesistemadedisposiciondeag.includes('Pozo séptico')}
                              onChange={handleInputChange}
                            />
                            Pozo séptico
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="quesistemadedisposiciondeag"
                              value="Campo de oxidación"
                              checked={dato.quesistemadedisposiciondeag.includes('Campo de oxidación')}
                              onChange={handleInputChange}
                            />
                            Campo de oxidación
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="quesistemadedisposiciondeag"
                              value="Biofiltro"
                              checked={dato.quesistemadedisposiciondeag.includes('Biofiltro')}
                              onChange={handleInputChange}
                            />
                            Biofiltro
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="quesistemadedisposiciondeag"
                              value="Fuente hídrica"
                              checked={dato.quesistemadedisposiciondeag.includes('Fuente hídrica')}
                              onChange={handleInputChange}
                            />
                            Fuente hídrica
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="quesistemadedisposiciondeag"
                              value="Campo abierto"
                              checked={dato.quesistemadedisposiciondeag.includes('Campo abierto')}
                              onChange={handleInputChange}
                            />
                            Campo abierto
                          </label>
                        </div>
                        <div class="input-group">
                          <div class="input-group-text">
                              <input
                                type="checkbox"
                                class="form-check-input mt-0"
                                name="quesistemadedisposiciondeag"
                                value={otrosquesistemadedisposiciondeag}
                                checked={dato.quesistemadedisposiciondeag.includes(otrosquesistemadedisposiciondeag)}
                                onChange={handleInputChange}
                                //onChange={(e) => dato.quesistemadedisposiciondeag(e.target.checked)}
                              />
                            </div>
                            <input type="text" class="form-control" aria-label="Text input with checkbox" 
                              placeholder="Digite otras opciones de ser necesario"
                              value={otrosquesistemadedisposiciondeag}
                              name="otrosquesistemadedisposiciondeag"
                              //onChange={handleInputChange}
                              //onBlur={handleInputBlur}
                              onChange={(event) => handleInputChange(event, setOtrosQuesistemadedisposiciondeag)}
                              onBlur={() => handleInputBlur(otrosquesistemadedisposiciondeag, 'quesistemadedisposiciondeag')}
                              />
                        </div>
                        {!dato.quesistemadedisposiciondeag.length && <div className="text-danger">Campo obligatorio</div>}
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Indique a que distancia esta ubicado el sistema de disposición de excretas y/o aguas residuales  domesticas que tiene la vivienda con respecto a: VIVIENDA</label>
                      <input
                        type="number"
                        name='indiqueaquedistanciaestauvi'
                        className='form-control'
                        // className={`form-control ${!dato.indiqueaquedistanciaestauvi ? 'is-invalid' : ''}`}
                        value={dato.indiqueaquedistanciaestauvi}
                        onChange={handleInputChange}
                      />
                      {/* {!dato.indiqueaquedistanciaestauvi && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Indique a que distancia esta ubicado el sistema de disposición de excretas y/o aguas residuales  domesticas que tiene la vivienda con respecto a: Fuentes de abastecimiento de agua superficial o subterranea</label>
                      <input
                        type="number"
                        name='indiqueaquedistanciaestaufu'
                        className='form-control'
                        // className={`form-control ${!dato.indiqueaquedistanciaestaufu ? 'is-invalid' : ''}`}
                        value={dato.indiqueaquedistanciaestaufu}
                        onChange={handleInputChange}
                      />
                      {/* {!dato.indiqueaquedistanciaestaufu && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Indique a que distancia esta ubicado el sistema de disposición de excretas y/o aguas residuales  domesticas que tiene la vivienda con respecto a: Rios y Quebradas</label>
                      <input
                        type="number"
                        name='indiqueaquedistanciaestauri'
                        className='form-control'
                        // className={`form-control ${!dato.indiqueaquedistanciaestauri ? 'is-invalid' : ''}`}
                        value={dato.indiqueaquedistanciaestauri}
                        onChange={handleInputChange}
                      />
                      {/* {!dato.indiqueaquedistanciaestauri && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Indique a que distancia esta ubicado el sistema de disposición de excretas y/o aguas residuales  domesticas que tiene la vivienda con respecto a: Tanques subterraneos de agua</label>
                      <input
                        type="number"
                        name='indiqueaquedistanciaestauta'
                        className='form-control'
                        // className={`form-control ${!dato.indiqueaquedistanciaestauta ? 'is-invalid' : ''}`}
                        value={dato.indiqueaquedistanciaestauta}
                        onChange={handleInputChange}
                      />
                      {/* {!dato.indiqueaquedistanciaestauta && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Indique a que distancia esta ubicado el sistema de disposición de excretas y/o aguas residuales  domesticas que tiene la vivienda con respecto a: Otros</label>
                      <input
                        type="number"
                        name='indiqueaquedistanciaestauot'
                        className='form-control'
                        // className={`form-control ${!dato.indiqueaquedistanciaestauot ? 'is-invalid' : ''}`}
                        value={dato.indiqueaquedistanciaestauot}
                        onChange={handleInputChange}
                      />
                      {/* {!dato.indiqueaquedistanciaestauot && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <div>
                        <label class="form-label">Los residuos sólidos de la vivienda se almacenan en:</label>
                          {dato.losresiduossolidosdelaviv.map((valor, index) => (
                            !otroslosresiduossolidosdelavivarr.includes(valor) && (
                              <div class="form-check" key={index}>
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name="losresiduossolidosdelaviv"
                                    value={valor}
                                    checked={dato.losresiduossolidosdelaviv.includes(valor)}
                                    onChange={handleInputChange}
                                  />
                                  {valor}
                                </label>
                              </div>
                            )
                          ))}
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="losresiduossolidosdelaviv"
                              value="Recipientes con tapa"
                              checked={dato.losresiduossolidosdelaviv.includes('Recipientes con tapa')}
                              onChange={handleInputChange}
                            />
                            Recipientes con tapa
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="losresiduossolidosdelaviv"
                              value="Recipientes sin tapa"
                              checked={dato.losresiduossolidosdelaviv.includes('Recipientes sin tapa')}
                              onChange={handleInputChange}
                            />
                            Recipientes sin tapa
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="losresiduossolidosdelaviv"
                              value="Directamente al suelo"
                              checked={dato.losresiduossolidosdelaviv.includes('Directamente al suelo')}
                              onChange={handleInputChange}
                            />
                            Directamente al suelo
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="losresiduossolidosdelaviv"
                              value="Bolsas plásticas"
                              checked={dato.losresiduossolidosdelaviv.includes('Bolsas plásticas')}
                              onChange={handleInputChange}
                            />
                            Bolsas plásticas
                          </label>
                        </div>
                        <div class="input-group">
                          <div class="input-group-text">
                              <input
                                type="checkbox"
                                class="form-check-input mt-0"
                                name="losresiduossolidosdelaviv"
                                value={otroslosresiduossolidosdelaviv}
                                checked={dato.losresiduossolidosdelaviv.includes(otroslosresiduossolidosdelaviv)}
                                onChange={handleInputChange}
                                //onChange={(e) => dato.losresiduossolidosdelaviv(e.target.checked)}
                              />
                            </div>
                            <input type="text" class="form-control" aria-label="Text input with checkbox" 
                              placeholder="Digite otras opciones de ser necesario"
                              value={otroslosresiduossolidosdelaviv}
                              name="otroslosresiduossolidosdelaviv"
                              //onChange={handleInputChange}
                              //onBlur={handleInputBlur}
                              onChange={(event) => handleInputChange(event, setOtrosLosresiduossolidosdelaviv)}
                              onBlur={() => handleInputBlur(otroslosresiduossolidosdelaviv, 'losresiduossolidosdelaviv')}
                              />
                        </div>
                        {!dato.losresiduossolidosdelaviv.length && <div className="text-danger">Campo obligatorio</div>}
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <div>
                        <label class="form-label">¿Como se realiza la disposición final de los residuos sólidos ordinarios de la vivienda? (puede señalar varias opciones)</label>
                          {dato.comoserealizaladisposicioso.map((valor, index) => (
                            !otroscomoserealizaladisposiciosoarr.includes(valor) && (
                              <div class="form-check" key={index}>
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name="comoserealizaladisposicioso"
                                    value={valor}
                                    checked={dato.comoserealizaladisposicioso.includes(valor)}
                                    onChange={handleInputChange}
                                  />
                                  {valor}
                                </label>
                              </div>
                            )
                          ))}
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="comoserealizaladisposicioso"
                              value="Recolección por parte del servicio de aseo distrital o municipal"
                              checked={dato.comoserealizaladisposicioso.includes('Recolección por parte del servicio de aseo distrital o municipal')}
                              onChange={handleInputChange}
                            />
                            Recolección por parte del servicio de aseo distrital o municipal
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="comoserealizaladisposicioso"
                              value="Enterramiento"
                              checked={dato.comoserealizaladisposicioso.includes('Enterramiento')}
                              onChange={handleInputChange}
                            />
                            Enterramiento
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="comoserealizaladisposicioso"
                              value="Quema a campo abierto"
                              checked={dato.comoserealizaladisposicioso.includes('Quema a campo abierto')}
                              onChange={handleInputChange}
                            />
                            Quema a campo abierto
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="comoserealizaladisposicioso"
                              value="Disposición en fuentes de agua cercana"
                              checked={dato.comoserealizaladisposicioso.includes('Disposición en fuentes de agua cercana')}
                              onChange={handleInputChange}
                            />
                            Disposición en fuentes de agua cercana
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="comoserealizaladisposicioso"
                              value="Disposición a campo abierto"
                              checked={dato.comoserealizaladisposicioso.includes('Disposición a campo abierto')}
                              onChange={handleInputChange}
                            />
                            Disposición a campo abierto
                          </label>
                        </div>
                        <div class="input-group">
                          <div class="input-group-text">
                              <input
                                type="checkbox"
                                class="form-check-input mt-0"
                                name="comoserealizaladisposicioso"
                                value={otroscomoserealizaladisposicioso}
                                checked={dato.comoserealizaladisposicioso.includes(otroscomoserealizaladisposicioso)}
                                onChange={handleInputChange}
                                //onChange={(e) => dato.comoserealizaladisposicioso(e.target.checked)}
                              />
                            </div>
                            <input type="text" class="form-control" aria-label="Text input with checkbox" 
                              placeholder="Digite otras opciones de ser necesario"
                              value={otroscomoserealizaladisposicioso}
                              name="otroscomoserealizaladisposicioso"
                              //onChange={handleInputChange}
                              //onBlur={handleInputBlur}
                              onChange={(event) => handleInputChange(event, setOtrosComoserealizaladisposicioso)}
                              onBlur={() => handleInputBlur(otroscomoserealizaladisposicioso, 'comoserealizaladisposicioso')}
                              />
                        </div>
                        {!dato.comoserealizaladisposicioso.length && <div className="text-danger">Campo obligatorio</div>}
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <div>
                        <label class="form-label">¿Cómo se realiza la disposicion final de los residuos peligrosos (recipientes de productos de  uso domestico, medicamentos, plaguicidas, RAEES, entre otros)?</label>
                          {dato.comoserealizaladisposiciope.map((valor, index) => (
                            !otroscomoserealizaladisposiciopearr.includes(valor) && (
                              <div class="form-check" key={index}>
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name="comoserealizaladisposiciope"
                                    value={valor}
                                    checked={dato.comoserealizaladisposiciope.includes(valor)}
                                    onChange={handleInputChange}
                                  />
                                  {valor}
                                </label>
                              </div>
                            )
                          ))}
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="comoserealizaladisposiciope"
                              value="Recolectados por empresas especializadas"
                              checked={dato.comoserealizaladisposiciope.includes('Recolectados por empresas especializadas')}
                              onChange={handleInputChange}
                            />
                            Recolectados por empresas especializadas
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="comoserealizaladisposiciope"
                              value="Sitios autorizados para su recolección"
                              checked={dato.comoserealizaladisposiciope.includes('Sitios autorizados para su recolección')}
                              onChange={handleInputChange}
                            />
                            Sitios autorizados para su recolección
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="comoserealizaladisposiciope"
                              value="Centros de acopio"
                              checked={dato.comoserealizaladisposiciope.includes('Centros de acopio')}
                              onChange={handleInputChange}
                            />
                            Centros de acopio
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="comoserealizaladisposiciope"
                              value="Enterramiento"
                              checked={dato.comoserealizaladisposiciope.includes('Enterramiento')}
                              onChange={handleInputChange}
                            />
                            Enterramiento
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="comoserealizaladisposiciope"
                              value="Quema a campo abierto"
                              checked={dato.comoserealizaladisposiciope.includes('Quema a campo abierto')}
                              onChange={handleInputChange}
                            />
                            Quema a campo abierto
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="comoserealizaladisposiciope"
                              value="Disposición en fuentes de agua cercana"
                              checked={dato.comoserealizaladisposiciope.includes('Disposición en fuentes de agua cercana')}
                              onChange={handleInputChange}
                            />
                            Disposición en fuentes de agua cercana
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="comoserealizaladisposiciope"
                              value="Disposición a campo abierto"
                              checked={dato.comoserealizaladisposiciope.includes('Disposición a campo abierto')}
                              onChange={handleInputChange}
                            />
                            Disposición a campo abierto
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="comoserealizaladisposiciope"
                              value="En conjunto con los residuos ordinarios de la vivienda"
                              checked={dato.comoserealizaladisposiciope.includes('En conjunto con los residuos ordinarios de la vivienda')}
                              onChange={handleInputChange}
                            />
                            En conjunto con los residuos ordinarios de la vivienda
                          </label>
                        </div>
                        <div class="input-group">
                          <div class="input-group-text">
                              <input
                                type="checkbox"
                                class="form-check-input mt-0"
                                name="comoserealizaladisposiciope"
                                value={otroscomoserealizaladisposiciope}
                                checked={dato.comoserealizaladisposiciope.includes(otroscomoserealizaladisposiciope)}
                                onChange={handleInputChange}
                                //onChange={(e) => dato.comoserealizaladisposiciope(e.target.checked)}
                              />
                            </div>
                            <input type="text" class="form-control" aria-label="Text input with checkbox" 
                              placeholder="Digite otras opciones de ser necesario"
                              value={otroscomoserealizaladisposiciope}
                              name="otroscomoserealizaladisposiciope"
                              //onChange={handleInputChange}
                              //onBlur={handleInputBlur}
                              onChange={(event) => handleInputChange(event, setOtrosComoserealizaladisposiciope)}
                              onBlur={() => handleInputBlur(otroscomoserealizaladisposiciope, 'comoserealizaladisposiciope')}
                              />
                        </div>
                        {!dato.comoserealizaladisposiciope.length && <div className="text-danger">Campo obligatorio</div>}
                      </div>
                    </div>   
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">               
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Conocen en la familia las prácticas de reducción de generación de residuos en la fuente, aprovechamiento y separación de residuos</label>
                      <select className={`form-select ${!dato.conocenenlafamilialasprac ? 'is-invalid' : ''}`} name='conocenenlafamilialasprac' value={dato.conocenenlafamilialasprac} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Si">Si</option>
                        <option value="No">No</option>
                      </select>
                      {!dato.conocenenlafamilialasprac && <div className="invalid-feedback">Campo obligatorio</div>}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <div>
                        <label class="form-label">¿Qué prácticas de reducción de generación de residuos en la fuente realiza?</label>
                          {dato.quepracticasdereduccionde.map((valor, index) => (
                            !otrosquepracticasdereducciondearr.includes(valor) && (
                              <div class="form-check" key={index}>
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name="quepracticasdereduccionde"
                                    value={valor}
                                    checked={dato.quepracticasdereduccionde.includes(valor)}
                                    onChange={handleInputChange}
                                  />
                                  {valor}
                                </label>
                              </div>
                            )
                          ))}
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="quepracticasdereduccionde"
                              value="Se evita el uso de productos de un solo uso como botellas, vajillas y cubiertos desechables, entre otros"
                              checked={dato.quepracticasdereduccionde.includes('Se evita el uso de productos de un solo uso como botellas, vajillas y cubiertos desechables, entre otros')}
                              onChange={handleInputChange}
                            />
                            Se evita el uso de productos de un solo uso como botellas, vajillas y cubiertos desechables, entre otros
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="quepracticasdereduccionde"
                              value="Se reutilizan materiales que hayan cumplido su primer ciclo, como el papel y el cartón"
                              checked={dato.quepracticasdereduccionde.includes('Se reutilizan materiales que hayan cumplido su primer ciclo, como el papel y el cartón')}
                              onChange={handleInputChange}
                            />
                            Se reutilizan materiales que hayan cumplido su primer ciclo, como el papel y el cartón
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="quepracticasdereduccionde"
                              value="Eliminación o reutilización de aparatos electricos a través de las empresas especializadas, como computadores, cartuchos de impresoras, enttre otros"
                              checked={dato.quepracticasdereduccionde.includes('Eliminación o reutilización de aparatos electricos a través de las empresas especializadas, como computadores, cartuchos de impresoras, enttre otros')}
                              onChange={handleInputChange}
                            />
                            Eliminación o reutilización de aparatos electricos a través de las empresas especializadas, como computadores, cartuchos de impresoras, enttre otros
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="quepracticasdereduccionde"
                              value="Se evita el uso de ambientadores o artificiales o aerosoles"
                              checked={dato.quepracticasdereduccionde.includes('Se evita el uso de ambientadores o artificiales o aerosoles')}
                              onChange={handleInputChange}
                            />
                            Se evita el uso de ambientadores o artificiales o aerosoles
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="quepracticasdereduccionde"
                              value="Se compra a grnael usando los propios envases o maletas para empacar"
                              checked={dato.quepracticasdereduccionde.includes('Se compra a grnael usando los propios envases o maletas para empacar')}
                              onChange={handleInputChange}
                            />
                            Se compra a grnael usando los propios envases o maletas para empacar
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="quepracticasdereduccionde"
                              value="Ninguna"
                              checked={dato.quepracticasdereduccionde.includes('Ninguna')}
                              onChange={handleInputChange}
                            />
                            Ninguna
                          </label>
                        </div>
                        <div class="input-group">
                          <div class="input-group-text">
                              <input
                                type="checkbox"
                                class="form-check-input mt-0"
                                name="quepracticasdereduccionde"
                                value={otrosquepracticasdereduccionde}
                                checked={dato.quepracticasdereduccionde.includes(otrosquepracticasdereduccionde)}
                                onChange={handleInputChange}
                                //onChange={(e) => dato.quepracticasdereduccionde(e.target.checked)}
                              />
                            </div>
                            <input type="text" class="form-control" aria-label="Text input with checkbox" 
                              placeholder="Digite otras opciones de ser necesario"
                              value={otrosquepracticasdereduccionde}
                              name="otrosquepracticasdereduccionde"
                              //onChange={handleInputChange}
                              //onBlur={handleInputBlur}
                              onChange={(event) => handleInputChange(event, setOtrosQuepracticasdereduccionde)}
                              onBlur={() => handleInputBlur(otrosquepracticasdereduccionde, 'quepracticasdereduccionde')}
                              />
                        </div>
                        {!dato.quepracticasdereduccionde.length && <div className="text-danger">Campo obligatorio</div>}
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <div>
                        <label class="form-label">¿Qué prácticas de aprovechamiento de residuos realiza (rural)?</label>
                          {dato.quepracticasdeaprovechami.map((valor, index) => (
                            !otrosquepracticasdeaprovechamiarr.includes(valor) && (
                              <div class="form-check" key={index}>
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name="quepracticasdeaprovechami"
                                    value={valor}
                                    checked={dato.quepracticasdeaprovechami.includes(valor)}
                                    onChange={handleInputChange}
                                  />
                                  {valor}
                                </label>
                              </div>
                            )
                          ))}
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="quepracticasdeaprovechami"
                              value="Compostaje"
                              checked={dato.quepracticasdeaprovechami.includes('Compostaje')}
                              onChange={handleInputChange}
                            />
                            Compostaje
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="quepracticasdeaprovechami"
                              value="Lombricultivo"
                              checked={dato.quepracticasdeaprovechami.includes('Lombricultivo')}
                              onChange={handleInputChange}
                            />
                            Lombricultivo
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="quepracticasdeaprovechami"
                              value="Biocombustible"
                              checked={dato.quepracticasdeaprovechami.includes('Biocombustible')}
                              onChange={handleInputChange}
                            />
                            Biocombustible
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="quepracticasdeaprovechami"
                              value="Biofertilizante"
                              checked={dato.quepracticasdeaprovechami.includes('Biofertilizante')}
                              onChange={handleInputChange}
                            />
                            Biofertilizante
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="quepracticasdeaprovechami"
                              value="Ninguna"
                              checked={dato.quepracticasdeaprovechami.includes('Ninguna')}
                              onChange={handleInputChange}
                            />
                            Ninguna
                          </label>
                        </div>
                        <div class="input-group">
                          <div class="input-group-text">
                              <input
                                type="checkbox"
                                class="form-check-input mt-0"
                                name="quepracticasdeaprovechami"
                                value={otrosquepracticasdeaprovechami}
                                checked={dato.quepracticasdeaprovechami.includes(otrosquepracticasdeaprovechami)}
                                onChange={handleInputChange}
                                //onChange={(e) => dato.quepracticasdeaprovechami(e.target.checked)}
                              />
                            </div>
                            <input type="text" class="form-control" aria-label="Text input with checkbox" 
                              placeholder="Digite otras opciones de ser necesario"
                              value={otrosquepracticasdeaprovechami}
                              name="otrosquepracticasdeaprovechami"
                              //onChange={handleInputChange}
                              //onBlur={handleInputBlur}
                              onChange={(event) => handleInputChange(event, setOtrosQuepracticasdeaprovechami)}
                              onBlur={() => handleInputBlur(otrosquepracticasdeaprovechami, 'quepracticasdeaprovechami')}
                              />
                        </div>
                        {!dato.quepracticasdeaprovechami.length && <div className="text-danger">Campo obligatorio</div>}
                      </div>
                    </div> 
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">                 
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">¿Qué prácticas de separación en la fuente de residuos realiza (rural)?</label>
                      <select className={`form-select ${!dato.quepracticasdeseparacione ? 'is-invalid' : ''}`} name='quepracticasdeseparacione' value={dato.quepracticasdeseparacione} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Separación de resiudos aprovechables (papel, cartón, vidrio, plástico, tetrapack, metal) ">Separación de resiudos aprovechables (papel, cartón, vidrio, plástico, tetrapack, metal) </option>
                        <option value="Separación de resiudos no aprovechables ( Icopor, pañales, toallas higiénicas, cerámicas, papel carbón, entre otros) ">Separación de resiudos no aprovechables ( Icopor, pañales, toallas higiénicas, cerámicas, papel carbón, entre otros) </option>
                        <option value="Separación de residuos orgánicos (residuos de alimentos, restos vegetales de la poda y jardinería, restos de la carpintería, entre otros) ">Separación de residuos orgánicos (residuos de alimentos, restos vegetales de la poda y jardinería, restos de la carpintería, entre otros) </option>
                        <option value="Ninguna">Ninguna</option>
                      </select>
                      {!dato.quepracticasdeseparacione && <div className="invalid-feedback">Campo obligatorio</div>}
                    </div> 
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">                 
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Se realizan prácticas adecuadas de reducción de generación de residuos en la fuente</label>
                      <select className={`form-select ${!dato.serealizanpracticasadecuaap ? 'is-invalid' : ''}`} name='serealizanpracticasadecuaap' value={dato.serealizanpracticasadecuaap} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Si">Si</option>
                        <option value="No">No</option>
                      </select>
                      {!dato.serealizanpracticasadecuaap && <div className="invalid-feedback">Campo obligatorio</div>}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Se realizan prácticas adecuadas de aprovechamiento de residuos</label>
                      <select className={`form-select ${!dato.serealizanpracticasadecuase ? 'is-invalid' : ''}`} name='serealizanpracticasadecuase' value={dato.serealizanpracticasadecuase} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Si">Si</option>
                        <option value="No">No</option>
                      </select>
                      {!dato.serealizanpracticasadecuase && <div className="invalid-feedback">Campo obligatorio</div>}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Se realiza el proceso de separación de los residuos en la fuente</label>
                      <select className={`form-select ${!dato.serealizaelprocesodesepar ? 'is-invalid' : ''}`} name='serealizaelprocesodesepar' value={dato.serealizaelprocesodesepar} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Si">Si</option>
                        <option value="No">No</option>
                      </select>
                      {!dato.serealizaelprocesodesepar && <div className="invalid-feedback">Campo obligatorio</div>}
                    </div>  
                  </div>
                </div>
              </div>
              <div class="m-2 mt-4 border border-primary-subtle"><h4 class="text"> 2.6.	HIGIENE</h4>            
                <div class="row">
                  <div class="col-12 col-md-6">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <div>
                        <label class="form-label">¿Cómo se realiza la limpieza de pisos en la vivienda? (señale una sola opción, la más predominante)</label>
                          {dato.comoserealizalalimpiezadepi.map((valor, index) => (
                            !otroscomoserealizalalimpiezadepiarr.includes(valor) && (
                              <div class="form-check" key={index}>
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name="comoserealizalalimpiezadepi"
                                    value={valor}
                                    checked={dato.comoserealizalalimpiezadepi.includes(valor)}
                                    onChange={handleInputChange}
                                  />
                                  {valor}
                                </label>
                              </div>
                            )
                          ))}
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="comoserealizalalimpiezadepi"
                              value="Barrido"
                              checked={dato.comoserealizalalimpiezadepi.includes('Barrido')}
                              onChange={handleInputChange}
                            />
                            Barrido
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="comoserealizalalimpiezadepi"
                              value="Trapeado"
                              checked={dato.comoserealizalalimpiezadepi.includes('Trapeado')}
                              onChange={handleInputChange}
                            />
                            Trapeado
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="comoserealizalalimpiezadepi"
                              value="Barrido y trapeado"
                              checked={dato.comoserealizalalimpiezadepi.includes('Barrido y trapeado')}
                              onChange={handleInputChange}
                            />
                            Barrido y trapeado
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="comoserealizalalimpiezadepi"
                              value="Barrido, trapeado y desinfección"
                              checked={dato.comoserealizalalimpiezadepi.includes('Barrido, trapeado y desinfección')}
                              onChange={handleInputChange}
                            />
                            Barrido, trapeado y desinfección
                          </label>
                        </div>
                        <div class="input-group">
                          <div class="input-group-text">
                              <input
                                type="checkbox"
                                class="form-check-input mt-0"
                                name="comoserealizalalimpiezadepi"
                                value={otroscomoserealizalalimpiezadepi}
                                checked={dato.comoserealizalalimpiezadepi.includes(otroscomoserealizalalimpiezadepi)}
                                onChange={handleInputChange}
                                //onChange={(e) => dato.comoserealizalalimpiezadepi(e.target.checked)}
                              />
                            </div>
                            <input type="text" class="form-control" aria-label="Text input with checkbox" 
                              placeholder="Digite otras opciones de ser necesario"
                              value={otroscomoserealizalalimpiezadepi}
                              name="otroscomoserealizalalimpiezadepi"
                              //onChange={handleInputChange}
                              //onBlur={handleInputBlur}
                              onChange={(event) => handleInputChange(event, setOtrosComoserealizalalimpiezadepi)}
                              onBlur={() => handleInputBlur(otroscomoserealizalalimpiezadepi, 'comoserealizalalimpiezadepi')}
                              />
                        </div>
                        {!dato.comoserealizalalimpiezadepi.length && <div className="text-danger">Campo obligatorio</div>}
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <div>
                        <label class="form-label">¿Cómo se realiza la limpieza de paredes en la vivienda? (señale una sola opción, la más predominante)</label>
                          {dato.comoserealizalalimpiezadepa.map((valor, index) => (
                            !otroscomoserealizalalimpiezadepaarr.includes(valor) && (
                              <div class="form-check" key={index}>
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name="comoserealizalalimpiezadepa"
                                    value={valor}
                                    checked={dato.comoserealizalalimpiezadepa.includes(valor)}
                                    onChange={handleInputChange}
                                  />
                                  {valor}
                                </label>
                              </div>
                            )
                          ))}
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="comoserealizalalimpiezadepa"
                              value="Barrido"
                              checked={dato.comoserealizalalimpiezadepa.includes('Barrido')}
                              onChange={handleInputChange}
                            />
                            Barrido
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="comoserealizalalimpiezadepa"
                              value="Trapeado"
                              checked={dato.comoserealizalalimpiezadepa.includes('Trapeado')}
                              onChange={handleInputChange}
                            />
                            Trapeado
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="comoserealizalalimpiezadepa"
                              value="Barrido y trapeado"
                              checked={dato.comoserealizalalimpiezadepa.includes('Barrido y trapeado')}
                              onChange={handleInputChange}
                            />
                            Barrido y trapeado
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="comoserealizalalimpiezadepa"
                              value="Barrido, trapeado y desinfección"
                              checked={dato.comoserealizalalimpiezadepa.includes('Barrido, trapeado y desinfección')}
                              onChange={handleInputChange}
                            />
                            Barrido, trapeado y desinfección
                          </label>
                        </div>
                        <div class="input-group">
                          <div class="input-group-text">
                              <input
                                type="checkbox"
                                class="form-check-input mt-0"
                                name="comoserealizalalimpiezadepa"
                                value={otroscomoserealizalalimpiezadepa}
                                checked={dato.comoserealizalalimpiezadepa.includes(otroscomoserealizalalimpiezadepa)}
                                onChange={handleInputChange}
                                //onChange={(e) => dato.comoserealizalalimpiezadepa(e.target.checked)}
                              />
                            </div>
                            <input type="text" class="form-control" aria-label="Text input with checkbox" 
                              placeholder="Digite otras opciones de ser necesario"
                              value={otroscomoserealizalalimpiezadepa}
                              name="otroscomoserealizalalimpiezadepa"
                              //onChange={handleInputChange}
                              //onBlur={handleInputBlur}
                              onChange={(event) => handleInputChange(event, setOtrosComoserealizalalimpiezadepa)}
                              onBlur={() => handleInputBlur(otroscomoserealizalalimpiezadepa, 'comoserealizalalimpiezadepa')}
                              />
                        </div>
                        {!dato.comoserealizalalimpiezadepa.length && <div className="text-danger">Campo obligatorio</div>}
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <div>
                        <label class="form-label">¿Cómo se realiza la limpieza de superficies en la vivienda? (señale una sola opción, la más predominante)</label>
                          {dato.comoserealizalalimpiezadesu.map((valor, index) => (
                            !otroscomoserealizalalimpiezadesuarr.includes(valor) && (
                              <div class="form-check" key={index}>
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name="comoserealizalalimpiezadesu"
                                    value={valor}
                                    checked={dato.comoserealizalalimpiezadesu.includes(valor)}
                                    onChange={handleInputChange}
                                  />
                                  {valor}
                                </label>
                              </div>
                            )
                          ))}
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="comoserealizalalimpiezadesu"
                              value="Retiro de polvo"
                              checked={dato.comoserealizalalimpiezadesu.includes('Retiro de polvo')}
                              onChange={handleInputChange}
                            />
                            Retiro de polvo
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="comoserealizalalimpiezadesu"
                              value="Retiro de polvo y limpieza con agua y jabón"
                              checked={dato.comoserealizalalimpiezadesu.includes('Retiro de polvo y limpieza con agua y jabón')}
                              onChange={handleInputChange}
                            />
                            Retiro de polvo y limpieza con agua y jabón
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="comoserealizalalimpiezadesu"
                              value="Retiro de polvo, limpieza con agua y jabón y desinfección"
                              checked={dato.comoserealizalalimpiezadesu.includes('Retiro de polvo, limpieza con agua y jabón y desinfección')}
                              onChange={handleInputChange}
                            />
                            Retiro de polvo, limpieza con agua y jabón y desinfección
                          </label>
                        </div>
                        <div class="input-group">
                          <div class="input-group-text">
                              <input
                                type="checkbox"
                                class="form-check-input mt-0"
                                name="comoserealizalalimpiezadesu"
                                value={otroscomoserealizalalimpiezadesu}
                                checked={dato.comoserealizalalimpiezadesu.includes(otroscomoserealizalalimpiezadesu)}
                                onChange={handleInputChange}
                                //onChange={(e) => dato.comoserealizalalimpiezadesu(e.target.checked)}
                              />
                            </div>
                            <input type="text" class="form-control" aria-label="Text input with checkbox" 
                              placeholder="Digite otras opciones de ser necesario"
                              value={otroscomoserealizalalimpiezadesu}
                              name="otroscomoserealizalalimpiezadesu"
                              //onChange={handleInputChange}
                              //onBlur={handleInputBlur}
                              onChange={(event) => handleInputChange(event, setOtrosComoserealizalalimpiezadesu)}
                              onBlur={() => handleInputBlur(otroscomoserealizalalimpiezadesu, 'comoserealizalalimpiezadesu')}
                              />
                        </div>
                        {!dato.comoserealizalalimpiezadesu.length && <div className="text-danger">Campo obligatorio</div>}
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">¿Los miembros de la familia se lavan las manos con agua y jabón?</label>
                      <select className={`form-select ${!dato.losmiembrosdelafamiliasel ? 'is-invalid' : ''}`} name='losmiembrosdelafamiliasel' value={dato.losmiembrosdelafamiliasel} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Si">Si</option>
                        <option value="No">No</option>
                      </select>
                      {!dato.losmiembrosdelafamiliasel && <div className="invalid-feedback">Campo obligatorio</div>}
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">¿Se comparten implementos de higiene personal entre los miembros de la familia?</label>
                      <div class="row align-items-start">
                        <div class="col-md-3">
                          <div>
                            <label class="form-label">[Pisos]</label>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehpi"
                                  value="Todos los días"
                                  checked={dato.secompartenimplementosdehpi.includes('Todos los días')}
                                  onChange={handleInputChange}
                                />
                                Todos los días
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehpi"
                                  value="Una vez a la semana"
                                  checked={dato.secompartenimplementosdehpi.includes('Una vez a la semana')}
                                  onChange={handleInputChange}
                                />
                              Una vez a la semana
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehpi"
                                  value="Una vez cada 15 días"
                                  checked={dato.secompartenimplementosdehpi.includes('Una vez cada 15 días')}
                                  onChange={handleInputChange}
                                />
                              Una vez cada 15 días
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehpi"
                                  value="Una vez al mes"
                                  checked={dato.secompartenimplementosdehpi.includes('Una vez al mes')}
                                  onChange={handleInputChange}
                                />
                              Una vez al mes
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehpi"
                                  value="Cada 6 meses"
                                  checked={dato.secompartenimplementosdehpi.includes('Cada 6 meses')}
                                  onChange={handleInputChange}
                                />
                              Cada 6 meses
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehpi"
                                  value="Cada Año"
                                  checked={dato.secompartenimplementosdehpi.includes('Cada Año')}
                                  onChange={handleInputChange}
                                />
                              Cada Año
                              </label>
                            </div>
                            {/* {!dato.secompartenimplementosdehpi.length && <div className="text-danger">Campo obligatorio</div>} */}
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div>
                            <label class="form-label">[Paredes]</label>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehpa"
                                  value="Todos los días"
                                  checked={dato.secompartenimplementosdehpa.includes('Todos los días')}
                                  onChange={handleInputChange}
                                />
                                Todos los días
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehpa"
                                  value="Una vez a la semana"
                                  checked={dato.secompartenimplementosdehpa.includes('Una vez a la semana')}
                                  onChange={handleInputChange}
                                />
                              Una vez a la semana
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehpa"
                                  value="Una vez cada 15 días"
                                  checked={dato.secompartenimplementosdehpa.includes('Una vez cada 15 días')}
                                  onChange={handleInputChange}
                                />
                              Una vez cada 15 días
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehpa"
                                  value="Una vez al mes"
                                  checked={dato.secompartenimplementosdehpa.includes('Una vez al mes')}
                                  onChange={handleInputChange}
                                />
                              Una vez al mes
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehpa"
                                  value="Cada 6 meses"
                                  checked={dato.secompartenimplementosdehpa.includes('Cada 6 meses')}
                                  onChange={handleInputChange}
                                />
                              Cada 6 meses
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehpa"
                                  value="Cada Año"
                                  checked={dato.secompartenimplementosdehpa.includes('Cada Año')}
                                  onChange={handleInputChange}
                                />
                              Cada Año
                              </label>
                            </div>
                            {/* {!dato.secompartenimplementosdehpa.length && <div className="text-danger">Campo obligatorio</div>} */}
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div>
                            <label class="form-label">[Techos Muebles]</label>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehte"
                                  value="Todos los días"
                                  checked={dato.secompartenimplementosdehte.includes('Todos los días')}
                                  onChange={handleInputChange}
                                />
                                Todos los días
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehte"
                                  value="Una vez a la semana"
                                  checked={dato.secompartenimplementosdehte.includes('Una vez a la semana')}
                                  onChange={handleInputChange}
                                />
                              Una vez a la semana
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehte"
                                  value="Una vez cada 15 días"
                                  checked={dato.secompartenimplementosdehte.includes('Una vez cada 15 días')}
                                  onChange={handleInputChange}
                                />
                              Una vez cada 15 días
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehte"
                                  value="Una vez al mes"
                                  checked={dato.secompartenimplementosdehte.includes('Una vez al mes')}
                                  onChange={handleInputChange}
                                />
                              Una vez al mes
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehte"
                                  value="Cada 6 meses"
                                  checked={dato.secompartenimplementosdehte.includes('Cada 6 meses')}
                                  onChange={handleInputChange}
                                />
                              Cada 6 meses
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehte"
                                  value="Cada Año"
                                  checked={dato.secompartenimplementosdehte.includes('Cada Año')}
                                  onChange={handleInputChange}
                                />
                              Cada Año
                              </label>
                            </div>
                            {/* {!dato.secompartenimplementosdehte.length && <div className="text-danger">Campo obligatorio</div>} */}
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div>
                            <label class="form-label">[Tapetes]</label>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehta"
                                  value="Todos los días"
                                  checked={dato.secompartenimplementosdehta.includes('Todos los días')}
                                  onChange={handleInputChange}
                                />
                                Todos los días
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehta"
                                  value="Una vez a la semana"
                                  checked={dato.secompartenimplementosdehta.includes('Una vez a la semana')}
                                  onChange={handleInputChange}
                                />
                              Una vez a la semana
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehta"
                                  value="Una vez cada 15 días"
                                  checked={dato.secompartenimplementosdehta.includes('Una vez cada 15 días')}
                                  onChange={handleInputChange}
                                />
                              Una vez cada 15 días
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehta"
                                  value="Una vez al mes"
                                  checked={dato.secompartenimplementosdehta.includes('Una vez al mes')}
                                  onChange={handleInputChange}
                                />
                              Una vez al mes
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehta"
                                  value="Cada 6 meses"
                                  checked={dato.secompartenimplementosdehta.includes('Cada 6 meses')}
                                  onChange={handleInputChange}
                                />
                              Cada 6 meses
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehta"
                                  value="Cada Año"
                                  checked={dato.secompartenimplementosdehta.includes('Cada Año')}
                                  onChange={handleInputChange}
                                />
                              Cada Año
                              </label>
                            </div>
                            {/* {!dato.secompartenimplementosdehta.length && <div className="text-danger">Campo obligatorio</div>} */}
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div>
                            <label class="form-label">[Cortinas]</label>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehco"
                                  value="Todos los días"
                                  checked={dato.secompartenimplementosdehco.includes('Todos los días')}
                                  onChange={handleInputChange}
                                />
                                Todos los días
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehco"
                                  value="Una vez a la semana"
                                  checked={dato.secompartenimplementosdehco.includes('Una vez a la semana')}
                                  onChange={handleInputChange}
                                />
                              Una vez a la semana
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehco"
                                  value="Una vez cada 15 días"
                                  checked={dato.secompartenimplementosdehco.includes('Una vez cada 15 días')}
                                  onChange={handleInputChange}
                                />
                              Una vez cada 15 días
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehco"
                                  value="Una vez al mes"
                                  checked={dato.secompartenimplementosdehco.includes('Una vez al mes')}
                                  onChange={handleInputChange}
                                />
                              Una vez al mes
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehco"
                                  value="Cada 6 meses"
                                  checked={dato.secompartenimplementosdehco.includes('Cada 6 meses')}
                                  onChange={handleInputChange}
                                />
                              Cada 6 meses
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehco"
                                  value="Cada Año"
                                  checked={dato.secompartenimplementosdehco.includes('Cada Año')}
                                  onChange={handleInputChange}
                                />
                              Cada Año
                              </label>
                            </div>
                            {/* {!dato.secompartenimplementosdehco.length && <div className="text-danger">Campo obligatorio</div>} */}
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div>
                            <label class="form-label">[Sabanas]</label>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehsa"
                                  value="Todos los días"
                                  checked={dato.secompartenimplementosdehsa.includes('Todos los días')}
                                  onChange={handleInputChange}
                                />
                                Todos los días
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehsa"
                                  value="Una vez a la semana"
                                  checked={dato.secompartenimplementosdehsa.includes('Una vez a la semana')}
                                  onChange={handleInputChange}
                                />
                              Una vez a la semana
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehsa"
                                  value="Una vez cada 15 días"
                                  checked={dato.secompartenimplementosdehsa.includes('Una vez cada 15 días')}
                                  onChange={handleInputChange}
                                />
                              Una vez cada 15 días
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehsa"
                                  value="Una vez al mes"
                                  checked={dato.secompartenimplementosdehsa.includes('Una vez al mes')}
                                  onChange={handleInputChange}
                                />
                              Una vez al mes
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehsa"
                                  value="Cada 6 meses"
                                  checked={dato.secompartenimplementosdehsa.includes('Cada 6 meses')}
                                  onChange={handleInputChange}
                                />
                              Cada 6 meses
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehsa"
                                  value="Cada Año"
                                  checked={dato.secompartenimplementosdehsa.includes('Cada Año')}
                                  onChange={handleInputChange}
                                />
                              Cada Año
                              </label>
                            </div>
                            {/* {!dato.secompartenimplementosdehsa.length && <div className="text-danger">Campo obligatorio</div>} */}
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div>
                            <label class="form-label">[Cobija]</label>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehcob"
                                  value="Todos los días"
                                  checked={dato.secompartenimplementosdehcob.includes('Todos los días')}
                                  onChange={handleInputChange}
                                />
                                Todos los días
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehcob"
                                  value="Una vez a la semana"
                                  checked={dato.secompartenimplementosdehcob.includes('Una vez a la semana')}
                                  onChange={handleInputChange}
                                />
                              Una vez a la semana
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehcob"
                                  value="Una vez cada 15 días"
                                  checked={dato.secompartenimplementosdehcob.includes('Una vez cada 15 días')}
                                  onChange={handleInputChange}
                                />
                              Una vez cada 15 días
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehcob"
                                  value="Una vez al mes"
                                  checked={dato.secompartenimplementosdehcob.includes('Una vez al mes')}
                                  onChange={handleInputChange}
                                />
                              Una vez al mes
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehcob"
                                  value="Cada 6 meses"
                                  checked={dato.secompartenimplementosdehcob.includes('Cada 6 meses')}
                                  onChange={handleInputChange}
                                />
                              Cada 6 meses
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehcob"
                                  value="Cada Año"
                                  checked={dato.secompartenimplementosdehcob.includes('Cada Año')}
                                  onChange={handleInputChange}
                                />
                              Cada Año
                              </label>
                            </div>
                            {/* {!dato.secompartenimplementosdehcob.length && <div className="text-danger">Campo obligatorio</div>} */}
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div>
                            <label class="form-label">[Baño]</label>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehba"
                                  value="Todos los días"
                                  checked={dato.secompartenimplementosdehba.includes('Todos los días')}
                                  onChange={handleInputChange}
                                />
                                Todos los días
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehba"
                                  value="Una vez a la semana"
                                  checked={dato.secompartenimplementosdehba.includes('Una vez a la semana')}
                                  onChange={handleInputChange}
                                />
                              Una vez a la semana
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehba"
                                  value="Una vez cada 15 días"
                                  checked={dato.secompartenimplementosdehba.includes('Una vez cada 15 días')}
                                  onChange={handleInputChange}
                                />
                              Una vez cada 15 días
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehba"
                                  value="Una vez al mes"
                                  checked={dato.secompartenimplementosdehba.includes('Una vez al mes')}
                                  onChange={handleInputChange}
                                />
                              Una vez al mes
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehba"
                                  value="Cada 6 meses"
                                  checked={dato.secompartenimplementosdehba.includes('Cada 6 meses')}
                                  onChange={handleInputChange}
                                />
                              Cada 6 meses
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehba"
                                  value="Cada Año"
                                  checked={dato.secompartenimplementosdehba.includes('Cada Año')}
                                  onChange={handleInputChange}
                                />
                              Cada Año
                              </label>
                            </div>
                            {/* {!dato.secompartenimplementosdehba.length && <div className="text-danger">Campo obligatorio</div>} */}
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div>
                            <label class="form-label">[Cocina]</label>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehcoc"
                                  value="Todos los días"
                                  checked={dato.secompartenimplementosdehcoc.includes('Todos los días')}
                                  onChange={handleInputChange}
                                />
                                Todos los días
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehcoc"
                                  value="Una vez a la semana"
                                  checked={dato.secompartenimplementosdehcoc.includes('Una vez a la semana')}
                                  onChange={handleInputChange}
                                />
                              Una vez a la semana
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehcoc"
                                  value="Una vez cada 15 días"
                                  checked={dato.secompartenimplementosdehcoc.includes('Una vez cada 15 días')}
                                  onChange={handleInputChange}
                                />
                              Una vez cada 15 días
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehcoc"
                                  value="Una vez al mes"
                                  checked={dato.secompartenimplementosdehcoc.includes('Una vez al mes')}
                                  onChange={handleInputChange}
                                />
                              Una vez al mes
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehcoc"
                                  value="Cada 6 meses"
                                  checked={dato.secompartenimplementosdehcoc.includes('Cada 6 meses')}
                                  onChange={handleInputChange}
                                />
                              Cada 6 meses
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehcoc"
                                  value="Cada Año"
                                  checked={dato.secompartenimplementosdehcoc.includes('Cada Año')}
                                  onChange={handleInputChange}
                                />
                              Cada Año
                              </label>
                            </div>
                            {/* {!dato.secompartenimplementosdehcoc.length && <div className="text-danger">Campo obligatorio</div>} */}
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div>
                            <label class="form-label">[Otras Superficie]</label>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehot"
                                  value="Todos los días"
                                  checked={dato.secompartenimplementosdehot.includes('Todos los días')}
                                  onChange={handleInputChange}
                                />
                                Todos los días
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehot"
                                  value="Una vez a la semana"
                                  checked={dato.secompartenimplementosdehot.includes('Una vez a la semana')}
                                  onChange={handleInputChange}
                                />
                              Una vez a la semana
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehot"
                                  value="Una vez cada 15 días"
                                  checked={dato.secompartenimplementosdehot.includes('Una vez cada 15 días')}
                                  onChange={handleInputChange}
                                />
                              Una vez cada 15 días
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehot"
                                  value="Una vez al mes"
                                  checked={dato.secompartenimplementosdehot.includes('Una vez al mes')}
                                  onChange={handleInputChange}
                                />
                              Una vez al mes
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehot"
                                  value="Cada 6 meses"
                                  checked={dato.secompartenimplementosdehot.includes('Cada 6 meses')}
                                  onChange={handleInputChange}
                                />
                              Cada 6 meses
                              </label>
                            </div>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  name="secompartenimplementosdehot"
                                  value="Cada Año"
                                  checked={dato.secompartenimplementosdehot.includes('Cada Año')}
                                  onChange={handleInputChange}
                                />
                              Cada Año
                              </label>
                            </div>
                            {/* {!dato.secompartenimplementosdehot.length && <div className="text-danger">Campo obligatorio</div>} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <div>
                        <label class="form-label">En que situaciones se practica el lavado de manos:</label>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="enquesituacionessepractic"
                              value="Después de entrar al baño"
                              checked={dato.enquesituacionessepractic.includes('Después de entrar al baño')}
                              onChange={handleInputChange}
                            />
                            Después de entrar al baño
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="enquesituacionessepractic"
                              value="Después de cambiar pañales"
                              checked={dato.enquesituacionessepractic.includes('Después de cambiar pañales')}
                              onChange={handleInputChange}
                            />
                            Después de cambiar pañales
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="enquesituacionessepractic"
                              value="Antes de preparar y consumir alimentos"
                              checked={dato.enquesituacionessepractic.includes('Antes de preparar y consumir alimentos')}
                              onChange={handleInputChange}
                            />
                            Antes de preparar y consumir alimentos
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="enquesituacionessepractic"
                              value="Después de tener contacto con animales"
                              checked={dato.enquesituacionessepractic.includes('Después de tener contacto con animales')}
                              onChange={handleInputChange}
                            />
                            Después de tener contacto con animales
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="enquesituacionessepractic"
                              value="Después de retirar secreciones nasales"
                              checked={dato.enquesituacionessepractic.includes('Después de retirar secreciones nasales')}
                              onChange={handleInputChange}
                            />
                            Después de retirar secreciones nasales
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="enquesituacionessepractic"
                              value="Después de llegar de la calle"
                              checked={dato.enquesituacionessepractic.includes('Después de llegar de la calle')}
                              onChange={handleInputChange}
                            />
                            Después de llegar de la calle
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="enquesituacionessepractic"
                              value="Después de manipular sustancias químicas"
                              checked={dato.enquesituacionessepractic.includes('Después de manipular sustancias químicas')}
                              onChange={handleInputChange}
                            />
                            Después de manipular sustancias químicas
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="enquesituacionessepractic"
                              value="Todas"
                              checked={dato.enquesituacionessepractic.includes('Todas')}
                              onChange={handleInputChange}
                            />
                            Todas
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="enquesituacionessepractic"
                              value="Ninguna de las anteriores"
                              checked={dato.enquesituacionessepractic.includes('Ninguna de las anteriores')}
                              onChange={handleInputChange}
                            />
                            Ninguna de las anteriores
                          </label>
                        </div>
                        {!dato.enquesituacionessepractic.length && <div className="text-danger">Campo obligatorio</div>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="m-2 mt-4 border border-primary-subtle"><h4 class="text"> 2.7.AIRE INTRADOMICILIARIO</h4>
                <div class="row">
                  <div class="col-12 col-md-6 col-lg-4"> 
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <div>
                        <label class="form-label">¿Cuál fuente de energía o combustible que se usa para cocinar? (puede señalar varias opciones)</label>
                          {dato.cualfuentedeenergiaocombu.map((valor, index) => (
                            !otroscualfuentedeenergiaocombuarr.includes(valor) && (
                              <div class="form-check" key={index}>
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name="cualfuentedeenergiaocombu"
                                    value={valor}
                                    checked={dato.cualfuentedeenergiaocombu.includes(valor)}
                                    onChange={handleInputChange}
                                  />
                                  {valor}
                                </label>
                              </div>
                            )
                          ))}
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="cualfuentedeenergiaocombu"
                              value="Electricidad"
                              checked={dato.cualfuentedeenergiaocombu.includes('Electricidad')}
                              onChange={handleInputChange}
                            />
                            Electricidad
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="cualfuentedeenergiaocombu"
                              value="Gas natural"
                              checked={dato.cualfuentedeenergiaocombu.includes('Gas natural')}
                              onChange={handleInputChange}
                            />
                            Gas natural
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="cualfuentedeenergiaocombu"
                              value="Gas Liquado del petroleo (gas propano)"
                              checked={dato.cualfuentedeenergiaocombu.includes('Gas Liquado del petroleo (gas propano)')}
                              onChange={handleInputChange}
                            />
                            Gas Liquado del petroleo (gas propano)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="cualfuentedeenergiaocombu"
                              value="Leña, madera o carbón de leña"
                              checked={dato.cualfuentedeenergiaocombu.includes('Leña, madera o carbón de leña')}
                              onChange={handleInputChange}
                            />
                            Leña, madera o carbón de leña
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="cualfuentedeenergiaocombu"
                              value="Petróleo, gasolina, kerosén, alcohol"
                              checked={dato.cualfuentedeenergiaocombu.includes('Petróleo, gasolina, kerosén, alcohol')}
                              onChange={handleInputChange}
                            />
                            Petróleo, gasolina, kerosén, alcohol
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="cualfuentedeenergiaocombu"
                              value="Carbón mineral"
                              checked={dato.cualfuentedeenergiaocombu.includes('Carbón mineral')}
                              onChange={handleInputChange}
                            />
                            Carbón mineral
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="cualfuentedeenergiaocombu"
                              value="Materiales de desecho"
                              checked={dato.cualfuentedeenergiaocombu.includes('Materiales de desecho')}
                              onChange={handleInputChange}
                            />
                            Materiales de desecho
                          </label>
                        </div>
                        <div class="input-group">
                          <div class="input-group-text">
                              <input
                                type="checkbox"
                                class="form-check-input mt-0"
                                name="cualfuentedeenergiaocombu"
                                value={otroscualfuentedeenergiaocombu}
                                checked={dato.cualfuentedeenergiaocombu.includes(otroscualfuentedeenergiaocombu)}
                                onChange={handleInputChange}
                                //onChange={(e) => dato.cualfuentedeenergiaocombu(e.target.checked)}
                              />
                            </div>
                            <input type="text" class="form-control" aria-label="Text input with checkbox" 
                              placeholder="Digite otras opciones de ser necesario"
                              value={otroscualfuentedeenergiaocombu}
                              name="otroscualfuentedeenergiaocombu"
                              //onChange={handleInputChange}
                              //onBlur={handleInputBlur}
                              onChange={(event) => handleInputChange(event, setOtrosCualfuentedeenergiaocombu)}
                              onBlur={() => handleInputBlur(otroscualfuentedeenergiaocombu, 'cualfuentedeenergiaocombu')}
                              />
                        </div>
                        {!dato.cualfuentedeenergiaocombu.length && <div className="text-danger">Campo obligatorio</div>}
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">¿Es frecuente que haya humo dentro de la vivienda?</label>
                      <select className={`form-select ${!dato.esfrecuentequehayahumoden ? 'is-invalid' : ''}`} name='esfrecuentequehayahumoden' value={dato.esfrecuentequehayahumoden} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Si">Si</option>
                        <option value="No">No</option>
                      </select>
                      {!dato.esfrecuentequehayahumoden && <div className="invalid-feedback">Campo obligatorio</div>}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">         
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <div>
                        <label class="form-label">Señale las fuentes frecuentes de humo dentro de la vivienda</label>
                          {dato.senalelasfuentesfrecuente.map((valor, index) => (
                            !otrossenalelasfuentesfrecuentearr.includes(valor) && (
                              <div class="form-check" key={index}>
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name="senalelasfuentesfrecuente"
                                    value={valor}
                                    checked={dato.senalelasfuentesfrecuente.includes(valor)}
                                    onChange={handleInputChange}
                                  />
                                  {valor}
                                </label>
                              </div>
                            )
                          ))}
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="senalelasfuentesfrecuente"
                              value="Cigarrillo o tabaco"
                              checked={dato.senalelasfuentesfrecuente.includes('Cigarrillo o tabaco')}
                              onChange={handleInputChange}
                            />
                            Cigarrillo o tabaco
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="senalelasfuentesfrecuente"
                              value="Leña"
                              checked={dato.senalelasfuentesfrecuente.includes('Leña')}
                              onChange={handleInputChange}
                            />
                            Leña
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="senalelasfuentesfrecuente"
                              value="Carbón"
                              checked={dato.senalelasfuentesfrecuente.includes('Carbón')}
                              onChange={handleInputChange}
                            />
                            Carbón
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="senalelasfuentesfrecuente"
                              value="Quema de basura"
                              checked={dato.senalelasfuentesfrecuente.includes('Quema de basura')}
                              onChange={handleInputChange}
                            />
                            Quema de basura
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="senalelasfuentesfrecuente"
                              value="No Aplica"
                              checked={dato.senalelasfuentesfrecuente.includes('No Aplica')}
                              onChange={handleInputChange}
                            />
                            No Aplica
                          </label>
                        </div>
                        <div class="input-group">
                          <div class="input-group-text">
                              <input
                                type="checkbox"
                                class="form-check-input mt-0"
                                name="senalelasfuentesfrecuente"
                                value={otrossenalelasfuentesfrecuente}
                                checked={dato.senalelasfuentesfrecuente.includes(otrossenalelasfuentesfrecuente)}
                                onChange={handleInputChange}
                                //onChange={(e) => dato.senalelasfuentesfrecuente(e.target.checked)}
                              />
                            </div>
                            <input type="text" class="form-control" aria-label="Text input with checkbox" 
                              placeholder="Digite otras opciones de ser necesario"
                              value={otrossenalelasfuentesfrecuente}
                              name="otrossenalelasfuentesfrecuente"
                              //onChange={handleInputChange}
                              //onBlur={handleInputBlur}
                              onChange={(event) => handleInputChange(event, setOtrosSenalelasfuentesfrecuente)}
                              onBlur={() => handleInputBlur(otrossenalelasfuentesfrecuente, 'senalelasfuentesfrecuente')}
                              />
                        </div>
                        {!dato.senalelasfuentesfrecuente.length && <div className="text-danger">Campo obligatorio</div>}
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <div>
                        <label class="form-label">¿Cuáles de las siguientes prácticas o hábitos relacionados con la calidad del aire intradomiciliario, se identifican en la vivienda?</label>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="cualesdelassiguientesprac"
                              value="En la vivienda se cocina con carbón o leña"
                              checked={dato.cualesdelassiguientesprac.includes('En la vivienda se cocina con carbón o leña')}
                              onChange={handleInputChange}
                            />
                            En la vivienda se cocina con carbón o leña
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="cualesdelassiguientesprac"
                              value="Hay uno o más fumadores activos en la vivienda y habitualmente consumen a lo largo del día uno o más cigarrillos."
                              checked={dato.cualesdelassiguientesprac.includes('Hay uno o más fumadores activos en la vivienda y habitualmente consumen a lo largo del día uno o más cigarrillos.')}
                              onChange={handleInputChange}
                            />
                            Hay uno o más fumadores activos en la vivienda y habitualmente consumen a lo largo del día uno o más cigarrillos.
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="cualesdelassiguientesprac"
                              value="Existen otras prácticas como enceder el vehículo dentro de la vivienda en un área donde no hay buena ventilación."
                              checked={dato.cualesdelassiguientesprac.includes('Existen otras prácticas como enceder el vehículo dentro de la vivienda en un área donde no hay buena ventilación.')}
                              onChange={handleInputChange}
                            />
                            Existen otras prácticas como enceder el vehículo dentro de la vivienda en un área donde no hay buena ventilación.
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="cualesdelassiguientesprac"
                              value="Quema de basura afuera de la vivienda y el humo ingresa a la vivienda"
                              checked={dato.cualesdelassiguientesprac.includes('Quema de basura afuera de la vivienda y el humo ingresa a la vivienda')}
                              onChange={handleInputChange}
                            />
                            Quema de basura afuera de la vivienda y el humo ingresa a la vivienda
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="cualesdelassiguientesprac"
                              value="Barrido de pisos con levantamiento de polvillo que se queda suspendido al interior de la vivienda"
                              checked={dato.cualesdelassiguientesprac.includes('Barrido de pisos con levantamiento de polvillo que se queda suspendido al interior de la vivienda')}
                              onChange={handleInputChange}
                            />
                            Barrido de pisos con levantamiento de polvillo que se queda suspendido al interior de la vivienda
                          </label>
                        </div>
                        {!dato.cualesdelassiguientesprac.length && <div className="text-danger">Campo obligatorio</div>}
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <div>
                        <label class="form-label">¿Que tipo de estufa se emplea en la vivienda?</label>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="quetipodeestufaseempleaen"
                              value="Electrica, de gas,  petróleo, gasolina, kerosén, alcohol con conexiones en buen estado, sin fugas, buen estado de combustión, no hay riesgo eléctrico"
                              checked={dato.quetipodeestufaseempleaen.includes('Electrica, de gas,  petróleo, gasolina, kerosén, alcohol con conexiones en buen estado, sin fugas, buen estado de combustión, no hay riesgo eléctrico')}
                              onChange={handleInputChange}
                            />
                            Electrica, de gas,  petróleo, gasolina, kerosén, alcohol con conexiones en buen estado, sin fugas, buen estado de combustión, no hay riesgo eléctrico
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="quetipodeestufaseempleaen"
                              value="Electrica, de gas,  petróleo, gasolina, kerosén, alcohol con conexiones en mal estado,  reparaciones defectuosas, mal estado de combustión"
                              checked={dato.quetipodeestufaseempleaen.includes('Electrica, de gas,  petróleo, gasolina, kerosén, alcohol con conexiones en mal estado,  reparaciones defectuosas, mal estado de combustión')}
                              onChange={handleInputChange}
                            />
                            Electrica, de gas,  petróleo, gasolina, kerosén, alcohol con conexiones en mal estado,  reparaciones defectuosas, mal estado de combustión
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="quetipodeestufaseempleaen"
                              value="De leña, madera, carbón de leña o carbón mineral mejorada, con sistema de extracción de humo y gases (Chimenea) y está ubicada fuera de la vivienda, o está ubica al interior de la vivienda, y se encuentra en buen estado"
                              checked={dato.quetipodeestufaseempleaen.includes('De leña, madera, carbón de leña o carbón mineral mejorada, con sistema de extracción de humo y gases (Chimenea) y está ubicada fuera de la vivienda, o está ubica al interior de la vivienda, y se encuentra en buen estado')}
                              onChange={handleInputChange}
                            />
                            De leña, madera, carbón de leña o carbón mineral mejorada, con sistema de extracción de humo y gases (Chimenea) y está ubicada fuera de la vivienda, o está ubica al interior de la vivienda, y se encuentra en buen estado
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="quetipodeestufaseempleaen"
                              value="De leña, madera, carbón de leña o carbón mineral ubicada dentro de la vivienda, con señales (manchas) de permitir el ingreso de humo en la vivienda"
                              checked={dato.quetipodeestufaseempleaen.includes('De leña, madera, carbón de leña o carbón mineral ubicada dentro de la vivienda, con señales (manchas) de permitir el ingreso de humo en la vivienda')}
                              onChange={handleInputChange}
                            />
                            De leña, madera, carbón de leña o carbón mineral ubicada dentro de la vivienda, con señales (manchas) de permitir el ingreso de humo en la vivienda
                          </label>
                        </div>
                        {!dato.quetipodeestufaseempleaen.length && <div className="text-danger">Campo obligatorio</div>}
                      </div>
                    </div>  
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">       
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <div>
                        <label class="form-label">Que medidas se implementan cuando un miembro de la familia padece una enfermedad 6.6 respiratoria, para prevenir el contagio de otras personas?</label>
                          {dato.quemedidasseimplementancu.map((valor, index) => (
                            !otrosquemedidasseimplementancuarr.includes(valor) && (
                              <div class="form-check" key={index}>
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name="quemedidasseimplementancu"
                                    value={valor}
                                    checked={dato.quemedidasseimplementancu.includes(valor)}
                                    onChange={handleInputChange}
                                  />
                                  {valor}
                                </label>
                              </div>
                            )
                          ))}
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="quemedidasseimplementancu"
                              value="Uso de tapabocas"
                              checked={dato.quemedidasseimplementancu.includes('Uso de tapabocas')}
                              onChange={handleInputChange}
                            />
                            Uso de tapabocas
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="quemedidasseimplementancu"
                              value="Lavado frecuente de manos"
                              checked={dato.quemedidasseimplementancu.includes('Lavado frecuente de manos')}
                              onChange={handleInputChange}
                            />
                            Lavado frecuente de manos
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="quemedidasseimplementancu"
                              value="Cubrir rostro al toser y estornudar"
                              checked={dato.quemedidasseimplementancu.includes('Cubrir rostro al toser y estornudar')}
                              onChange={handleInputChange}
                            />
                            Cubrir rostro al toser y estornudar
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="quemedidasseimplementancu"
                              value="Ninguno"
                              checked={dato.quemedidasseimplementancu.includes('Ninguno')}
                              onChange={handleInputChange}
                            />
                            Ninguno
                          </label>
                        </div>
                        <div class="input-group">
                          <div class="input-group-text">
                              <input
                                type="checkbox"
                                class="form-check-input mt-0"
                                name="quemedidasseimplementancu"
                                value={otrosquemedidasseimplementancu}
                                checked={dato.quemedidasseimplementancu.includes(otrosquemedidasseimplementancu)}
                                onChange={handleInputChange}
                                //onChange={(e) => dato.quemedidasseimplementancu(e.target.checked)}
                              />
                            </div>
                            <input type="text" class="form-control" aria-label="Text input with checkbox" 
                              placeholder="Digite otras opciones de ser necesario"
                              value={otrosquemedidasseimplementancu}
                              name="otrosquemedidasseimplementancu"
                              //onChange={handleInputChange}
                              //onBlur={handleInputBlur}
                              onChange={(event) => handleInputChange(event, setOtrosQuemedidasseimplementancu)}
                              onBlur={() => handleInputBlur(otrosquemedidasseimplementancu, 'quemedidasseimplementancu')}
                              />
                        </div>
                        {!dato.quemedidasseimplementancu.length && <div className="text-danger">Campo obligatorio</div>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="m-2 mt-4 border border-primary-subtle"><h4 class="text"> 2.8.	ZOONOSIS</h4>
                <div class="row">
                  <div class="col-12">       
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">La familia convive con animales de compañía y/o producción dentro de la vivienda o su entorno inmediato</label>
                      <select className={`form-select ${!dato.lafamiliaconviveconanimal ? 'is-invalid' : ''}`} name='lafamiliaconviveconanimal' value={dato.lafamiliaconviveconanimal} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Si">Si</option>
                        <option value="No">No</option>
                      </select>
                      {!dato.lafamiliaconviveconanimal && <div className="invalid-feedback">Campo obligatorio</div>}
                    </div>
                  </div>
                  <div class="col-12"> 
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Señale los animales que conviven con la familia dentro de la vivienda o en su entorno inmediato, e indique cuantos son:</label>
                      <div class="row">
                        <div class="col-md-3">
                          <label class="form-label">Perros</label>
                          <input
                            type="number"
                            name='senalelosanimalesqueconviper'
                            placeholder='Perros'
                            className={`form-control`}
                            value={dato.senalelosanimalesqueconviper}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div class="col-md-3">
                          <label class="form-label">Gatos</label>
                          <input
                            type="number"
                            name='senalelosanimalesqueconviga'
                            placeholder='Gatos'
                            className={`form-control`}
                            value={dato.senalelosanimalesqueconviga}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div class="col-md-3">
                          <label class="form-label">Porcinos</label>
                          <input
                            type="number"
                            name='senalelosanimalesqueconvipo'
                            placeholder='Porcinos'
                            className={`form-control`}
                            value={dato.senalelosanimalesqueconvipo}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div class="col-md-3">
                          <label class="form-label">Bóvidos: Búfalos, vacas, toros</label>
                          <input
                            type="number"
                            name='senalelosanimalesqueconvibo'
                            placeholder='Bóvidos: Búfalos, vacas, toros'
                            className={`form-control`}
                            value={dato.senalelosanimalesqueconvibo}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div class="col-md-3">
                          <label class="form-label">Equidos: Asnos, mulas, caballos, burros</label>
                          <input
                            type="number"
                            name='senalelosanimalesqueconvieq'
                            placeholder='Equidos: Asnos, mulas, caballos, burros'
                            className={`form-control`}
                            value={dato.senalelosanimalesqueconvieq}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div class="col-md-3">
                          <label class="form-label">Ovinos / caprino</label>
                          <input
                            type="number"
                            name='senalelosanimalesqueconviov'
                            placeholder='Ovinos / caprino'
                            className={`form-control`}
                            value={dato.senalelosanimalesqueconviov}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div class="col-md-3">
                          <label class="form-label">Aves de producción</label>
                          <input
                            type="number"
                            name='senalelosanimalesqueconviap'
                            placeholder='Aves de producción'
                            className={`form-control`}
                            value={dato.senalelosanimalesqueconviap}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div class="col-md-3">
                          <label class="form-label">Aves ornamentales: canarios, pericos australianos, cacatuas</label>
                          <input
                            type="number"
                            name='senalelosanimalesqueconviao'
                            placeholder='Aves ornamentales: canarios, pericos australianos, cacatuas'
                            className={`form-control`}
                            value={dato.senalelosanimalesqueconviao}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div class="col-md-3">
                          <label class="form-label">Peces ornamentales, hamster</label>
                          <input
                            type="number"
                            name='senalelosanimalesqueconvipe'
                            placeholder='Peces ornamentales, hamster'
                            className={`form-control`}
                            value={dato.senalelosanimalesqueconvipe}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div class="col-md-3">
                          <label class="form-label">Cobayos, conejos</label>
                          <input
                            type="number"
                            name='senalelosanimalesqueconvico'
                            placeholder='Cobayos, conejos'
                            className={`form-control`}
                            value={dato.senalelosanimalesqueconvico}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div class="col-md-3">
                          <label class="form-label">Animales silvestres: serpientes, loros, tortugas, osos hormigueros, perezosos, águilas, buhos, primates, palomas, entre otros</label>
                          <input
                            type="number"
                            name='senalelosanimalesqueconvian'
                            placeholder='Animales silvestres: serpientes, loros, tortugas, osos hormigueros, perezosos, águilas, buhos, primates, palomas, entre otros'
                            className={`form-control`}
                            value={dato.senalelosanimalesqueconvian}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div class="col-md-3">
                          <label class="form-label">Otros</label>
                          <input
                            type="number"
                            name='senalelosanimalesqueconviot'
                            placeholder='Otros'
                            className={`form-control`}
                            value={dato.senalelosanimalesqueconviot}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      {/* {!dato.numeroficha && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <div>
                        <label class="form-label">Cual es la finalidad de tener estos animales?</label>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="cualeslafinalidaddetenere"
                              value="compañía"
                              checked={dato.cualeslafinalidaddetenere.includes('compañía')}
                              onChange={handleInputChange}
                            />
                            compañía
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="cualeslafinalidaddetenere"
                              value="Producción"
                              checked={dato.cualeslafinalidaddetenere.includes('Producción')}
                              onChange={handleInputChange}
                            />
                            Producción
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="cualeslafinalidaddetenere"
                              value="Autoconsumo"
                              checked={dato.cualeslafinalidaddetenere.includes('Autoconsumo')}
                              onChange={handleInputChange}
                            />
                            Autoconsumo
                          </label>
                        </div>
                        {/* {!dato.cualeslafinalidaddetenere.length && <div className="text-danger">Campo obligatorio</div>} */}
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <div>
                        <label class="form-label">Los animales domésticos están:</label>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="losanimalesdomesticosesta"
                              value="Totalmente confinados"
                              checked={dato.losanimalesdomesticosesta.includes('Totalmente confinados')}
                              onChange={handleInputChange}
                            />
                            Totalmente confinados
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="losanimalesdomesticosesta"
                              value="Parcialmente confinados"
                              checked={dato.losanimalesdomesticosesta.includes('Parcialmente confinados')}
                              onChange={handleInputChange}
                            />
                            Parcialmente confinados
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="losanimalesdomesticosesta"
                              value="Libres"
                              checked={dato.losanimalesdomesticosesta.includes('Libres')}
                              onChange={handleInputChange}
                            />
                            Libres
                          </label>
                        </div>
                        {/* {!dato.losanimalesdomesticosesta.length && <div className="text-danger">Campo obligatorio</div>} */}
                      </div>
                    </div> 
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">     
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Se desparasita a los animales domésticos?</label>
                      <select className={`form-select`} name='sedesparasitaalosanimales' value={dato.sedesparasitaalosanimales} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Si">Si</option>
                        <option value="No">No</option>
                      </select>
                      {/* {!dato.sedesparasitaalosanimales && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>  
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">    
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Las instalaciones para atención y manejo de los animales de producción que habitan en la  vivienda (patios, solares, establos, pecebreras),</label>
                      <select className={`form-select`} name='lasinstalacionesparaatenc' value={dato.lasinstalacionesparaatenc} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Si">Si</option>
                        <option value="No">No</option>
                      </select>
                      {/* {!dato.lasinstalacionesparaatenc && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>   
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">   
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Los animales de compañía (perros y gatos) son vacunados contra la rabia una vez al año</label>
                      <select className={`form-select`} name='losanimalesdecompaniaperr' value={dato.losanimalesdecompaniaperr} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Si">Si</option>
                        <option value="No">No</option>
                      </select>
                      {/* {!dato.losanimalesdecompaniaperr && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>    
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">  
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Las excretas de los animales de compañía se recogen y disponen adecuadamente?</label>
                      <select className={`form-select`} name='lasexcretasdelosanimalesd' value={dato.lasexcretasdelosanimalesd} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Si">Si</option>
                        <option value="No">No</option>
                      </select>
                      {/* {!dato.lasexcretasdelosanimalesd && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                </div>
              </div>
              <div class="m-2 mt-4 border border-primary-subtle"><h4 class="text"> 2.9.	CONTROL DE VECTORES</h4>
                <div class="row">
                  <div class="col-12 col-md-6"> 
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Se evidencia o la familia informa la presencia vectores transmisores de enfermedades en la vivienda o en su entorno inmediato?</label>
                      <select className={`form-select`} name='seevidenciaolafamiliainfo' value={dato.seevidenciaolafamiliainfo} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Si">Si</option>
                        <option value="No">No</option>
                      </select>
                      {/* {!dato.seevidenciaolafamiliainfo && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-12 col-md-6"> 
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <div>
                        <label class="form-label">¿Cuáles de los siguientes vectores transmisores de enfermedades se evidencian en la vivienda o en su entorno inmediato?</label>
                          {dato.cualesdelossiguientesvect.map((valor, index) => (
                            !otroscualesdelossiguientesvectarr.includes(valor) && (
                              <div class="form-check" key={index}>
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name="cualesdelossiguientesvect"
                                    value={valor}
                                    checked={dato.cualesdelossiguientesvect.includes(valor)}
                                    onChange={handleInputChange}
                                  />
                                  {valor}
                                </label>
                              </div>
                            )
                          ))}
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="cualesdelossiguientesvect"
                              value="Zancudos"
                              checked={dato.cualesdelossiguientesvect.includes('Zancudos')}
                              onChange={handleInputChange}
                            />
                            Zancudos
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="cualesdelossiguientesvect"
                              value="Cucarachas"
                              checked={dato.cualesdelossiguientesvect.includes('Cucarachas')}
                              onChange={handleInputChange}
                            />
                            Cucarachas
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="cualesdelossiguientesvect"
                              value="Moscas"
                              checked={dato.cualesdelossiguientesvect.includes('Moscas')}
                              onChange={handleInputChange}
                            />
                            Moscas
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="cualesdelossiguientesvect"
                              value="Mosquitos"
                              checked={dato.cualesdelossiguientesvect.includes('Mosquitos')}
                              onChange={handleInputChange}
                            />
                            Mosquitos
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="cualesdelossiguientesvect"
                              value="Pulgas"
                              checked={dato.cualesdelossiguientesvect.includes('Pulgas')}
                              onChange={handleInputChange}
                            />
                            Pulgas
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="cualesdelossiguientesvect"
                              value="Piojos"
                              checked={dato.cualesdelossiguientesvect.includes('Piojos')}
                              onChange={handleInputChange}
                            />
                            Piojos
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="cualesdelossiguientesvect"
                              value="Garrapatas"
                              checked={dato.cualesdelossiguientesvect.includes('Garrapatas')}
                              onChange={handleInputChange}
                            />
                            Garrapatas
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="cualesdelossiguientesvect"
                              value="Roedores"
                              checked={dato.cualesdelossiguientesvect.includes('Roedores')}
                              onChange={handleInputChange}
                            />
                            Roedores
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="cualesdelossiguientesvect"
                              value="Chinches"
                              checked={dato.cualesdelossiguientesvect.includes('Chinches')}
                              onChange={handleInputChange}
                            />
                            Chinches
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="cualesdelossiguientesvect"
                              value="Triatominos (Pito)"
                              checked={dato.cualesdelossiguientesvect.includes('Triatominos (Pito)')}
                              onChange={handleInputChange}
                            />
                            Triatominos (Pito)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="cualesdelossiguientesvect"
                              value="Ninguno"
                              checked={dato.cualesdelossiguientesvect.includes('Ninguno')}
                              onChange={handleInputChange}
                            />
                            Ninguno
                          </label>
                        </div>
                        <div class="input-group">
                          <div class="input-group-text">
                              <input
                                type="checkbox"
                                class="form-check-input mt-0"
                                name="cualesdelossiguientesvect"
                                value={otroscualesdelossiguientesvect}
                                checked={dato.cualesdelossiguientesvect.includes(otroscualesdelossiguientesvect)}
                                onChange={handleInputChange}
                                //onChange={(e) => dato.cualesdelossiguientesvect(e.target.checked)}
                              />
                            </div>
                            <input type="text" class="form-control" aria-label="Text input with checkbox" 
                              placeholder="Digite otras opciones de ser necesario"
                              value={otroscualesdelossiguientesvect}
                              name="otroscualesdelossiguientesvect"
                              //onChange={handleInputChange}
                              //onBlur={handleInputBlur}
                              onChange={(event) => handleInputChange(event, setOtrosCualesdelossiguientesvect)}
                              onBlur={() => handleInputBlur(otroscualesdelossiguientesvect, 'cualesdelossiguientesvect')}
                              />
                        </div>
                        {/* {!dato.cualesdelossiguientesvect.length && <div className="text-danger">Campo obligatorio</div>} */}
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6"> 
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <div>
                        <label class="form-label">¿Se observa cerca de la vivienda o dentro de ella alguno(s) de los siguientes criaderos o reservorios que pueden favorecer la presencia de vectores transmisores de enfermedades?</label>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="seobservacercadelaviviend"
                              value="Agua estancada o de agua lluvias cerca de la vivienda o dentro de ella que puede favorecer la presencia de vectores"
                              checked={dato.seobservacercadelaviviend.includes('Agua estancada o de agua lluvias cerca de la vivienda o dentro de ella que puede favorecer la presencia de vectores')}
                              onChange={handleInputChange}
                            />
                            Agua estancada o de agua lluvias cerca de la vivienda o dentro de ella que puede favorecer la presencia de vectores
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="seobservacercadelaviviend"
                              value="El perodomicilio, jardín o espacios de la vivienda como patio o azotea tienen el pasto sin podar, matorrales o con acumulación de hojarasca, maleza,  se observa materia fecal de los animales de compañía, hay desagües destapados, canaletas obstruidas con hojas secas, hay agua almacenada en recipientes sin tapa (baldes, tanques de agua, albercas, lavadero), hay acumulación de elementos inservibles como llantas, y bebederos y comederos de los animales de compañía sucios"
                              checked={dato.seobservacercadelaviviend.includes('El perodomicilio, jardín o espacios de la vivienda como patio o azotea tienen el pasto sin podar, matorrales o con acumulación de hojarasca, maleza,  se observa materia fecal de los animales de compañía, hay desagües destapados, canaletas obstruidas con hojas secas, hay agua almacenada en recipientes sin tapa (baldes, tanques de agua, albercas, lavadero), hay acumulación de elementos inservibles como llantas, y bebederos y comederos de los animales de compañía sucios')}
                              onChange={handleInputChange}
                            />
                            El perodomicilio, jardín o espacios de la vivienda como patio o azotea tienen el pasto sin podar, matorrales o con acumulación de hojarasca, maleza,  se observa materia fecal de los animales de compañía, hay desagües destapados, canaletas obstruidas con hojas secas, hay agua almacenada en recipientes sin tapa (baldes, tanques de agua, albercas, lavadero), hay acumulación de elementos inservibles como llantas, y bebederos y comederos de los animales de compañía sucios
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="seobservacercadelaviviend"
                              value="Dentro de la vivienda se encuentran desagües destapados, paredes con grietas, se almacena agua en recipientes sin tapa, hay acumulación de residuos generales como bolsas de plástico, latas vacías, botellas, etc.,  bebederos y comederos de los animales de compañía sucios, floreros y materas con platos debajo de ellas con agua estancada"
                              checked={dato.seobservacercadelaviviend.includes('Dentro de la vivienda se encuentran desagües destapados, paredes con grietas, se almacena agua en recipientes sin tapa, hay acumulación de residuos generales como bolsas de plástico, latas vacías, botellas, etc.,  bebederos y comederos de los animales de compañía sucios, floreros y materas con platos debajo de ellas con agua estancada')}
                              onChange={handleInputChange}
                            />
                            Dentro de la vivienda se encuentran desagües destapados, paredes con grietas, se almacena agua en recipientes sin tapa, hay acumulación de residuos generales como bolsas de plástico, latas vacías, botellas, etc.,  bebederos y comederos de los animales de compañía sucios, floreros y materas con platos debajo de ellas con agua estancada
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="seobservacercadelaviviend"
                              value="Se encuentran los gallineros o porquerizas cerca de los dormitorios "
                              checked={dato.seobservacercadelaviviend.includes('Se encuentran los gallineros o porquerizas cerca de los dormitorios ')}
                              onChange={handleInputChange}
                            />
                            Se encuentran los gallineros o porquerizas cerca de los dormitorios 
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="seobservacercadelaviviend"
                              value="No se observan condiciones o prácticas que favorezcan la presencia de vectores"
                              checked={dato.seobservacercadelaviviend.includes('No se observan condiciones o prácticas que favorezcan la presencia de vectores')}
                              onChange={handleInputChange}
                            />
                            No se observan condiciones o prácticas que favorezcan la presencia de vectores
                          </label>
                        </div>
                        {/* {!dato.seobservacercadelaviviend.length && <div className="text-danger">Campo obligatorio</div>} */}
                      </div>
                    </div> 
                  </div>
                  <div class="col-12 col-md-6">         
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <div>
                        <label class="form-label">¿Que medidas se ponen en práctica para control de vectores transmisores de enfermedades?</label>
                          {dato.quemedidasseponenenpracti.map((valor, index) => (
                            !otrosquemedidasseponenenpractiarr.includes(valor) && (
                              <div class="form-check" key={index}>
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name="quemedidasseponenenpracti"
                                    value={valor}
                                    checked={dato.quemedidasseponenenpracti.includes(valor)}
                                    onChange={handleInputChange}
                                  />
                                  {valor}
                                </label>
                              </div>
                            )
                          ))}
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="quemedidasseponenenpracti"
                              value="Manejo higiénico de los residuos sólidos al interior de la vivienda"
                              checked={dato.quemedidasseponenenpracti.includes('Manejo higiénico de los residuos sólidos al interior de la vivienda')}
                              onChange={handleInputChange}
                            />
                            Manejo higiénico de los residuos sólidos al interior de la vivienda
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="quemedidasseponenenpracti"
                              value="Evitar el uso de fuentes de luz blanca o brillante en ambientes oscuros dentro de la vivienda"
                              checked={dato.quemedidasseponenenpracti.includes('Evitar el uso de fuentes de luz blanca o brillante en ambientes oscuros dentro de la vivienda')}
                              onChange={handleInputChange}
                            />
                            Evitar el uso de fuentes de luz blanca o brillante en ambientes oscuros dentro de la vivienda
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="quemedidasseponenenpracti"
                              value="Manejo higiénico de los residuos sólidos en el entorno de la vivienda"
                              checked={dato.quemedidasseponenenpracti.includes('Manejo higiénico de los residuos sólidos en el entorno de la vivienda')}
                              onChange={handleInputChange}
                            />
                            Manejo higiénico de los residuos sólidos en el entorno de la vivienda
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="quemedidasseponenenpracti"
                              value="Recolección de inservibles"
                              checked={dato.quemedidasseponenenpracti.includes('Recolección de inservibles')}
                              onChange={handleInputChange}
                            />
                            Recolección de inservibles
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="quemedidasseponenenpracti"
                              value="Uso de toldillos"
                              checked={dato.quemedidasseponenenpracti.includes('Uso de toldillos')}
                              onChange={handleInputChange}
                            />
                            Uso de toldillos
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="quemedidasseponenenpracti"
                              value="Uso de angeos y trampas caseras"
                              checked={dato.quemedidasseponenenpracti.includes('Uso de angeos y trampas caseras')}
                              onChange={handleInputChange}
                            />
                            Uso de angeos y trampas caseras
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="quemedidasseponenenpracti"
                              value="Ordenamiento e higiene"
                              checked={dato.quemedidasseponenenpracti.includes('Ordenamiento e higiene')}
                              onChange={handleInputChange}
                            />
                            Ordenamiento e higiene
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="quemedidasseponenenpracti"
                              value="Limpieza de malezas"
                              checked={dato.quemedidasseponenenpracti.includes('Limpieza de malezas')}
                              onChange={handleInputChange}
                            />
                            Limpieza de malezas
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="quemedidasseponenenpracti"
                              value="Drenaje de zonas encharcadas"
                              checked={dato.quemedidasseponenenpracti.includes('Drenaje de zonas encharcadas')}
                              onChange={handleInputChange}
                            />
                            Drenaje de zonas encharcadas
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="quemedidasseponenenpracti"
                              value="Protección de depósitos de agua de consumo mientras no estén en uso"
                              checked={dato.quemedidasseponenenpracti.includes('Protección de depósitos de agua de consumo mientras no estén en uso')}
                              onChange={handleInputChange}
                            />
                            Protección de depósitos de agua de consumo mientras no estén en uso
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="quemedidasseponenenpracti"
                              value="Retiro de recipientes que usualmente contienen o acumulan agua"
                              checked={dato.quemedidasseponenenpracti.includes('Retiro de recipientes que usualmente contienen o acumulan agua')}
                              onChange={handleInputChange}
                            />
                            Retiro de recipientes que usualmente contienen o acumulan agua
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="quemedidasseponenenpracti"
                              value="Reemplazo de techos en paja por techo de zinc"
                              checked={dato.quemedidasseponenenpracti.includes('Reemplazo de techos en paja por techo de zinc')}
                              onChange={handleInputChange}
                            />
                            Reemplazo de techos en paja por techo de zinc
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="quemedidasseponenenpracti"
                              value="Reparaciones para eliminar sitios húmedos o pocos iluminados"
                              checked={dato.quemedidasseponenenpracti.includes('Reparaciones para eliminar sitios húmedos o pocos iluminados')}
                              onChange={handleInputChange}
                            />
                            Reparaciones para eliminar sitios húmedos o pocos iluminados
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="quemedidasseponenenpracti"
                              value="Uso de pantalones y camisas manga larga de colores claro"
                              checked={dato.quemedidasseponenenpracti.includes('Uso de pantalones y camisas manga larga de colores claro')}
                              onChange={handleInputChange}
                            />
                            Uso de pantalones y camisas manga larga de colores claro
                          </label>
                        </div>
                        <div class="input-group">
                          <div class="input-group-text">
                              <input
                                type="checkbox"
                                class="form-check-input mt-0"
                                name="quemedidasseponenenpracti"
                                value={otrosquemedidasseponenenpracti}
                                checked={dato.quemedidasseponenenpracti.includes(otrosquemedidasseponenenpracti)}
                                onChange={handleInputChange}
                                //onChange={(e) => dato.quemedidasseponenenpracti(e.target.checked)}
                              />
                            </div>
                            <input type="text" class="form-control" aria-label="Text input with checkbox" 
                              placeholder="Digite otras opciones de ser necesario"
                              value={otrosquemedidasseponenenpracti}
                              name="otrosquemedidasseponenenpracti"
                              //onChange={handleInputChange}
                              //onBlur={handleInputBlur}
                              onChange={(event) => handleInputChange(event, setOtrosQuemedidasseponenenpracti)}
                              onBlur={() => handleInputBlur(otrosquemedidasseponenenpracti, 'quemedidasseponenenpracti')}
                              />
                        </div>
                        {/* {!dato.quemedidasseponenenpracti.length && <div className="text-danger">Campo obligatorio</div>} */}
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6"> 
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">¿Se observan animales ponzoñosos o venenosos al interior de la vivienda o en su entorno inmediato?</label>
                      <select className={`form-select`} name='seobservananimalesponzono' value={dato.seobservananimalesponzono} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Si">Si</option>
                        <option value="No">No</option>
                      </select>
                      {/* {!dato.seobservananimalesponzono && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-12 col-md-6"> 
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <div>
                        <label class="form-label">¿Se observan alguno(s) de los siguientes animales ponzoñosos o venenosos al interior de la vivienda o en su entorno inmediato?</label>
                          {dato.seobservanalgunosdelossig.map((valor, index) => (
                            !otrosseobservanalgunosdelossigarr.includes(valor) && (
                              <div class="form-check" key={index}>
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name="seobservanalgunosdelossig"
                                    value={valor}
                                    checked={dato.seobservanalgunosdelossig.includes(valor)}
                                    onChange={handleInputChange}
                                  />
                                  {valor}
                                </label>
                              </div>
                            )
                          ))}
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="seobservanalgunosdelossig"
                              value="Arañas"
                              checked={dato.seobservanalgunosdelossig.includes('Arañas')}
                              onChange={handleInputChange}
                            />
                            Arañas
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="seobservanalgunosdelossig"
                              value="Escorpiones o Alacranes"
                              checked={dato.seobservanalgunosdelossig.includes('Escorpiones o Alacranes')}
                              onChange={handleInputChange}
                            />
                            Escorpiones o Alacranes
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="seobservanalgunosdelossig"
                              value="Serpientes o víboras"
                              checked={dato.seobservanalgunosdelossig.includes('Serpientes o víboras')}
                              onChange={handleInputChange}
                            />
                            Serpientes o víboras
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="seobservanalgunosdelossig"
                              value="Abejas"
                              checked={dato.seobservanalgunosdelossig.includes('Abejas')}
                              onChange={handleInputChange}
                            />
                            Abejas
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="seobservanalgunosdelossig"
                              value="Avispas"
                              checked={dato.seobservanalgunosdelossig.includes('Avispas')}
                              onChange={handleInputChange}
                            />
                            Avispas
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="seobservanalgunosdelossig"
                              value="Orugas"
                              checked={dato.seobservanalgunosdelossig.includes('Orugas')}
                              onChange={handleInputChange}
                            />
                            Orugas
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="seobservanalgunosdelossig"
                              value="Ninguno"
                              checked={dato.seobservanalgunosdelossig.includes('Ninguno')}
                              onChange={handleInputChange}
                            />
                            Ninguno
                          </label>
                        </div>
                        <div class="input-group">
                          <div class="input-group-text">
                              <input
                                type="checkbox"
                                class="form-check-input mt-0"
                                name="seobservanalgunosdelossig"
                                value={otrosseobservanalgunosdelossig}
                                checked={dato.seobservanalgunosdelossig.includes(otrosseobservanalgunosdelossig)}
                                onChange={handleInputChange}
                                //onChange={(e) => dato.seobservanalgunosdelossig(e.target.checked)}
                              />
                            </div>
                            <input type="text" class="form-control" aria-label="Text input with checkbox" 
                              placeholder="Digite otras opciones de ser necesario"
                              value={otrosseobservanalgunosdelossig}
                              name="otrosseobservanalgunosdelossig"
                              //onChange={handleInputChange}
                              //onBlur={handleInputBlur}
                              onChange={(event) => handleInputChange(event, setOtrosSeobservanalgunosdelossig)}
                              onBlur={() => handleInputBlur(otrosseobservanalgunosdelossig, 'seobservanalgunosdelossig')}
                              />
                        </div>
                        {/* {!dato.seobservanalgunosdelossig.length && <div className="text-danger">Campo obligatorio</div>} */}
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6"> 
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <div>
                        <label class="form-label">¿Que medidas se ponen en práctica para reducir el riesgo por ataque de animales venenosos, ponzoñosos y mosquitos?</label>
                          {dato.quemedidasseponenenpract.map((valor, index) => (
                            !otrosquemedidasseponenenpractarr.includes(valor) && (
                              <div class="form-check" key={index}>
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name="quemedidasseponenenpract"
                                    value={valor}
                                    checked={dato.quemedidasseponenenpract.includes(valor)}
                                    onChange={handleInputChange}
                                  />
                                  {valor}
                                </label>
                              </div>
                            )
                          ))}
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="quemedidasseponenenpract"
                              value="Sacudir la ropa y los zapatos antes de colocarselos"
                              checked={dato.quemedidasseponenenpract.includes('Sacudir la ropa y los zapatos antes de colocarselos')}
                              onChange={handleInputChange}
                            />
                            Sacudir la ropa y los zapatos antes de colocarselos
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="quemedidasseponenenpract"
                              value="No meter las manos en huecos de los árboles"
                              checked={dato.quemedidasseponenenpract.includes('No meter las manos en huecos de los árboles')}
                              onChange={handleInputChange}
                            />
                            No meter las manos en huecos de los árboles
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="quemedidasseponenenpract"
                              value="Tener precaución al levantar piedras o rocas para no entrar en contacto con animales venenosos o ponzoñosos"
                              checked={dato.quemedidasseponenenpract.includes('Tener precaución al levantar piedras o rocas para no entrar en contacto con animales venenosos o ponzoñosos')}
                              onChange={handleInputChange}
                            />
                            Tener precaución al levantar piedras o rocas para no entrar en contacto con animales venenosos o ponzoñosos
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="quemedidasseponenenpract"
                              value="Orden e higiene en las viviendas y el peridomicilio"
                              checked={dato.quemedidasseponenenpract.includes('Orden e higiene en las viviendas y el peridomicilio')}
                              onChange={handleInputChange}
                            />
                            Orden e higiene en las viviendas y el peridomicilio
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="quemedidasseponenenpract"
                              value="No caminar junto al corte del monte en los caminos de herradura"
                              checked={dato.quemedidasseponenenpract.includes('No caminar junto al corte del monte en los caminos de herradura')}
                              onChange={handleInputChange}
                            />
                            No caminar junto al corte del monte en los caminos de herradura
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="quemedidasseponenenpract"
                              value="No caminar descalzo en el campo, usar botas de caña alta"
                              checked={dato.quemedidasseponenenpract.includes('No caminar descalzo en el campo, usar botas de caña alta')}
                              onChange={handleInputChange}
                            />
                            No caminar descalzo en el campo, usar botas de caña alta
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="quemedidasseponenenpract"
                              value="Evitar que los niños y niñas jueguen en zonas boscosas"
                              checked={dato.quemedidasseponenenpract.includes('Evitar que los niños y niñas jueguen en zonas boscosas')}
                              onChange={handleInputChange}
                            />
                            Evitar que los niños y niñas jueguen en zonas boscosas
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="quemedidasseponenenpract"
                              value="Transitar si es posible en compañía de perros"
                              checked={dato.quemedidasseponenenpract.includes('Transitar si es posible en compañía de perros')}
                              onChange={handleInputChange}
                            />
                            Transitar si es posible en compañía de perros
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="quemedidasseponenenpract"
                              value="Evitar deambular de noche"
                              checked={dato.quemedidasseponenenpract.includes('Evitar deambular de noche')}
                              onChange={handleInputChange}
                            />
                            Evitar deambular de noche
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="quemedidasseponenenpract"
                              value="No molestar panales de abejas o avispas"
                              checked={dato.quemedidasseponenenpract.includes('No molestar panales de abejas o avispas')}
                              onChange={handleInputChange}
                            />
                            No molestar panales de abejas o avispas
                          </label>
                        </div>
                        <div class="input-group">
                          <div class="input-group-text">
                              <input
                                type="checkbox"
                                class="form-check-input mt-0"
                                name="quemedidasseponenenpract"
                                value={otrosquemedidasseponenenpract}
                                checked={dato.quemedidasseponenenpract.includes(otrosquemedidasseponenenpract)}
                                onChange={handleInputChange}
                                //onChange={(e) => dato.quemedidasseponenenpract(e.target.checked)}
                              />
                            </div>
                            <input type="text" class="form-control" aria-label="Text input with checkbox" 
                              placeholder="Digite otras opciones de ser necesario"
                              value={otrosquemedidasseponenenpract}
                              name="otrosquemedidasseponenenpract"
                              //onChange={handleInputChange}
                              //onBlur={handleInputBlur}
                              onChange={(event) => handleInputChange(event, setOtrosQuemedidasseponenenpract)}
                              onBlur={() => handleInputBlur(otrosquemedidasseponenenpract, 'quemedidasseponenenpract')}
                              />
                        </div>
                        {/* {!dato.quemedidasseponenenpract.length && <div className="text-danger">Campo obligatorio</div>} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="m-2 mt-4 border border-primary-subtle"><h4 class="text"> 2.10.	MANEJO DE SUSTANCIAS QUIMICAS Y RESIDUOS PELIGROSOS.</h4>
                <div class="row">
                  <div class="col-12 col-md-6 col-lg-4">      
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">¿Se revisan las etiquetas y se siguen las indicaciones proporcionadas por el fabricante, para el manejo, almacenamiento y precauciones de seguridad de los productos químicos en la vivienda?</label>
                      <select className={`form-select`} name='serevisanlasetiquetasyses' value={dato.serevisanlasetiquetasyses} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Si">Si</option>
                        <option value="No">No</option>
                      </select>
                      {/* {!dato.serevisanlasetiquetasyses && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">¿Los productos de limpieza, aseo personal, medicamentos, cosméticos y plaguicidas se almacenan en sus recipientes originales y en un lugar específico?</label>
                      <select className={`form-select`} name='losproductosdelimpiezaase' value={dato.losproductosdelimpiezaase} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Si">Si</option>
                        <option value="No">No</option>
                      </select>
                      {/* {!dato.losproductosdelimpiezaase && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">El lugar donde se almacenan los productos químicos para el uso en el hogar se encuentra ventilado, cerrado y separado de comida, alimentos para animales o medicamentos y fuera del alcance de los niños, niñas y las macotas?</label>
                      <select className={`form-select`} name='ellugardondesealmacenanlo' value={dato.ellugardondesealmacenanlo} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Si">Si</option>
                        <option value="No">No</option>
                      </select>
                      {/* {!dato.ellugardondesealmacenanlo && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">¿Cuándo se utilizan productos de limpieza, se mantiene el área ventilada y se usan elementos de protección personal durante su aplicación?</label>
                      <select className={`form-select`} name='cuandoseutilizanproductos' value={dato.cuandoseutilizanproductos} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Si">Si</option>
                        <option value="No">No</option>
                      </select>
                      {/* {!dato.cuandoseutilizanproductos && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <div>
                        <label class="form-label">Dónde se adquieren los productos químicos para la vivienda</label>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="dondeseadquierenlosproduc"
                              value="Tiendas de barrio"
                              checked={dato.dondeseadquierenlosproduc.includes('Tiendas de barrio')}
                              onChange={handleInputChange}
                            />
                            Tiendas de barrio
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="dondeseadquierenlosproduc"
                              value="Almacenes de cadena"
                              checked={dato.dondeseadquierenlosproduc.includes('Almacenes de cadena')}
                              onChange={handleInputChange}
                            />
                            Almacenes de cadena
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="dondeseadquierenlosproduc"
                              value="Establecimientos especificos de venta de productos químicos"
                              checked={dato.dondeseadquierenlosproduc.includes('Establecimientos especificos de venta de productos químicos')}
                              onChange={handleInputChange}
                            />
                            Establecimientos especificos de venta de productos químicos
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="dondeseadquierenlosproduc"
                              value="Establecimientos de venta a granel"
                              checked={dato.dondeseadquierenlosproduc.includes('Establecimientos de venta a granel')}
                              onChange={handleInputChange}
                            />
                            Establecimientos de venta a granel
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="dondeseadquierenlosproduc"
                              value="Via WEB"
                              checked={dato.dondeseadquierenlosproduc.includes('Via WEB')}
                              onChange={handleInputChange}
                            />
                            Via WEB
                          </label>
                        </div>
                        {/* {!dato.dondeseadquierenlosproduc.length && <div className="text-danger">Campo obligatorio</div>} */}
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <div>
                        <label class="form-label">Cómo se realiza la disposicion final de los residuos como envases de productos de aseo</label>
                          {dato.comoserealizaladisposicioen.map((valor, index) => (
                            !otroscomoserealizaladisposicioenarr.includes(valor) && (
                              <div class="form-check" key={index}>
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name="comoserealizaladisposicioen"
                                    value={valor}
                                    checked={dato.comoserealizaladisposicioen.includes(valor)}
                                    onChange={handleInputChange}
                                  />
                                  {valor}
                                </label>
                              </div>
                            )
                          ))}
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="comoserealizaladisposicioen"
                              value="Se separan de los residuos ordinarios en la vivienda para posterior aprovechamiento"
                              checked={dato.comoserealizaladisposicioen.includes('Se separan de los residuos ordinarios en la vivienda para posterior aprovechamiento')}
                              onChange={handleInputChange}
                            />
                            Se separan de los residuos ordinarios en la vivienda para posterior aprovechamiento
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="comoserealizaladisposicioen"
                              value="En conjunto con los residuos ordinarios de la vivienda"
                              checked={dato.comoserealizaladisposicioen.includes('En conjunto con los residuos ordinarios de la vivienda')}
                              onChange={handleInputChange}
                            />
                            En conjunto con los residuos ordinarios de la vivienda
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="comoserealizaladisposicioen"
                              value="Quema a campo abierto"
                              checked={dato.comoserealizaladisposicioen.includes('Quema a campo abierto')}
                              onChange={handleInputChange}
                            />
                            Quema a campo abierto
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="comoserealizaladisposicioen"
                              value="Disposición en fuentes de agua cercana"
                              checked={dato.comoserealizaladisposicioen.includes('Disposición en fuentes de agua cercana')}
                              onChange={handleInputChange}
                            />
                            Disposición en fuentes de agua cercana
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="comoserealizaladisposicioen"
                              value="Disposición a campo abierto"
                              checked={dato.comoserealizaladisposicioen.includes('Disposición a campo abierto')}
                              onChange={handleInputChange}
                            />
                            Disposición a campo abierto
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="comoserealizaladisposicioen"
                              value="Enterramiento"
                              checked={dato.comoserealizaladisposicioen.includes('Enterramiento')}
                              onChange={handleInputChange}
                            />
                            Enterramiento
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="comoserealizaladisposicioen"
                              value="Se utilizan para contener bebidad, alimentos y agua y para otros usos en el hogar"
                              checked={dato.comoserealizaladisposicioen.includes('Se utilizan para contener bebidad, alimentos y agua y para otros usos en el hogar')}
                              onChange={handleInputChange}
                            />
                            Se utilizan para contener bebidad, alimentos y agua y para otros usos en el hogar
                          </label>
                        </div>
                        <div class="input-group">
                          <div class="input-group-text">
                              <input
                                type="checkbox"
                                class="form-check-input mt-0"
                                name="comoserealizaladisposicioen"
                                value={otroscomoserealizaladisposicioen}
                                checked={dato.comoserealizaladisposicioen.includes(otroscomoserealizaladisposicioen)}
                                onChange={handleInputChange}
                                //onChange={(e) => dato.comoserealizaladisposicioen(e.target.checked)}
                              />
                            </div>
                            <input type="text" class="form-control" aria-label="Text input with checkbox" 
                              placeholder="Digite otras opciones de ser necesario"
                              value={otroscomoserealizaladisposicioen}
                              name="otroscomoserealizaladisposicioen"
                              //onChange={handleInputChange}
                              //onBlur={handleInputBlur}
                              onChange={(event) => handleInputChange(event, setOtrosComoserealizaladisposicioen)}
                              onBlur={() => handleInputBlur(otroscomoserealizaladisposicioen, 'comoserealizaladisposicioen')}
                              />
                        </div>
                        {/* {!dato.comoserealizaladisposicioen.length && <div className="text-danger">Campo obligatorio</div>} */}
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <div>
                        <label class="form-label">¿Cómo se realiza la disposicion final de los residuos peligrosos como medicamentos vencidos, plaguicidas, RAEES, pilas, aceites usados, bombillos, baterías para autos, entre otros?</label>
                          {dato.comoserealizaladisposiciopel.map((valor, index) => (
                            !otroscomoserealizaladisposiciopelarr.includes(valor) && (
                              <div class="form-check" key={index}>
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name="comoserealizaladisposiciopel"
                                    value={valor}
                                    checked={dato.comoserealizaladisposiciopel.includes(valor)}
                                    onChange={handleInputChange}
                                  />
                                  {valor}
                                </label>
                              </div>
                            )
                          ))}
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="comoserealizaladisposiciopel"
                              value="Recolectados por empresas especializadas"
                              checked={dato.comoserealizaladisposiciopel.includes('Recolectados por empresas especializadas')}
                              onChange={handleInputChange}
                            />
                            Recolectados por empresas especializadas
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="comoserealizaladisposiciopel"
                              value="Sitios autorizados para su recolección"
                              checked={dato.comoserealizaladisposiciopel.includes('Sitios autorizados para su recolección')}
                              onChange={handleInputChange}
                            />
                            Sitios autorizados para su recolección
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="comoserealizaladisposiciopel"
                              value="Centros de acopio"
                              checked={dato.comoserealizaladisposiciopel.includes('Centros de acopio')}
                              onChange={handleInputChange}
                            />
                            Centros de acopio
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="comoserealizaladisposiciopel"
                              value="Enterramiento"
                              checked={dato.comoserealizaladisposiciopel.includes('Enterramiento')}
                              onChange={handleInputChange}
                            />
                            Enterramiento
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="comoserealizaladisposiciopel"
                              value="Quema a campo abierto"
                              checked={dato.comoserealizaladisposiciopel.includes('Quema a campo abierto')}
                              onChange={handleInputChange}
                            />
                            Quema a campo abierto
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="comoserealizaladisposiciopel"
                              value="Disposición en fuentes de agua cercana"
                              checked={dato.comoserealizaladisposiciopel.includes('Disposición en fuentes de agua cercana')}
                              onChange={handleInputChange}
                            />
                            Disposición en fuentes de agua cercana
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="comoserealizaladisposiciopel"
                              value="Disposición a campo abierto"
                              checked={dato.comoserealizaladisposiciopel.includes('Disposición a campo abierto')}
                              onChange={handleInputChange}
                            />
                            Disposición a campo abierto
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="comoserealizaladisposiciopel"
                              value="En conjunto con los residuos ordinarios de la vivienda"
                              checked={dato.comoserealizaladisposiciopel.includes('En conjunto con los residuos ordinarios de la vivienda')}
                              onChange={handleInputChange}
                            />
                            En conjunto con los residuos ordinarios de la vivienda
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="comoserealizaladisposiciopel"
                              value="Se utilizan para contener bebidad, alimentos y agua y para otros usos en el hogar"
                              checked={dato.comoserealizaladisposiciopel.includes('Se utilizan para contener bebidad, alimentos y agua y para otros usos en el hogar')}
                              onChange={handleInputChange}
                            />
                            Se utilizan para contener bebidad, alimentos y agua y para otros usos en el hogar
                          </label>
                        </div>
                        <div class="input-group">
                          <div class="input-group-text">
                              <input
                                type="checkbox"
                                class="form-check-input mt-0"
                                name="comoserealizaladisposiciopel"
                                value={otroscomoserealizaladisposiciopel}
                                checked={dato.comoserealizaladisposiciopel.includes(otroscomoserealizaladisposiciopel)}
                                onChange={handleInputChange}
                                //onChange={(e) => dato.comoserealizaladisposiciopel(e.target.checked)}
                              />
                            </div>
                            <input type="text" class="form-control" aria-label="Text input with checkbox" 
                              placeholder="Digite otras opciones de ser necesario"
                              value={otroscomoserealizaladisposiciopel}
                              name="otroscomoserealizaladisposiciopel"
                              //onChange={handleInputChange}
                              //onBlur={handleInputBlur}
                              onChange={(event) => handleInputChange(event, setOtrosComoserealizaladisposiciopel)}
                              onBlur={() => handleInputBlur(otroscomoserealizaladisposiciopel, 'comoserealizaladisposiciopel')}
                              />
                        </div>
                        {/* {!dato.comoserealizaladisposiciopel.length && <div className="text-danger">Campo obligatorio</div>} */}
                      </div>
                    </div> 
                  </div>
                </div>   
              </div>
              <div class="m-2 mt-4 border border-primary-subtle"><h4 class="text">Observaciones Coordinador Equipo Inicial de Atención</h4>
                <div class="row">
                  <div class="col-12 col-md-6">  
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Analisis de la caracterización familiar:</label>
                      <input
                        type="textarea"
                        name='analisisdelacaracterizaci'
                        className={`form-control ${!dato.analisisdelacaracterizaci ? 'is-invalid' : ''}`}
                        value={dato.analisisdelacaracterizaci}
                        onChange={handleInputChange}
                      />
                      {!dato.analisisdelacaracterizaci && <div className="invalid-feedback">Campo obligatorio</div>}
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Principales factores de riesgo identificado en el hogar caracterizado.</label>
                      <input
                        type="textarea"
                        name='principalesfactoresderies'
                        className={`form-control ${!dato.principalesfactoresderies ? 'is-invalid' : ''}`}
                        value={dato.principalesfactoresderies}
                        onChange={handleInputChange}
                      />
                      {!dato.principalesfactoresderies && <div className="invalid-feedback">Campo obligatorio</div>}
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Integrante(s) de la familia que requieren intervenciones especificas</label>
                      <input
                        type="textarea"
                        name='integrantesdelafamiliaque'
                        className={`form-control ${!dato.integrantesdelafamiliaque ? 'is-invalid' : ''}`}
                        value={dato.integrantesdelafamiliaque}
                        onChange={handleInputChange}
                      />
                      {!dato.integrantesdelafamiliaque && <div className="invalid-feedback">Campo obligatorio</div>}
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">¿Cuáles son los factores de riesgo identificado?</label>
                      <input
                        type="textarea"
                        name='cualessonlosfactoresderie'
                        className={`form-control ${!dato.cualessonlosfactoresderie ? 'is-invalid' : ''}`}
                        value={dato.cualessonlosfactoresderie}
                        onChange={handleInputChange}
                      />
                      {!dato.cualessonlosfactoresderie && <div className="invalid-feedback">Campo obligatorio</div>}
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Resultados por Componentes:</label>
                      <div class="row">
                        <div class="col-md-3">
                          <label class="form-label">Condiciones de habitad  familiar y social</label>
                          <input
                            type="number"
                            name='resultadosporcompocoh'
                            placeholder='Condiciones de habitad  familiar y social'
                            className={`form-control`}
                            value={dato.resultadosporcompocoh}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div class="col-md-3">
                          <label class="form-label">Saniamiento Basico</label>
                          <input
                            type="number"
                            name='resultadosporcomposan'
                            placeholder='Saniamiento Basico'
                            className={`form-control`}
                            value={dato.resultadosporcomposan}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div class="col-md-3">
                          <label class="form-label">Aire Intradocimiciliario</label>
                          <input
                            type="number"
                            name='resultadosporcompoair'
                            placeholder='Aire Intradocimiciliario'
                            className={`form-control`}
                            value={dato.resultadosporcompoair}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div class="col-md-3">
                          <label class="form-label">Zoonosis</label>
                          <input
                            type="number"
                            name='resultadosporcompozoo'
                            placeholder='Zoonosis'
                            className={`form-control`}
                            value={dato.resultadosporcompozoo}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div class="col-md-3">
                          <label class="form-label">Control de Vectores</label>
                          <input
                            type="number"
                            name='resultadosporcompocon'
                            placeholder='Control de Vectores'
                            className={`form-control`}
                            value={dato.resultadosporcompocon}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div class="col-md-3">
                          <label class="form-label">Manejo de sustancias Quimicas  y Eliminación de residuales</label>
                          <input
                            type="number"
                            name='resultadosporcompoman'
                            placeholder='Manejo de sustancias Quimicas  y Eliminación de residuales'
                            className={`form-control`}
                            value={dato.resultadosporcompoman}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div class="col-md-3">
                          <label class="form-label">Valoración Individual</label>
                          <input
                            type="number"
                            name='resultadosporcompoval'
                            placeholder='Valoración Individual'
                            className={`form-control`}
                            value={dato.resultadosporcompoval}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div class="col-md-3">
                          <label class="form-label">Antecedentes Medicos</label>
                          <input
                            type="number"
                            name='resultadosporcompoant'
                            placeholder='Antecedentes Medicos'
                            className={`form-control`}
                            value={dato.resultadosporcompoant}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div class="col-md-3">
                          <label class="form-label">Sexualidad, Derechos Sexuales y Reproductivos</label>
                          <input
                            type="number"
                            name='resultadosporcomposex'
                            placeholder='Sexualidad, Derechos Sexuales y Reproductivos'
                            className={`form-control`}
                            value={dato.resultadosporcomposex}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div class="col-md-3">
                          <label class="form-label">Recien Nacido</label>
                          <input
                            type="number"
                            name='resultadosporcomporec'
                            placeholder='Recien Nacido'
                            className={`form-control`}
                            value={dato.resultadosporcomporec}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div class="col-md-3">
                          <label class="form-label">Vida saludable y condiciones No Transmisibles</label>
                          <input
                            type="number"
                            name='resultadosporcompovid'
                            placeholder='Vida saludable y condiciones No Transmisibles'
                            className={`form-control`}
                            value={dato.resultadosporcompovid}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div class="col-md-3">
                          <label class="form-label">Salud Mental</label>
                          <input
                            type="number"
                            name='resultadosporcomposal'
                            placeholder='Salud Mental'
                            className={`form-control`}
                            value={dato.resultadosporcomposal}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div class="col-md-3">
                          <label class="form-label">Programa Ampliado de Inmunizaciones</label>
                          <input
                            type="number"
                            name='resultadosporcompopro'
                            placeholder='Programa Ampliado de Inmunizaciones'
                            className={`form-control`}
                            value={dato.resultadosporcompopro}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      {/* {!dato.numeroficha && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Clasificación del Riesgo - Alto</label>
                      <input
                        type="text"
                        name='clasificaciondelriesgoalt'
                        className={`form-control`}
                        value={dato.clasificaciondelriesgoalt}
                        onChange={handleInputChange}
                      />
                      {/* {!dato.clasificaciondelriesgoalt && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Clasificación del Riesgo - Medio</label>
                      <input
                        type="text"
                        name='clasificaciondelriesgomed'
                        className={`form-control`}
                        value={dato.clasificaciondelriesgomed}
                        onChange={handleInputChange}
                      />
                      {/* {!dato.clasificaciondelriesgomed && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Clasificación del Riesgo - Bajo</label>
                      <input
                        type="text"
                        name='clasificaciondelriesgobaj'
                        className={`form-control`}
                        value={dato.clasificaciondelriesgobaj}
                        onChange={handleInputChange}
                      />
                      {/* {!dato.clasificaciondelriesgobaj && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div> 
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">¿Requiere priorizar valoración del riesgo antes de generar plan de cuidado?</label>
                      <select className={`form-select`} name='requierepriorizarvaloraci' value={dato.requierepriorizarvaloraci} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Si">Si</option>
                        <option value="No">No</option>
                      </select>
                      {/* {!dato.requierepriorizarvaloraci && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Justifique la respuesta anterior</label>
                      <input
                        type="text"
                        name='justifiquelarespuestaante'
                        className={`form-control`}
                        value={dato.justifiquelarespuestaante}
                        onChange={handleInputChange}
                      />
                      {/* {!dato.justifiquelarespuestaante && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div> 
                  </div>
                </div> 
              </div>
              <div class="m-2 mt-4 border border-primary-subtle"><h4 class="text">Datos del encuestador</h4>
                <div class="row">
                  <div class="col-12 col-md-6 col-lg-4">   
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Nombre y Apellido del Responsable del diligenciamiento</label>
                      <input
                        type="text"
                        name='nombreyapellidodelrespons'
                        className={`form-control ${!dato.nombreyapellidodelrespons ? 'is-invalid' : ''}`}
                        value={dato.nombreyapellidodelrespons}
                        onChange={handleInputChange}
                      />
                      {!dato.nombreyapellidodelrespons && <div className="invalid-feedback">Campo obligatorio</div>}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Rol en el Equipo Inicial de Atención</label>
                      <input
                        type="text"
                        name='rolenelequipoinicialdeate'
                        className={`form-control ${!dato.rolenelequipoinicialdeate ? 'is-invalid' : ''}`}
                        value={dato.rolenelequipoinicialdeate}
                        onChange={handleInputChange}
                      />
                      {!dato.rolenelequipoinicialdeate && <div className="invalid-feedback">Campo obligatorio</div>}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Teléfono/Celular</label>
                      <input
                        type="number"
                        name='telefonocelularresdil'
                        className={`form-control ${!dato.telefonocelularresdil ? 'is-invalid' : ''}`}
                        value={dato.telefonocelularresdil}
                        onChange={handleInputChange}
                      />
                      {!dato.telefonocelularresdil && <div className="invalid-feedback">Campo obligatorio</div>}
                    </div>
                  </div>
                </div> 
              </div>

            </form>
            <button type="submit" className="btn btn-primary" onClick={handleGuardar}>Guardar</button>
          </div>
        ) : (
          <div>
            <h4>Editar ficha: {_id}</h4>
            <button type="submit" className="btn btn-primary" onClick={() => setEdicion(true)}>Editar</button>
          </div>
        )
      ) : (
        <p>Datos editados, busque una nueva ficha..</p>
      )}
    </div>
  );
}

export default EditarDatos;
