import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

function CrearPersona() {
  const navigate = useNavigate();
  const { _id } = useParams(); // _id del documento en la coleccion de mongodb
  const [nuevoMiembro, setNuevoMiembro] = useState({   
    persona: 11,
    nombresyapellidos: '',
    tipodeidentidad: '',
    ndedocumentodeidentidad: '',
    fechadenacimiento: '',
    sexo: '',
    etnia: '',
    condicionespecialsm: '',
    discapacidadsm: '',
    estadocivil: '',
    parentescoconeljefedehoga: '',
    regsalud: '',
    nombredelaeps: '',
    ocupacionprincipal: '',
    dependeeconomicamentedeun: '',
    escolaridad: '',
    antecedentepersonalsm: [],
    enfermedadactualsm: [],
    antecedentefamiliarprimer: [],
    sintomasrecientessincausa: [],
    cepilladodiariominimovec: '',
    utilizaotroelementoparael: '',
    laultimavezquevisitoelodo: '',
    fechadelaultimamenstruaci: '',
    suultimamenstruacionfuede: '',
    actualmentetienerelacione: '',
    practicasexualriesgosasma: '',
    quemetododeplanificacionu: '',
    esconstanteconelmetododep: '',
    hautilizadopormasde10anos: '',
    quienfacilitaelmetodoelac: '',
    npartos: '',
    ncesareas: '',
    nabortos: '',
    nnacidosvivos: '',
    nmortinatos: '',
    edadalmomentodenacerelpri: '',
    ensusembarazosconsumioalc: '',
    embarazoplaneado: '',
    clasificaciondelriesgoobs: '',
    sabeelmotivodelriesgo: '',
    alimentaconlechematernaex: '',
    alimentacioncomplementari: '',
    ultimavezqueasistioacitol: '',
    ultimavezlerealizaronprue: '',
    ultimavezquelerealizarone: '',
    ultimavezquelerealizaronm: '',
    practicadeactividadfisica: '',
    habitosdealimentacion: '',
    exposicionfrecuenteahumos: '',
    sedesparasitadoenlosultim: '',
    ultimavezquelepracticaron: '',
    pesokg: '',
    tallacm: '',
    clasificacionantrometrica: '',
    circunferenciadelacintura: '',
    perimetrodelbrazoninosyni: '',
    actualmenteexistealgunacr: '',
    sedespiertavariashorasant: '',
    avecessienteenquenotienes: '',
    consideraquelamuertepuede: '',
    estarcargodelcuidadodealg: '',
    experimentaunaperdidamuer: '',
    consideraqueloscomportami: '',
    consideraanormalessushabi: '',
    identificaensuhogarcompor: '',
    consumodesustanciaspsicoa: '',
    seisdosisrnbcg: '',
    seistercerasdosispenta: '',
    seisdosisanotripleviral: '',
    seisdiesiochomesesdptopenta: '',
    seisdosiscincoaniossegundorfdpt: '',
    seisdosisdeinfluenzaanual: '',
    seisesquemacompleto: '',
    seisvacunadocanalizado: '',
    dosdosisadicionalsr: '',
    dosesquemacompleto: '',
    dosvacunadocanalizado: '',
    covidprimeradosis: '',
    coviddosusunica: '',
    covidsegundadodis: '',
    covidprimerrefuerzo: '',
    covidsegundorefuerzo: '',
    covidesquemacompleto: '',
    covidvacunadocanalizado: '',
    mujereseps: '',
    mujeresprimeraradosis: '',
    mujeressegundadadosis: '',
    mujeresesquemacompleto: '',
    mujeresvacunadocanalizado: '',
    gestsemanasdegestacion: '',
    gestcontrolprenatal: '',
    gesttdapcelularsemveinte: '',
    gesttoxoidetetanicosem: '',
    gestinfluenzasemdoce: '',
    gestcovidsemdoce: '',
    gestesquemacompleto: '',
    gestvacunadocanalizado: '',
    mayordosisanualdeinfluenza: '',
    mayordosiscovid: '',
    mayorenfermedadcronica: '',
    mayorcontrolenfermedad:   '',    
    mayoresquemacompleto: '',
    mayorvacunadocanalizado: '',      
    amaridosisfa: '',    
    amariesquemacompleto: '',
    amarivacunadocanalizado: '',
  }); 
  const [nuevo, setNuevo] = useState(false);

  useEffect(() => {
    // Puedes realizar alguna lógica inicial si es necesario
  }, []);

  const handleGuardar = () => {
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/agregar-miembro/${_id}`, {
      grupospersonas: [nuevoMiembro],
    })
      .then((response) => {
        // Puedes realizar acciones adicionales después de agregar el nuevo miembro
        setNuevoMiembro(response.data.grupospersonas[0]);
        alert('Nuevo miembro agregado');
        navigate('/fichas');
      })
      .catch((error) => {
        console.error('Error al agregar nuevo miembro:', error);
      });
  };
  console.log('_id:', _id);
  
  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;

    setNuevoMiembro((prevCampos) => {
        if (type === 'checkbox') {
            return {
                ...prevCampos,
                [name]: prevCampos[name].includes(value)
                    ? prevCampos[name].filter((item) => item !== value)
                    : [...prevCampos[name], value],
            };
        }

        // Asegúrate de que `newArray` esté definido antes de utilizarlo
        const newArray = type === 'checkbox' ? [] : value;

        return {
            ...prevCampos,
            [name]: newArray,
        };
    });
};

  return (
    <div class="container">  
      {nuevoMiembro ? (
        nuevo ? (
        <div>
            <form>
                <div class="mb-4"><h4 class="text-center"> 3.	DATOS BASICOS</h4>
                <div class="row">
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">Nombres y Apellidos:</label>
                        <input
                        type="text"
                        name='nombresyapellidos'
                        className={`form-control`}
                        value={nuevoMiembro.nombresyapellidos}
                        onChange={handleInputChange}
                        />
                    </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">Tipo de Identidad</label>
                        <select className={`form-select`} name='tipodeidentidad' value={nuevoMiembro.tipodeidentidad} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="RC">RC</option>
                        <option value="TI">TI</option>
                        <option value="CC">CC</option>
                        <option value="ASI">ASI</option>
                        <option value="MSI">MSI</option>
                        <option value="CE">CE</option>
                        </select>
                        {/* {!nuevoMiembro.tipodeidentidad && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">N° de documento de Identidad:</label>
                        <input
                        type="number"
                        name='ndedocumentodeidentidad'
                        className={`form-control`}
                        value={nuevoMiembro.ndedocumentodeidentidad}
                        onChange={handleInputChange}
                        />
                    </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">Fecha de Nacimiento</label>
                        <input
                        type="date"
                        name='fechadenacimiento'
                        className={`form-control`}
                        value={nuevoMiembro.fechadenacimiento ? nuevoMiembro.fechadenacimiento.split('T')[0] : ''}
                        onChange={handleInputChange}
                        />
                    </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">Sexo</label>
                        <select className={`form-select`} name='sexo' value={nuevoMiembro.sexo} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="F">F</option>
                        <option value="M">M</option>
                        </select>
                        {/* {!nuevoMiembro.sexo && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">Etnia</label>
                        <select className={`form-select`} name='etnia' value={nuevoMiembro.etnia} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Afrocolombiano">Afrocolombiano</option>
                        <option value="mulato, negro">mulato, negro</option>
                        <option value="Palenquero">Palenquero</option>
                        <option value="Indígena, pueblo">Indígena, pueblo</option>
                        <option value="Raizal">Raizal</option>
                        <option value="ROM">ROM</option>
                        <option value="Ninguno">Ninguno</option>
                        </select>
                        {/* {!nuevoMiembro.etnia && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">Condición especial (SM)</label>
                        <select className={`form-select`} name='condicionespecialsm' value={nuevoMiembro.condicionespecialsm} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Victima">Victima</option>
                        <option value="Habitante de calle">Habitante de calle</option>
                        <option value="LGTBI">LGTBI</option>
                        <option value="No aplica ">No aplica </option>
                        </select>
                        {/* {!nuevoMiembro.condicionespecialsm && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">Discapacidad (S/M)</label>
                        <select className={`form-select`} name='discapacidadsm' value={nuevoMiembro.discapacidadsm} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Cognitiva">Cognitiva</option>
                        <option value="Motora">Motora</option>
                        <option value="Sensorial">Sensorial</option>
                        <option value="Ninguna ">Ninguna </option>
                        </select>
                        {/* {!nuevoMiembro.discapacidadsm && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">Estado civil</label>
                        <select className={`form-select`} name='estadocivil' value={nuevoMiembro.estadocivil} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Unión libre">Unión libre</option>
                        <option value="hijastro(a)">Casado(a)</option>
                        <option value="Viudo(a)">Viudo(a)</option>
                        <option value="Separado(a)">Separado(a)</option>
                        <option value="Soltero(a)">Soltero(a)</option>
                        <option value="Compañero permanente">Compañero permanente</option>
                        <option value="No aplica">No aplica</option>
                        </select>
                        {/* {!nuevoMiembro.estadocivil && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">Parentesco con el jefe de hogar</label>
                        <select className={`form-select`} name='parentescoconeljefedehoga' value={nuevoMiembro.parentescoconeljefedehoga} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Hijo(a)">Hijo(a)</option>
                        <option value="hijastro(a)">hijastro(a)</option>
                        <option value="Nieto(a)">Nieto(a)</option>
                        <option value="padre o madre">padre o madre</option>
                        <option value="Hermano(a)">Hermano(a)</option>
                        <option value="Yerno –Nuera">Yerno –Nuera</option>
                        <option value="Abuelos">Abuelos</option>
                        <option value="Suegros">Suegros</option>
                        <option value="No parientes ">No parientes </option>
                        </select>
                        {/* {!nuevoMiembro.parentescoconeljefedehoga && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">Reg. Salud</label>
                        <select className={`form-select`} name='regsalud' value={nuevoMiembro.regsalud} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="S">S</option>
                        <option value="C">C</option>
                        <option value="E">E</option>
                        <option value="PNA">PNA</option>
                        </select>
                        {/* {!nuevoMiembro.regsalud && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">Nombre de la EPS</label>
                        <select className={`form-select`} name='nombredelaeps' value={nuevoMiembro.nombredelaeps} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Asmet Salud">Asmet Salud</option>
                        <option value="Nueva EPS">Nueva EPS</option>
                        <option value="Sanitas EPS">Sanitas EPS</option>
                        <option value="FAMAC">FAMAC</option>
                        <option value="Sanidad Ejercito">Sanidad Ejercito</option>
                        <option value="Sanidad Policia.">Sanidad Policia.</option>
                        <option value="Otro">Otro</option>
                        </select>
                        {/* {!nuevoMiembro.nombredelaeps && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">Ocupación principal</label>
                        <select className={`form-select`} name='ocupacionprincipal' value={nuevoMiembro.ocupacionprincipal} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Desempleado (desde los 12 a)">Desempleado (desde los 12 a)</option>
                        <option value="Trabajador (desde los 12 a)">Trabajador (desde los 12 a)</option>
                        <option value="Ama de casa (desde los 12 a)">Ama de casa (desde los 12 a)</option>
                        <option value="Pensionado/jubilado (desde los 18 a)">Pensionado/jubilado (desde los 18 a)</option>
                        <option value="Estudiante (desde de los 7 a)">Estudiante (desde de los 7 a)</option>
                        <option value="Ninguna (Entre los 7 y 11 años)">Ninguna (Entre los 7 y 11 años)</option>
                        </select>
                        {/* {!nuevoMiembro.ocupacionprincipal && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">Depende económicamente de un familiar</label>
                        <select className={`form-select`} name='dependeeconomicamentedeun' value={nuevoMiembro.dependeeconomicamentedeun} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Si">Si</option>
                        <option value="No">No</option>
                        <option value="SD">SD</option>
                        </select>
                        {/* {!nuevoMiembro.dependeeconomicamentedeun && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">Escolaridad</label>
                        <select className={`form-select`} name='escolaridad' value={nuevoMiembro.escolaridad} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Ningún estudio">Ningún estudio</option>
                        <option value="Primaria">Primaria</option>
                        <option value="Secundaria">Secundaria</option>
                        <option value="Técnica">Técnica</option>
                        <option value="Tecnológico">Tecnológico</option>
                        <option value="Universitario">Universitario</option>
                        <option value="Postgrado">Postgrado</option>
                        <option value="Abandono los estudios de primaria o bachiller (De 7 a 21 años)">Abandono los estudios de primaria o bachiller (De 7 a 21 años)</option>
                        </select>
                        {/* {!nuevoMiembro.escolaridad && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                    </div>
                </div>
                </div>
                <div class="mb-4"><h4 class="text-center"> 3.1.	ANTECEDENTES MÉDICOS</h4>
                <div class="row">
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <div>
                        <label class="form-label">Antecedente Personal (SM)</label>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="antecedentepersonalsm"
                                value="1. Abuso sexual en la infancia (a partir 14 a)"
                                checked={nuevoMiembro.antecedentepersonalsm.includes('1. Abuso sexual en la infancia (a partir 14 a)')}
                                onChange={handleInputChange}
                            />
                            1. Abuso sexual en la infancia (a partir 14 a)
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="antecedentepersonalsm"
                                value="2. Cáncer"
                                checked={nuevoMiembro.antecedentepersonalsm.includes('2. Cáncer')}
                                onChange={handleInputChange}
                            />
                            2. Cáncer
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="antecedentepersonalsm"
                                value="3. Complicación de la madre durante la gestación, parto o puerperio (0 a 4 a)"
                                checked={nuevoMiembro.antecedentepersonalsm.includes('3. Complicación de la madre durante la gestación, parto o puerperio (0 a 4 a)')}
                                onChange={handleInputChange}
                            />
                            3. Complicación de la madre durante la gestación, parto o puerperio (0 a 4 a)
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="antecedentepersonalsm"
                                value="4. Depresión – ansiedad (a partir 13 a)"
                                checked={nuevoMiembro.antecedentepersonalsm.includes('4. Depresión – ansiedad (a partir 13 a)')}
                                onChange={handleInputChange}
                            />
                            4. Depresión – ansiedad (a partir 13 a)
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="antecedentepersonalsm"
                                value="5. Diabetes (a partir 6 a)"
                                checked={nuevoMiembro.antecedentepersonalsm.includes('5. Diabetes (a partir 6 a)')}
                                onChange={handleInputChange}
                            />
                            5. Diabetes (a partir 6 a)
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="antecedentepersonalsm"
                                value="6. Diabetes Gestacional (a partir 12 a)"
                                checked={nuevoMiembro.antecedentepersonalsm.includes('6. Diabetes Gestacional (a partir 12 a)')}
                                onChange={handleInputChange}
                            />
                            6. Diabetes Gestacional (a partir 12 a)
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="antecedentepersonalsm"
                                value="7. Dificultades escolares (a partir 15 a)"
                                checked={nuevoMiembro.antecedentepersonalsm.includes('7. Dificultades escolares (a partir 15 a)')}
                                onChange={handleInputChange}
                            />
                            7. Dificultades escolares (a partir 15 a)
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="antecedentepersonalsm"
                                value="8. Enfermedad cardiovascular (a partir 31 a)"
                                checked={nuevoMiembro.antecedentepersonalsm.includes('8. Enfermedad cardiovascular (a partir 31 a)')}
                                onChange={handleInputChange}
                            />
                            8. Enfermedad cardiovascular (a partir 31 a)
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="antecedentepersonalsm"
                                value="9. ERC"
                                checked={nuevoMiembro.antecedentepersonalsm.includes('9. ERC')}
                                onChange={handleInputChange}
                            />
                            9. ERC
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="antecedentepersonalsm"
                                value="10. EPOC (a partir 31 a)"
                                checked={nuevoMiembro.antecedentepersonalsm.includes('10. EPOC (a partir 31 a)')}
                                onChange={handleInputChange}
                            />
                            10. EPOC (a partir 31 a)
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="antecedentepersonalsm"
                                value="11. Esquizofrenia"
                                checked={nuevoMiembro.antecedentepersonalsm.includes('11. Esquizofrenia')}
                                onChange={handleInputChange}
                            />
                            11. Esquizofrenia
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="antecedentepersonalsm"
                                value="12. Exposición a la violencia"
                                checked={nuevoMiembro.antecedentepersonalsm.includes('12. Exposición a la violencia')}
                                onChange={handleInputChange}
                            />
                            12. Exposición a la violencia
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="antecedentepersonalsm"
                                value="13. Helicobacter pylori (a partir 31 a)"
                                checked={nuevoMiembro.antecedentepersonalsm.includes('13. Helicobacter pylori (a partir 31 a)')}
                                onChange={handleInputChange}
                            />
                            13. Helicobacter pylori (a partir 31 a)
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="antecedentepersonalsm"
                                value="14. HTA"
                                checked={nuevoMiembro.antecedentepersonalsm.includes('14. HTA')}
                                onChange={handleInputChange}
                            />
                            14. HTA
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="antecedentepersonalsm"
                                value="15. Intento de suicidio (a partir 11 a)"
                                checked={nuevoMiembro.antecedentepersonalsm.includes('15. Intento de suicidio (a partir 11 a)')}
                                onChange={handleInputChange}
                            />
                            15. Intento de suicidio (a partir 11 a)
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="antecedentepersonalsm"
                                value="16. No lactancia materna (0 a 5 a)"
                                checked={nuevoMiembro.antecedentepersonalsm.includes('16. No lactancia materna (0 a 5 a)')}
                                onChange={handleInputChange}
                            />
                            16. No lactancia materna (0 a 5 a)
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="antecedentepersonalsm"
                                value="17. No lactancia materna actual (6 a 23 m)"
                                checked={nuevoMiembro.antecedentepersonalsm.includes('17. No lactancia materna actual (6 a 23 m)')}
                                onChange={handleInputChange}
                            />
                            17. No lactancia materna actual (6 a 23 m)
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="antecedentepersonalsm"
                                value="18. No lactancia materna exclusiva hasta los 6 meses"
                                checked={nuevoMiembro.antecedentepersonalsm.includes('18. No lactancia materna exclusiva hasta los 6 meses')}
                                onChange={handleInputChange}
                            />
                            18. No lactancia materna exclusiva hasta los 6 meses
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="antecedentepersonalsm"
                                value="19. Retraso desarrollo psicomotor en el primer año de vida (a partir 15 a)"
                                checked={nuevoMiembro.antecedentepersonalsm.includes('19. Retraso desarrollo psicomotor en el primer año de vida (a partir 15 a)')}
                                onChange={handleInputChange}
                            />
                            19. Retraso desarrollo psicomotor en el primer año de vida (a partir 15 a)
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="antecedentepersonalsm"
                                value="20. TB"
                                checked={nuevoMiembro.antecedentepersonalsm.includes('20. TB')}
                                onChange={handleInputChange}
                            />
                            20. TB
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="antecedentepersonalsm"
                                value="21. Trastorno afectivo bipolar (a partir 9 a)"
                                checked={nuevoMiembro.antecedentepersonalsm.includes('21. Trastorno afectivo bipolar (a partir 9 a)')}
                                onChange={handleInputChange}
                            />
                            21. Trastorno afectivo bipolar (a partir 9 a)
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="antecedentepersonalsm"
                                value="22. Trastornos mentales en la infancia y adolescencia (a partir 18 a)"
                                checked={nuevoMiembro.antecedentepersonalsm.includes('22. Trastornos mentales en la infancia y adolescencia (a partir 18 a)')}
                                onChange={handleInputChange}
                            />
                            22. Trastornos mentales en la infancia y adolescencia (a partir 18 a)
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="antecedentepersonalsm"
                                value="23. VIH"
                                checked={nuevoMiembro.antecedentepersonalsm.includes('23. VIH')}
                                onChange={handleInputChange}
                            />
                            23. VIH
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="antecedentepersonalsm"
                                value="24. VPH (a partir 12 a)"
                                checked={nuevoMiembro.antecedentepersonalsm.includes('24. VPH (a partir 12 a)')}
                                onChange={handleInputChange}
                            />
                            24. VPH (a partir 12 a)
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="antecedentepersonalsm"
                                value="25. Niveles de azúcar altos"
                                checked={nuevoMiembro.antecedentepersonalsm.includes('25. Niveles de azúcar altos')}
                                onChange={handleInputChange}
                            />
                            25. Niveles de azúcar altos
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="antecedentepersonalsm"
                                value="26. Ninguna"
                                checked={nuevoMiembro.antecedentepersonalsm.includes('26. Ninguna')}
                                onChange={handleInputChange}
                            />
                            26. Ninguna
                            </label>
                        </div>
                        {/* {!nuevoMiembro.antecedentepersonalsm.length && <div className="text-danger">Campo obligatorio</div>} */}
                        </div>
                    </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <div>
                        <label class="form-label">Enfermedad actual (SM)</label>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="enfermedadactualsm"
                                value="1. Bajo peso al nacer (o a 11 m)"
                                checked={nuevoMiembro.enfermedadactualsm.includes('1. Bajo peso al nacer (o a 11 m)')}
                                onChange={handleInputChange}
                            />
                            1. Bajo peso al nacer (o a 11 m)
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="enfermedadactualsm"
                                value="2. Cáncer de colon-recto (a partir 31 a)"
                                checked={nuevoMiembro.enfermedadactualsm.includes('2. Cáncer de colon-recto (a partir 31 a)')}
                                onChange={handleInputChange}
                            />
                            2. Cáncer de colon-recto (a partir 31 a)
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="enfermedadactualsm"
                                value="3. Cáncer de cuello uterino-cérvix (a partir 21 a)"
                                checked={nuevoMiembro.enfermedadactualsm.includes('3. Cáncer de cuello uterino-cérvix (a partir 21 a)')}
                                onChange={handleInputChange}
                            />
                            3. Cáncer de cuello uterino-cérvix (a partir 21 a)
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="enfermedadactualsm"
                                value="4. Cáncer de estómago (a partir 31 a)"
                                checked={nuevoMiembro.enfermedadactualsm.includes('4. Cáncer de estómago (a partir 31 a)')}
                                onChange={handleInputChange}
                            />
                            4. Cáncer de estómago (a partir 31 a)
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="enfermedadactualsm"
                                value="5. Cáncer de mama (a partir 21 a)"
                                checked={nuevoMiembro.enfermedadactualsm.includes('5. Cáncer de mama (a partir 21 a)')}
                                onChange={handleInputChange}
                            />
                            5. Cáncer de mama (a partir 21 a)
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="enfermedadactualsm"
                                value="6. Cáncer de próstata (a partir 40 a)"
                                checked={nuevoMiembro.enfermedadactualsm.includes('6. Cáncer de próstata (a partir 40 a)')}
                                onChange={handleInputChange}
                            />
                            6. Cáncer de próstata (a partir 40 a)
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="enfermedadactualsm"
                                value="7. Cáncer de pulmón (a partir 50 a)"
                                checked={nuevoMiembro.enfermedadactualsm.includes('7. Cáncer de pulmón (a partir 50 a)')}
                                onChange={handleInputChange}
                            />
                            7. Cáncer de pulmón (a partir 50 a)
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="enfermedadactualsm"
                                value="8. Caries (6m a 5 a)"
                                checked={nuevoMiembro.enfermedadactualsm.includes('8. Caries (6m a 5 a)')}
                                onChange={handleInputChange}
                            />
                            8. Caries (6m a 5 a)
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="enfermedadactualsm"
                                value="9. Depresión – ansiedad (a partir 13 a)"
                                checked={nuevoMiembro.enfermedadactualsm.includes('9. Depresión – ansiedad (a partir 13 a)')}
                                onChange={handleInputChange}
                            />
                            9. Depresión – ansiedad (a partir 13 a)
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="enfermedadactualsm"
                                value="10. Desnutrición"
                                checked={nuevoMiembro.enfermedadactualsm.includes('10. Desnutrición')}
                                onChange={handleInputChange}
                            />
                            10. Desnutrición
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="enfermedadactualsm"
                                value="11. Diabetes Gestacional (a partir 12 a)"
                                checked={nuevoMiembro.enfermedadactualsm.includes('11. Diabetes Gestacional (a partir 12 a)')}
                                onChange={handleInputChange}
                            />
                            11. Diabetes Gestacional (a partir 12 a)
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="enfermedadactualsm"
                                value="12. Diarrea permanente"
                                checked={nuevoMiembro.enfermedadactualsm.includes('12. Diarrea permanente')}
                                onChange={handleInputChange}
                            />
                            12. Diarrea permanente
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="enfermedadactualsm"
                                value="13. Dislipidemia (a partir 31 a)"
                                checked={nuevoMiembro.enfermedadactualsm.includes('13. Dislipidemia (a partir 31 a)')}
                                onChange={handleInputChange}
                            />
                            13. Dislipidemia (a partir 31 a)
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="enfermedadactualsm"
                                value="14. Enfermedad periodontal"
                                checked={nuevoMiembro.enfermedadactualsm.includes('14. Enfermedad periodontal')}
                                onChange={handleInputChange}
                            />
                            14. Enfermedad periodontal
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="enfermedadactualsm"
                                value="15. Helicobacter pylori (a partir 31 a)"
                                checked={nuevoMiembro.enfermedadactualsm.includes('15. Helicobacter pylori (a partir 31 a)')}
                                onChange={handleInputChange}
                            />
                            15. Helicobacter pylori (a partir 31 a)
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="enfermedadactualsm"
                                value="16. Hipotiroidismo congénito"
                                checked={nuevoMiembro.enfermedadactualsm.includes('16. Hipotiroidismo congénito')}
                                onChange={handleInputChange}
                            />
                            16. Hipotiroidismo congénito
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="enfermedadactualsm"
                                value="17. ITS"
                                checked={nuevoMiembro.enfermedadactualsm.includes('17. ITS')}
                                onChange={handleInputChange}
                            />
                            17. ITS
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="enfermedadactualsm"
                                value="18. Leucemia y linfoma en niños (0 a 18 a)"
                                checked={nuevoMiembro.enfermedadactualsm.includes('18. Leucemia y linfoma en niños (0 a 18 a)')}
                                onChange={handleInputChange}
                            />
                            18. Leucemia y linfoma en niños (0 a 18 a)
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="enfermedadactualsm"
                                value="19. Neumonía (0 a 4 años)"
                                checked={nuevoMiembro.enfermedadactualsm.includes('19. Neumonía (0 a 4 años)')}
                                onChange={handleInputChange}
                            />
                            19. Neumonía (0 a 4 años)
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="enfermedadactualsm"
                                value="20. TB"
                                checked={nuevoMiembro.enfermedadactualsm.includes('20. TB')}
                                onChange={handleInputChange}
                            />
                            20. TB
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="enfermedadactualsm"
                                value="21. Trastornos mentales en la infancia y adolescencia (9 a 17 a)"
                                checked={nuevoMiembro.enfermedadactualsm.includes('21. Trastornos mentales en la infancia y adolescencia (9 a 17 a)')}
                                onChange={handleInputChange}
                            />
                            21. Trastornos mentales en la infancia y adolescencia (9 a 17 a)
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="enfermedadactualsm"
                                value="22. VPH (a partir 12 a)"
                                checked={nuevoMiembro.enfermedadactualsm.includes('22. VPH (a partir 12 a)')}
                                onChange={handleInputChange}
                            />
                            22. VPH (a partir 12 a)
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="enfermedadactualsm"
                                value="23. Infección urinaria recurrente (a partir 9 a)"
                                checked={nuevoMiembro.enfermedadactualsm.includes('23. Infección urinaria recurrente (a partir 9 a)')}
                                onChange={handleInputChange}
                            />
                            23. Infección urinaria recurrente (a partir 9 a)
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="enfermedadactualsm"
                                value="24. Ninguna"
                                checked={nuevoMiembro.enfermedadactualsm.includes('24. Ninguna')}
                                onChange={handleInputChange}
                            />
                            24. Ninguna
                            </label>
                        </div>
                        {/* {!nuevoMiembro.enfermedadactualsm.length && <div className="text-danger">Campo obligatorio</div>} */}
                        </div>
                    </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <div>
                        <label class="form-label">Antecedente Familiar primer y segundo grado (SM)</label>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="antecedentefamiliarprimer"
                                value="1.Cáncer de colon-recto (a partir de 19 a)"
                                checked={nuevoMiembro.antecedentefamiliarprimer.includes('1.Cáncer de colon-recto (a partir de 19 a)')}
                                onChange={handleInputChange}
                            />
                            1.Cáncer de colon-recto (a partir de 19 a)
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="antecedentefamiliarprimer"
                                value="2. Cáncer de cuello uterino-cérvix (a partir 19 a)"
                                checked={nuevoMiembro.antecedentefamiliarprimer.includes('2. Cáncer de cuello uterino-cérvix (a partir 19 a)')}
                                onChange={handleInputChange}
                            />
                            2. Cáncer de cuello uterino-cérvix (a partir 19 a)
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="antecedentefamiliarprimer"
                                value="3. Cáncer de estómago. (a partir 19 a)"
                                checked={nuevoMiembro.antecedentefamiliarprimer.includes('3. Cáncer de estómago. (a partir 19 a)')}
                                onChange={handleInputChange}
                            />
                            3. Cáncer de estómago. (a partir 19 a)
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="antecedentefamiliarprimer"
                                value="4. Cáncer de mama (a partir 19 a)"
                                checked={nuevoMiembro.antecedentefamiliarprimer.includes('4. Cáncer de mama (a partir 19 a)')}
                                onChange={handleInputChange}
                            />
                            4. Cáncer de mama (a partir 19 a)
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="antecedentefamiliarprimer"
                                value="5. Cáncer de próstata (a partir 19 a)"
                                checked={nuevoMiembro.antecedentefamiliarprimer.includes('5. Cáncer de próstata (a partir 19 a)')}
                                onChange={handleInputChange}
                            />
                            5. Cáncer de próstata (a partir 19 a)
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="antecedentefamiliarprimer"
                                value="6. Cáncer de pulmón (a partir 19 a)"
                                checked={nuevoMiembro.antecedentefamiliarprimer.includes('6. Cáncer de pulmón (a partir 19 a)')}
                                onChange={handleInputChange}
                            />
                            6. Cáncer de pulmón (a partir 19 a)
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="antecedentefamiliarprimer"
                                value="7. Diabetes (a partir de los 6 a)"
                                checked={nuevoMiembro.antecedentefamiliarprimer.includes('7. Diabetes (a partir de los 6 a)')}
                                onChange={handleInputChange}
                            />
                            7. Diabetes (a partir de los 6 a)
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="antecedentefamiliarprimer"
                                value="8. Enfermedad cardiovascular (a partir 18 a)"
                                checked={nuevoMiembro.antecedentefamiliarprimer.includes('8. Enfermedad cardiovascular (a partir 18 a)')}
                                onChange={handleInputChange}
                            />
                            8. Enfermedad cardiovascular (a partir 18 a)
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="antecedentefamiliarprimer"
                                value="9. Esquizofrenia (a partir 9 a)"
                                checked={nuevoMiembro.antecedentefamiliarprimer.includes('9. Esquizofrenia (a partir 9 a)')}
                                onChange={handleInputChange}
                            />
                            9. Esquizofrenia (a partir 9 a)
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="antecedentefamiliarprimer"
                                value="10. Leucemia y linfoma en niños (0 a 17 a)"
                                checked={nuevoMiembro.antecedentefamiliarprimer.includes('10. Leucemia y linfoma en niños (0 a 17 a)')}
                                onChange={handleInputChange}
                            />
                            10. Leucemia y linfoma en niños (0 a 17 a)
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="antecedentefamiliarprimer"
                                value="11. Trastorno afectivo bipolar (a partir 9 a)"
                                checked={nuevoMiembro.antecedentefamiliarprimer.includes('11. Trastorno afectivo bipolar (a partir 9 a)')}
                                onChange={handleInputChange}
                            />
                            11. Trastorno afectivo bipolar (a partir 9 a)
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="antecedentefamiliarprimer"
                                value="12. Ninguna"
                                checked={nuevoMiembro.antecedentefamiliarprimer.includes('12. Ninguna')}
                                onChange={handleInputChange}
                            />
                            12. Ninguna
                            </label>
                        </div>
                        {/* {!nuevoMiembro.antecedentefamiliarprimer.length && <div className="text-danger">Campo obligatorio</div>} */}
                        </div>
                    </div>
                    </div>
                    <div class="col-12">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <div>
                        <label class="form-label">Síntomas recientes sin causa aparente (SM)</label>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="sintomasrecientessincausa"
                                value="1. Adinamia-debilidad"
                                checked={nuevoMiembro.sintomasrecientessincausa.includes('1. Adinamia-debilidad')}
                                onChange={handleInputChange}
                            />
                            1. Adinamia-debilidad
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="sintomasrecientessincausa"
                                value="2. Bronquitis o sibilancias"
                                checked={nuevoMiembro.sintomasrecientessincausa.includes('2. Bronquitis o sibilancias')}
                                onChange={handleInputChange}
                            />
                            2. Bronquitis o sibilancias
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="sintomasrecientessincausa"
                                value="3. Candidiasis"
                                checked={nuevoMiembro.sintomasrecientessincausa.includes('3. Candidiasis')}
                                onChange={handleInputChange}
                            />
                            3. Candidiasis
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="sintomasrecientessincausa"
                                value="4. Cefaleas"
                                checked={nuevoMiembro.sintomasrecientessincausa.includes('4. Cefaleas')}
                                onChange={handleInputChange}
                            />
                            4. Cefaleas
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="sintomasrecientessincausa"
                                value="5. Cicatrización lenta"
                                checked={nuevoMiembro.sintomasrecientessincausa.includes('5. Cicatrización lenta')}
                                onChange={handleInputChange}
                            />
                            5. Cicatrización lenta
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="sintomasrecientessincausa"
                                value="6. Diarrea permanente"
                                checked={nuevoMiembro.sintomasrecientessincausa.includes('6. Diarrea permanente')}
                                onChange={handleInputChange}
                            />
                            6. Diarrea permanente
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="sintomasrecientessincausa"
                                value="7. Dificultad para orinar (M)"
                                checked={nuevoMiembro.sintomasrecientessincausa.includes('7. Dificultad para orinar (M)')}
                                onChange={handleInputChange}
                            />
                            7. Dificultad para orinar (M)
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="sintomasrecientessincausa"
                                value="8. Dificultad para respirar"
                                checked={nuevoMiembro.sintomasrecientessincausa.includes('8. Dificultad para respirar')}
                                onChange={handleInputChange}
                            />
                            8. Dificultad para respirar
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="sintomasrecientessincausa"
                                value="9. Disminución y alteración en la materia fecal"
                                checked={nuevoMiembro.sintomasrecientessincausa.includes('9. Disminución y alteración en la materia fecal')}
                                onChange={handleInputChange}
                            />
                            9. Disminución y alteración en la materia fecal
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="sintomasrecientessincausa"
                                value="10. Dolor abdominal"
                                checked={nuevoMiembro.sintomasrecientessincausa.includes('10. Dolor abdominal')}
                                onChange={handleInputChange}
                            />
                            10. Dolor abdominal
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="sintomasrecientessincausa"
                                value="11. Dolor al eyacular u orinar (M)"
                                checked={nuevoMiembro.sintomasrecientessincausa.includes('11. Dolor al eyacular u orinar (M)')}
                                onChange={handleInputChange}
                            />
                            11. Dolor al eyacular u orinar (M)
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="sintomasrecientessincausa"
                                value="12. Dolor constante en el pecho"
                                checked={nuevoMiembro.sintomasrecientessincausa.includes('12. Dolor constante en el pecho')}
                                onChange={handleInputChange}
                            />
                            12. Dolor constante en el pecho
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="sintomasrecientessincausa"
                                value="13. Dolor durante las relaciones sexuales (F)"
                                checked={nuevoMiembro.sintomasrecientessincausa.includes('13. Dolor durante las relaciones sexuales (F)')}
                                onChange={handleInputChange}
                            />
                            13. Dolor durante las relaciones sexuales (F)
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="sintomasrecientessincausa"
                                value="14. Dolor torácico al respirar y/o toser"
                                checked={nuevoMiembro.sintomasrecientessincausa.includes('14. Dolor torácico al respirar y/o toser')}
                                onChange={handleInputChange}
                            />
                            14. Dolor torácico al respirar y/o toser
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="sintomasrecientessincausa"
                                value="15. Dolores óseos"
                                checked={nuevoMiembro.sintomasrecientessincausa.includes('15. Dolores óseos')}
                                onChange={handleInputChange}
                            />
                            15. Dolores óseos
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="sintomasrecientessincausa"
                                value="16. Endurecimiento o hinchazón de una parte de las mamas"
                                checked={nuevoMiembro.sintomasrecientessincausa.includes('16. Endurecimiento o hinchazón de una parte de las mamas')}
                                onChange={handleInputChange}
                            />
                            16. Endurecimiento o hinchazón de una parte de las mamas
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="sintomasrecientessincausa"
                                value="17. Enrojecimiento o descamación en el pezón o las mamas"
                                checked={nuevoMiembro.sintomasrecientessincausa.includes('17. Enrojecimiento o descamación en el pezón o las mamas')}
                                onChange={handleInputChange}
                            />
                            17. Enrojecimiento o descamación en el pezón o las mamas
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="sintomasrecientessincausa"
                                value="18. Erupción macular de piel"
                                checked={nuevoMiembro.sintomasrecientessincausa.includes('18. Erupción macular de piel')}
                                onChange={handleInputChange}
                            />
                            18. Erupción macular de piel
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="sintomasrecientessincausa"
                                value="19. Esputos sanguinolentos"
                                checked={nuevoMiembro.sintomasrecientessincausa.includes('19. Esputos sanguinolentos')}
                                onChange={handleInputChange}
                            />
                            19. Esputos sanguinolentos
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="sintomasrecientessincausa"
                                value="20. Exacerbación de la tos previamente existente generalmente sin moco"
                                checked={nuevoMiembro.sintomasrecientessincausa.includes('20. Exacerbación de la tos previamente existente generalmente sin moco')}
                                onChange={handleInputChange}
                            />
                            20. Exacerbación de la tos previamente existente generalmente sin moco
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="sintomasrecientessincausa"
                                value="21. Sangre en la orina (M)"
                                checked={nuevoMiembro.sintomasrecientessincausa.includes('21. Sangre en la orina (M)')}
                                onChange={handleInputChange}
                            />
                            21. Sangre en la orina (M)
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="sintomasrecientessincausa"
                                value="22. Secreción vaginal inusual (F)"
                                checked={nuevoMiembro.sintomasrecientessincausa.includes('22. Secreción vaginal inusual (F)')}
                                onChange={handleInputChange}
                            />
                            22. Secreción vaginal inusual (F)
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="sintomasrecientessincausa"
                                value="23. Sed excesiva"
                                checked={nuevoMiembro.sintomasrecientessincausa.includes('23. Sed excesiva')}
                                onChange={handleInputChange}
                            />
                            23. Sed excesiva
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="sintomasrecientessincausa"
                                value="24. Sensación de que el intestino no se vacía por completo"
                                checked={nuevoMiembro.sintomasrecientessincausa.includes('24. Sensación de que el intestino no se vacía por completo')}
                                onChange={handleInputChange}
                            />
                            24. Sensación de que el intestino no se vacía por completo
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="sintomasrecientessincausa"
                                value="25. Sudoración"
                                checked={nuevoMiembro.sintomasrecientessincausa.includes('25. Sudoración')}
                                onChange={handleInputChange}
                            />
                            25. Sudoración
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="sintomasrecientessincausa"
                                value="26. Tos con expectoración mucosa mayor a 15 días"
                                checked={nuevoMiembro.sintomasrecientessincausa.includes('26. Tos con expectoración mucosa mayor a 15 días')}
                                onChange={handleInputChange}
                            />
                            26. Tos con expectoración mucosa mayor a 15 días
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="sintomasrecientessincausa"
                                value="27. Faringitis"
                                checked={nuevoMiembro.sintomasrecientessincausa.includes('27. Faringitis')}
                                onChange={handleInputChange}
                            />
                            27. Faringitis
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="sintomasrecientessincausa"
                                value="28. Fiebre persistente"
                                checked={nuevoMiembro.sintomasrecientessincausa.includes('28. Fiebre persistente')}
                                onChange={handleInputChange}
                            />
                            28. Fiebre persistente
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="sintomasrecientessincausa"
                                value="29. Fosfenos"
                                checked={nuevoMiembro.sintomasrecientessincausa.includes('29. Fosfenos')}
                                onChange={handleInputChange}
                            />
                            29. Fosfenos
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="sintomasrecientessincausa"
                                value="30. Gastritis crónica"
                                checked={nuevoMiembro.sintomasrecientessincausa.includes('30. Gastritis crónica')}
                                onChange={handleInputChange}
                            />
                            30. Gastritis crónica
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="sintomasrecientessincausa"
                                value="31. Hambre excesiva"
                                checked={nuevoMiembro.sintomasrecientessincausa.includes('31. Hambre excesiva')}
                                onChange={handleInputChange}
                            />
                            31. Hambre excesiva
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="sintomasrecientessincausa"
                                value="32. Herpes"
                                checked={nuevoMiembro.sintomasrecientessincausa.includes('32. Herpes')}
                                onChange={handleInputChange}
                            />
                            32. Herpes
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="sintomasrecientessincausa"
                                value="33. Hundimiento del pezón (F)"
                                checked={nuevoMiembro.sintomasrecientessincausa.includes('33. Hundimiento del pezón (F)')}
                                onChange={handleInputChange}
                            />
                            33. Hundimiento del pezón (F)
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="sintomasrecientessincausa"
                                value="34. Infección respiratoria recurrente que no cede (bronquitis-neumonía)"
                                checked={nuevoMiembro.sintomasrecientessincausa.includes('34. Infección respiratoria recurrente que no cede (bronquitis-neumonía)')}
                                onChange={handleInputChange}
                            />
                            34. Infección respiratoria recurrente que no cede (bronquitis-neumonía)
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="sintomasrecientessincausa"
                                value="35. Inflamación de los ganglios"
                                checked={nuevoMiembro.sintomasrecientessincausa.includes('35. Inflamación de los ganglios')}
                                onChange={handleInputChange}
                            />
                            35. Inflamación de los ganglios
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="sintomasrecientessincausa"
                                value="36. Mialgias"
                                checked={nuevoMiembro.sintomasrecientessincausa.includes('36. Mialgias')}
                                onChange={handleInputChange}
                            />
                            36. Mialgias
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="sintomasrecientessincausa"
                                value="37. Orina con frecuencia"
                                checked={nuevoMiembro.sintomasrecientessincausa.includes('37. Orina con frecuencia')}
                                onChange={handleInputChange}
                            />
                            37. Orina con frecuencia
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="sintomasrecientessincausa"
                                value="38. Pérdida de apetito"
                                checked={nuevoMiembro.sintomasrecientessincausa.includes('38. Pérdida de apetito')}
                                onChange={handleInputChange}
                            />
                            38. Pérdida de apetito
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="sintomasrecientessincausa"
                                value="39. Perdida de la sensibilidad en los pies"
                                checked={nuevoMiembro.sintomasrecientessincausa.includes('39. Perdida de la sensibilidad en los pies')}
                                onChange={handleInputChange}
                            />
                            39. Perdida de la sensibilidad en los pies
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="sintomasrecientessincausa"
                                value="40. Pérdida de peso"
                                checked={nuevoMiembro.sintomasrecientessincausa.includes('40. Pérdida de peso')}
                                onChange={handleInputChange}
                            />
                            40. Pérdida de peso
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="sintomasrecientessincausa"
                                value="41. Presencia de masas o bultos en los senos o en las axilas"
                                checked={nuevoMiembro.sintomasrecientessincausa.includes('41. Presencia de masas o bultos en los senos o en las axilas')}
                                onChange={handleInputChange}
                            />
                            41. Presencia de masas o bultos en los senos o en las axilas
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="sintomasrecientessincausa"
                                value="42. Producción de esputo"
                                checked={nuevoMiembro.sintomasrecientessincausa.includes('42. Producción de esputo')}
                                onChange={handleInputChange}
                            />
                            42. Producción de esputo
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="sintomasrecientessincausa"
                                value="43. Salida de secreción por el pezón (F)"
                                checked={nuevoMiembro.sintomasrecientessincausa.includes('43. Salida de secreción por el pezón (F)')}
                                onChange={handleInputChange}
                            />
                            43. Salida de secreción por el pezón (F)
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="sintomasrecientessincausa"
                                value="44. Sangrado vaginal anormal (F)"
                                checked={nuevoMiembro.sintomasrecientessincausa.includes('44. Sangrado vaginal anormal (F)')}
                                onChange={handleInputChange}
                            />
                            44. Sangrado vaginal anormal (F)
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="sintomasrecientessincausa"
                                value="45. Sangrados en mucosas y/o pie"
                                checked={nuevoMiembro.sintomasrecientessincausa.includes('45. Sangrados en mucosas y/o pie')}
                                onChange={handleInputChange}
                            />
                            45. Sangrados en mucosas y/o pie
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="sintomasrecientessincausa"
                                value="46. Sangre en el semen (M)"
                                checked={nuevoMiembro.sintomasrecientessincausa.includes('46. Sangre en el semen (M)')}
                                onChange={handleInputChange}
                            />
                            46. Sangre en el semen (M)
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="sintomasrecientessincausa"
                                value="47. Sangre en la materia fecal"
                                checked={nuevoMiembro.sintomasrecientessincausa.includes('47. Sangre en la materia fecal')}
                                onChange={handleInputChange}
                            />
                            47. Sangre en la materia fecal
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="sintomasrecientessincausa"
                                value="48. Tos crónica"
                                checked={nuevoMiembro.sintomasrecientessincausa.includes('48. Tos crónica')}
                                onChange={handleInputChange}
                            />
                            48. Tos crónica
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="sintomasrecientessincausa"
                                value="49. Tos persistente mayor a 21 días (0 a 5 a)"
                                checked={nuevoMiembro.sintomasrecientessincausa.includes('49. Tos persistente mayor a 21 días (0 a 5 a)')}
                                onChange={handleInputChange}
                            />
                            49. Tos persistente mayor a 21 días (0 a 5 a)
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="sintomasrecientessincausa"
                                value="50. Tinnitus"
                                checked={nuevoMiembro.sintomasrecientessincausa.includes('50. Tinnitus')}
                                onChange={handleInputChange}
                            />
                            50. Tinnitus
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="sintomasrecientessincausa"
                                value="51. Hemorragia conjuntival"
                                checked={nuevoMiembro.sintomasrecientessincausa.includes('51. Hemorragia conjuntival')}
                                onChange={handleInputChange}
                            />
                            51. Hemorragia conjuntival
                            </label>
                        </div>
                        <div class="form-check">
                            <label class="form-check-label">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                name="sintomasrecientessincausa"
                                value="52. Ninguno de los anteriores"
                                checked={nuevoMiembro.sintomasrecientessincausa.includes('52. Ninguno de los anteriores')}
                                onChange={handleInputChange}
                            />
                            52. Ninguno de los anteriores
                            </label>
                        </div>
                        {/* {!nuevoMiembro.sintomasrecientessincausa.length && <div className="text-danger">Campo obligatorio</div>} */}
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <div class="mb-4"><h4 class="text-center"> 4.	PRACTICAS DE SALUD DE SALUD ORAL</h4>
                <div class="row">
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">Cepillado diario mínimo 3 veces al día</label>
                        <select className={`form-select`} name='cepilladodiariominimovec' value={nuevoMiembro.cepilladodiariominimovec} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="SI">SI</option>
                        <option value="NO">NO</option>
                        <option value="SD">SD</option>
                        </select>
                        {/* {!nuevoMiembro.cepilladodiariominimovec && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">¿Utiliza otro elemento para el higiene de salud oral?</label>
                        <select className={`form-select`} name='utilizaotroelementoparael' value={nuevoMiembro.utilizaotroelementoparael} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="SI">SI</option>
                        <option value="NO">NO</option>
                        <option value="SD">SD</option>
                        </select>
                        {/* {!nuevoMiembro.utilizaotroelementoparael && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">¿la última vez que visitó el odontólogo?</label>
                        <select className={`form-select`} name='laultimavezquevisitoelodo' value={nuevoMiembro.laultimavezquevisitoelodo} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Menos de 6 meses">Menos de 6 meses</option>
                        <option value="Entre 6 y 12 meses">Entre 6 y 12 meses</option>
                        <option value="Entre 1 y 2 años">Entre 1 y 2 años</option>
                        <option value="Más de 2 años">Más de 2 años</option>
                        <option value="Nunca lo han visitado">Nunca lo han visitado</option>
                        </select>
                        {/* {!nuevoMiembro.laultimavezquevisitoelodo && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                    </div>
                </div>
                </div>
                <div class="mb-4"><h4 class="text-center"> 5.	SEXUALIDAD, DERECHOS SEXUALES Y REPRODUCTIVOS</h4>
                <div class="row">
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">Fecha de la última menstruación</label>
                        <input
                        type="date"
                        name='fechadelaultimamenstruaci'
                        className={`form-control`}
                        value={nuevoMiembro.fechadelaultimamenstruaci ? nuevoMiembro.fechadelaultimamenstruaci.split('T')[0] : ''}
                        onChange={handleInputChange}
                        />
                    </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">Su última menstruación fue después de los 50 años (F)</label>
                        <select className={`form-select`} name='suultimamenstruacionfuede' value={nuevoMiembro.suultimamenstruacionfuede} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="SI">SI</option>
                        <option value="NO">NO</option>
                        <option value="SD">SD</option>
                        <option value="NA">NA</option>
                        </select>
                        {/* {!nuevoMiembro.suultimamenstruacionfuede && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">Actualmente tiene relaciones sexuales (SM)</label>
                        <select className={`form-select`} name='actualmentetienerelacione' value={nuevoMiembro.actualmentetienerelacione} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="SI">SI</option>
                        <option value="NO">NO</option>
                        <option value="SD">SD</option>
                        <option value="NA">NA</option>
                        </select>
                        {/* {!nuevoMiembro.actualmentetienerelacione && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">Práctica sexual riesgosa (SM) a partir 12 a</label>
                        <select className={`form-select`} name='practicasexualriesgosasma' value={nuevoMiembro.practicasexualriesgosasma} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Trabajo sexual">Trabajo sexual</option>
                        <option value="Relaciones sexuales hombre con hombre (M)">Relaciones sexuales hombre con hombre (M)</option>
                        <option value="Relaciones sexuales sin condón">Relaciones sexuales sin condón</option>
                        <option value="Ninguna">Ninguna</option>
                        <option value="NA">NA</option>
                        </select>
                        {/* {!nuevoMiembro.practicasexualriesgosasma && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">PQue método de planificación utiliza como pareja (SM) de 12 a 49 a</label>
                        <select className={`form-select`} name='quemetododeplanificacionu' value={nuevoMiembro.quemetododeplanificacionu} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Barrera">Barrera</option>
                        <option value="Definitivo">Definitivo</option>
                        <option value="Hormonal">Hormonal</option>
                        <option value="Mecánico">Mecánico</option>
                        <option value="Natural">Natural</option>
                        <option value="Ninguna">Ninguna</option>
                        <option value="NA">NA</option>
                        </select>
                        {/* {!nuevoMiembro.quemetododeplanificacionu && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">Es constante con el método de planificación (12 a 49 a)</label>
                        <select className={`form-select`} name='esconstanteconelmetododep' value={nuevoMiembro.esconstanteconelmetododep} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="SI">SI</option>
                        <option value="NO">NO</option>
                        <option value="SD">SD</option>
                        <option value="NA">NA</option>
                        </select>
                        {/* {!nuevoMiembro.esconstanteconelmetododep && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">Ha utilizado por más de 10 años anticonceptivos orales (F) (a partir 22 a)</label>
                        <select className={`form-select`} name='hautilizadopormasdediezan' value={nuevoMiembro.hautilizadopormasdediezan} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="SI">SI</option>
                        <option value="NO">NO</option>
                        <option value="SD">SD</option>
                        <option value="NA">NA</option>
                        </select>
                        {/* {!nuevoMiembro.hautilizadopormasdediezan && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">¿Quién facilita el método el acceso al método planificación?</label>
                        <select className={`form-select`} name='quienfacilitaelmetodoelac' value={nuevoMiembro.quienfacilitaelmetodoelac} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="IPS">IPS</option>
                        <option value="EPS">EPS</option>
                        <option value="Adquirido por cuenta propia">Adquirido por cuenta propia</option>
                        <option value="NA">NA</option>
                        </select>
                        {/* {!nuevoMiembro.quienfacilitaelmetodoelac && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                    </div>
                </div>
                </div>
                <div class="mb-4"><h4 class="text-center"> 5.	SEXUALIDAD, DERECHOS SEXUALES Y REPRODUCTIVOS (MATERNIDAD)</h4>
                <div class="row">
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">N° Partos</label>
                        <input
                        type="number"
                        name='npartos'
                        className={`form-control`}
                        value={nuevoMiembro.npartos}
                        onChange={handleInputChange}
                        />
                    </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">N° cesáreas</label>
                        <input
                        type="number"
                        name='ncesareas'
                        className={`form-control`}
                        value={nuevoMiembro.ncesareas}
                        onChange={handleInputChange}
                        />
                    </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">N° abortos</label>
                        <input
                        type="number"
                        name='nabortos'
                        className={`form-control`}
                        value={nuevoMiembro.nabortos}
                        onChange={handleInputChange}
                        />
                    </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">N° nacidos vivos</label>
                        <input
                        type="number"
                        name='nnacidosvivos'
                        className={`form-control`}
                        value={nuevoMiembro.nnacidosvivos}
                        onChange={handleInputChange}
                        />
                    </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">N° mortinatos</label>
                        <input
                        type="number"
                        name='nmortinatos'
                        className={`form-control`}
                        value={nuevoMiembro.nmortinatos}
                        onChange={handleInputChange}
                        />
                    </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">Edad al momento de nacer el primer hijo</label>
                        <select className={`form-select`} name='edadalmomentodenacerelpri' value={nuevoMiembro.edadalmomentodenacerelpri} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Menores de 14 años">Menores de 14 años</option>
                        <option value="Entre 14 y 17 años">Entre 14 y 17 años</option>
                        <option value="Entre  20 y 29 años">Entre  20 y 29 años</option>
                        <option value="Entre  30 y 39 años">Entre  30 y 39 años</option>
                        <option value="Mayor de 40 años">Mayor de 40 años</option>
                        <option value="NA">NA</option>
                        </select>
                        {/* {!nuevoMiembro.edadalmomentodenacerelpri && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">En sus embarazos consumió alcohol o cigarrillo de forma frecuente</label>
                        <select className={`form-select`} name='ensusembarazosconsumioalc' value={nuevoMiembro.ensusembarazosconsumioalc} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="SI">SI</option>
                        <option value="NO">NO</option>
                        <option value="NA">NA</option>
                        </select>
                        {/* {!nuevoMiembro.ensusembarazosconsumioalc && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">Embarazo planeado</label>
                        <select className={`form-select`} name='embarazoplaneado' value={nuevoMiembro.embarazoplaneado} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="SI">SI</option>
                        <option value="NO">NO</option>
                        <option value="NA">NA</option>
                        </select>
                        {/* {!nuevoMiembro.embarazoplaneado && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">Clasificación del riesgo obstétrico</label>
                        <select className={`form-select`} name='clasificaciondelriesgoobs' value={nuevoMiembro.clasificaciondelriesgoobs} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Biologico ">Biologico </option>
                        <option value="Psicologico">Psicologico</option>
                        <option value="Sociocultutral ">Sociocultutral </option>
                        </select>
                        {/* {!nuevoMiembro.clasificaciondelriesgoobs && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                    </div>
                </div>
                </div>
                <div class="mb-4"><h4 class="text-center"> 6.	RECIEN NACIDO</h4>
                <div class="row">
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">¿Sabe el Motivo del riesgo?</label>
                        <input
                        type="text"
                        name='sabeelmotivodelriesgo'
                        className={`form-control`}
                        value={nuevoMiembro.sabeelmotivodelriesgo}
                        onChange={handleInputChange}
                        />
                    </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">Alimenta con leche materna exclusiva? (hasta 6 m)</label>
                        <select className={`form-select`} name='alimentaconlechematernaex' value={nuevoMiembro.alimentaconlechematernaex} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="SI ">SI </option>
                        <option value="NO">NO</option>
                        <option value="SD">SD</option>
                        </select>
                        {/* {!nuevoMiembro.alimentaconlechematernaex && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">Alimentación complementaria (hasta 2 años)</label>
                        <select className={`form-select`} name='alimentacioncomplementari' value={nuevoMiembro.alimentacioncomplementari} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="SI ">SI </option>
                        <option value="NO">NO</option>
                        <option value="SD">SD</option>
                        </select>
                        {/* {!nuevoMiembro.alimentacioncomplementari && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div> 
                    </div>
                </div> 
                </div>
                <div class="mb-4"><h4 class="text-center"> 7.	VIDA SALUDABLE Y CONDICIONES NO TRANSMISIBLES</h4>
                <div class="row">
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">¿Última vez que asistió a citología y/o VPH? Mujeres 25 - 29 años - citología) -Mujeres persona de 30 años-VPH (resultado negativo con frecuencia 5 años)</label>
                        <select className={`form-select`} name='ultimavezqueasistioacitol' value={nuevoMiembro.ultimavezqueasistioacitol} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Menor a 3 años">Menor a 3 años</option>
                        <option value="Más de 3 años">Más de 3 años</option>
                        <option value="Nunca">Nunca</option>
                        <option value="NA">NA</option>
                        </select>
                        {/* {!nuevoMiembro.ultimavezqueasistioacitol && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">¿Última vez le realizaron prueba de Antígeno prostático y/o tacto rectal? (persona de 50 años)</label>
                        <select className={`form-select`} name='ultimavezlerealizaronprue' value={nuevoMiembro.ultimavezlerealizaronprue} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Menor a 5 años">Menor a 5 años</option>
                        <option value="Más de 5 años">Más de 5 años</option>
                        <option value="Nunca">Nunca</option>
                        <option value="NA">NA</option>
                        </select>
                        {/* {!nuevoMiembro.ultimavezlerealizaronprue && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">¿última vez que le realizaron examen clínico de mama? (menores 50 años)</label>
                        <select className={`form-select`} name='ultimavezquelerealizarone' value={nuevoMiembro.ultimavezquelerealizarone} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Menor a 1 año">Menor a 1 año</option>
                        <option value="Más de 1 año">Más de 1 año</option>
                        <option value="Nunca">Nunca</option>
                        <option value="NA">NA</option>
                        </select>
                        {/* {!nuevoMiembro.ultimavezquelerealizarone && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">¿última vez que le realizaron mamografía (persona de 50 años)</label>
                        <select className={`form-select`} name='ultimavezquelerealizaronm' value={nuevoMiembro.ultimavezquelerealizaronm} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Menor a 2 años">Menor a 2 años</option>
                        <option value="Más de 2 años">Más de 2 años</option>
                        <option value="Nunca">Nunca</option>
                        <option value="NA">NA</option>
                        </select>
                        {/* {!nuevoMiembro.ultimavezquelerealizaronm && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">Práctica de actividad física en minutos</label>
                        <select className={`form-select`} name='practicadeactividadfisica' value={nuevoMiembro.practicadeactividadfisica} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Mayor a 60 min diarios">Mayor a 60 min diarios</option>
                        <option value="Menor a 60 min diarios">Menor a 60 min diarios</option>
                        <option value="Mayor a 30 min diarios">Mayor a 30 min diarios</option>
                        <option value="Menor a 30 min diarios">Menor a 30 min diarios</option>
                        <option value="No Practica Ninguna actividad fisica">No Practica Ninguna actividad fisica</option>
                        </select>
                        {/* {!nuevoMiembro.practicadeactividadfisica && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">Hábitos de alimentación</label>
                        <select className={`form-select`} name='habitosdealimentacion' value={nuevoMiembro.habitosdealimentacion} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Alto consumo de sal">Alto consumo de sal</option>
                        <option value="No consume mínimo 2 frutas diarias">No consume mínimo 2 frutas diarias</option>
                        <option value="No consume mínimo 2 verduras diarias">No consume mínimo 2 verduras diarias</option>
                        <option value="Consumo alto de grasas">Consumo alto de grasas</option>
                        <option value="Alto consumo de azúcar">Alto consumo de azúcar</option>
                        <option value="Alto consumo de empaquetados, salsas, dulces, jugos en caja, embutidos">Alto consumo de empaquetados, salsas, dulces, jugos en caja, embutidos</option>
                        <option value="Adecuados">Adecuados</option>
                        <option value="NA">NA</option>
                        </select>
                        {/* {!nuevoMiembro.habitosdealimentacion && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">Exposición frecuente a humo (SM)</label>
                        <select className={`form-select`} name='exposicionfrecuenteahumos' value={nuevoMiembro.exposicionfrecuenteahumos} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Cigarrillo">Cigarrillo</option>
                        <option value="Leña">Leña</option>
                        <option value="carbón">carbón</option>
                        <option value="NA">NA</option>
                        </select>
                        {/* {!nuevoMiembro.exposicionfrecuenteahumos && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">¿Se desparasitado en los ultimos 6 meses? *A partir de los 6 meses de edad.</label>
                        <select className={`form-select`} name='sedesparasitadoenlosultim' value={nuevoMiembro.sedesparasitadoenlosultim} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="SI">SI</option>
                        <option value="NO">NO</option>
                        <option value="NA">NA</option>
                        </select>
                        {/* {!nuevoMiembro.sedesparasitadoenlosultim && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">¿última vez que le practicaron tamizaje para cáncer colorrectal? (prueba de sangre oculta)</label>
                        <select className={`form-select`} name='ultimavezquelepracticaron' value={nuevoMiembro.ultimavezquelepracticaron} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="hace dos años">hace dos años</option>
                        <option value="Más de 3 años">Más de 3 años</option>
                        <option value="Nunca">Nunca</option>
                        </select>
                        {/* {!nuevoMiembro.ultimavezquelepracticaron && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">Peso (Kg)</label>
                        <input
                        type="number"
                        name='pesokg'
                        className={`form-control`}
                        value={nuevoMiembro.pesokg}
                        onChange={handleInputChange}
                        />
                    </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">Talla (Cm)</label>
                        <input
                        type="number"
                        name='tallacm'
                        className={`form-control`}
                        value={nuevoMiembro.tallacm}
                        onChange={handleInputChange}
                        />
                    </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">Clasificación Antrometrica</label>
                        <input
                        type="text"
                        name='clasificacionantrometrica'
                        className={`form-control`}
                        value={nuevoMiembro.clasificacionantrometrica}
                        onChange={handleInputChange}
                        />
                    </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">Circunferencia de la Cintura (cm)</label>
                        <input
                        type="number"
                        name='circunferenciadelacintura'
                        className={`form-control`}
                        value={nuevoMiembro.circunferenciadelacintura}
                        onChange={handleInputChange}
                        />
                    </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">Perimetro del brazo (Niños y Niñas de 6 a 59 meses)</label>
                        <input
                        type="number"
                        name='perimetrodelbrazoninosyni'
                        className={`form-control`}
                        value={nuevoMiembro.perimetrodelbrazoninosyni}
                        onChange={handleInputChange}
                        />
                    </div>
                    </div>
                </div> 
                </div>    
                <div class="mb-4"><h4 class="text-center"> 8.	SALUD MENTAL</h4>
                <div class="row">
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">¿Actualmente existe alguna crisis familiar o personal en la cual usted no se siente en las capacidades para enfrentar</label>
                        <select className={`form-select`} name='actualmenteexistealgunacr' value={nuevoMiembro.actualmenteexistealgunacr} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="SI">SI</option>
                        <option value="NO">NO</option>
                        <option value="NA">NA</option>
                        </select>
                        {/* {!nuevoMiembro.actualmenteexistealgunacr && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">¿Se despierta varias horas antes de lo habitual y no puede volver a dormir?</label>
                        <select className={`form-select`} name='sedespiertavariashorasant' value={nuevoMiembro.sedespiertavariashorasant} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="SI">SI</option>
                        <option value="NO">NO</option>
                        <option value="NA">NA</option>
                        </select>
                        {/* {!nuevoMiembro.sedespiertavariashorasant && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">¿A veces siente en que no tiene sentido vivir?</label>
                        <select className={`form-select`} name='avecessienteenquenotienes' value={nuevoMiembro.avecessienteenquenotienes} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="SI">SI</option>
                        <option value="NO">NO</option>
                        <option value="NA">NA</option>
                        </select>
                        {/* {!nuevoMiembro.avecessienteenquenotienes && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">¿considera que la muerte puede ser  la solución al /los problema(s) que usted está presentando?</label>
                        <select className={`form-select`} name='consideraquelamuertepuede' value={nuevoMiembro.consideraquelamuertepuede} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="SI">SI</option>
                        <option value="NO">NO</option>
                        <option value="NA">NA</option>
                        </select>
                        {/* {!nuevoMiembro.consideraquelamuertepuede && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">¿Estar cargo del cuidado de alguna persona con dependencia funcional (discapacidad – vejez) le genera malestar psicológico o físico ?</label>
                        <select className={`form-select`} name='estarcargodelcuidadodealg' value={nuevoMiembro.estarcargodelcuidadodealg} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="SI">SI</option>
                        <option value="NO">NO</option>
                        <option value="NA">NA</option>
                        </select>
                        {/* {!nuevoMiembro.estarcargodelcuidadodealg && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">¿experimenta una perdida/muerte que sobrepasa su capacidad de afrontamiento? </label>
                        <select className={`form-select`} name='experimentaunaperdidamuer' value={nuevoMiembro.experimentaunaperdidamuer} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="SI">SI</option>
                        <option value="NO">NO</option>
                        <option value="NA">NA</option>
                        </select>
                        {/* {!nuevoMiembro.experimentaunaperdidamuer && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">¿Considera que los comportamientos que su familia emplea para solucionar los problemas son inadecuados, causan sufrimiento? </label>
                        <select className={`form-select`} name='consideraqueloscomportami' value={nuevoMiembro.consideraqueloscomportami} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="SI">SI</option>
                        <option value="NO">NO</option>
                        <option value="NA">NA</option>
                        </select>
                        {/* {!nuevoMiembro.consideraqueloscomportami && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">¿Considera anormales  sus hábitos alimenticios o los de algún integrante de su hogar’</label>
                        <select className={`form-select`} name='consideraanormalessushabi' value={nuevoMiembro.consideraanormalessushabi} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="SI">SI</option>
                        <option value="NO">NO</option>
                        <option value="NA">NA</option>
                        </select>
                        {/* {!nuevoMiembro.consideraanormalessushabi && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">¿Identifica en su hogar comportamientos que considere extraños, que afectan la cotidianidad?</label>
                        <select className={`form-select`} name='identificaensuhogarcompor' value={nuevoMiembro.identificaensuhogarcompor} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="SI">SI</option>
                        <option value="NO">NO</option>
                        <option value="NA">NA</option>
                        </select>
                        {/* {!nuevoMiembro.identificaensuhogarcompor && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                        <label class="form-label">Consumo de Sustancias Psicoactiva</label>
                        <select className={`form-select`} name='consumodesustanciaspsicoa' value={nuevoMiembro.consumodesustanciaspsicoa} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Alcohol (<9 a 17 a)">Alcohol (Menor 9 a 17 a)</option>
                        <option value="Abuso de alcohol (a partir 18 a)">Abuso de alcohol (a partir 18 a)</option>
                        <option value="Cigarrillo">Cigarrillo</option>
                        <option value="Otras SPA">Otras SPA</option>
                        <option value="Riesgo o uso de SPA por vía inyectable">Riesgo o uso de SPA por vía inyectable</option>
                        <option value="Ninguna">Ninguna</option>
                        <option value="NA">NA</option>
                        </select>
                        {/* {!nuevoMiembro.consumodesustanciaspsicoa && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                    </div>
                </div> 
                </div>
                <div class="mb-5"><h4 class="text-center">SEGUIMIENTO VACUNACIÓN</h4>
                <div class="mb-5">
                    <h5 class="text">Niños menores de 6 años</h5>
                    <div class="accordion" id={`accordionSixYeards-1`}>
                    {/* Mostrar los grupos de campos agregados */}
                    <div class="accordion-item border border-primary">
                        <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapsesix-1`} aria-expanded="true" aria-controls={`collapsesix-1`}>
                            Desplegar para diligenciar datos
                        </button>
                        </h2>
                        <div id={`collapsesix-1`} class="accordion-collapse collapse" data-bs-parent={`#accordionSixYeard-1`}>
                        <div class="accordion-body">
                            {/* Agregar aquí los campos específicos de este grupo */}
                            <div class="mb-4"><h5 class="text-center">Por favor al diligenciar registre la fecha  de aplicación de los biológicos trazadores de los niños y niñas menores de 6 años</h5>
                            <div class="row">
                                <div class="col-12 col-md-6 col-lg-4">
                                <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">DOSIS RN (BCG)</label>
                                    <input
                                    type="date"
                                    name='seisdosisrnbcg'
                                    className={`form-control`}
                                    value={nuevoMiembro.seisdosisrnbcg ? nuevoMiembro.seisdosisrnbcg.split('T')[0] : ''}
                                    onChange={handleInputChange}
                                    />
                                </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">3RAS DOSIS (PENTA)</label>
                                    <input
                                    type="date"
                                    name='seistercerasdosispenta'
                                    className={`form-control`}
                                    value={nuevoMiembro.seistercerasdosispenta ? nuevoMiembro.seistercerasdosispenta.split('T')[0] : ''}
                                    onChange={handleInputChange}
                                    />
                                </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">DOSIS AÑO (Triple Viral)</label>
                                    <input
                                    type="date"
                                    name='seisdosisanotripleviral'
                                    className={`form-control`}
                                    value={nuevoMiembro.seisdosisanotripleviral ? nuevoMiembro.seisdosisanotripleviral.split('T')[0] : ''}
                                    onChange={handleInputChange}
                                    />
                                </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">18 MESES (DPT Ó PENTA)</label>
                                    <input
                                    type="date"
                                    name='seisdiesiochomesesdptopenta'
                                    className={`form-control`}
                                    value={nuevoMiembro.seisdiesiochomesesdptopenta ? nuevoMiembro.seisdiesiochomesesdptopenta.split('T')[0] : ''}
                                    onChange={handleInputChange}
                                    />
                                </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">DOSIS 5 AÑOS (2 RF DPT)</label>
                                    <input
                                    type="date"
                                    name='seisdosiscincoaniossegundorfdpt'
                                    className={`form-control`}
                                    value={nuevoMiembro.seisdosiscincoaniossegundorfdpt ? nuevoMiembro.seisdosiscincoaniossegundorfdpt.split('T')[0] : ''}
                                    onChange={handleInputChange}
                                    />
                                </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">DOSIS DE INFLUENZA ANUAL</label>
                                    <input
                                    type="date"
                                    name='seisdosisdeinfluenzaanual'
                                    className={`form-control`}
                                    value={nuevoMiembro.seisdosisdeinfluenzaanual ? nuevoMiembro.seisdosisdeinfluenzaanual.split('T')[0] : ''}
                                    onChange={handleInputChange}
                                    />
                                </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">ESQUEMA COMPLETO (1.SI - 2.NO)</label>
                                    <select className={`form-select`} name='seisesquemacompleto' value={nuevoMiembro.seisesquemacompleto} onChange={handleInputChange}>
                                    <option selected></option>
                                    <option value="SI">SI</option>
                                    <option value="NO">NO</option>
                                    </select>
                                </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">1.VACUNADO / 2.CANALIZADO</label>
                                    <select className={`form-select`} name='seisvacunadocanalizado' value={nuevoMiembro.seisvacunadocanalizado} onChange={handleInputChange}>
                                    <option selected></option>
                                    <option value="VACUNADO">VACUNADO</option>
                                    <option value="CANALIZADO">CANALIZADO</option>
                                    </select>
                                </div>
                                </div>
                            </div> 
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="mb-5">
                    <h5 class="text">Dosis adicional de SR niños de 2 A 11 años</h5>
                    <div class="accordion" id="accordionTwoEleven"> 
                        <div class="accordion-item border border-primary">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapsetwoeleven-1`} aria-expanded="true" aria-controls={`collapsetwoeleven-1`}>
                            Desplegar para diligenciar datos
                            </button>
                        </h2>
                        <div id={`collapsetwoeleven-1`} class="accordion-collapse collapse" data-bs-parent="#accordionTwoEleven">
                            <div class="accordion-body">
                            {/* Agregar aquí los campos específicos de este grupo */}
                            <div class="mb-4"><h5 class="text-center">Por favor al diligenciar registre la fecha  de aplicación de la dosis adicional de SR en niños de a 2 a 11 años</h5>
                                <div class="row">
                                <div class="col-12 col-md-6 col-lg-4">
                                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">DOSIS ADICIONAL SR</label>
                                    <input
                                        type="date"
                                        name='dosdosisadicionalsr'
                                        className={`form-control`}
                                        value={nuevoMiembro.dosdosisadicionalsr ? nuevoMiembro.dosdosisadicionalsr.split('T')[0] : ''}
                                        onChange={handleInputChange}
                                    />
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">ESQUEMA COMPLETO (1.SI - 2.NO)</label>
                                    <select className={`form-select`} name='dosesquemacompleto' value={nuevoMiembro.dosesquemacompleto} onChange={handleInputChange}>
                                        <option selected></option>
                                        <option value="SI">SI</option>
                                        <option value="NO">NO</option>
                                    </select>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">1.VACUNADO / 2.CANALIZADO</label>
                                    <select className={`form-select`} name='dosvacunadocanalizado' value={nuevoMiembro.dosvacunadocanalizado} onChange={handleInputChange}>
                                        <option selected></option>
                                        <option value="VACUNADO">VACUNADO</option>
                                        <option value="CANALIZADO">CANALIZADO</option>
                                    </select>
                                    </div>
                                </div>
                                </div> 
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div class="mb-5">
                    <h5 class="text">COVID-19 persona de 3 años</h5>
                    <div class="accordion" id="accordionCovidTres">
                        <div class="accordion-item border border-primary">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapsecovidtres-1`} aria-expanded="true" aria-controls={`collapsecovidtres-1`}>
                            Desplegar para diligenciar datos
                            </button>
                        </h2>
                        <div id={`collapsecovidtres-1`} class="accordion-collapse collapse" data-bs-parent="#accordionCovidTres">
                            <div class="accordion-body">
                            {/* Agregar aquí los campos específicos de este grupo */}
                            <div class="mb-4"><h5 class="text-center">Por favor al diligenciar registre la fecha  de aplicación de las dosis de vacuna contra el COVID-19</h5>
                                <div class="row">
                                <div class="col-12 col-md-6 col-lg-4">
                                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">1 DOSIS</label>
                                    <input
                                        type="date"
                                        name='covidprimeradosis'
                                        className={`form-control`}
                                        value={nuevoMiembro.covidprimeradosis ? nuevoMiembro.covidprimeradosis.split('T')[0] : ''}
                                        onChange={handleInputChange}
                                    />
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">DOSUS ÚNICA</label>
                                    <input
                                        type="date"
                                        name='coviddosusunica'
                                        className={`form-control`}
                                        value={nuevoMiembro.coviddosusunica ? nuevoMiembro.coviddosusunica.split('T')[0] : ''}
                                        onChange={handleInputChange}
                                    />
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">SEGUNDA DODIS</label>
                                    <input
                                        type="date"
                                        name='covidsegundadodis'
                                        className={`form-control`}
                                        value={nuevoMiembro.covidsegundadodis ? nuevoMiembro.covidsegundadodis.split('T')[0] : ''}
                                        onChange={handleInputChange}
                                    />
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">PRIMER REFUERZO</label>
                                    <input
                                        type="date"
                                        name='covidprimerrefuerzo'
                                        className={`form-control`}
                                        value={nuevoMiembro.covidprimerrefuerzo ? nuevoMiembro.covidprimerrefuerzo.split('T')[0] : ''}
                                        onChange={handleInputChange}
                                    />
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">SEGUNDO REFUERZO</label>
                                    <input
                                        type="date"
                                        name='covidsegundorefuerzo'
                                        className={`form-control`}
                                        value={nuevoMiembro.covidsegundorefuerzo ? nuevoMiembro.covidsegundorefuerzo.split('T')[0] : ''}
                                        onChange={handleInputChange}
                                    />
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">ESQUEMA COMPLETO (1.SI - 2.NO)</label>
                                    <select className={`form-select`} name='covidesquemacompleto' value={nuevoMiembro.covidesquemacompleto} onChange={handleInputChange}>
                                        <option selected></option>
                                        <option value="SI">SI</option>
                                        <option value="NO">NO</option>
                                    </select>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">1.VACUNADO / 2.CANALIZADO</label>
                                    <select className={`form-select`} name='covidvacunadocanalizado' value={nuevoMiembro.covidvacunadocanalizado} onChange={handleInputChange}>
                                        <option selected></option>
                                        <option value="VACUNADO">VACUNADO</option>
                                        <option value="CANALIZADO">CANALIZADO</option>
                                    </select>
                                    </div>
                                </div>
                                </div> 
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div class="mb-5">
                    <h5 class="text">Mujeres entre 9 a 17 años (en persona de 18 años con la primera dosis se le garantiza la segunda dosis de VPH)</h5>
                    <div class="accordion" id="accordionMujeresNueve">
                        <div class="accordion-item border border-primary">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapsemujeresnueve-1`} aria-expanded="true" aria-controls={`collapsemujeresnueve-1`}>
                            Desplegar para diligenciar datos
                            </button>
                        </h2>
                        <div id={`collapsemujeresnueve-1`} class="accordion-collapse collapse" data-bs-parent="#accordionMujeresNueve">
                            <div class="accordion-body">
                            {/* Agregar aquí los campos específicos de este grupo */}
                            <div class="mb-4"><h5 class="text-center">Por favor al diligenciar registre el año de aplicación de las dosis, de acuerdo a los biológicos trazadores que aquí se indican. </h5>
                                <div class="row">
                                <div class="col-12 col-md-6 col-lg-4">
                                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">EPS</label>
                                    <select className={`form-select`} name='mujereseps' value={nuevoMiembro.mujereseps} onChange={handleInputChange}>
                                        <option selected></option>
                                        <option value="Asmet Salud">Asmet Salud</option>
                                        <option value="Nueva EPS">Nueva EPS</option>
                                        <option value="Sanitas EPS">Sanitas EPS</option>
                                        <option value="FAMAC">FAMAC</option>
                                        <option value="Sanidad Ejercito">Sanidad Ejercito</option>
                                        <option value="Sanidad Policia.">Sanidad Policia.</option>
                                        <option value="Otro">Otro</option>
                                    </select>
                                    {/* {!nuevoMiembro.eps && <div className="invalid-feedback">Campo obligatorio</div>} */}
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">1RA DOSIS</label>
                                    <input
                                        type="date"
                                        name='mujeresprimeraradosis'
                                        className={`form-control`}
                                        value={nuevoMiembro.mujeresprimeraradosis ? nuevoMiembro.mujeresprimeraradosis.split('T')[0] : ''}
                                        onChange={handleInputChange}
                                    />
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">2DA DOSIS</label>
                                    <input
                                        type="date"
                                        name='mujeressegundadadosis'
                                        className={`form-control`}
                                        value={nuevoMiembro.mujeressegundadadosis ? nuevoMiembro.mujeressegundadadosis.split('T')[0] : ''}
                                        onChange={handleInputChange}
                                    />
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">ESQUEMA COMPLETO (1.SI - 2.NO)</label>
                                    <select className={`form-select`} name='mujeresesquemacompleto' value={nuevoMiembro.mujeresesquemacompleto} onChange={handleInputChange}>
                                        <option selected></option>
                                        <option value="SI">SI</option>
                                        <option value="NO">NO</option>
                                    </select>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">1.VACUNADO / 2.CANALIZADO</label>
                                    <select className={`form-select`} name='mujeresvacunadocanalizado' value={nuevoMiembro.mujeresvacunadocanalizado} onChange={handleInputChange}>
                                        <option selected></option>
                                        <option value="VACUNADO">VACUNADO</option>
                                        <option value="CANALIZADO">CANALIZADO</option>
                                    </select>
                                    </div>
                                </div>
                                </div> 
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div class="mb-5">
                    <h5 class="text">Mujeres gestantes</h5>
                    <div class="accordion" id="accordionGestantes">
                        <div class="accordion-item border border-primary">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapsegestantes-1`} aria-expanded="true" aria-controls={`collapsegestantes-1`}>
                            Desplegar para diligenciar datos
                            </button>
                        </h2>
                        <div id={`collapsegestantes-1`} class="accordion-collapse collapse" data-bs-parent="#accordionGestantes">
                            <div class="accordion-body">
                            {/* Agregar aquí los campos específicos de este grupo */}
                            <div class="mb-4"><h5 class="text-center">Por favor al diligenciar registre el año de aplicación de las dosis, de acuerdo a los biológicos trazadores que aquí se indican.</h5>
                                <div class="row">
                                <div class="col-12 col-md-6 col-lg-4">
                                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">SEMANAS DE GESTACIÓN</label>
                                    <input
                                        type="number"
                                        name='gestsemanasdegestacion'
                                        className={`form-control`}
                                        value={nuevoMiembro.gestsemanasdegestacion}
                                        onChange={handleInputChange}
                                    />
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">CONTROL PRENATAL</label>
                                    <input
                                        type="date"
                                        name='gestcontrolprenatal'
                                        className={`form-control`}
                                        value={nuevoMiembro.gestcontrolprenatal ? nuevoMiembro.gestcontrolprenatal.split('T')[0] : ''}
                                        onChange={handleInputChange}
                                    />
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">Tdap Celular (sem 20)rural</label>
                                    <input
                                        type="date"
                                        name='gesttdapcelularsemveinte'
                                        className={`form-control`}
                                        value={nuevoMiembro.gesttdapcelularsemveinte ? nuevoMiembro.gesttdapcelularsemveinte.split('T')[0] : ''}
                                        onChange={handleInputChange}
                                    />
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">TOXOIDE TETÁNICO ( sem 14)</label>
                                    <input
                                        type="date"
                                        name='gesttoxoidetetanicosem'
                                        className={`form-control`}
                                        value={nuevoMiembro.gesttoxoidetetanicosem ? nuevoMiembro.gesttoxoidetetanicosem.split('T')[0] : ''}
                                        onChange={handleInputChange}
                                    />
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">INFLUENZA (sem 12)</label>
                                    <input
                                        type="date"
                                        name='gestinfluenzasemdoce'
                                        className={`form-control`}
                                        value={nuevoMiembro.gestinfluenzasemdoce ? nuevoMiembro.gestinfluenzasemdoce.split('T')[0] : ''}
                                        onChange={handleInputChange}
                                    />
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">COVID (sem12)</label>
                                    <input
                                        type="date"
                                        name='gestcovidsemdoce'
                                        className={`form-control`}
                                        value={nuevoMiembro.gestcovidsemdoce ? nuevoMiembro.gestcovidsemdoce.split('T')[0] : ''}
                                        onChange={handleInputChange}
                                    />
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">ESQUEMA COMPLETO (1.SI - 2.NO)</label>
                                    <select className={`form-select`} name='gestesquemacompleto' value={nuevoMiembro.gestesquemacompleto} onChange={handleInputChange}>
                                        <option selected></option>
                                        <option value="SI">SI</option>
                                        <option value="NO">NO</option>
                                    </select>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">1.VACUNADO / 2.CANALIZADO</label>
                                    <select className={`form-select`} name='gestvacunadocanalizado' value={nuevoMiembro.gestvacunadocanalizado} onChange={handleInputChange}>
                                        <option selected></option>
                                        <option value="VACUNADO">VACUNADO</option>
                                        <option value="CANALIZADO">CANALIZADO</option>
                                    </select>
                                    </div>
                                </div>
                                </div> 
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div class="mb-5">
                    <h5 class="text">Adultos persona y pacientes crónicos</h5>
                    <div class="accordion" id="accordionMayores">
                        <div class="accordion-item border border-primary">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapsemayores-1`} aria-expanded="true" aria-controls={`collapsemayores-1`}>
                            Desplegar para diligenciar datos
                            </button>
                        </h2>
                        <div id={`collapsemayores-1`} class="accordion-collapse collapse" data-bs-parent="#accordionMayores">
                            <div class="accordion-body">
                            {/* Agregar aquí los campos específicos de este grupo */}
                            <div class="mb-4"><h5 class="text-center">Por favor al diligenciar registre el año de aplicación de las dosis, de acuerdo a los biológicos trazadores que aquí se indican.</h5>
                                <div class="row">
                                <div class="col-12 col-md-6 col-lg-4">
                                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">DOSIS ANUAL DE INFLUENZA</label>
                                    <input
                                        type="date"
                                        name='mayordosisanualdeinfluenza'
                                        className={`form-control`}
                                        value={nuevoMiembro.mayordosisanualdeinfluenza ? nuevoMiembro.mayordosisanualdeinfluenza.split('T')[0] : ''}
                                        onChange={handleInputChange}
                                    />
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">DOSIS COVID</label>
                                    <input
                                        type="date"
                                        name='mayordosiscovid'
                                        className={`form-control`}
                                        value={nuevoMiembro.mayordosiscovid ? nuevoMiembro.mayordosiscovid.split('T')[0] : ''}
                                        onChange={handleInputChange}
                                    />
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">ENFERMEDAD CRÓNICA</label>
                                    <input
                                        type="text"
                                        name='mayorenfermedadcronica'
                                        className={`form-control`}
                                        value={nuevoMiembro.mayorenfermedadcronica}
                                        onChange={handleInputChange}
                                    />
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">CONTROL ENFERMEDAD</label>
                                    <input
                                        type="text"
                                        name='mayorcontrolenfermedad'
                                        className={`form-control`}
                                        value={nuevoMiembro.mayorcontrolenfermedad}
                                        onChange={handleInputChange}
                                    />
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">ESQUEMA COMPLETO (1.SI - 2.NO)</label>
                                    <select className={`form-select`} name='mayoresquemacompleto' value={nuevoMiembro.mayoresquemacompleto} onChange={handleInputChange}>
                                        <option selected></option>
                                        <option value="SI">SI</option>
                                        <option value="NO">NO</option>
                                    </select>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">1.VACUNADO / 2.CANALIZADO</label>
                                    <select className={`form-select`} name='mayorvacunadocanalizado' value={nuevoMiembro.mayorvacunadocanalizado} onChange={handleInputChange}>
                                        <option selected></option>
                                        <option value="VACUNADO">VACUNADO</option>
                                        <option value="CANALIZADO">CANALIZADO</option>
                                    </select>
                                    </div>
                                </div>
                                </div> 
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div class="mb-5">
                    <h5 class="text">Fiebre amarilla persona DE 1 a 59 años</h5>
                    <div class="accordion" id="accordionAmarilla">
                        <div class="accordion-item border border-primary">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapseamarilla-1`} aria-expanded="true" aria-controls={`collapseamarilla-1`}>
                            Desplegar para diligenciar datos
                            </button>
                        </h2>
                        <div id={`collapseamarilla-1`} class="accordion-collapse collapse" data-bs-parent="#accordionAmarilla">
                            <div class="accordion-body">
                            {/* Agregar aquí los campos específicos de este grupo */}
                            <div class="mb-4"><h5 class="text-center">Por favor al diligenciar registre el año de aplicación de las dosis, de acuerdo a los biológicos trazadores que aquí se indican.</h5>
                                <div class="row">
                                <div class="col-12 col-md-6 col-lg-4">
                                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">DOSIS FA</label>
                                    <input
                                        type="date"
                                        name='amaridosisfa'
                                        className={`form-control`}
                                        value={nuevoMiembro.amaridosisfa ? nuevoMiembro.amaridosisfa.split('T')[0] : ''}
                                        onChange={handleInputChange}
                                    />
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">ESQUEMA COMPLETO (1.SI - 2.NO)</label>
                                    <select className={`form-select`} name='amariesquemacompleto' value={nuevoMiembro.amariesquemacompleto} onChange={handleInputChange}>
                                        <option selected></option>
                                        <option value="SI">SI</option>
                                        <option value="NO">NO</option>
                                    </select>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">1.VACUNADO / 2.CANALIZADO</label>
                                    <select className={`form-select`} name='amarivacunadocanalizado' value={nuevoMiembro.amarivacunadocanalizado} onChange={handleInputChange}>
                                        <option selected></option>
                                        <option value="VACUNADO">VACUNADO</option>
                                        <option value="CANALIZADO">CANALIZADO</option>
                                    </select>
                                    </div>                                        
                                </div>
                                </div> 
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                </div>

            </form>
            <button type="submit" className="btn btn-primary" onClick={handleGuardar}>Guardar</button>
        </div>
        ) : (
          <div>
            <h4>Adicionar Miembro</h4>
            <button type="submit" className="btn btn-primary" onClick={() => setNuevo(true)}>Agregar</button>
          </div>
        )
      ) : (
        <p>Miembro agregado</p>
      )}
    </div>
    );
}

export default CrearPersona;