import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate, Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Aps from './componentes/Aps';
import Caracterizacion from './componentes/Caracterizacion';
import Pif from './componentes/Pif';
import Fichas from './componentes/Fichas';
// import Apellido from './componentes/Apellido';
import Login from './componentes/Login';
import EditarDatos from './componentes/EditarDatos';
import EditarPersona from './componentes/EditarPersona';
import CrearPersona from './componentes/CrearPersona';
import { auth } from './config/firebaseConfig';
import PrivateRoute from './PrivateRoute';
import apsImage from './media/aps.png'; // Importa la imagen

const App = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate('/login'); // Utiliza la función de navegación para redirigir al usuario
    } catch (error) {
      console.error('Error al cerrar sesión:', error.message);
    }
  };

  return (
    <div className="center">
      <h3 className="text-center">FICHA FAMILIAR DE ATENCIÓN PRIMARIA EN SALUD (APS)</h3>
      {user ? (
        <div>  
          {/* <button onClick={handleLogout}>Cerrar sesión</button> */}
          <div className="menu">
            {/* <Link to="/caracterizacion">FICHA APS</Link> */}
            {/* <Link to="/pif">PIF</Link> */}
            <nav class="navbar navbar-expand-lg bg-primary bg-opacity-10">
              <img src={apsImage} alt="APS" className="img-thumbnail" width="80"/>
              <div class="container-fluid">
              <Link className="navbar-brand" to="/aps">Dashboard</Link>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                  <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNav">
                  <ul class="navbar-nav">
                    <li class="nav-item">
                      <Link class="nav-link" to="/caracterizacion">Formulario</Link>
                    </li>
                    <li class="nav-item">
                      <Link class="nav-link" to="/pif">PIF</Link>
                    </li>
                    <li class="nav-item">
                      <Link class="nav-link" to="/fichas">Listar fichas</Link>
                    </li>
                  </ul>
                </div>
                <div className="ml-auto"> {/* Para alinear a la derecha */}
                  <button onClick={handleLogout} className="btn btn-danger">Cerrar sesión</button>
                </div>
              </div>
            </nav>
          </div>
          <div className="contenido">
            <Routes>
              <Route path="/aps" element={<Aps />} />
              <Route path="/caracterizacion" element={<Caracterizacion />} />
              <Route path="/pif" element={<Pif />} />
              <Route path="/fichas" element={<Fichas />} />
              <Route path="/datos/:_id" element={<EditarDatos />} />
              <Route path="/persona/:_id/:ndedocumentodeidentidad" element={<EditarPersona />} />
              <Route path="/agregar-miembro/:_id" element={<CrearPersona />} />
            </Routes>
          </div>
        </div>
      ) : (
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Navigate to="/login" />} />
        </Routes>
      )}
    </div>
  );
};

export default App;
