import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { saveFichaFamiliar, deleteFichaFamiliar, getAllFichaFamiliar } from '../utils/indexedDB';
import { syncWithMongoDB } from '../utils/api';
import { syncLocalDataWithMongoDB } from '../utils/api';
import moment from 'moment-timezone';

const fechaActual = moment().tz('America/Bogota');

const Caracterizacion = (props) => {
  const navigate = useNavigate();

  const [fichaFamiliar, setFichaFamiliar] = useState({
    fechacreacion:  fechaActual.toDate(),
    planintervenciones: '',
    numeroficha: '',
    nombreese: '',
    otrainstitucion: '',
    fechavisita: '',
    horainicio: '',
    apellidofamiliar: '',
    nombreyapellidoatiende: '',
    tipodocumento: '',
    numerodocumentoatiende: '',
    nombremunicipio: '',
    nombredelterritoriodondeh: '',
    nombredelmicroterritoriod: '',
    direccionvivienda: '',
    zona: '',
    zonageografica: '',
    tipodevivienda: '',
    telefonocelular: '',
    estratovivienda: '',
    numerointegrantesfamilia: '',
    familia: '',
    estadoregistro: '',
    observesicercadelaviviendahay: [],
    cualeselmaterialpredominapiso: '',
    cualeselmaterialpredominaparedes: '',
    cualeselmaterialpredominatecho: '',
    lasparedesestan: '',
    cualeselestadoengeneralde: '',
    lacocinaseencuentrasepara: '',
    elbanoseencuentraseparado: '',
    losdormitoriosdondeduerme: '',
    decuantoscuartosopiezasdo: '',
    senaledelossiguientesambiilu: [],
    sefacilitalacirculacionde: '',
    senaledelossiguientesambiven: [],
    senalelosusosdelossiguienlm: [],
    senalelosusosdelossiguienlp: [],
    senalelosusosdelossiguienlr: [],
    quetipodealumbradoutiliza: '',
    alinteriordelaviviendase: [],
    sirealizaunaactividadecon: '',
    algunmiembrodelafamiliase: '',
    desdelaviviendasepuedeacc: [],
    comosedesplazangeneralmen: [],
    cualeselementosdesegurida: [],
    sieltiempodedesplazamienttra: [],
    sieltiempodedesplazamientest: [],
    sieltiempodedesplazamientotr: [],
    enelultimoanoalgunaperson: '',
    tipoaccidenteprimerainfa: [],
    tipoaccidenteinfancia: [],
    tipoaccidenteadolescenci: [],
    tipoaccidentejuventud: [],
    tipoaccidenteadultez: [],
    tipoaccidentepersonamayo: [],
    seidentificanalgunosdelos: [],
    cualeslaprincipalfuentede: [],
    cuantashorasaldiacuentaco: '',
    secuentacontanquedealmace: [],
    serealizaalgunodelossigui: [],
    comoserealizaelalmacenami: '',
    cualeslafrecuenciadelavad: [],
    ladistanciadeltanquesubte: '',
    quesistemadedisposiciondeex: [],
    quesistemadedisposiciondeag: [],
    indiqueaquedistanciaestauvi: '',
    indiqueaquedistanciaestaufu: '',
    indiqueaquedistanciaestauri: '',
    indiqueaquedistanciaestauta: '',
    indiqueaquedistanciaestauot: '',
    losresiduossolidosdelaviv: [],
    comoserealizaladisposicioso: [],
    comoserealizaladisposiciope: [],
    conocenenlafamilialasprac: '',
    quepracticasdereduccionde: [],
    quepracticasdeaprovechami: [],
    quepracticasdeseparacione: '',
    serealizanpracticasadecuaap: '',
    serealizanpracticasadecuase: '',
    serealizaelprocesodesepar: '',
    comoserealizalalimpiezadepi: [],
    comoserealizalalimpiezadepa: [],
    comoserealizalalimpiezadesu: [],
    losmiembrosdelafamiliasel: '',
    secompartenimplementosdehpi: [],
    secompartenimplementosdehpa: [],
    secompartenimplementosdehte: [],
    secompartenimplementosdehta: [],
    secompartenimplementosdehco: [],
    secompartenimplementosdehsa: [],
    secompartenimplementosdehcob: [],
    secompartenimplementosdehba: [],
    secompartenimplementosdehcoc: [],
    secompartenimplementosdehot: [],
    enquesituacionessepractic: [],
    cualfuentedeenergiaocombu: [],
    esfrecuentequehayahumoden: '',
    senalelasfuentesfrecuente: [],
    cualesdelassiguientesprac: [],
    quetipodeestufaseempleaen: [],
    quemedidasseimplementancu: [],
    lafamiliaconviveconanimal: '',
    senalelosanimalesqueconviper: '',
    senalelosanimalesqueconviga: '',
    senalelosanimalesqueconvipo: '',
    senalelosanimalesqueconvibo: '',
    senalelosanimalesqueconvieq: '',
    senalelosanimalesqueconviov: '',
    senalelosanimalesqueconviap: '',
    senalelosanimalesqueconviao: '',
    senalelosanimalesqueconvipe: '',
    senalelosanimalesqueconvico: '',
    senalelosanimalesqueconvian: '',
    senalelosanimalesqueconviot: '',
    cualeslafinalidaddetenere: [],
    losanimalesdomesticosesta: [],
    sedesparasitaalosanimales: '',
    lasinstalacionesparaatenc: '',
    losanimalesdecompaniaperr: '',
    lasexcretasdelosanimalesd: '',
    seevidenciaolafamiliainfo: '',
    cualesdelossiguientesvect: [],
    seobservacercadelaviviend: [],
    quemedidasseponenenpracti: [],
    seobservananimalesponzono: '',
    seobservanalgunosdelossig: [],
    quemedidasseponenenpract: [],
    serevisanlasetiquetasyses: '',
    losproductosdelimpiezaase: '',
    ellugardondesealmacenanlo: '',
    cuandoseutilizanproductos: '',
    dondeseadquierenlosproduc: [],
    comoserealizaladisposicioen: [],
    comoserealizaladisposiciopel: [],
    grupospersonas: [],
    gruposdosonceanios: [],
    gruposcovidtresanios: [],
    gruposmujeresnueveanios: [],
    gruposgestantes: [],
    gruposmayores: [],
    gruposamarilla: [],
    analisisdelacaracterizaci: '',
    principalesfactoresderies: '',
    integrantesdelafamiliaque: '',
    cualessonlosfactoresderie: '',
    resultadosporcompocoh: '',
    resultadosporcomposan: '',
    resultadosporcompoair: '',
    resultadosporcompozoo: '',
    resultadosporcompocon: '',
    resultadosporcompoman: '',
    resultadosporcompoval: '',
    resultadosporcompoant: '',
    resultadosporcomposex: '',
    resultadosporcomporec: '',
    resultadosporcompovid: '',
    resultadosporcomposal: '',
    resultadosporcompopro: '',
    clasificaciondelriesgoalt: '',
    clasificaciondelriesgomed: '',
    clasificaciondelriesgobaj: '',
    requierepriorizarvaloraci: '',
    justifiquelarespuestaante: '',
    nombreyapellidodelrespons: '',
    rolenelequipoinicialdeate: '',
    telefonocelularresdil: '',

  });
  const [otrosobs, setOtrosObs] = useState('');
  const [otrosalinteriordelaviviendase, setOtrosAlinteriordelaviviendase] = useState('');
  const [otrosdesdelaviviendasepuedeacc, setOtrosDesdelaviviendasepuedeacc] = useState('');
  const [otroscomosedesplazangeneralmen, setOtrosComosedesplazangeneralmen] = useState('');
  const [otroscualeselementosdesegurida, setOtrosCualeselementosdesegurida] = useState('');
  const [otrosseidentificanalgunosdelos, setOtrosSeidentificanalgunosdelos] = useState('');
  const [otroscualeslaprincipalfuentede, setOtrosCualeslaprincipalfuentede] = useState('');
  const [otrosserealizaalgunodelossigui, setOtrosSerealizaalgunodelossigui] = useState('');
  const [otroscualeslafrecuenciadelavad, setOtrosCualeslafrecuenciadelavad] = useState('');
  const [otrosquesistemadedisposiciondeex, setOtrosQuesistemadedisposiciondeex] = useState('');
  const [otrosquesistemadedisposiciondeag, setOtrosQuesistemadedisposiciondeag] = useState('');
  const [otroslosresiduossolidosdelaviv, setOtrosLosresiduossolidosdelaviv] = useState('');
  const [otroscomoserealizaladisposicioso, setOtrosComoserealizaladisposicioso] = useState('');
  const [otroscomoserealizaladisposiciope, setOtrosComoserealizaladisposiciope] = useState('');
  const [otrosquepracticasdereduccionde, setOtrosQuepracticasdereduccionde] = useState('');
  const [otrosquepracticasdeaprovechami, setOtrosQuepracticasdeaprovechami] = useState('');
  const [otroscomoserealizalalimpiezadepi, setOtrosComoserealizalalimpiezadepi] = useState('');
  const [otroscomoserealizalalimpiezadepa, setOtrosComoserealizalalimpiezadepa] = useState('');
  const [otroscomoserealizalalimpiezadesu, setOtrosComoserealizalalimpiezadesu] = useState('');
  const [otroscualfuentedeenergiaocombu, setOtrosCualfuentedeenergiaocombu] = useState('');
  const [otrossenalelasfuentesfrecuente, setOtrosSenalelasfuentesfrecuente] = useState('');
  const [otrosquemedidasseimplementancu, setOtrosQuemedidasseimplementancu] = useState('');
  const [otroscualesdelossiguientesvect, setOtrosCualesdelossiguientesvect] = useState('');
  const [otrosquemedidasseponenenpracti, setOtrosQuemedidasseponenenpracti] = useState('');
  const [otrosseobservanalgunosdelossig, setOtrosSeobservanalgunosdelossig] = useState('');
  const [otrosquemedidasseponenenpract, setOtrosQuemedidasseponenenpract] = useState('');
  const [otroscomoserealizaladisposicioen, setOtrosComoserealizaladisposicioen] = useState('');
  const [otroscomoserealizaladisposiciopel, setOtrosComoserealizaladisposiciopel] = useState('');
  const [showDivtipoaccidente, setShowDivtipoaccidente] = useState(false); // Estado para controlar la visibilidad del div
  const [showDivotrainstitucion, setShowDivotrainstitucion] = useState(false); // Estado para controlar la visibilidad del div


  //para los grupos de campos
  const [grupospersonas, setGruposPersonas] = useState([]);
  const [grupoActualpersonas, setGrupoActualpersonas] = useState(1); // Contador para identificar grupos

  const agregarGrupoPersonas = () => {
    // Crea un nuevo grupo de campos con el identificador actual
    const nuevoGrupopersonas = {
      persona: grupoActualpersonas,
      nombresyapellidos: '',
      tipodeidentidad: '',
      ndedocumentodeidentidad: '',
      fechadenacimiento: '',
      sexo: '',
      etnia: '',
      condicionespecialsm: '',
      discapacidadsm: '',
      estadocivil: '',
      parentescoconeljefedehoga: '',
      regsalud: '',
      nombredelaeps: '',
      ocupacionprincipal: '',
      dependeeconomicamentedeun: '',
      escolaridad: '',
      antecedentepersonalsm: [],
      enfermedadactualsm: [],
      antecedentefamiliarprimer: [],
      sintomasrecientessincausa: [],
      cepilladodiariominimovec: '',
      utilizaotroelementoparael: '',
      laultimavezquevisitoelodo: '',
      fechadelaultimamenstruaci: '',
      suultimamenstruacionfuede: '',
      actualmentetienerelacione: '',
      practicasexualriesgosasma: '',
      quemetododeplanificacionu: '',
      esconstanteconelmetododep: '',
      hautilizadopormasde10anos: '',
      quienfacilitaelmetodoelac: '',
      npartos: '',
      ncesareas: '',
      nabortos: '',
      nnacidosvivos: '',
      nmortinatos: '',
      edadalmomentodenacerelpri: '',
      ensusembarazosconsumioalc: '',
      embarazoplaneado: '',
      clasificaciondelriesgoobs: '',
      sabeelmotivodelriesgo: '',
      alimentaconlechematernaex: '',
      alimentacioncomplementari: '',
      ultimavezqueasistioacitol: '',
      ultimavezlerealizaronprue: '',
      ultimavezquelerealizarone: '',
      ultimavezquelerealizaronm: '',
      practicadeactividadfisica: '',
      habitosdealimentacion: '',
      exposicionfrecuenteahumos: '',
      sedesparasitadoenlosultim: '',
      ultimavezquelepracticaron: '',
      pesokg: '',
      tallacm: '',
      clasificacionantrometrica: '',
      circunferenciadelacintura: '',
      perimetrodelbrazoninosyni: '',
      actualmenteexistealgunacr: '',
      sedespiertavariashorasant: '',
      avecessienteenquenotienes: '',
      consideraquelamuertepuede: '',
      estarcargodelcuidadodealg: '',
      experimentaunaperdidamuer: '',
      consideraqueloscomportami: '',
      consideraanormalessushabi: '',
      identificaensuhogarcompor: '',
      consumodesustanciaspsicoa: '',
      seisdosisrnbcg: '',
      seistercerasdosispenta: '',
      seisdosisanotripleviral: '',
      seisdiesiochomesesdptopenta: '',
      seisdosiscincoaniossegundorfdpt: '',
      seisdosisdeinfluenzaanual: '',
      seisesquemacompleto: '',
      seisvacunadocanalizado: '',
      dosdosisadicionalsr: '',
      dosesquemacompleto: '',
      dosvacunadocanalizado: '',
      covidprimeradosis: '',
      coviddosusunica: '',
      covidsegundadodis: '',
      covidprimerrefuerzo: '',
      covidsegundorefuerzo: '',
      covidesquemacompleto: '',
      covidvacunadocanalizado: '',
      mujereseps: '',
      mujeresprimeraradosis: '',
      mujeressegundadadosis: '',
      mujeresesquemacompleto: '',
      mujeresvacunadocanalizado: '',
      gestsemanasdegestacion: '',
      gestcontrolprenatal: '',
      gesttdapcelularsemveinte: '',
      gesttoxoidetetanicosem: '',
      gestinfluenzasemdoce: '',
      gestcovidsemdoce: '',
      gestesquemacompleto: '',
      gestvacunadocanalizado: '',
      mayordosisanualdeinfluenza: '',
      mayordosiscovid: '',
      mayorenfermedadcronica: '',
      mayorcontrolenfermedad:   '',    
      mayoresquemacompleto: '',
      mayorvacunadocanalizado: '',      
      amaridosisfa: '',    
      amariesquemacompleto: '',
      amarivacunadocanalizado: '',
    };
    // Agrega el nuevo grupo al estado y aumenta el identificador
    setGruposPersonas([...grupospersonas, nuevoGrupopersonas]);
    setGrupoActualpersonas(grupoActualpersonas + 1);
  };
  const eliminarGrupoPersonas = (persona) => {
    // Filtra los grupos de campos para eliminar el grupo con el ID proporcionado
    const gruposActualizados = grupospersonas.filter((grupo) => grupo.persona !== persona);
    setGruposPersonas(gruposActualizados);
    setGrupoActualpersonas(grupoActualpersonas - 1);
  };

  const handleChangeCampo = (persona, campo, value, isCheckbox = false) => {
    const gruposActualizados = grupospersonas.map((grupo) => {
      if (grupo.persona === persona) {
        if (isCheckbox) {
          if (grupo[campo].includes(value)) {
            return { ...grupo, [campo]: grupo[campo].filter((item) => item !== value) };
          } else {
            return { ...grupo, [campo]: [...grupo[campo], value] };
          }
        } else {
          return { ...grupo, [campo]: value };
        }
      }
      return grupo;
    });
    setGruposPersonas(gruposActualizados);
  };
  //fin grupo de campos

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') { // Manejo especial para checkboxes
      if (checked) {
        setFichaFamiliar((prevCampos) => ({
          ...prevCampos,
          [name]: [...prevCampos[name], value], // Actualizamos el array correspondiente
        }));
      } else {
        setFichaFamiliar((prevCampos) => ({
          ...prevCampos,
          [name]: prevCampos[name].filter((item) => item !== value), // Removemos el valor del array correspondiente
        }));
      }
    } else {
      setFichaFamiliar((prevCampos) => ({
        ...prevCampos,
        [name]: value,
      }));

      const currentShowDivotrainstitucion = showDivotrainstitucion;

      // Mostrar u ocultar el div basado en la selección del select
      if (name === 'nombreese' && value === 'OTRA INSTITUCIÓN') {
        setShowDivotrainstitucion(true);
      } else {
        setShowDivotrainstitucion(false);
      }
      
      // Mostrar u ocultar el div basado en la selección del select
      if (name === 'enelultimoanoalgunaperson' && value === 'Si') {
        setShowDivtipoaccidente(true);
      } else {
        setShowDivtipoaccidente(false);
      }

      // Restaurar el estado actual
      if (currentShowDivotrainstitucion) {
        setShowDivotrainstitucion(true);
      }

    }
  };

  const handleInputChange = (event, setter) => {
    const { value } = event.target;
    setter(value);
  };
  
  const handleInputBlur = (setter, arrayName) => {
    if (setter && !fichaFamiliar[arrayName].includes(setter)) {
      setFichaFamiliar((prevCampos) => ({
        ...prevCampos,
        [arrayName]: [...prevCampos[arrayName], setter],
      }));
    }
  };

  useEffect(() => {
    // Detectar si hay conexión activa
    const isOnline = navigator.onLine;
  
    // Si hay conexión, sincronizar datos con MongoDB
    if (isOnline) {
      syncLocalDataWithMongoDB();
    }
  }, []);

  const syncLocalDataWithMongoDB = async () => {
    try {
      const localData = await getAllFichaFamiliar(); // Obtén todos los datos de IndexedDB
      for (const fichaFamiliar of localData) {
        const response = await syncWithMongoDB(fichaFamiliar);
        if (response) {
          await deleteFichaFamiliar(fichaFamiliar.id); // Elimina los datos de IndexedDB después de sincronizar con MongoDB
        }
      }
    } catch (error) {
      console.error('Error al sincronizar datos con MongoDB:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Verificar que todos los campos obligatorios estén llenos
    const camposObligatorios = [
      'planintervenciones',
      'numeroficha',
      'nombreese',
      'fechavisita',
      'horainicio',
      'apellidofamiliar',
      'nombreyapellidoatiende',
      'tipodocumento',
      'numerodocumentoatiende',
      'nombremunicipio',
      'nombredelterritoriodondeh',
      'nombredelmicroterritoriod',
      'direccionvivienda',
      'zona',
      'zonageografica',
      'tipodevivienda',
      'telefonocelular',
      'estratovivienda',
      'numerointegrantesfamilia',
      'familia',
      'estadoregistro',
      'observesicercadelaviviendahay',
      'cualeselmaterialpredominapiso',
      'cualeselmaterialpredominaparedes',
      'cualeselmaterialpredominatecho',
      'lasparedesestan',
      'cualeselestadoengeneralde',
      'lacocinaseencuentrasepara',
      'elbanoseencuentraseparado',
      'losdormitoriosdondeduerme',
      'decuantoscuartosopiezasdo',
      'senaledelossiguientesambiilu',
      'sefacilitalacirculacionde',
      'senaledelossiguientesambiven',
      'quetipodealumbradoutiliza',
      'alinteriordelaviviendase',
      'sirealizaunaactividadecon',
      'algunmiembrodelafamiliase',
      'desdelaviviendasepuedeacc',
      'comosedesplazangeneralmen',
      'cualeselementosdesegurida',
      'enelultimoanoalgunaperson',
      'seidentificanalgunosdelos', 
      'cualeslaprincipalfuentede',
      'cuantashorasaldiacuentaco',
      'secuentacontanquedealmace',
      'serealizaalgunodelossigui',
      'comoserealizaelalmacenami',
      'cualeslafrecuenciadelavad',
      'quesistemadedisposiciondeex',
      'quesistemadedisposiciondeag',
      'losresiduossolidosdelaviv',
      'comoserealizaladisposicioso',
      'comoserealizaladisposiciope',
      'conocenenlafamilialasprac',
      'quepracticasdereduccionde',
      'quepracticasdeaprovechami',
      'quepracticasdeseparacione',
      'serealizanpracticasadecuaap',
      'serealizanpracticasadecuase',
      'serealizaelprocesodesepar',
      'comoserealizalalimpiezadepi',
      'comoserealizalalimpiezadepa',
      'comoserealizalalimpiezadesu',
      'losmiembrosdelafamiliasel',
      'enquesituacionessepractic',
      'cualfuentedeenergiaocombu',
      'esfrecuentequehayahumoden',
      'senalelasfuentesfrecuente',
      'cualesdelassiguientesprac',
      'quetipodeestufaseempleaen',
      'quemedidasseimplementancu',
      'lafamiliaconviveconanimal',
      'nombreyapellidodelrespons',
      'rolenelequipoinicialdeate',
      'telefonocelularresdil',

    ];

    for (const campo of camposObligatorios) {
      if (!fichaFamiliar[campo]) {
        alert('Por favor, complete todos los campos requeridos.');
        return;
      }
    }
    // Verifica si ya hay al menos un registro
    if (grupospersonas.length === 0) {
      alert('Por favor, agrega al menos una persona antes de guardar la ficha.');
      return;
    }

    // Genera un ID numérico para IndexedDB (si aún no lo tienes)
    const id = new Date().getTime();
    
    const fichaFamiliarWithId = {
      ...fichaFamiliar,
      id: id,
      grupospersonas: grupospersonas,
    };
    console.log(JSON.stringify(fichaFamiliarWithId, null, 2));
    
    await saveFichaFamiliar(fichaFamiliarWithId);
    const response = await syncWithMongoDB(fichaFamiliarWithId);
    if (response) {
      alert('Datos guardados y sincronizados con MongoDB.');
       // Elimina los datos de IndexedDB después de sincronizar con MongoDB
      await deleteFichaFamiliar(id);
      navigate('/fichas');
    } else {
      alert('Datos guardados localmente, se sincronizarán cuando haya conexión.');
      navigate('/fichas');
    }
    
    //limpiar el formulario de envio
    setFichaFamiliar({
      planintervenciones: '',
      numeroficha: '',
      nombreese: '',
      otrainstitucion: '',
      fechavisita: '',
      horainicio: '',
      apellidofamiliar: '',
      nombreyapellidoatiende: '',
      tipodocumento: '',
      numerodocumentoatiende: '',
      nombremunicipio: '',
      nombredelterritoriodondeh: '',
      nombredelmicroterritoriod: '',
      direccionvivienda: '',
      zona: '',
      zonageografica: '',
      tipodevivienda: '',
      telefonocelular: '',
      estratovivienda: '',
      numerointegrantesfamilia: '',
      familia: '',
      estadoregistro: '',
      observesicercadelaviviendahay: [],
      cualeselmaterialpredominapiso: '',
      cualeselmaterialpredominaparedes: '',
      cualeselmaterialpredominatecho: '',
      lasparedesestan: '',
      cualeselestadoengeneralde: '',
      lacocinaseencuentrasepara: '',
      elbanoseencuentraseparado: '',
      losdormitoriosdondeduerme: '',
      decuantoscuartosopiezasdo: '',
      senaledelossiguientesambiilu: [],
      sefacilitalacirculacionde: '',
      senaledelossiguientesambiven: [],
      senalelosusosdelossiguienlm: [],
      senalelosusosdelossiguienlp: [],
      senalelosusosdelossiguienlr: [],
      quetipodealumbradoutiliza: '',
      alinteriordelaviviendase: [],
      sirealizaunaactividadecon: '',
      algunmiembrodelafamiliase: '',
      desdelaviviendasepuedeacc: [],
      comosedesplazangeneralmen: [],
      cualeselementosdesegurida: [],  
      sieltiempodedesplazamienttra: [],
      sieltiempodedesplazamientest: [],
      sieltiempodedesplazamientotr: [],
      enelultimoanoalgunaperson: '',
      tipoaccidenteprimerainfa: [],
      tipoaccidenteinfancia: [],
      tipoaccidenteadolescenci: [],
      tipoaccidentejuventud: [],
      tipoaccidenteadultez: [],
      tipoaccidentepersonamayo: [],
      seidentificanalgunosdelos: [],
      cualeslaprincipalfuentede: [],
      cuantashorasaldiacuentaco: '',
      secuentacontanquedealmace: [],
      serealizaalgunodelossigui: [],
      comoserealizaelalmacenami: '',
      cualeslafrecuenciadelavad: [],
      ladistanciadeltanquesubte: '',
      quesistemadedisposiciondeex: [],
      quesistemadedisposiciondeag: [],
      indiqueaquedistanciaestauvi: '',
      indiqueaquedistanciaestaufu: '',
      indiqueaquedistanciaestauri: '',
      indiqueaquedistanciaestauta: '',
      indiqueaquedistanciaestauot: '',
      losresiduossolidosdelaviv: [],
      comoserealizaladisposicioso: [],
      comoserealizaladisposiciope: [],
      conocenenlafamilialasprac: '',
      quepracticasdereduccionde: [],
      quepracticasdeaprovechami: [],
      quepracticasdeseparacione: '',
      serealizanpracticasadecuaap: '',
      serealizanpracticasadecuase: '',
      serealizaelprocesodesepar: '',
      comoserealizalalimpiezadepi: '',
      comoserealizalalimpiezadepa: '',
      comoserealizalalimpiezadesu: '',
      losmiembrosdelafamiliasel: '',
      secompartenimplementosdehpi: [],
      secompartenimplementosdehpa: [],
      secompartenimplementosdehte: [],
      secompartenimplementosdehta: [],
      secompartenimplementosdehco: [],
      secompartenimplementosdehsa: [],
      secompartenimplementosdehcob: [],
      secompartenimplementosdehba: [],
      secompartenimplementosdehcoc: [],
      secompartenimplementosdehot: [],
      enquesituacionessepractic: [],
      cualfuentedeenergiaocombu: [],
      esfrecuentequehayahumoden: '',
      senalelasfuentesfrecuente: [],
      cualesdelassiguientesprac: [],
      quetipodeestufaseempleaen: [],
      quemedidasseimplementancu: [],
      lafamiliaconviveconanimal: '',
      senalelosanimalesqueconviper: '',
      senalelosanimalesqueconviga: '',
      senalelosanimalesqueconvipo: '',
      senalelosanimalesqueconvibo: '',
      senalelosanimalesqueconvieq: '',
      senalelosanimalesqueconviov: '',
      senalelosanimalesqueconviap: '',
      senalelosanimalesqueconviao: '',
      senalelosanimalesqueconvipe: '',
      senalelosanimalesqueconvico: '',
      senalelosanimalesqueconvian: '',
      senalelosanimalesqueconviot: '',
      cualeslafinalidaddetenere: [],
      losanimalesdomesticosesta: [],
      sedesparasitaalosanimales: '',
      lasinstalacionesparaatenc: '',
      losanimalesdecompaniaperr: '',
      lasexcretasdelosanimalesd: '',
      seevidenciaolafamiliainfo: '',
      cualesdelossiguientesvect: [],
      seobservacercadelaviviend: [],
      quemedidasseponenenpracti: [],
      seobservananimalesponzono: '',
      seobservanalgunosdelossig: [],
      quemedidasseponenenpract: [],
      serevisanlasetiquetasyses: '',
      losproductosdelimpiezaase: '',
      ellugardondesealmacenanlo: '',
      cuandoseutilizanproductos: '',
      dondeseadquierenlosproduc: [],
      comoserealizaladisposicioen: [],
      comoserealizaladisposiciopel: [],
      grupospersonas: [],
      analisisdelacaracterizaci: '',
      principalesfactoresderies: '',
      integrantesdelafamiliaque: '',
      cualessonlosfactoresderie: '',
      resultadosporcompocoh: '',
      resultadosporcomposan: '',
      resultadosporcompoair: '',
      resultadosporcompozoo: '',
      resultadosporcompocon: '',
      resultadosporcompoman: '',
      resultadosporcompoval: '',
      resultadosporcompoant: '',
      resultadosporcomposex: '',
      resultadosporcomporec: '',
      resultadosporcompovid: '',
      resultadosporcomposal: '',
      resultadosporcompopro: '',
      clasificaciondelriesgoalt: '',
      clasificaciondelriesgomed: '',
      clasificaciondelriesgobaj: '',
      requierepriorizarvaloraci: '',
      justifiquelarespuestaante: '',
      nombreyapellidodelrespons: '',
      rolenelequipoinicialdeate: '',
      telefonocelularresdil: '',
      
    });
    setOtrosObs({
      otrosobs: '',
    });
    setOtrosAlinteriordelaviviendase({
      otrosalinteriordelaviviendase: '',
    });
    setOtrosDesdelaviviendasepuedeacc({
      otrosdesdelaviviendasepuedeacc: '',
    });
    setOtrosComosedesplazangeneralmen({
      otroscomosedesplazangeneralmen: '',
    });
    setOtrosCualeselementosdesegurida({
      otroscualeselementosdesegurida: '',
    });
    setOtrosSeidentificanalgunosdelos({
      otrosseidentificanalgunosdelos: '',
    });
    setOtrosCualeslaprincipalfuentede({
      otroscualeslaprincipalfuentede: '',
    });
    setOtrosSerealizaalgunodelossigui({
      otrosserealizaalgunodelossigui: '',
    });
    setOtrosCualeslafrecuenciadelavad({
      otroscualeslafrecuenciadelavad: '',
    });
    setOtrosQuesistemadedisposiciondeex({
      otrosquesistemadedisposiciondeex: '',
    });
    setOtrosQuesistemadedisposiciondeag({
      otrosquesistemadedisposiciondeag: '',
    });
    setOtrosLosresiduossolidosdelaviv({
      otroslosresiduossolidosdelaviv: '',
    });
    setOtrosComoserealizaladisposicioso({
      otroscomoserealizaladisposicioso: '',
    });
    setOtrosComoserealizaladisposiciope({
      otroscomoserealizaladisposiciope: '',
    });
    setOtrosQuepracticasdereduccionde({
      otrosquepracticasdereduccionde: '',
    });
    setOtrosQuepracticasdeaprovechami({
      otrosquepracticasdeaprovechami: '',
    });
    setOtrosComoserealizalalimpiezadepi({
      otroscomoserealizalalimpiezadepi: '',
    });
    setOtrosComoserealizalalimpiezadepa({
      otroscomoserealizalalimpiezadepa: '',
    });
    setOtrosComoserealizalalimpiezadesu({
      otroscomoserealizalalimpiezadesu: '',
    });
    setOtrosCualfuentedeenergiaocombu({
      otroscualfuentedeenergiaocombu: '',
    });
    setOtrosSenalelasfuentesfrecuente({
      otrossenalelasfuentesfrecuente: '',
    });
    setOtrosQuemedidasseimplementancu({
      otrosquemedidasseimplementancu: '',
    });
    setOtrosCualesdelossiguientesvect({
      otroscualesdelossiguientesvect: '',
    });
    setOtrosQuemedidasseponenenpracti({
      otrosquemedidasseponenenpracti: '',
    });
    setOtrosSeobservanalgunosdelossig({
      otrosseobservanalgunosdelossig: '',
    });
    setOtrosQuemedidasseponenenpract({
      otrosquemedidasseponenenpract: '',
    });
    setOtrosComoserealizaladisposicioen({
      otroscomoserealizaladisposicioen: '',
    });
    setOtrosComoserealizaladisposiciopel({
      otroscomoserealizaladisposiciopel: '',
    });

    setGruposPersonas([]);
    setGrupoActualpersonas(1);
  };


  useEffect(() => {
    // Aquí realizarías una solicitud GET al servidor para obtener datos desde MongoDB
    // Por simplicidad, utilizaremos una alerta para simularlo
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/formdata`)
      .then((response) => {
        console.log('Datos recibidos desde el servidor:', response.data);
      })
      .catch((error) => {
        console.error('Error al obtener datos desde el servidor:', error);
      });
  }, []);

  return (
    <div class="container-xxl">
      <form onSubmit={handleSubmit} noValidate>
        <div class="m-2 mt-4 border border-primary-subtle"><h4 class="text"> 1. DATOS VIVIENDA</h4>
          <div class="row">
            <div class="col-12 col-md-6 col-lg-4"> 
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <div>
                  <label class="form-label">Plan Interveciones</label>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="radio"
                        class="form-check-input"
                        name="planintervenciones"
                        value="Municipal"
                        checked={fichaFamiliar.planintervenciones === 'Municipal'}
                        onChange={handleChange}
                      />
                      Municipal
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="radio"
                        class="form-check-input"
                        name="planintervenciones"
                        value="Departamental"
                        checked={fichaFamiliar.planintervenciones === 'Departamental'}
                        onChange={handleChange}
                      />
                    Departamental
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="radio"
                        class="form-check-input"
                        name="planintervenciones"
                        value="APS Recursos Ministerio"
                        checked={fichaFamiliar.planintervenciones === 'APS Recursos Ministerio'}
                        onChange={handleChange}
                      />
                    APS Recursos Ministerio
                    </label>
                  </div>
                  {!fichaFamiliar.planintervenciones && <div className="text-danger">Campo obligatorio</div>}
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">  
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">N° de la ficha:</label>
                <input
                  type="text"
                  name='numeroficha'
                  className={`form-control ${!fichaFamiliar.numeroficha ? 'is-invalid' : ''}`}
                  value={fichaFamiliar.numeroficha}
                  onChange={handleChange}
                />
                {!fichaFamiliar.numeroficha && <div className="invalid-feedback">Campo obligatorio</div>}
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4"> 
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">Nombre de la ESE:</label>
                <select className={`form-select ${!fichaFamiliar.nombreese ? 'is-invalid' : ''}`} name='nombreese' value={fichaFamiliar.nombreese} onChange={handleChange}>
                  <option selected></option>
                  <option value="ESE HOSPITAL SAN RAFAEL">ESE HOSPITAL SAN RAFAEL</option>
                  <option value="ESE RAFAEL TOVAR POVEDA">ESE RAFAEL TOVAR POVEDA</option>
                  <option value="ESE HOSPITAL DEPARTAMENTAL MARIA INMACULADA">ESE HOSPITAL DEPARTAMENTAL MARIA INMACULADA</option>
                  <option value="ESE SOR TERESA ADELE">ESE SOR TERESA ADELE</option>
                  <option value="ESE FABIO JARAMILLO LONDOÑO">ESE FABIO JARAMILLO LONDOÑO</option>                   
                  <option value="ESE HOSPITAL MALVINAS">ESE HOSPITAL MALVINAS</option> 
                  <option value="OTRA INSTITUCIÓN">OTRA INSTITUCIÓN</option>
                </select>
                {!fichaFamiliar.nombreese && <div className="invalid-feedback">Campo obligatorio</div>}
              </div>
            </div>
            {showDivotrainstitucion && (
            <div class="col-12 col-md-6 col-lg-4"> 
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">Nombre de la OTRA IPS o Fundación:</label>
                <input
                  type="text"
                  name='otrainstitucion'
                  className={`form-control`}
                  value={fichaFamiliar.otrainstitucion}
                  onChange={handleChange}
                />
                {/* {!fichaFamiliar.otrainstitucion && <div className="invalid-feedback">Campo obligatorio</div>} */}
              </div>
            </div>
            )}
            <div class="col-12 col-md-6 col-lg-4"> 
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">Fecha de la visita:</label>
                <input
                  type="date"
                  name='fechavisita'
                  className={`form-control ${!fichaFamiliar.fechavisita ? 'is-invalid' : ''}`}
                  value={fichaFamiliar.fechavisita}
                  onChange={handleChange}
                />
                {!fichaFamiliar.fechavisita && <div className="invalid-feedback">Campo obligatorio</div>}
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4"> 
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">Hora de inicio:</label>
                <input
                  type="time"
                  name='horainicio'
                  className={`form-control ${!fichaFamiliar.horainicio ? 'is-invalid' : ''}`}
                  value={fichaFamiliar.horainicio}
                  onChange={handleChange}
                />
                {!fichaFamiliar.horainicio && <div className="invalid-feedback">Campo obligatorio</div>}
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4"> 
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">Apellido Familiar:</label>
                <input
                  type="text"
                  name='apellidofamiliar'
                  className={`form-control ${!fichaFamiliar.apellidofamiliar ? 'is-invalid' : ''}`}
                  value={fichaFamiliar.apellidofamiliar}
                  onChange={handleChange}
                />
                {!fichaFamiliar.apellidofamiliar && <div className="invalid-feedback">Campo obligatorio</div>}
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4"> 
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">Nombre y apellido de la Persona que Atiende la Visita:</label>
                <input
                  type="text"
                  name='nombreyapellidoatiende'
                  className={`form-control ${!fichaFamiliar.nombreyapellidoatiende ? 'is-invalid' : ''}`}
                  value={fichaFamiliar.nombreyapellidoatiende}
                  onChange={handleChange}
                />
                {!fichaFamiliar.nombreyapellidoatiende && <div className="invalid-feedback">Campo obligatorio</div>}
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4"> 
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <div>
                  <label class="form-label">Tipo Documento</label>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="radio"
                        class="form-check-input"
                        name="tipodocumento"
                        value="RC"
                        checked={fichaFamiliar.tipodocumento === 'RC'}
                        onChange={handleChange}
                      />
                      1. RC
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="radio"
                        class="form-check-input"
                        name="tipodocumento"
                        value="TI"
                        checked={fichaFamiliar.tipodocumento === 'TI'}
                        onChange={handleChange}
                      />
                    2. TI
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="radio"
                        class="form-check-input"
                        name="tipodocumento"
                        value="CC"
                        checked={fichaFamiliar.tipodocumento === 'CC'}
                        onChange={handleChange}
                      />
                    3. CC
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="radio"
                        class="form-check-input"
                        name="tipodocumento"
                        value="ASI"
                        checked={fichaFamiliar.tipodocumento === 'ASI'}
                        onChange={handleChange}
                      />
                    4. ASI
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="radio"
                        class="form-check-input"
                        name="tipodocumento"
                        value="MSI"
                        checked={fichaFamiliar.tipodocumento === 'MSI'}
                        onChange={handleChange}
                      />
                    5. MSI
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="radio"
                        class="form-check-input"
                        name="tipodocumento"
                        value="CE"
                        checked={fichaFamiliar.tipodocumento === 'CE'}
                        onChange={handleChange}
                      />
                    6. CE
                    </label>
                  </div>
                  {!fichaFamiliar.tipodocumento && <div className="text-danger">Campo obligatorio</div>}
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4"> 
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">Número identificación:</label>
                <input
                  type="number"
                  name='numerodocumentoatiende'
                  className={`form-control ${!fichaFamiliar.numerodocumentoatiende ? 'is-invalid' : ''}`}
                  value={fichaFamiliar.numerodocumentoatiende}
                  onChange={handleChange}
                />
                {!fichaFamiliar.numerodocumentoatiende && <div className="invalid-feedback">Campo obligatorio</div>}
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4"> 
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">Nombre del Municipio</label>
                <select className={`form-select ${!fichaFamiliar.nombremunicipio ? 'is-invalid' : ''}`} name='nombremunicipio' value={fichaFamiliar.nombremunicipio} onChange={handleChange}>
                  <option selected></option>
                  <option value="Albania">Albania</option>
                  <option value="Belén de los Andaquíes">Belén de los Andaquíes</option>
                  <option value="Cartagena del Chairá">Cartagena del Chairá</option>
                  <option value="Curillo">Curillo</option>
                  <option value="El Doncello">El Doncello</option>
                  <option value="El Paujil">El Paujil</option>
                  <option value="Florencia">Florencia</option>
                  <option value="La Montañita">La Montañita</option>
                  <option value="Morelia">Morelia</option>
                  <option value="Milán">Milán</option>
                  <option value="Puerto Rico">Puerto Rico</option>
                  <option value="San José del Fragua">San José del Fragua</option>
                  <option value="San Vicente del Caguán">San Vicente del Caguán</option>
                  <option value="Solano">Solano</option>
                  <option value="Solita">Solita</option>
                  <option value="Valparaíso">Valparaíso</option>
                </select>
                {!fichaFamiliar.nombremunicipio && <div className="invalid-feedback">Campo obligatorio</div>}
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4"> 
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">Nombre del territorio donde hace presencia el equipo</label>
                <input
                  type="text"
                  name='nombredelterritoriodondeh'
                  className={`form-control ${!fichaFamiliar.nombredelterritoriodondeh ? 'is-invalid' : ''}`}
                  value={fichaFamiliar.nombredelterritoriodondeh}
                  onChange={handleChange}
                />
                {!fichaFamiliar.nombredelterritoriodondeh && <div className="invalid-feedback">Campo obligatorio</div>}
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4"> 
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">Nombre del microterritorio donde hace presencia el equipo</label>
                <input
                  type="text"
                  name='nombredelmicroterritoriod'
                  className={`form-control ${!fichaFamiliar.nombredelmicroterritoriod ? 'is-invalid' : ''}`}
                  value={fichaFamiliar.nombredelmicroterritoriod}
                  onChange={handleChange}
                />
                {!fichaFamiliar.nombredelmicroterritoriod && <div className="invalid-feedback">Campo obligatorio</div>}
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4"> 
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">Dirección de la vivienda:</label>
                <input
                  type="text"
                  name='direccionvivienda'
                  className={`form-control ${!fichaFamiliar.direccionvivienda ? 'is-invalid' : ''}`}
                  value={fichaFamiliar.direccionvivienda}
                  onChange={handleChange}
                />
                {!fichaFamiliar.direccionvivienda && <div className="invalid-feedback">Campo obligatorio</div>}
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4"> 
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <div>
                  <label class="form-label">Zona</label>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="radio"
                        class="form-check-input"
                        name="zona"
                        value="Urbano"
                        checked={fichaFamiliar.zona === 'Urbano'}
                        onChange={handleChange}
                      />
                      Urbano
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="radio"
                        class="form-check-input"
                        name="zona"
                        value="Rural"
                        checked={fichaFamiliar.zona === 'Rural'}
                        onChange={handleChange}
                      />
                    Rural
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="radio"
                        class="form-check-input"
                        name="zona"
                        value="Rural Disperso"
                        checked={fichaFamiliar.zona === 'Rural Disperso'}
                        onChange={handleChange}
                      />
                    Rural Disperso
                    </label>
                  </div>
                  {!fichaFamiliar.zona && <div className="text-danger">Campo obligatorio</div>}
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4"> 
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">Zona geográfica</label>
                <select className={`form-select ${!fichaFamiliar.zonageografica ? 'is-invalid' : ''}`} name='zonageografica' value={fichaFamiliar.zonageografica} onChange={handleChange}>
                  <option selected></option>
                  <option value="Corregimiento">Corregimiento</option>
                  <option value="vereda">vereda</option>
                  <option value="Inspección">Inspección</option>
                  <option value="caserio">caserio</option>
                  <option value="Resguardo Indigena">Resguardo Indigena</option>
                  <option value="Territorio Colectivo de comunidades Negras.">Territorio Colectivo de comunidades Negras.</option>
                  <option value="Comuna">Florencia</option>
                  <option value="barrio">barrio</option>
                </select>
                {!fichaFamiliar.zonageografica && <div className="invalid-feedback">Campo obligatorio</div>}
              </div>
            </div>
          </div>
        </div>
        <div class="m-2 mt-4 border border-primary-subtle"><h4 class="text"> 1.2 DATOS VIVIENDA</h4>
          <div class="row">
            <div class="col-12 col-md-6 col-lg-4"> 
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">Tipo de Vivienda</label>
                <select className={`form-select ${!fichaFamiliar.tipodevivienda ? 'is-invalid' : ''}`} name='tipodevivienda' value={fichaFamiliar.tipodevivienda} onChange={handleChange}>
                  <option selected></option>
                  <option value="Casa">Casa</option>
                  <option value="Casa Indígena">Casa Indígena</option>
                  <option value="Apartamento">Apartamento</option>
                  <option value="Cuarto de inquilinato">Cuarto de inquilinato</option>
                  <option value="Tipocuarto">Tipocuarto</option>
                  <option value="Otro tipo de vivienda (contenedor, carpa, embarcación, vagón, refugio natural, cueva, puente, etc .)">Otro tipo de vivienda (contenedor, carpa, embarcación, vagón, refugio natural, cueva, puente, etc .)</option>
                </select>
                {!fichaFamiliar.tipodevivienda && <div className="invalid-feedback">Campo obligatorio</div>}
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4"> 
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">Teléfono/Celular:</label>
                <input
                  type="number"
                  name='telefonocelular'
                  className={`form-control ${!fichaFamiliar.telefonocelular ? 'is-invalid' : ''}`}
                  value={fichaFamiliar.telefonocelular}
                  onChange={handleChange}
                />
                {!fichaFamiliar.telefonocelular && <div className="invalid-feedback">Campo obligatorio</div>}
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4"> 
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">¿A qué estrato pertenece esta vivienda? (Solicite recibo deservicio público, si lo tiene):</label>
                <input
                  type="number"
                  name='estratovivienda'
                  className={`form-control ${!fichaFamiliar.estratovivienda ? 'is-invalid' : ''}`}
                  value={fichaFamiliar.estratovivienda}
                  onChange={handleChange}
                />
                {!fichaFamiliar.estratovivienda && <div className="invalid-feedback">Campo obligatorio</div>}
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4"> 
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">N° de integrantes de la familia:</label>
                <input
                  type="number"
                  name='numerointegrantesfamilia'
                  className={`form-control ${!fichaFamiliar.numerointegrantesfamilia ? 'is-invalid' : ''}`}
                  value={fichaFamiliar.numerointegrantesfamilia}
                  onChange={handleChange}
                />
                {!fichaFamiliar.numerointegrantesfamilia && <div className="invalid-feedback">Campo obligatorio</div>}
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4"> 
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">Familia</label>
                <select className={`form-select ${!fichaFamiliar.familia ? 'is-invalid' : ''}`} name='familia' value={fichaFamiliar.familia} onChange={handleChange}>
                  <option selected></option>
                  <option value="Nueva">Nueva</option>
                  <option value="Actualizada">Actualizada</option>
                  <option value="No ubicada">No ubicada</option>
                  <option value="Cerrada">Cerrada</option>
                </select>
                {!fichaFamiliar.familia && <div className="invalid-feedback">Campo obligatorio</div>}
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4"> 
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">Estado de Registro</label>
                <select className={`form-select ${!fichaFamiliar.estadoregistro ? 'is-invalid' : ''}`} name='estadoregistro' value={fichaFamiliar.estadoregistro} onChange={handleChange}>
                  <option selected></option>
                  <option value="Completa">Completa</option>
                  <option value="En proceso">En proceso</option>
                </select>
                {!fichaFamiliar.estadoregistro && <div className="invalid-feedback">Campo obligatorio</div>}
              </div>
            </div>
          </div>
        </div>
        <div class="m-2 mt-4 border border-primary-subtle"><h4 class="text"> 2.1. HABITABILIDAD</h4>       
          <div class="row">
            <div class="col-12 col-md-6 col-lg-4"> 
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <div>
                  <label class="form-label">Observe si cerca de la vivienda hay: (puede señalar varias opciones)</label>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="observesicercadelaviviendahay"
                        value="Cultivos"
                        checked={fichaFamiliar.observesicercadelaviviendahay.includes('Cultivos')}
                        onChange={handleChange}
                      />
                      Cultivos
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="observesicercadelaviviendahay"
                        value="Apriscos"
                        checked={fichaFamiliar.observesicercadelaviviendahay.includes('Apriscos')}
                        onChange={handleChange}
                      />
                    Apriscos
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="observesicercadelaviviendahay"
                        value="Porquerizas"
                        checked={fichaFamiliar.observesicercadelaviviendahay.includes('Porquerizas')}
                        onChange={handleChange}
                      />
                    Porquerizas
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="observesicercadelaviviendahay"
                        value="Galpones"
                        checked={fichaFamiliar.observesicercadelaviviendahay.includes('Galpones')}
                        onChange={handleChange}
                      />
                    Galpones
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="observesicercadelaviviendahay"
                        value="Terrenos baldíos"
                        checked={fichaFamiliar.observesicercadelaviviendahay.includes('Terrenos baldíos')}
                        onChange={handleChange}
                      />
                    Terrenos baldíos
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="observesicercadelaviviendahay"
                        value="Presencia de Plagas: roedores, cucarachas, zancudos, moscas, etc"
                        checked={fichaFamiliar.observesicercadelaviviendahay.includes('Presencia de Plagas: roedores, cucarachas, zancudos, moscas, etc')}
                        onChange={handleChange}
                      />
                    Presencia de Plagas: roedores, cucarachas, zancudos, moscas, etc
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="observesicercadelaviviendahay"
                        value="Industrias Contaminantes"
                        checked={fichaFamiliar.observesicercadelaviviendahay.includes('Industrias Contaminantes')}
                        onChange={handleChange}
                      />
                    Industrias Contaminantes
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="observesicercadelaviviendahay"
                        value="Malos olores"
                        checked={fichaFamiliar.observesicercadelaviviendahay.includes('Malos olores')}
                        onChange={handleChange}
                      />
                    Malos olores
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="observesicercadelaviviendahay"
                        value="Sitios satélites de disposición de excretas"
                        checked={fichaFamiliar.observesicercadelaviviendahay.includes('Sitios satélites de disposición de excretas')}
                        onChange={handleChange}
                      />
                    Sitios satélites de disposición de excretas
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="observesicercadelaviviendahay"
                        value="Rellenos sanitarios/botaderos"
                        checked={fichaFamiliar.observesicercadelaviviendahay.includes('Rellenos sanitarios/botaderos')}
                        onChange={handleChange}
                      />
                    Rellenos sanitarios/botaderos
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="observesicercadelaviviendahay"
                        value="Ruido o sonidos desagradables"
                        checked={fichaFamiliar.observesicercadelaviviendahay.includes('Ruido o sonidos desagradables')}
                        onChange={handleChange}
                      />
                    Ruido o sonidos desagradables
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="observesicercadelaviviendahay"
                        value="Contaminación visual"
                        checked={fichaFamiliar.observesicercadelaviviendahay.includes('Contaminación visual')}
                        onChange={handleChange}
                      />
                    Contaminación visual
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="observesicercadelaviviendahay"
                        value="Río o quebrada"
                        checked={fichaFamiliar.observesicercadelaviviendahay.includes('Río o quebrada')}
                        onChange={handleChange}
                      />
                    Río o quebrada
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="observesicercadelaviviendahay"
                        value="Planta de tratamiento de agua residual"
                        checked={fichaFamiliar.observesicercadelaviviendahay.includes('Planta de tratamiento de agua residual')}
                        onChange={handleChange}
                      />
                    Planta de tratamiento de agua residual
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="observesicercadelaviviendahay"
                        value="Extracción minera"
                        checked={fichaFamiliar.observesicercadelaviviendahay.includes('Extracción minera')}
                        onChange={handleChange}
                      />
                    Extracción minera
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="observesicercadelaviviendahay"
                        value="Canales de agua lluvia"
                        checked={fichaFamiliar.observesicercadelaviviendahay.includes('Canales de agua lluvia')}
                        onChange={handleChange}
                      />
                    Canales de agua lluvia
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="observesicercadelaviviendahay"
                        value="Vías de tráfico vehícular"
                        checked={fichaFamiliar.observesicercadelaviviendahay.includes('Vías de tráfico vehícular')}
                        onChange={handleChange}
                      />
                    Vías de tráfico vehícular
                    </label>
                  </div>
                  <div class="input-group">
                    <div class="input-group-text">
                        <input
                          type="checkbox"
                          class="form-check-input mt-0"
                          name="observesicercadelaviviendahay"
                          value={otrosobs}
                          checked={fichaFamiliar.observesicercadelaviviendahay.includes(otrosobs)}
                          onChange={handleChange}
                          //onChange={(e) => fichaFamiliar.observesicercadelaviviendahay(e.target.checked)}
                        />
                      </div>
                      <input type="text" class="form-control" aria-label="Text input with checkbox" 
                        placeholder="Si aplica, digite aqui otros"
                        name="otrosobs"
                        value={otrosobs}
                        onChange={(event) => handleInputChange(event, setOtrosObs)}
                        onBlur={() => handleInputBlur(otrosobs, 'observesicercadelaviviendahay')}
                        //onChange={handleInputChange}
                        //onBlur={handleInputBlur}
                        />
                  </div>
                  {!fichaFamiliar.observesicercadelaviviendahay.length && <div className="text-danger">Campo obligatorio</div>}
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4"> 
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">¿Cuál es el material predominante del piso de la vivienda? (señale una sola opción)</label>
                <select className={`form-select ${!fichaFamiliar.cualeselmaterialpredominapiso ? 'is-invalid' : ''}`} name='cualeselmaterialpredominapiso' value={fichaFamiliar.cualeselmaterialpredominapiso} onChange={handleChange}>
                  <option selected></option>
                  <option value="Tierra, arena, barro">Tierra, arena, barro</option>
                  <option value="Cemento, gravilla">Cemento, gravilla</option>
                  <option value="Madera burda, tabla, tablón, otro vegetal">Madera burda, tabla, tablón, otro vegetal</option>
                  <option value="Liso e impermeable (baldosa, ladrillo, tableta, granito, mármol)">Liso e impermeable (baldosa, ladrillo, tableta, granito, mármol)</option>
                  <option value="Madera pulida o lacada, parqué, laminado">Madera pulida o lacada, parqué, laminado</option>
                  <option value="Alfombra o tapete de pared a pared">Alfombra o tapete de pared a pared</option>
                  <option value="Material plástico (vinilo, otro material sintético)">Material plástico (vinilo, otro material sintético)</option>
                  <option value="Lámina">Lámina</option>
                  <option value="Otro">Otro</option>
                </select>
                {!fichaFamiliar.cualeselmaterialpredominapiso && <div className="invalid-feedback">Campo obligatorio</div>}
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4"> 
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">¿Cuál es el material predominante de las paredes? (señale una sola opción)</label>
                <select className={`form-select ${!fichaFamiliar.cualeselmaterialpredominaparedes ? 'is-invalid' : ''}`} name='cualeselmaterialpredominaparedes' value={fichaFamiliar.cualeselmaterialpredominaparedes} onChange={handleChange}>
                  <option selected></option>
                  <option value="Impermeable (cemento, bloque, ladrillo, piedra, concreto vaciado) ">Impermeable (cemento, bloque, ladrillo, piedra, concreto vaciado) </option>
                  <option value="Bahareque/Barro, tapia pisada, esterilla, caña, otro tipo de material vegetal">Bahareque/Barro, tapia pisada, esterilla, caña, otro tipo de material vegetal</option>
                  <option value="Madera pulida, Madera burda (tabla, tablón), Guadua">Madera pulida, Madera burda (tabla, tablón), Guadua</option>
                  <option value="Tapia pisada, bahareque, adobe">Tapia pisada, bahareque, adobe</option>
                  <option value="Materiales de desecho (zinc, tela, cartón, latas, plásticos, otros)">Materiales de desecho (zinc, tela, cartón, latas, plásticos, otros)</option>
                  <option value="No tiene">No tiene</option>
                  <option value="Otro">Otro</option>
                </select>
                {!fichaFamiliar.cualeselmaterialpredominaparedes && <div className="invalid-feedback">Campo obligatorio</div>}
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4"> 
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">¿Cuál es el material predominante del techo? (señale una sola opción)</label>
                <select className={`form-select ${!fichaFamiliar.cualeselmaterialpredominatecho ? 'is-invalid' : ''}`} name='cualeselmaterialpredominatecho' value={fichaFamiliar.cualeselmaterialpredominatecho} onChange={handleChange}>
                  <option selected></option>
                  <option value="Concreto">Concreto</option>
                  <option value="Tejas de barro">Tejas de barro</option>
                  <option value="Fibrocemento">Fibrocemento</option>
                  <option value="Zinc">Zinc</option>
                  <option value="Palma o paja">Palma o paja</option>
                  <option value="Plástico">Plástico</option>
                  <option value="Desechos (cartón, lata, tela, sacos, etc)">Desechos (cartón, lata, tela, sacos, etc)</option>
                  <option value="Otros">Otros</option>
                </select>
                {!fichaFamiliar.cualeselmaterialpredominatecho && <div className="invalid-feedback">Campo obligatorio</div>}
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4"> 
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">Las paredes estan:</label>
                <select className={`form-select ${!fichaFamiliar.lasparedesestan ? 'is-invalid' : ''}`} name='lasparedesestan' value={fichaFamiliar.lasparedesestan} onChange={handleChange}>
                  <option selected></option>
                  <option value="En buen estado">En buen estado</option>
                  <option value="Con descascaramiento, humedad o cualquier otro tipo de deterioro">Con descascaramiento, humedad o cualquier otro tipo de deterioro</option>
                </select>
                {!fichaFamiliar.lasparedesestan && <div className="invalid-feedback">Campo obligatorio</div>}
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4"> 
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">Cual es el estado en general del techo? Señale una opción</label>
                <select className={`form-select ${!fichaFamiliar.cualeselestadoengeneralde ? 'is-invalid' : ''}`} name='cualeselestadoengeneralde' value={fichaFamiliar.cualeselestadoengeneralde} onChange={handleChange}>
                  <option selected></option>
                  <option value="Buen estado">Buen estado</option>
                  <option value="Presenta agrietamiento, goteras u otro tipo de fisuras">Presenta agrietamiento, goteras u otro tipo de fisuras</option>
                </select>
                {!fichaFamiliar.cualeselestadoengeneralde && <div className="invalid-feedback">Campo obligatorio</div>}
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4"> 
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">La cocina se encuentra separada de otros espacios de la vivienda (dormitorios, sala, comedor, baño)</label>
                <select className={`form-select ${!fichaFamiliar.lacocinaseencuentrasepara ? 'is-invalid' : ''}`} name='lacocinaseencuentrasepara' value={fichaFamiliar.lacocinaseencuentrasepara} onChange={handleChange}>
                  <option selected></option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </select>
                {!fichaFamiliar.lacocinaseencuentrasepara && <div className="invalid-feedback">Campo obligatorio</div>}
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4"> 
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">El baño se encuentra separado de otros espacios de la vivienda (dormitorios, sala, comedor)</label>
                <select className={`form-select ${!fichaFamiliar.elbanoseencuentraseparado ? 'is-invalid' : ''}`} name='elbanoseencuentraseparado' value={fichaFamiliar.elbanoseencuentraseparado} onChange={handleChange}>
                  <option selected></option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </select>
                {!fichaFamiliar.elbanoseencuentraseparado && <div className="invalid-feedback">Campo obligatorio</div>}
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4"> 
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">Los dormitorios donde duermen adultos y niños estan separados físicamente?</label>
                <select className={`form-select ${!fichaFamiliar.losdormitoriosdondeduerme ? 'is-invalid' : ''}`} name='losdormitoriosdondeduerme' value={fichaFamiliar.losdormitoriosdondeduerme} onChange={handleChange}>
                  <option selected></option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </select>
                {!fichaFamiliar.losdormitoriosdondeduerme && <div className="invalid-feedback">Campo obligatorio</div>}
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4"> 
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">¿De cuántos cuartos o piezas dormitorio (incluyendo comedor), dispone este hogar? Respuesta Numerica:</label>
                <input
                  type="number"
                  name='decuantoscuartosopiezasdo'
                  className={`form-control ${!fichaFamiliar.decuantoscuartosopiezasdo ? 'is-invalid' : ''}`}
                  value={fichaFamiliar.decuantoscuartosopiezasdo}
                  onChange={handleChange}
                />
                {!fichaFamiliar.decuantoscuartosopiezasdo && <div className="invalid-feedback">Campo obligatorio</div>}
              </div>  
            </div>
            <div class="col-12 col-md-6 col-lg-4">  
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <div>
                  <label class="form-label">Señale de los siguientes ambientes de la vivienda, cuales tiene iluminación natural</label>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="senaledelossiguientesambiilu"
                        value="Cocina"
                        checked={fichaFamiliar.senaledelossiguientesambiilu.includes('Cocina')}
                        onChange={handleChange}
                      />
                      Cocina
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="senaledelossiguientesambiilu"
                        value="Dormitorio adultos"
                        checked={fichaFamiliar.senaledelossiguientesambiilu.includes('Dormitorio adultos')}
                        onChange={handleChange}
                      />
                    Dormitorio adultos
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="senaledelossiguientesambiilu"
                        value="Dormitorio niños"
                        checked={fichaFamiliar.senaledelossiguientesambiilu.includes('Dormitorio niños')}
                        onChange={handleChange}
                      />
                    Dormitorio niños
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="senaledelossiguientesambiilu"
                        value="Sala / Comedor"
                        checked={fichaFamiliar.senaledelossiguientesambiilu.includes('Sala / Comedor')}
                        onChange={handleChange}
                      />
                    Sala / Comedor
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="senaledelossiguientesambiilu"
                        value="Sanitario"
                        checked={fichaFamiliar.senaledelossiguientesambiilu.includes('Sanitario')}
                        onChange={handleChange}
                      />
                    Sanitario
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="senaledelossiguientesambiilu"
                        value="Zona de lavado de ropas"
                        checked={fichaFamiliar.senaledelossiguientesambiilu.includes('Zona de lavado de ropas')}
                        onChange={handleChange}
                      />
                    Zona de lavado de ropas
                    </label>
                  </div>
                  {!fichaFamiliar.senaledelossiguientesambiilu.length && <div className="text-danger">Campo obligatorio</div>}
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4"> 
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">Se facilita la circulación del aire en la vivienda a través de la ventilación natural o artificial</label>
                <select className={`form-select ${!fichaFamiliar.sefacilitalacirculacionde ? 'is-invalid' : ''}`} name='sefacilitalacirculacionde' value={fichaFamiliar.sefacilitalacirculacionde} onChange={handleChange}>
                  <option selected></option>
                  <option value="Siempre">Siempre</option>
                  <option value="Algunas Veces">Algunas Veces</option>
                  <option value="Nunca">Nunca</option>
                </select>
                {!fichaFamiliar.sefacilitalacirculacionde && <div className="invalid-feedback">Campo obligatorio</div>}
              </div> 
            </div>
            <div class="col-12 col-md-6 col-lg-4">   
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <div>
                  <label class="form-label">Señale de los siguientes ambientes de la vivienda, cuales tiene ventilación natural (ventanas que se abren regularmente)</label>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="senaledelossiguientesambiven"
                        value="Cocina"
                        checked={fichaFamiliar.senaledelossiguientesambiven.includes('Cocina')}
                        onChange={handleChange}
                      />
                      Cocina
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="senaledelossiguientesambiven"
                        value="Dormitorio adultos"
                        checked={fichaFamiliar.senaledelossiguientesambiven.includes('Dormitorio adultos')}
                        onChange={handleChange}
                      />
                    Dormitorio adultos
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="senaledelossiguientesambiven"
                        value="Dormitorio niños"
                        checked={fichaFamiliar.senaledelossiguientesambiven.includes('Dormitorio niños')}
                        onChange={handleChange}
                      />
                    Dormitorio niños
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="senaledelossiguientesambiven"
                        value="Sala / Comedor"
                        checked={fichaFamiliar.senaledelossiguientesambiven.includes('Sala / Comedor')}
                        onChange={handleChange}
                      />
                    Sala / Comedor
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="senaledelossiguientesambiven"
                        value="Sanitario"
                        checked={fichaFamiliar.senaledelossiguientesambiven.includes('Sanitario')}
                        onChange={handleChange}
                      />
                    Sanitario
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="senaledelossiguientesambiven"
                        value="Zona de lavado de ropas"
                        checked={fichaFamiliar.senaledelossiguientesambiven.includes('Zona de lavado de ropas')}
                        onChange={handleChange}
                      />
                    Zona de lavado de ropas
                    </label>
                  </div>
                  {!fichaFamiliar.senaledelossiguientesambiven.length && <div className="text-danger">Campo obligatorio</div>}
                </div>
              </div>
            </div>
            <div class="col-12 col-md-8"> 
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <div class="row align-items-start">
                  <div class="col-md-4">
                    <div>
                      <label class="form-label">Señale los usos de los siguiente elementos en la vivienda: <b>[Lavamanos]</b></label>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="senalelosusosdelossiguienlm"
                            value="Lavado de Manos"
                            checked={fichaFamiliar.senalelosusosdelossiguienlm.includes('Lavado de Manos')}
                            onChange={handleChange}
                          />
                          Lavado de Manos
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="senalelosusosdelossiguienlm"
                            value="Lavado de Ropa"
                            checked={fichaFamiliar.senalelosusosdelossiguienlm.includes('Lavado de Ropa')}
                            onChange={handleChange}
                          />
                        Lavado de Ropa
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="senalelosusosdelossiguienlm"
                            value="Lavado de utencillos"
                            checked={fichaFamiliar.senalelosusosdelossiguienlm.includes('Lavado de utencillos')}
                            onChange={handleChange}
                          />
                        Lavado de utencillos
                        </label>
                      </div>
                      {/* {!fichaFamiliar.senalelosusosdelossiguienlm.length && <div className="text-danger">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div>
                      <label class="form-label">Señale los usos de los siguiente elementos en la vivienda: <b>[Lavaplatos]</b></label>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="senalelosusosdelossiguienlp"
                            value="Lavado de Manos"
                            checked={fichaFamiliar.senalelosusosdelossiguienlp.includes('Lavado de Manos')}
                            onChange={handleChange}
                          />
                          Lavado de Manos
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="senalelosusosdelossiguienlp"
                            value="Lavado de Ropa"
                            checked={fichaFamiliar.senalelosusosdelossiguienlp.includes('Lavado de Ropa')}
                            onChange={handleChange}
                          />
                        Lavado de Ropa
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="senalelosusosdelossiguienlp"
                            value="Lavado de utencillos"
                            checked={fichaFamiliar.senalelosusosdelossiguienlp.includes('Lavado de utencillos')}
                            onChange={handleChange}
                          />
                        Lavado de utencillos
                        </label>
                      </div>
                      {/* {!fichaFamiliar.senalelosusosdelossiguienlp.length && <div className="text-danger">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div>
                      <label class="form-label">Señale los usos de los siguiente elementos en la vivienda: <b>[Lavado de Ropas]</b></label>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="senalelosusosdelossiguienlr"
                            value="Lavado de Manos"
                            checked={fichaFamiliar.senalelosusosdelossiguienlr.includes('Lavado de Manos')}
                            onChange={handleChange}
                          />
                          Lavado de Manos
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="senalelosusosdelossiguienlr"
                            value="Lavado de Ropa"
                            checked={fichaFamiliar.senalelosusosdelossiguienlr.includes('Lavado de Ropa')}
                            onChange={handleChange}
                          />
                        Lavado de Ropa
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="senalelosusosdelossiguienlr"
                            value="Lavado de utencillos"
                            checked={fichaFamiliar.senalelosusosdelossiguienlr.includes('Lavado de utencillos')}
                            onChange={handleChange}
                          />
                        Lavado de utencillos
                        </label>
                      </div>
                      {/* {!fichaFamiliar.senalelosusosdelossiguienlr.length && <div className="text-danger">Campo obligatorio</div>} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">          
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">¿Qué tipo de alumbrado utilizan en la vivienda? (señale una sola opción, la más predominante)</label>
                <select className={`form-select ${!fichaFamiliar.quetipodealumbradoutiliza ? 'is-invalid' : ''}`} name='quetipodealumbradoutiliza' value={fichaFamiliar.quetipodealumbradoutiliza} onChange={handleChange}>
                  <option selected></option>
                  <option value="Luz eléctrica">Luz eléctrica</option>
                  <option value="Kerosén, petróleo, gasolina">Kerosén, petróleo, gasolina</option>
                  <option value="Velas">Velas</option>
                  <option value="Energia solar">Energia solar</option>
                  <option value="Planta de electricidad">Planta de electricidad</option>
                  <option value="Otros">Otros</option>
                </select>
                {!fichaFamiliar.quetipodealumbradoutiliza && <div className="invalid-feedback">Campo obligatorio</div>}
              </div> 
            </div>
            <div class="col-12 col-md-6 col-lg-4">          
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <div>
                  <label class="form-label">¿Al interior de la vivienda se realiza alguna actividad económica?</label>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="alinteriordelaviviendase"
                        value="No"
                        checked={fichaFamiliar.alinteriordelaviviendase.includes('No')}
                        onChange={handleChange}
                      />
                      No
                    </label>
                  </div>
                  <div class="input-group">
                    <div class="input-group-text">
                        <input
                          type="checkbox"
                          class="form-check-input mt-0"
                          name="alinteriordelaviviendase"
                          value={otrosalinteriordelaviviendase}
                          checked={fichaFamiliar.alinteriordelaviviendase.includes(otrosalinteriordelaviviendase)}
                          onChange={handleChange}
                          //onChange={(e) => fichaFamiliar.alinteriordelaviviendase(e.target.checked)}
                        />
                      </div>
                      <input type="text" class="form-control" aria-label="Text input with checkbox" 
                        placeholder="Si, digite aqui las actividades"
                        value={otrosalinteriordelaviviendase}
                        name="otrosalinteriordelaviviendase"
                        //onChange={handleInputChange}
                        //onBlur={handleInputBlur}
                        onChange={(event) => handleInputChange(event, setOtrosAlinteriordelaviviendase)}
                        onBlur={() => handleInputBlur(otrosalinteriordelaviviendase, 'alinteriordelaviviendase')}
                        />
                  </div>
                  {!fichaFamiliar.alinteriordelaviviendase.length && <div className="text-danger">Campo obligatorio</div>}
                </div>
              </div> 
            </div>
            <div class="col-12 col-md-6 col-lg-4"> 
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">Si realiza una actividad económica, el area de trabajo esta separada y es independiente de las  otras áreas de la vivienda?</label>
                <select className={`form-select ${!fichaFamiliar.sirealizaunaactividadecon ? 'is-invalid' : ''}`} name='sirealizaunaactividadecon' value={fichaFamiliar.sirealizaunaactividadecon} onChange={handleChange}>
                  <option selected></option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </select>
                {!fichaFamiliar.sirealizaunaactividadecon && <div className="invalid-feedback">Campo obligatorio</div>}
              </div>  
            </div>
            <div class="col-12 col-md-6 col-lg-4">         
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">Algun miembro de la familia se ha visto afectado por la actividad económica que se realiza en la vivienda?</label>
                <select className={`form-select ${!fichaFamiliar.algunmiembrodelafamiliase ? 'is-invalid' : ''}`} name='algunmiembrodelafamiliase' value={fichaFamiliar.algunmiembrodelafamiliase} onChange={handleChange}>
                  <option selected></option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </select>
                {!fichaFamiliar.algunmiembrodelafamiliase && <div className="invalid-feedback">Campo obligatorio</div>}
              </div> 
            </div>
            <div class="col-12 col-md-6 col-lg-4"> 
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <div>
                  <label class="form-label">Desde la vivienda se puede acceder fácilmente a: (puede señalar varias opciones)</label>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="desdelaviviendasepuedeacc"
                        value="Medios de transporte (Buses, autos, camiones, lanchas, et)"
                        checked={fichaFamiliar.desdelaviviendasepuedeacc.includes('Medios de transporte (Buses, autos, camiones, lanchas, et)')}
                        onChange={handleChange}
                      />
                      Medios de transporte (Buses, autos, camiones, lanchas, et)
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="desdelaviviendasepuedeacc"
                        value="Centros sociales, culturales y/o recreacionales"
                        checked={fichaFamiliar.desdelaviviendasepuedeacc.includes('Centros sociales, culturales y/o recreacionales')}
                        onChange={handleChange}
                      />
                      Centros sociales, culturales y/o recreacionales
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="desdelaviviendasepuedeacc"
                        value="Parques, y áreas deportivas"
                        checked={fichaFamiliar.desdelaviviendasepuedeacc.includes('Parques, y áreas deportivas')}
                        onChange={handleChange}
                      />
                      Parques, y áreas deportivas
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="desdelaviviendasepuedeacc"
                        value="Iglesias, templos, espacios para cultos religiosos"
                        checked={fichaFamiliar.desdelaviviendasepuedeacc.includes('Iglesias, templos, espacios para cultos religiosos')}
                        onChange={handleChange}
                      />
                      Iglesias, templos, espacios para cultos religiosos
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="desdelaviviendasepuedeacc"
                        value="Instituciones educativas"
                        checked={fichaFamiliar.desdelaviviendasepuedeacc.includes('Instituciones educativas')}
                        onChange={handleChange}
                      />
                      Instituciones educativas
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="desdelaviviendasepuedeacc"
                        value="Servicios de salud"
                        checked={fichaFamiliar.desdelaviviendasepuedeacc.includes('Servicios de salud')}
                        onChange={handleChange}
                      />
                      Servicios de salud
                    </label>
                  </div>
                  <div class="input-group">
                    <div class="input-group-text">
                        <input
                          type="checkbox"
                          class="form-check-input mt-0"
                          name="desdelaviviendasepuedeacc"
                          value={otrosdesdelaviviendasepuedeacc}
                          checked={fichaFamiliar.desdelaviviendasepuedeacc.includes(otrosdesdelaviviendasepuedeacc)}
                          onChange={handleChange}
                          //onChange={(e) => fichaFamiliar.desdelaviviendasepuedeacc(e.target.checked)}
                        />
                      </div>
                      <input type="text" class="form-control" aria-label="Text input with checkbox" 
                        placeholder="Digite otras opciones de ser necesario"
                        value={otrosdesdelaviviendasepuedeacc}
                        name="otrosdesdelaviviendasepuedeacc"
                        //onChange={handleInputChange}
                        //onBlur={handleInputBlur}
                        onChange={(event) => handleInputChange(event, setOtrosDesdelaviviendasepuedeacc)}
                        onBlur={() => handleInputBlur(otrosdesdelaviviendasepuedeacc, 'desdelaviviendasepuedeacc')}
                        />
                  </div>
                  {!fichaFamiliar.desdelaviviendasepuedeacc.length && <div className="text-danger">Campo obligatorio</div>}
                </div>
              </div> 
            </div>
          </div>
        </div>
        <div class="m-2 mt-4 border border-primary-subtle"><h4 class="text"> 2.2. MOVILIDAD</h4>       
          <div class="row">
          <div class="col-12 col-md-6 col-lg-6">
              <div class="mb-4 p-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <div>
                  <label class="form-label">¿Como se desplazan generalmente los miembros de la familia hacia los lugares de trabajo, estudio, entre otros?</label>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="comosedesplazangeneralmen"
                        value="Vehículo particular"
                        checked={fichaFamiliar.comosedesplazangeneralmen.includes('Vehículo particular')}
                        onChange={handleChange}
                      />
                      Vehículo particular
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="comosedesplazangeneralmen"
                        value="Servicio público de transporte masivo (Articulados, metro, servicios de alimentación)"
                        checked={fichaFamiliar.comosedesplazangeneralmen.includes('Servicio público de transporte masivo (Articulados, metro, servicios de alimentación)')}
                        onChange={handleChange}
                      />
                      Servicio público de transporte masivo (Articulados, metro, servicios de alimentación)
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="comosedesplazangeneralmen"
                        value="Servicio público - Bus, buseta, colectivo"
                        checked={fichaFamiliar.comosedesplazangeneralmen.includes('Servicio público - Bus, buseta, colectivo')}
                        onChange={handleChange}
                      />
                      Servicio público - Bus, buseta, colectivo
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="comosedesplazangeneralmen"
                        value="Servicio público - Taxi"
                        checked={fichaFamiliar.comosedesplazangeneralmen.includes('Servicio público - Taxi')}
                        onChange={handleChange}
                      />
                      Servicio público - Taxi
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="comosedesplazangeneralmen"
                        value="Motocicleta"
                        checked={fichaFamiliar.comosedesplazangeneralmen.includes('Motocicleta')}
                        onChange={handleChange}
                      />
                      Motocicleta
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="comosedesplazangeneralmen"
                        value="Bicicleta"
                        checked={fichaFamiliar.comosedesplazangeneralmen.includes('Bicicleta')}
                        onChange={handleChange}
                      />
                      Bicicleta
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="comosedesplazangeneralmen"
                        value="Bicitaxi"
                        checked={fichaFamiliar.comosedesplazangeneralmen.includes('Bicitaxi')}
                        onChange={handleChange}
                      />
                      Bicitaxi
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="comosedesplazangeneralmen"
                        value="Semovientes"
                        checked={fichaFamiliar.comosedesplazangeneralmen.includes('Semovientes')}
                        onChange={handleChange}
                      />
                      Semovientes
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="comosedesplazangeneralmen"
                        value="Maquinaria agrícola o camión"
                        checked={fichaFamiliar.comosedesplazangeneralmen.includes('Maquinaria agrícola o camión')}
                        onChange={handleChange}
                      />
                      Maquinaria agrícola o camión
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="comosedesplazangeneralmen"
                        value="Tarabita, cable aereo artesanal"
                        checked={fichaFamiliar.comosedesplazangeneralmen.includes('Tarabita, cable aereo artesanal')}
                        onChange={handleChange}
                      />
                      Tarabita, cable aereo artesanal
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="comosedesplazangeneralmen"
                        value="Canoa, lancha, chalupa, piragua"
                        checked={fichaFamiliar.comosedesplazangeneralmen.includes('Canoa, lancha, chalupa, piragua')}
                        onChange={handleChange}
                      />
                      Canoa, lancha, chalupa, piragua
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="comosedesplazangeneralmen"
                        value="Caminando"
                        checked={fichaFamiliar.comosedesplazangeneralmen.includes('Caminando')}
                        onChange={handleChange}
                      />
                      Caminando
                    </label>
                  </div>
                  <div class="input-group">
                    <div class="input-group-text">
                        <input
                          type="checkbox"
                          class="form-check-input mt-0"
                          name="comosedesplazangeneralmen"
                          value={otroscomosedesplazangeneralmen}
                          checked={fichaFamiliar.comosedesplazangeneralmen.includes(otroscomosedesplazangeneralmen)}
                          onChange={handleChange}
                          //onChange={(e) => fichaFamiliar.comosedesplazangeneralmen(e.target.checked)}
                        />
                      </div>
                      <input type="text" class="form-control" aria-label="Text input with checkbox" 
                        placeholder="Digite otras opciones de ser necesario"
                        value={otroscomosedesplazangeneralmen}
                        name="otroscomosedesplazangeneralmen"
                        //onChange={handleInputChange}
                        //onBlur={handleInputBlur}
                        onChange={(event) => handleInputChange(event, setOtrosComosedesplazangeneralmen)}
                        onBlur={() => handleInputBlur(otroscomosedesplazangeneralmen, 'comosedesplazangeneralmen')}
                        />
                  </div>
                  {!fichaFamiliar.comosedesplazangeneralmen.length && <div className="text-danger">Campo obligatorio</div>}
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-6">
              <div class="mb-4 p-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <div>
                  <label class="form-label">¿Cuales elementos de seguridad utilizan los miembros de la familia para su medio de transporte?</label>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="cualeselementosdesegurida"
                        value="Casco"
                        checked={fichaFamiliar.cualeselementosdesegurida.includes('Casco')}
                        onChange={handleChange}
                      />
                      Casco
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="cualeselementosdesegurida"
                        value="Cinturon de seguridad"
                        checked={fichaFamiliar.cualeselementosdesegurida.includes('Cinturon de seguridad')}
                        onChange={handleChange}
                      />
                      Cinturon de seguridad
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="cualeselementosdesegurida"
                        value="Sistema de Retención Infantil"
                        checked={fichaFamiliar.cualeselementosdesegurida.includes('Sistema de Retención Infantil')}
                        onChange={handleChange}
                      />
                      Sistema de Retención Infantil
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="cualeselementosdesegurida"
                        value="Chaleco reflectivo"
                        checked={fichaFamiliar.cualeselementosdesegurida.includes('Chaleco reflectivo')}
                        onChange={handleChange}
                      />
                      Chaleco reflectivo
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="cualeselementosdesegurida"
                        value="Chaleco Salva Vidas"
                        checked={fichaFamiliar.cualeselementosdesegurida.includes('Chaleco Salva Vidas')}
                        onChange={handleChange}
                      />
                      Chaleco Salva Vidas
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="cualeselementosdesegurida"
                        value="Ninguno"
                        checked={fichaFamiliar.cualeselementosdesegurida.includes('Ninguno')}
                        onChange={handleChange}
                      />
                      Ninguno
                    </label>
                  </div>
                  <div class="input-group">
                    <div class="input-group-text">
                        <input
                          type="checkbox"
                          class="form-check-input mt-0"
                          name="cualeselementosdesegurida"
                          value={otroscualeselementosdesegurida}
                          checked={fichaFamiliar.cualeselementosdesegurida.includes(otroscualeselementosdesegurida)}
                          onChange={handleChange}
                          //onChange={(e) => fichaFamiliar.cualeselementosdesegurida(e.target.checked)}
                        />
                      </div>
                      <input type="text" class="form-control" aria-label="Text input with checkbox" 
                        placeholder="Digite otras opciones de ser necesario"
                        value={otroscualeselementosdesegurida}
                        name="otroscualeselementosdesegurida"
                        //onChange={handleInputChange}
                        //onBlur={handleInputBlur}
                        onChange={(event) => handleInputChange(event, setOtrosCualeselementosdesegurida)}
                        onBlur={() => handleInputBlur(otroscualeselementosdesegurida, 'cualeselementosdesegurida')}
                        />
                  </div>
                  {!fichaFamiliar.cualeselementosdesegurida.length && <div className="text-danger">Campo obligatorio</div>}
                </div>
              </div>          
            </div>
            <div class="col-12">
              <div class="mb-4 p-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <div class="row align-items-start">
                  <div class="col-md-4">
                    <div>
                      <label class="form-label">Si el tiempo de desplazamiento entre el hogar y los entornos educativos o laborales es mayor a 30 minutos, señale con X la razón por la cual el tiempo se extiende de esa manera: <b>[TRABAJO]</b></label>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="sieltiempodedesplazamienttra"
                            value="Estado de la infraestructura vial"
                            checked={fichaFamiliar.sieltiempodedesplazamienttra.includes('Estado de la infraestructura vial')}
                            onChange={handleChange}
                          />
                          Estado de la infraestructura vial
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="sieltiempodedesplazamienttra"
                            value="Congestión vial"
                            checked={fichaFamiliar.sieltiempodedesplazamienttra.includes('Congestión vial')}
                            onChange={handleChange}
                          />
                        Congestión vial
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="sieltiempodedesplazamienttra"
                            value="Disponibilidad de medios de transporte"
                            checked={fichaFamiliar.sieltiempodedesplazamienttra.includes('Disponibilidad de medios de transporte')}
                            onChange={handleChange}
                          />
                        Disponibilidad de medios de transporte
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="sieltiempodedesplazamienttra"
                            value="Distancias a recorrer"
                            checked={fichaFamiliar.sieltiempodedesplazamienttra.includes('Distancias a recorrer')}
                            onChange={handleChange}
                          />
                        Distancias a recorrer
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="sieltiempodedesplazamienttra"
                            value="Disponibilidad económica"
                            checked={fichaFamiliar.sieltiempodedesplazamienttra.includes('Disponibilidad económica')}
                            onChange={handleChange}
                          />
                        Disponibilidad económica
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="sieltiempodedesplazamienttra"
                            value="Otros"
                            checked={fichaFamiliar.sieltiempodedesplazamienttra.includes('Otros')}
                            onChange={handleChange}
                          />
                        Otros
                        </label>
                      </div>
                      {/* {!fichaFamiliar.sieltiempodedesplazamienttra.length && <div className="text-danger">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div>
                      <label class="form-label">Si el tiempo de desplazamiento entre el hogar y los entornos educativos o laborales es mayor a 30 minutos, señale con X la razón por la cual el tiempo se extiende de esa manera: <b>[ESTUDIO]</b></label>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="sieltiempodedesplazamientest"
                            value="Estado de la infraestructura vial"
                            checked={fichaFamiliar.sieltiempodedesplazamientest.includes('Estado de la infraestructura vial')}
                            onChange={handleChange}
                          />
                          Estado de la infraestructura vial
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="sieltiempodedesplazamientest"
                            value="Congestión vial"
                            checked={fichaFamiliar.sieltiempodedesplazamientest.includes('Congestión vial')}
                            onChange={handleChange}
                          />
                        Congestión vial
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="sieltiempodedesplazamientest"
                            value="Disponibilidad de medios de transporte"
                            checked={fichaFamiliar.sieltiempodedesplazamientest.includes('Disponibilidad de medios de transporte')}
                            onChange={handleChange}
                          />
                        Disponibilidad de medios de transporte
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="sieltiempodedesplazamientest"
                            value="Distancias a recorrer"
                            checked={fichaFamiliar.sieltiempodedesplazamientest.includes('Distancias a recorrer')}
                            onChange={handleChange}
                          />
                        Distancias a recorrer
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="sieltiempodedesplazamientest"
                            value="Disponibilidad económica"
                            checked={fichaFamiliar.sieltiempodedesplazamientest.includes('Disponibilidad económica')}
                            onChange={handleChange}
                          />
                        Disponibilidad económica
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="sieltiempodedesplazamientest"
                            value="Otros"
                            checked={fichaFamiliar.sieltiempodedesplazamientest.includes('Otros')}
                            onChange={handleChange}
                          />
                        Otros
                        </label>
                      </div>
                      {/* {!fichaFamiliar.sieltiempodedesplazamientest.length && <div className="text-danger">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div>
                      <label class="form-label">Si el tiempo de desplazamiento entre el hogar y los entornos educativos o laborales es mayor a 30 minutos, señale con X la razón por la cual el tiempo se extiende de esa manera: <b>[OTRAS ACTIVIDADES]</b></label>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="sieltiempodedesplazamientotr"
                            value="Estado de la infraestructura vial"
                            checked={fichaFamiliar.sieltiempodedesplazamientotr.includes('Estado de la infraestructura vial')}
                            onChange={handleChange}
                          />
                          Estado de la infraestructura vial
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="sieltiempodedesplazamientotr"
                            value="Congestión vial"
                            checked={fichaFamiliar.sieltiempodedesplazamientotr.includes('Congestión vial')}
                            onChange={handleChange}
                          />
                        Congestión vial
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="sieltiempodedesplazamientotr"
                            value="Disponibilidad de medios de transporte"
                            checked={fichaFamiliar.sieltiempodedesplazamientotr.includes('Disponibilidad de medios de transporte')}
                            onChange={handleChange}
                          />
                        Disponibilidad de medios de transporte
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="sieltiempodedesplazamientotr"
                            value="Distancias a recorrer"
                            checked={fichaFamiliar.sieltiempodedesplazamientotr.includes('Distancias a recorrer')}
                            onChange={handleChange}
                          />
                        Distancias a recorrer
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="sieltiempodedesplazamientotr"
                            value="Disponibilidad económica"
                            checked={fichaFamiliar.sieltiempodedesplazamientotr.includes('Disponibilidad económica')}
                            onChange={handleChange}
                          />
                        Disponibilidad económica
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="sieltiempodedesplazamientotr"
                            value="Otros"
                            checked={fichaFamiliar.sieltiempodedesplazamientotr.includes('Otros')}
                            onChange={handleChange}
                          />
                        Otros
                        </label>
                      </div>
                      {/* {!fichaFamiliar.sieltiempodedesplazamientotr.length && <div className="text-danger">Campo obligatorio</div>} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="m-2 mt-4 border border-primary-subtle"><h4 class="text"> 2.3. ACCIDENTES EN EL HOGAR</h4>
          <div class="mb-4 p-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
            <label class="form-label">¿ En el último año alguna persona de la familia acudió a un servicio de urgencia por un accidente doméstico?</label>
            <select className={`form-select ${!fichaFamiliar.enelultimoanoalgunaperson ? 'is-invalid' : ''}`} name='enelultimoanoalgunaperson' value={fichaFamiliar.enelultimoanoalgunaperson} onChange={handleChange}>
              <option selected></option>
              <option value="Si">Si</option>
              <option value="No">No</option>
            </select>
            {!fichaFamiliar.enelultimoanoalgunaperson && <div className="invalid-feedback">Campo obligatorio</div>}
          </div>
          {showDivtipoaccidente && (
          <div class="mb-4 p-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
            <div class="row align-items-start">
              <div class="col-md-4">
                <div>
                  <label class="form-label">Primera infancia (0-5 años) </label>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidenteprimerainfa"
                        value="Heridas objetos cortopunzantes"
                        checked={fichaFamiliar.tipoaccidenteprimerainfa.includes('Heridas objetos cortopunzantes')}
                        onChange={handleChange}
                      />
                      Heridas objetos cortopunzantes
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidenteprimerainfa"
                        value="Intoxicación"
                        checked={fichaFamiliar.tipoaccidenteprimerainfa.includes('Intoxicación')}
                        onChange={handleChange}
                      />
                    Intoxicación
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidenteprimerainfa"
                        value="Quemadura"
                        checked={fichaFamiliar.tipoaccidenteprimerainfa.includes('Quemadura')}
                        onChange={handleChange}
                      />
                    Quemadura
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidenteprimerainfa"
                        value="Electrocución"
                        checked={fichaFamiliar.tipoaccidenteprimerainfa.includes('Electrocución')}
                        onChange={handleChange}
                      />
                    Electrocución
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidenteprimerainfa"
                        value="Asfixia respiratoria"
                        checked={fichaFamiliar.tipoaccidenteprimerainfa.includes('Asfixia respiratoria')}
                        onChange={handleChange}
                      />
                    Asfixia respiratoria
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidenteprimerainfa"
                        value="Golpes y atrapamientos"
                        checked={fichaFamiliar.tipoaccidenteprimerainfa.includes('Golpes y atrapamientos')}
                        onChange={handleChange}
                      />
                    Golpes y atrapamientos
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidenteprimerainfa"
                        value="Caída"
                        checked={fichaFamiliar.tipoaccidenteprimerainfa.includes('Caída')}
                        onChange={handleChange}
                      />
                    Caída
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidenteprimerainfa"
                        value="Ahogamientos o sumersiones"
                        checked={fichaFamiliar.tipoaccidenteprimerainfa.includes('Ahogamientos o sumersiones')}
                        onChange={handleChange}
                      />
                    Ahogamientos o sumersiones
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidenteprimerainfa"
                        value="Agresión animal"
                        checked={fichaFamiliar.tipoaccidenteprimerainfa.includes('Agresión animal')}
                        onChange={handleChange}
                      />
                    Agresión animal
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidenteprimerainfa"
                        value="Caída de alturas"
                        checked={fichaFamiliar.tipoaccidenteprimerainfa.includes('Caída de alturas')}
                        onChange={handleChange}
                      />
                    Caída de alturas
                    </label>
                  </div>
                  {/* {!fichaFamiliar.tipoaccidenteprimerainfa.length && <div className="text-danger">Campo obligatorio</div>} */}
                </div>
              </div>
              <div class="col-md-4">
                <div>
                  <label class="form-label">Infancia (6 - 11 años)</label>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidenteinfancia"
                        value="Heridas objetos cortopunzantes"
                        checked={fichaFamiliar.tipoaccidenteinfancia.includes('Heridas objetos cortopunzantes')}
                        onChange={handleChange}
                      />
                      Heridas objetos cortopunzantes
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidenteinfancia"
                        value="Intoxicación"
                        checked={fichaFamiliar.tipoaccidenteinfancia.includes('Intoxicación')}
                        onChange={handleChange}
                      />
                    Intoxicación
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidenteinfancia"
                        value="Quemadura"
                        checked={fichaFamiliar.tipoaccidenteinfancia.includes('Quemadura')}
                        onChange={handleChange}
                      />
                    Quemadura
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidenteinfancia"
                        value="Electrocución"
                        checked={fichaFamiliar.tipoaccidenteinfancia.includes('Electrocución')}
                        onChange={handleChange}
                      />
                    Electrocución
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidenteinfancia"
                        value="Asfixia respiratoria"
                        checked={fichaFamiliar.tipoaccidenteinfancia.includes('Asfixia respiratoria')}
                        onChange={handleChange}
                      />
                    Asfixia respiratoria
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidenteinfancia"
                        value="Golpes y atrapamientos"
                        checked={fichaFamiliar.tipoaccidenteinfancia.includes('Golpes y atrapamientos')}
                        onChange={handleChange}
                      />
                    Golpes y atrapamientos
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidenteinfancia"
                        value="Caída"
                        checked={fichaFamiliar.tipoaccidenteinfancia.includes('Caída')}
                        onChange={handleChange}
                      />
                    Caída
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidenteinfancia"
                        value="Ahogamientos o sumersiones"
                        checked={fichaFamiliar.tipoaccidenteinfancia.includes('Ahogamientos o sumersiones')}
                        onChange={handleChange}
                      />
                    Ahogamientos o sumersiones
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidenteinfancia"
                        value="Agresión animal"
                        checked={fichaFamiliar.tipoaccidenteinfancia.includes('Agresión animal')}
                        onChange={handleChange}
                      />
                    Agresión animal
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidenteinfancia"
                        value="Caída de alturas"
                        checked={fichaFamiliar.tipoaccidenteinfancia.includes('Caída de alturas')}
                        onChange={handleChange}
                      />
                    Caída de alturas
                    </label>
                  </div>
                  {/* {!fichaFamiliar.tipoaccidenteinfancia.length && <div className="text-danger">Campo obligatorio</div>} */}
                </div>
              </div>
              <div class="col-md-4">
                <div>
                  <label class="form-label">Adolescencia (12-18 años)</label>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidenteadolescenci"
                        value="Heridas objetos cortopunzantes"
                        checked={fichaFamiliar.tipoaccidenteadolescenci.includes('Heridas objetos cortopunzantes')}
                        onChange={handleChange}
                      />
                      Heridas objetos cortopunzantes
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidenteadolescenci"
                        value="Intoxicación"
                        checked={fichaFamiliar.tipoaccidenteadolescenci.includes('Intoxicación')}
                        onChange={handleChange}
                      />
                    Intoxicación
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidenteadolescenci"
                        value="Quemadura"
                        checked={fichaFamiliar.tipoaccidenteadolescenci.includes('Quemadura')}
                        onChange={handleChange}
                      />
                    Quemadura
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidenteadolescenci"
                        value="Electrocución"
                        checked={fichaFamiliar.tipoaccidenteadolescenci.includes('Electrocución')}
                        onChange={handleChange}
                      />
                    Electrocución
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidenteadolescenci"
                        value="Asfixia respiratoria"
                        checked={fichaFamiliar.tipoaccidenteadolescenci.includes('Asfixia respiratoria')}
                        onChange={handleChange}
                      />
                    Asfixia respiratoria
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidenteadolescenci"
                        value="Golpes y atrapamientos"
                        checked={fichaFamiliar.tipoaccidenteadolescenci.includes('Golpes y atrapamientos')}
                        onChange={handleChange}
                      />
                    Golpes y atrapamientos
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidenteadolescenci"
                        value="Caída"
                        checked={fichaFamiliar.tipoaccidenteadolescenci.includes('Caída')}
                        onChange={handleChange}
                      />
                    Caída
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidenteadolescenci"
                        value="Ahogamientos o sumersiones"
                        checked={fichaFamiliar.tipoaccidenteadolescenci.includes('Ahogamientos o sumersiones')}
                        onChange={handleChange}
                      />
                    Ahogamientos o sumersiones
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidenteadolescenci"
                        value="Agresión animal"
                        checked={fichaFamiliar.tipoaccidenteadolescenci.includes('Agresión animal')}
                        onChange={handleChange}
                      />
                    Agresión animal
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidenteadolescenci"
                        value="Caída de alturas"
                        checked={fichaFamiliar.tipoaccidenteadolescenci.includes('Caída de alturas')}
                        onChange={handleChange}
                      />
                    Caída de alturas
                    </label>
                  </div>
                  {/* {!fichaFamiliar.tipoaccidenteadolescenci.length && <div className="text-danger">Campo obligatorio</div>} */}
                </div>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-md-4">
                <div>
                  <label class="form-label">Juventud (14 - 26 años)</label>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidentejuventud"
                        value="Heridas objetos cortopunzantes"
                        checked={fichaFamiliar.tipoaccidentejuventud.includes('Heridas objetos cortopunzantes')}
                        onChange={handleChange}
                      />
                      Heridas objetos cortopunzantes
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidentejuventud"
                        value="Intoxicación"
                        checked={fichaFamiliar.tipoaccidentejuventud.includes('Intoxicación')}
                        onChange={handleChange}
                      />
                    Intoxicación
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidentejuventud"
                        value="Quemadura"
                        checked={fichaFamiliar.tipoaccidentejuventud.includes('Quemadura')}
                        onChange={handleChange}
                      />
                    Quemadura
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidentejuventud"
                        value="Electrocución"
                        checked={fichaFamiliar.tipoaccidentejuventud.includes('Electrocución')}
                        onChange={handleChange}
                      />
                    Electrocución
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidentejuventud"
                        value="Asfixia respiratoria"
                        checked={fichaFamiliar.tipoaccidentejuventud.includes('Asfixia respiratoria')}
                        onChange={handleChange}
                      />
                    Asfixia respiratoria
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidentejuventud"
                        value="Golpes y atrapamientos"
                        checked={fichaFamiliar.tipoaccidentejuventud.includes('Golpes y atrapamientos')}
                        onChange={handleChange}
                      />
                    Golpes y atrapamientos
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidentejuventud"
                        value="Caída"
                        checked={fichaFamiliar.tipoaccidentejuventud.includes('Caída')}
                        onChange={handleChange}
                      />
                    Caída
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidentejuventud"
                        value="Ahogamientos o sumersiones"
                        checked={fichaFamiliar.tipoaccidentejuventud.includes('Ahogamientos o sumersiones')}
                        onChange={handleChange}
                      />
                    Ahogamientos o sumersiones
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidentejuventud"
                        value="Agresión animal"
                        checked={fichaFamiliar.tipoaccidentejuventud.includes('Agresión animal')}
                        onChange={handleChange}
                      />
                    Agresión animal
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidentejuventud"
                        value="Caída de alturas"
                        checked={fichaFamiliar.tipoaccidentejuventud.includes('Caída de alturas')}
                        onChange={handleChange}
                      />
                    Caída de alturas
                    </label>
                  </div>
                  {/* {!fichaFamiliar.tipoaccidentejuventud.length && <div className="text-danger">Campo obligatorio</div>} */}
                </div>
              </div>
              <div class="col-md-4">
                <div>
                  <label class="form-label">Adultez (27- 59 años)</label>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidenteadultez"
                        value="Heridas objetos cortopunzantes"
                        checked={fichaFamiliar.tipoaccidenteadultez.includes('Heridas objetos cortopunzantes')}
                        onChange={handleChange}
                      />
                      Heridas objetos cortopunzantes
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidenteadultez"
                        value="Intoxicación"
                        checked={fichaFamiliar.tipoaccidenteadultez.includes('Intoxicación')}
                        onChange={handleChange}
                      />
                    Intoxicación
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidenteadultez"
                        value="Quemadura"
                        checked={fichaFamiliar.tipoaccidenteadultez.includes('Quemadura')}
                        onChange={handleChange}
                      />
                    Quemadura
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidenteadultez"
                        value="Electrocución"
                        checked={fichaFamiliar.tipoaccidenteadultez.includes('Electrocución')}
                        onChange={handleChange}
                      />
                    Electrocución
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidenteadultez"
                        value="Asfixia respiratoria"
                        checked={fichaFamiliar.tipoaccidenteadultez.includes('Asfixia respiratoria')}
                        onChange={handleChange}
                      />
                    Asfixia respiratoria
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidenteadultez"
                        value="Golpes y atrapamientos"
                        checked={fichaFamiliar.tipoaccidenteadultez.includes('Golpes y atrapamientos')}
                        onChange={handleChange}
                      />
                    Golpes y atrapamientos
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidenteadultez"
                        value="Caída"
                        checked={fichaFamiliar.tipoaccidenteadultez.includes('Caída')}
                        onChange={handleChange}
                      />
                    Caída
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidenteadultez"
                        value="Ahogamientos o sumersiones"
                        checked={fichaFamiliar.tipoaccidenteadultez.includes('Ahogamientos o sumersiones')}
                        onChange={handleChange}
                      />
                    Ahogamientos o sumersiones
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidenteadultez"
                        value="Agresión animal"
                        checked={fichaFamiliar.tipoaccidenteadultez.includes('Agresión animal')}
                        onChange={handleChange}
                      />
                    Agresión animal
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidenteadultez"
                        value="Caída de alturas"
                        checked={fichaFamiliar.tipoaccidenteadultez.includes('Caída de alturas')}
                        onChange={handleChange}
                      />
                    Caída de alturas
                    </label>
                  </div>
                  {/* {!fichaFamiliar.tipoaccidenteadultez.length && <div className="text-danger">Campo obligatorio</div>} */}
                </div>
              </div>
              <div class="col-md-4">
                <div>
                  <label class="form-label">Persona Mayor (60 años o mas)</label>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidentepersonamayo"
                        value="Heridas objetos cortopunzantes"
                        checked={fichaFamiliar.tipoaccidentepersonamayo.includes('Heridas objetos cortopunzantes')}
                        onChange={handleChange}
                      />
                      Heridas objetos cortopunzantes
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidentepersonamayo"
                        value="Intoxicación"
                        checked={fichaFamiliar.tipoaccidentepersonamayo.includes('Intoxicación')}
                        onChange={handleChange}
                      />
                    Intoxicación
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidentepersonamayo"
                        value="Quemadura"
                        checked={fichaFamiliar.tipoaccidentepersonamayo.includes('Quemadura')}
                        onChange={handleChange}
                      />
                    Quemadura
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidentepersonamayo"
                        value="Electrocución"
                        checked={fichaFamiliar.tipoaccidentepersonamayo.includes('Electrocución')}
                        onChange={handleChange}
                      />
                    Electrocución
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidentepersonamayo"
                        value="Asfixia respiratoria"
                        checked={fichaFamiliar.tipoaccidentepersonamayo.includes('Asfixia respiratoria')}
                        onChange={handleChange}
                      />
                    Asfixia respiratoria
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidentepersonamayo"
                        value="Golpes y atrapamientos"
                        checked={fichaFamiliar.tipoaccidentepersonamayo.includes('Golpes y atrapamientos')}
                        onChange={handleChange}
                      />
                    Golpes y atrapamientos
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidentepersonamayo"
                        value="Caída"
                        checked={fichaFamiliar.tipoaccidentepersonamayo.includes('Caída')}
                        onChange={handleChange}
                      />
                    Caída
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidentepersonamayo"
                        value="Ahogamientos o sumersiones"
                        checked={fichaFamiliar.tipoaccidentepersonamayo.includes('Ahogamientos o sumersiones')}
                        onChange={handleChange}
                      />
                    Ahogamientos o sumersiones
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidentepersonamayo"
                        value="Agresión animal"
                        checked={fichaFamiliar.tipoaccidentepersonamayo.includes('Agresión animal')}
                        onChange={handleChange}
                      />
                    Agresión animal
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="tipoaccidentepersonamayo"
                        value="Caída de alturas"
                        checked={fichaFamiliar.tipoaccidentepersonamayo.includes('Caída de alturas')}
                        onChange={handleChange}
                      />
                    Caída de alturas
                    </label>
                  </div>
                  {/* {!fichaFamiliar.tipoaccidentepersonamayo.length && <div className="text-danger">Campo obligatorio</div>} */}
                </div>
              </div>
            </div>
          </div> 
          )} 
          <div class="mb-4 p-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
            <div>
              <label class="form-label">Se identifican algunos de los siguientes escenarios de riesgo de accidente en la vivienda (Señale con x)</label>
              <div class="form-check">
                <label class="form-check-label">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    name="seidentificanalgunosdelos"
                    value="Objetos cortantes o punzantes al alcance de los niños"
                    checked={fichaFamiliar.seidentificanalgunosdelos.includes('Objetos cortantes o punzantes al alcance de los niños')}
                    onChange={handleChange}
                  />
                  Objetos cortantes o punzantes al alcance de los niños
                </label>
              </div>
              <div class="form-check">
                <label class="form-check-label">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    name="seidentificanalgunosdelos"
                    value="Sustancias químicas al alcance de los niños"
                    checked={fichaFamiliar.seidentificanalgunosdelos.includes('Sustancias químicas al alcance de los niños')}
                    onChange={handleChange}
                  />
                  Sustancias químicas al alcance de los niños
                </label>
              </div>
              <div class="form-check">
                <label class="form-check-label">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    name="seidentificanalgunosdelos"
                    value="Medicamentos al alcance de los niños"
                    checked={fichaFamiliar.seidentificanalgunosdelos.includes('Medicamentos al alcance de los niños')}
                    onChange={handleChange}
                  />
                  Medicamentos al alcance de los niños
                </label>
              </div>
              <div class="form-check">
                <label class="form-check-label">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    name="seidentificanalgunosdelos"
                    value="Velas, velones, incienso encendidos en la vivienda"
                    checked={fichaFamiliar.seidentificanalgunosdelos.includes('Velas, velones, incienso encendidos en la vivienda')}
                    onChange={handleChange}
                  />
                  Velas, velones, incienso encendidos en la vivienda
                </label>
              </div>
              <div class="form-check">
                <label class="form-check-label">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    name="seidentificanalgunosdelos"
                    value="Conexiones eléctricas en mal estado o sobrecargadas"
                    checked={fichaFamiliar.seidentificanalgunosdelos.includes('Conexiones eléctricas en mal estado o sobrecargadas')}
                    onChange={handleChange}
                  />
                  Conexiones eléctricas en mal estado o sobrecargadas
                </label>
              </div>
              <div class="form-check">
                <label class="form-check-label">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    name="seidentificanalgunosdelos"
                    value="Botones, canicas entre otros objetos pequeños o con piezas que puedan desmontarse, al alcance de los niños"
                    checked={fichaFamiliar.seidentificanalgunosdelos.includes('Botones, canicas entre otros objetos pequeños o con piezas que puedan desmontarse, al alcance de los niños')}
                    onChange={handleChange}
                  />
                  Botones, canicas entre otros objetos pequeños o con piezas que puedan desmontarse, al alcance de los niños
                </label>
              </div>
              <div class="form-check">
                <label class="form-check-label">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    name="seidentificanalgunosdelos"
                    value="Pasillos obstruidos con juguetes, sillas u otros objetos"
                    checked={fichaFamiliar.seidentificanalgunosdelos.includes('Pasillos obstruidos con juguetes, sillas u otros objetos')}
                    onChange={handleChange}
                  />
                  Pasillos obstruidos con juguetes, sillas u otros objetos
                </label>
              </div>
              <div class="form-check">
                <label class="form-check-label">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    name="seidentificanalgunosdelos"
                    value="Superficies resbaladizas, suelos con agua, grasas, aceites, entre otros"
                    checked={fichaFamiliar.seidentificanalgunosdelos.includes('Superficies resbaladizas, suelos con agua, grasas, aceites, entre otros')}
                    onChange={handleChange}
                  />
                  Superficies resbaladizas, suelos con agua, grasas, aceites, entre otros
                </label>
              </div>
              <div class="form-check">
                <label class="form-check-label">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    name="seidentificanalgunosdelos"
                    value="Tanques o recipientes de almacenamiento de agua sin tapa"
                    checked={fichaFamiliar.seidentificanalgunosdelos.includes('Tanques o recipientes de almacenamiento de agua sin tapa')}
                    onChange={handleChange}
                  />
                  Tanques o recipientes de almacenamiento de agua sin tapa
                </label>
              </div>
              <div class="form-check">
                <label class="form-check-label">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    name="seidentificanalgunosdelos"
                    value="Escaleras sin protección"
                    checked={fichaFamiliar.seidentificanalgunosdelos.includes('Escaleras sin protección')}
                    onChange={handleChange}
                  />
                  Escaleras sin protección
                </label>
              </div>
              <div class="input-group">
                <div class="input-group-text">
                    <input
                      type="checkbox"
                      class="form-check-input mt-0"
                      name="seidentificanalgunosdelos"
                      value={otrosseidentificanalgunosdelos}
                      checked={fichaFamiliar.seidentificanalgunosdelos.includes(otrosseidentificanalgunosdelos)}
                      onChange={handleChange}
                      //onChange={(e) => fichaFamiliar.seidentificanalgunosdelos(e.target.checked)}
                    />
                  </div>
                  <input type="text" class="form-control" aria-label="Text input with checkbox" 
                    placeholder="Digite otras opciones de ser necesario"
                    value={otrosseidentificanalgunosdelos}
                    name="otrosseidentificanalgunosdelos"
                    //onChange={handleInputChange}
                    //onBlur={handleInputBlur}
                    onChange={(event) => handleInputChange(event, setOtrosSeidentificanalgunosdelos)}
                    onBlur={() => handleInputBlur(otrosseidentificanalgunosdelos, 'seidentificanalgunosdelos')}
                    />
              </div>
              {!fichaFamiliar.seidentificanalgunosdelos.length && <div className="text-danger">Campo obligatorio</div>}
            </div>
          </div>   
        </div>
        <div class="m-2 mt-4 border border-primary-subtle"><h4 class="text"> 2.4. AGUA Y SANEAMIENTO BASICO</h4>       
          <div class="row">
            <div class="col-12 col-md-6 col-lg-4">
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <div>
                  <label class="form-label">¿Cual es la principal fuente de abastecimiento de agua para consumo humano en la vivienda?</label>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="cualeslaprincipalfuentede"
                        value="Acueducto administrado por empresa prestadora (ESP)"
                        checked={fichaFamiliar.cualeslaprincipalfuentede.includes('Acueducto administrado por empresa prestadora (ESP)')}
                        onChange={handleChange}
                      />
                      Acueducto administrado por empresa prestadora (ESP)
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="cualeslaprincipalfuentede"
                        value="Agua embotellada o en bolsa"
                        checked={fichaFamiliar.cualeslaprincipalfuentede.includes('Agua embotellada o en bolsa')}
                        onChange={handleChange}
                      />
                      Agua embotellada o en bolsa
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="cualeslaprincipalfuentede"
                        value="Acueducto veredal o comunitario"
                        checked={fichaFamiliar.cualeslaprincipalfuentede.includes('Acueducto veredal o comunitario')}
                        onChange={handleChange}
                      />
                      Acueducto veredal o comunitario
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="cualeslaprincipalfuentede"
                        value="Pila pública"
                        checked={fichaFamiliar.cualeslaprincipalfuentede.includes('Pila pública')}
                        onChange={handleChange}
                      />
                      Pila pública
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="cualeslaprincipalfuentede"
                        value="Carro tanque"
                        checked={fichaFamiliar.cualeslaprincipalfuentede.includes('Carro tanque')}
                        onChange={handleChange}
                      />
                      Carro tanque
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="cualeslaprincipalfuentede"
                        value="Abasto con distribución comunitaria"
                        checked={fichaFamiliar.cualeslaprincipalfuentede.includes('Abasto con distribución comunitaria')}
                        onChange={handleChange}
                      />
                      Abasto con distribución comunitaria
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="cualeslaprincipalfuentede"
                        value="Pozo con bomba"
                        checked={fichaFamiliar.cualeslaprincipalfuentede.includes('Pozo con bomba')}
                        onChange={handleChange}
                      />
                      Pozo con bomba
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="cualeslaprincipalfuentede"
                        value="Pozo sin bomba, aljibe, jagüey o barreno"
                        checked={fichaFamiliar.cualeslaprincipalfuentede.includes('Pozo sin bomba, aljibe, jagüey o barreno')}
                        onChange={handleChange}
                      />
                      Pozo sin bomba, aljibe, jagüey o barreno
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="cualeslaprincipalfuentede"
                        value="Laguna o jagüey"
                        checked={fichaFamiliar.cualeslaprincipalfuentede.includes('Laguna o jagüey')}
                        onChange={handleChange}
                      />
                      Laguna o jagüey
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="cualeslaprincipalfuentede"
                        value="Río, quebrada, manantial o nacimiento"
                        checked={fichaFamiliar.cualeslaprincipalfuentede.includes('Río, quebrada, manantial o nacimiento')}
                        onChange={handleChange}
                      />
                      Río, quebrada, manantial o nacimiento
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="cualeslaprincipalfuentede"
                        value="Aguas lluvias"
                        checked={fichaFamiliar.cualeslaprincipalfuentede.includes('Aguas lluvias')}
                        onChange={handleChange}
                      />
                      Aguas lluvias
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="cualeslaprincipalfuentede"
                        value="Aguatero"
                        checked={fichaFamiliar.cualeslaprincipalfuentede.includes('Aguatero')}
                        onChange={handleChange}
                      />
                      Aguatero
                    </label>
                  </div>
                  <div class="input-group">
                    <div class="input-group-text">
                        <input
                          type="checkbox"
                          class="form-check-input mt-0"
                          name="cualeslaprincipalfuentede"
                          value={otroscualeslaprincipalfuentede}
                          checked={fichaFamiliar.cualeslaprincipalfuentede.includes(otroscualeslaprincipalfuentede)}
                          onChange={handleChange}
                          //onChange={(e) => fichaFamiliar.cualeslaprincipalfuentede(e.target.checked)}
                        />
                      </div>
                      <input type="text" class="form-control" aria-label="Text input with checkbox" 
                        placeholder="Digite otras opciones de ser necesario"
                        value={otroscualeslaprincipalfuentede}
                        name="otroscualeslaprincipalfuentede"
                        //onChange={handleInputChange}
                        //onBlur={handleInputBlur}
                        onChange={(event) => handleInputChange(event, setOtrosCualeslaprincipalfuentede)}
                        onBlur={() => handleInputBlur(otroscualeslaprincipalfuentede, 'cualeslaprincipalfuentede')}
                        />
                  </div>
                  {!fichaFamiliar.cualeslaprincipalfuentede.length && <div className="text-danger">Campo obligatorio</div>}
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">        
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">¿Cuántas horas al día cuenta con suministro de agua?</label>
                <select className={`form-select ${!fichaFamiliar.cuantashorasaldiacuentaco ? 'is-invalid' : ''}`} name='cuantashorasaldiacuentaco' value={fichaFamiliar.cuantashorasaldiacuentaco} onChange={handleChange}>
                  <option selected></option>
                  <option value="24 horas">24 horas</option>
                  <option value="Entre 12 a 23 horas">Entre 12 a 23 horas</option>
                  <option value="Entre 4 y 12 horas">Entre 4 y 12 horas</option>
                  <option value="Menor a 4 horas">Menor a 4 horas</option>
                  <option value="Se presentan días sin suministro">Se presentan días sin suministro</option>
                </select>
                {!fichaFamiliar.cuantashorasaldiacuentaco && <div className="invalid-feedback">Campo obligatorio</div>}
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <div>
                  <label class="form-label">Se cuenta con tanque de almacenamiento de agua</label>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="secuentacontanquedealmace"
                        value="Aereo"
                        checked={fichaFamiliar.secuentacontanquedealmace.includes('Aereo')}
                        onChange={handleChange}
                      />
                      Aereo
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="secuentacontanquedealmace"
                        value="Superficial"
                        checked={fichaFamiliar.secuentacontanquedealmace.includes('Superficial')}
                        onChange={handleChange}
                      />
                      Superficial
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="secuentacontanquedealmace"
                        value="Subterráneo"
                        checked={fichaFamiliar.secuentacontanquedealmace.includes('Subterráneo')}
                        onChange={handleChange}
                      />
                      Subterráneo
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="secuentacontanquedealmace"
                        value="No tiene tanque de almancenamiento"
                        checked={fichaFamiliar.secuentacontanquedealmace.includes('No tiene tanque de almancenamiento')}
                        onChange={handleChange}
                      />
                      No tiene tanque de almancenamiento
                    </label>
                  </div>
                  {!fichaFamiliar.secuentacontanquedealmace.length && <div className="text-danger">Campo obligatorio</div>}
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <div>
                  <label class="form-label">Se realiza alguno de los siguientes tipos de tratamiento casero al agua antes del consumo</label>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="serealizaalgunodelossigui"
                        value="Asentamiento de solidos suspendidos o clarificación"
                        checked={fichaFamiliar.serealizaalgunodelossigui.includes('Asentamiento de solidos suspendidos o clarificación')}
                        onChange={handleChange}
                      />
                      Asentamiento de solidos suspendidos o clarificación
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="serealizaalgunodelossigui"
                        value="Filtración"
                        checked={fichaFamiliar.serealizaalgunodelossigui.includes('Filtración')}
                        onChange={handleChange}
                      />
                      Filtración
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="serealizaalgunodelossigui"
                        value="Desinfección con cloro o con otras sustancias"
                        checked={fichaFamiliar.serealizaalgunodelossigui.includes('Desinfección con cloro o con otras sustancias')}
                        onChange={handleChange}
                      />
                      Desinfección con cloro o con otras sustancias
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="serealizaalgunodelossigui"
                        value="Hervido"
                        checked={fichaFamiliar.serealizaalgunodelossigui.includes('Hervido')}
                        onChange={handleChange}
                      />
                      Hervido
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="serealizaalgunodelossigui"
                        value="Ninguno"
                        checked={fichaFamiliar.serealizaalgunodelossigui.includes('Ninguno')}
                        onChange={handleChange}
                      />
                      Ninguno
                    </label>
                  </div>
                  <div class="input-group">
                    <div class="input-group-text">
                        <input
                          type="checkbox"
                          class="form-check-input mt-0"
                          name="serealizaalgunodelossigui"
                          value={otrosserealizaalgunodelossigui}
                          checked={fichaFamiliar.serealizaalgunodelossigui.includes(otrosserealizaalgunodelossigui)}
                          onChange={handleChange}
                          //onChange={(e) => fichaFamiliar.serealizaalgunodelossigui(e.target.checked)}
                        />
                      </div>
                      <input type="text" class="form-control" aria-label="Text input with checkbox" 
                        placeholder="Digite otras opciones de ser necesario"
                        value={otrosserealizaalgunodelossigui}
                        name="otrosserealizaalgunodelossigui"
                        //onChange={handleInputChange}
                        //onBlur={handleInputBlur}
                        onChange={(event) => handleInputChange(event, setOtrosSerealizaalgunodelossigui)}
                        onBlur={() => handleInputBlur(otrosserealizaalgunodelossigui, 'serealizaalgunodelossigui')}
                        />
                  </div>
                  {!fichaFamiliar.serealizaalgunodelossigui.length && <div className="text-danger">Campo obligatorio</div>}
                </div>
              </div>    
            </div>
            <div class="col-12 col-md-6 col-lg-4">              
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">¿Como se realiza el almacenamiento de agua para consumo humano en la vivienda?</label>
                <select className={`form-select ${!fichaFamiliar.comoserealizaelalmacenami ? 'is-invalid' : ''}`} name='comoserealizaelalmacenami' value={fichaFamiliar.comoserealizaelalmacenami} onChange={handleChange}>
                  <option selected></option>
                  <option value="Aereo">Aereo</option>
                  <option value="Superficial">Superficial</option>
                  <option value="Subterráneo">Subterráneo</option>
                  <option value="No tiene tanque de almancenamiento">No tiene tanque de almancenamiento</option>
                </select>
                {!fichaFamiliar.comoserealizaelalmacenami && <div className="invalid-feedback">Campo obligatorio</div>}
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <div>
                  <label class="form-label">¿Cual es la frecuencia de lavado del tanque de almacenamiento de agua para consumo humano en  la vivienda?</label>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="cualeslafrecuenciadelavad"
                        value="Despues de cada uso"
                        checked={fichaFamiliar.cualeslafrecuenciadelavad.includes('Despues de cada uso')}
                        onChange={handleChange}
                      />
                      Despues de cada uso
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="cualeslafrecuenciadelavad"
                        value="Semestral"
                        checked={fichaFamiliar.cualeslafrecuenciadelavad.includes('Semestral')}
                        onChange={handleChange}
                      />
                      Semestral
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="cualeslafrecuenciadelavad"
                        value="No realiza lavado"
                        checked={fichaFamiliar.cualeslafrecuenciadelavad.includes('No realiza lavado')}
                        onChange={handleChange}
                      />
                      No realiza lavado
                    </label>
                  </div>
                  <div class="input-group">
                    <div class="input-group-text">
                        <input
                          type="checkbox"
                          class="form-check-input mt-0"
                          name="cualeslafrecuenciadelavad"
                          value={otroscualeslafrecuenciadelavad}
                          checked={fichaFamiliar.cualeslafrecuenciadelavad.includes(otroscualeslafrecuenciadelavad)}
                          onChange={handleChange}
                          //onChange={(e) => fichaFamiliar.cualeslafrecuenciadelavad(e.target.checked)}
                        />
                      </div>
                      <input type="text" class="form-control" aria-label="Text input with checkbox" 
                        placeholder="Digite otras opciones de ser necesario"
                        value={otroscualeslafrecuenciadelavad}
                        name="otroscualeslafrecuenciadelavad"
                        //onChange={handleInputChange}
                        //onBlur={handleInputBlur}
                        onChange={(event) => handleInputChange(event, setOtrosCualeslafrecuenciadelavad)}
                        onBlur={() => handleInputBlur(otroscualeslafrecuenciadelavad, 'cualeslafrecuenciadelavad')}
                        />
                  </div>
                  {!fichaFamiliar.cualeslafrecuenciadelavad.length && <div className="text-danger">Campo obligatorio</div>}
                </div>
              </div>  
            </div>
            <div class="col-12 col-md-6 col-lg-4">                
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">La distancia del tanque subterráneo de almacenamiento en relación al pozo séptico o punto de entrega al alcantarillado es:</label>
                <select className={`form-select ${!fichaFamiliar.ladistanciadeltanquesubte ? 'is-invalid' : ''}`} name='ladistanciadeltanquesubte' value={fichaFamiliar.ladistanciadeltanquesubte} onChange={handleChange}>
                  <option selected></option>
                  <option value="Mas de 10 metros">Mas de 10 metros</option>
                  <option value="Entre 5 y 9 metros">Entre 5 y 9 metros</option>
                  <option value="Menor a 5 metros">Menor a 5 metros</option>
                </select>
                {!fichaFamiliar.ladistanciadeltanquesubte && <div className="invalid-feedback">Campo obligatorio</div>}
              </div> 
            </div>
          </div>     
        </div>
        <div class="m-2 mt-4 border border-primary-subtle"><h4 class="text"> 2.5.	SANEAMIENTO BASICO</h4>            
          <div class="row">
            <div class="col-12 col-md-6 col-lg-4">  
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <div>
                  <label class="form-label">¿Que sistema de disposición de excretas tiene la vivienda?</label>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="quesistemadedisposiciondeex"
                        value="Sanitario conectado al alcantarillado"
                        checked={fichaFamiliar.quesistemadedisposiciondeex.includes('Sanitario conectado al alcantarillado')}
                        onChange={handleChange}
                      />
                      Sanitario conectado al alcantarillado
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="quesistemadedisposiciondeex"
                        value="Sanitario y letrina"
                        checked={fichaFamiliar.quesistemadedisposiciondeex.includes('Sanitario y letrina')}
                        onChange={handleChange}
                      />
                      Sanitario y letrina
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="quesistemadedisposiciondeex"
                        value="Sanitario conectado a pozo séptico"
                        checked={fichaFamiliar.quesistemadedisposiciondeex.includes('Sanitario conectado a pozo séptico')}
                        onChange={handleChange}
                      />
                      Sanitario conectado a pozo séptico
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="quesistemadedisposiciondeex"
                        value="Sanitario ecológico seco"
                        checked={fichaFamiliar.quesistemadedisposiciondeex.includes('Sanitario ecológico seco')}
                        onChange={handleChange}
                      />
                      Sanitario ecológico seco
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="quesistemadedisposiciondeex"
                        value="Sanitario sin conexión"
                        checked={fichaFamiliar.quesistemadedisposiciondeex.includes('Sanitario sin conexión')}
                        onChange={handleChange}
                      />
                      Sanitario sin conexión
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="quesistemadedisposiciondeex"
                        value="Sanitario con disposición a fuente hídrica"
                        checked={fichaFamiliar.quesistemadedisposiciondeex.includes('Sanitario con disposición a fuente hídrica')}
                        onChange={handleChange}
                      />
                      Sanitario con disposición a fuente hídrica
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="quesistemadedisposiciondeex"
                        value="Campo abierto"
                        checked={fichaFamiliar.quesistemadedisposiciondeex.includes('Campo abierto')}
                        onChange={handleChange}
                      />
                      Campo abierto
                    </label>
                  </div>
                  <div class="input-group">
                    <div class="input-group-text">
                        <input
                          type="checkbox"
                          class="form-check-input mt-0"
                          name="quesistemadedisposiciondeex"
                          value={otrosquesistemadedisposiciondeex}
                          checked={fichaFamiliar.quesistemadedisposiciondeex.includes(otrosquesistemadedisposiciondeex)}
                          onChange={handleChange}
                          //onChange={(e) => fichaFamiliar.quesistemadedisposiciondeex(e.target.checked)}
                        />
                      </div>
                      <input type="text" class="form-control" aria-label="Text input with checkbox" 
                        placeholder="Digite otras opciones de ser necesario"
                        value={otrosquesistemadedisposiciondeex}
                        name="otrosquesistemadedisposiciondeex"
                        //onChange={handleInputChange}
                        //onBlur={handleInputBlur}
                        onChange={(event) => handleInputChange(event, setOtrosQuesistemadedisposiciondeex)}
                        onBlur={() => handleInputBlur(otrosquesistemadedisposiciondeex, 'quesistemadedisposiciondeex')}
                        />
                  </div>
                  {!fichaFamiliar.quesistemadedisposiciondeex.length && <div className="text-danger">Campo obligatorio</div>}
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <div>
                  <label class="form-label">¿Que sistema de disposición de aguas residuales domesticas tiene la vivienda?</label>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="quesistemadedisposiciondeag"
                        value="Pozo séptico"
                        checked={fichaFamiliar.quesistemadedisposiciondeag.includes('Pozo séptico')}
                        onChange={handleChange}
                      />
                      Pozo séptico
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="quesistemadedisposiciondeag"
                        value="Campo de oxidación"
                        checked={fichaFamiliar.quesistemadedisposiciondeag.includes('Campo de oxidación')}
                        onChange={handleChange}
                      />
                      Campo de oxidación
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="quesistemadedisposiciondeag"
                        value="Biofiltro"
                        checked={fichaFamiliar.quesistemadedisposiciondeag.includes('Biofiltro')}
                        onChange={handleChange}
                      />
                      Biofiltro
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="quesistemadedisposiciondeag"
                        value="Fuente hídrica"
                        checked={fichaFamiliar.quesistemadedisposiciondeag.includes('Fuente hídrica')}
                        onChange={handleChange}
                      />
                      Fuente hídrica
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="quesistemadedisposiciondeag"
                        value="Campo abierto"
                        checked={fichaFamiliar.quesistemadedisposiciondeag.includes('Campo abierto')}
                        onChange={handleChange}
                      />
                      Campo abierto
                    </label>
                  </div>
                  <div class="input-group">
                    <div class="input-group-text">
                        <input
                          type="checkbox"
                          class="form-check-input mt-0"
                          name="quesistemadedisposiciondeag"
                          value={otrosquesistemadedisposiciondeag}
                          checked={fichaFamiliar.quesistemadedisposiciondeag.includes(otrosquesistemadedisposiciondeag)}
                          onChange={handleChange}
                          //onChange={(e) => fichaFamiliar.quesistemadedisposiciondeag(e.target.checked)}
                        />
                      </div>
                      <input type="text" class="form-control" aria-label="Text input with checkbox" 
                        placeholder="Digite otras opciones de ser necesario"
                        value={otrosquesistemadedisposiciondeag}
                        name="otrosquesistemadedisposiciondeag"
                        //onChange={handleInputChange}
                        //onBlur={handleInputBlur}
                        onChange={(event) => handleInputChange(event, setOtrosQuesistemadedisposiciondeag)}
                        onBlur={() => handleInputBlur(otrosquesistemadedisposiciondeag, 'quesistemadedisposiciondeag')}
                        />
                  </div>
                  {!fichaFamiliar.quesistemadedisposiciondeag.length && <div className="text-danger">Campo obligatorio</div>}
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">Indique a que distancia esta ubicado el sistema de disposición de excretas y/o aguas residuales  domesticas que tiene la vivienda con respecto a: VIVIENDA</label>
                <input
                  type="number"
                  name='indiqueaquedistanciaestauvi'
                  className='form-control'
                  // className={`form-control ${!fichaFamiliar.indiqueaquedistanciaestauvi ? 'is-invalid' : ''}`}
                  value={fichaFamiliar.indiqueaquedistanciaestauvi}
                  onChange={handleChange}
                />
                {/* {!fichaFamiliar.indiqueaquedistanciaestauvi && <div className="invalid-feedback">Campo obligatorio</div>} */}
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">Indique a que distancia esta ubicado el sistema de disposición de excretas y/o aguas residuales  domesticas que tiene la vivienda con respecto a: Fuentes de abastecimiento de agua superficial o subterranea</label>
                <input
                  type="number"
                  name='indiqueaquedistanciaestaufu'
                  className='form-control'
                  // className={`form-control ${!fichaFamiliar.indiqueaquedistanciaestaufu ? 'is-invalid' : ''}`}
                  value={fichaFamiliar.indiqueaquedistanciaestaufu}
                  onChange={handleChange}
                />
                {/* {!fichaFamiliar.indiqueaquedistanciaestaufu && <div className="invalid-feedback">Campo obligatorio</div>} */}
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">Indique a que distancia esta ubicado el sistema de disposición de excretas y/o aguas residuales  domesticas que tiene la vivienda con respecto a: Rios y Quebradas</label>
                <input
                  type="number"
                  name='indiqueaquedistanciaestauri'
                  className='form-control'
                  // className={`form-control ${!fichaFamiliar.indiqueaquedistanciaestauri ? 'is-invalid' : ''}`}
                  value={fichaFamiliar.indiqueaquedistanciaestauri}
                  onChange={handleChange}
                />
                {/* {!fichaFamiliar.indiqueaquedistanciaestauri && <div className="invalid-feedback">Campo obligatorio</div>} */}
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">Indique a que distancia esta ubicado el sistema de disposición de excretas y/o aguas residuales  domesticas que tiene la vivienda con respecto a: Tanques subterraneos de agua</label>
                <input
                  type="number"
                  name='indiqueaquedistanciaestauta'
                  className='form-control'
                  // className={`form-control ${!fichaFamiliar.indiqueaquedistanciaestauta ? 'is-invalid' : ''}`}
                  value={fichaFamiliar.indiqueaquedistanciaestauta}
                  onChange={handleChange}
                />
                {/* {!fichaFamiliar.indiqueaquedistanciaestauta && <div className="invalid-feedback">Campo obligatorio</div>} */}
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">Indique a que distancia esta ubicado el sistema de disposición de excretas y/o aguas residuales  domesticas que tiene la vivienda con respecto a: Otros</label>
                <input
                  type="number"
                  name='indiqueaquedistanciaestauot'
                  className='form-control'
                  // className={`form-control ${!fichaFamiliar.indiqueaquedistanciaestauot ? 'is-invalid' : ''}`}
                  value={fichaFamiliar.indiqueaquedistanciaestauot}
                  onChange={handleChange}
                />
                {/* {!fichaFamiliar.indiqueaquedistanciaestauot && <div className="invalid-feedback">Campo obligatorio</div>} */}
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <div>
                  <label class="form-label">Los residuos sólidos de la vivienda se almacenan en:</label>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="losresiduossolidosdelaviv"
                        value="Recipientes con tapa"
                        checked={fichaFamiliar.losresiduossolidosdelaviv.includes('Recipientes con tapa')}
                        onChange={handleChange}
                      />
                      Recipientes con tapa
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="losresiduossolidosdelaviv"
                        value="Recipientes sin tapa"
                        checked={fichaFamiliar.losresiduossolidosdelaviv.includes('Recipientes sin tapa')}
                        onChange={handleChange}
                      />
                      Recipientes sin tapa
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="losresiduossolidosdelaviv"
                        value="Directamente al suelo"
                        checked={fichaFamiliar.losresiduossolidosdelaviv.includes('Directamente al suelo')}
                        onChange={handleChange}
                      />
                      Directamente al suelo
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="losresiduossolidosdelaviv"
                        value="Bolsas plásticas"
                        checked={fichaFamiliar.losresiduossolidosdelaviv.includes('Bolsas plásticas')}
                        onChange={handleChange}
                      />
                      Bolsas plásticas
                    </label>
                  </div>
                  <div class="input-group">
                    <div class="input-group-text">
                        <input
                          type="checkbox"
                          class="form-check-input mt-0"
                          name="losresiduossolidosdelaviv"
                          value={otroslosresiduossolidosdelaviv}
                          checked={fichaFamiliar.losresiduossolidosdelaviv.includes(otroslosresiduossolidosdelaviv)}
                          onChange={handleChange}
                          //onChange={(e) => fichaFamiliar.losresiduossolidosdelaviv(e.target.checked)}
                        />
                      </div>
                      <input type="text" class="form-control" aria-label="Text input with checkbox" 
                        placeholder="Digite otras opciones de ser necesario"
                        value={otroslosresiduossolidosdelaviv}
                        name="otroslosresiduossolidosdelaviv"
                        //onChange={handleInputChange}
                        //onBlur={handleInputBlur}
                        onChange={(event) => handleInputChange(event, setOtrosLosresiduossolidosdelaviv)}
                        onBlur={() => handleInputBlur(otroslosresiduossolidosdelaviv, 'losresiduossolidosdelaviv')}
                        />
                  </div>
                  {!fichaFamiliar.losresiduossolidosdelaviv.length && <div className="text-danger">Campo obligatorio</div>}
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <div>
                  <label class="form-label">¿Como se realiza la disposición final de los residuos sólidos ordinarios de la vivienda? (puede señalar varias opciones)</label>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="comoserealizaladisposicioso"
                        value="Recolección por parte del servicio de aseo distrital o municipal"
                        checked={fichaFamiliar.comoserealizaladisposicioso.includes('Recolección por parte del servicio de aseo distrital o municipal')}
                        onChange={handleChange}
                      />
                      Recolección por parte del servicio de aseo distrital o municipal
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="comoserealizaladisposicioso"
                        value="Enterramiento"
                        checked={fichaFamiliar.comoserealizaladisposicioso.includes('Enterramiento')}
                        onChange={handleChange}
                      />
                      Enterramiento
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="comoserealizaladisposicioso"
                        value="Quema a campo abierto"
                        checked={fichaFamiliar.comoserealizaladisposicioso.includes('Quema a campo abierto')}
                        onChange={handleChange}
                      />
                      Quema a campo abierto
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="comoserealizaladisposicioso"
                        value="Disposición en fuentes de agua cercana"
                        checked={fichaFamiliar.comoserealizaladisposicioso.includes('Disposición en fuentes de agua cercana')}
                        onChange={handleChange}
                      />
                      Disposición en fuentes de agua cercana
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="comoserealizaladisposicioso"
                        value="Disposición a campo abierto"
                        checked={fichaFamiliar.comoserealizaladisposicioso.includes('Disposición a campo abierto')}
                        onChange={handleChange}
                      />
                      Disposición a campo abierto
                    </label>
                  </div>
                  <div class="input-group">
                    <div class="input-group-text">
                        <input
                          type="checkbox"
                          class="form-check-input mt-0"
                          name="comoserealizaladisposicioso"
                          value={otroscomoserealizaladisposicioso}
                          checked={fichaFamiliar.comoserealizaladisposicioso.includes(otroscomoserealizaladisposicioso)}
                          onChange={handleChange}
                          //onChange={(e) => fichaFamiliar.comoserealizaladisposicioso(e.target.checked)}
                        />
                      </div>
                      <input type="text" class="form-control" aria-label="Text input with checkbox" 
                        placeholder="Digite otras opciones de ser necesario"
                        value={otroscomoserealizaladisposicioso}
                        name="otroscomoserealizaladisposicioso"
                        //onChange={handleInputChange}
                        //onBlur={handleInputBlur}
                        onChange={(event) => handleInputChange(event, setOtrosComoserealizaladisposicioso)}
                        onBlur={() => handleInputBlur(otroscomoserealizaladisposicioso, 'comoserealizaladisposicioso')}
                        />
                  </div>
                  {!fichaFamiliar.comoserealizaladisposicioso.length && <div className="text-danger">Campo obligatorio</div>}
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <div>
                  <label class="form-label">¿Cómo se realiza la disposicion final de los residuos peligrosos (recipientes de productos de  uso domestico, medicamentos, plaguicidas, RAEES, entre otros)?</label>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="comoserealizaladisposiciope"
                        value="Recolectados por empresas especializadas"
                        checked={fichaFamiliar.comoserealizaladisposiciope.includes('Recolectados por empresas especializadas')}
                        onChange={handleChange}
                      />
                      Recolectados por empresas especializadas
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="comoserealizaladisposiciope"
                        value="Sitios autorizados para su recolección"
                        checked={fichaFamiliar.comoserealizaladisposiciope.includes('Sitios autorizados para su recolección')}
                        onChange={handleChange}
                      />
                      Sitios autorizados para su recolección
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="comoserealizaladisposiciope"
                        value="Centros de acopio"
                        checked={fichaFamiliar.comoserealizaladisposiciope.includes('Centros de acopio')}
                        onChange={handleChange}
                      />
                      Centros de acopio
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="comoserealizaladisposiciope"
                        value="Enterramiento"
                        checked={fichaFamiliar.comoserealizaladisposiciope.includes('Enterramiento')}
                        onChange={handleChange}
                      />
                      Enterramiento
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="comoserealizaladisposiciope"
                        value="Quema a campo abierto"
                        checked={fichaFamiliar.comoserealizaladisposiciope.includes('Quema a campo abierto')}
                        onChange={handleChange}
                      />
                      Quema a campo abierto
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="comoserealizaladisposiciope"
                        value="Disposición en fuentes de agua cercana"
                        checked={fichaFamiliar.comoserealizaladisposiciope.includes('Disposición en fuentes de agua cercana')}
                        onChange={handleChange}
                      />
                      Disposición en fuentes de agua cercana
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="comoserealizaladisposiciope"
                        value="Disposición a campo abierto"
                        checked={fichaFamiliar.comoserealizaladisposiciope.includes('Disposición a campo abierto')}
                        onChange={handleChange}
                      />
                      Disposición a campo abierto
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="comoserealizaladisposiciope"
                        value="En conjunto con los residuos ordinarios de la vivienda"
                        checked={fichaFamiliar.comoserealizaladisposiciope.includes('En conjunto con los residuos ordinarios de la vivienda')}
                        onChange={handleChange}
                      />
                      En conjunto con los residuos ordinarios de la vivienda
                    </label>
                  </div>
                  <div class="input-group">
                    <div class="input-group-text">
                        <input
                          type="checkbox"
                          class="form-check-input mt-0"
                          name="comoserealizaladisposiciope"
                          value={otroscomoserealizaladisposiciope}
                          checked={fichaFamiliar.comoserealizaladisposiciope.includes(otroscomoserealizaladisposiciope)}
                          onChange={handleChange}
                          //onChange={(e) => fichaFamiliar.comoserealizaladisposiciope(e.target.checked)}
                        />
                      </div>
                      <input type="text" class="form-control" aria-label="Text input with checkbox" 
                        placeholder="Digite otras opciones de ser necesario"
                        value={otroscomoserealizaladisposiciope}
                        name="otroscomoserealizaladisposiciope"
                        //onChange={handleInputChange}
                        //onBlur={handleInputBlur}
                        onChange={(event) => handleInputChange(event, setOtrosComoserealizaladisposiciope)}
                        onBlur={() => handleInputBlur(otroscomoserealizaladisposiciope, 'comoserealizaladisposiciope')}
                        />
                  </div>
                  {!fichaFamiliar.comoserealizaladisposiciope.length && <div className="text-danger">Campo obligatorio</div>}
                </div>
              </div>   
            </div>
            <div class="col-12 col-md-6 col-lg-4">               
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">Conocen en la familia las prácticas de reducción de generación de residuos en la fuente, aprovechamiento y separación de residuos</label>
                <select className={`form-select ${!fichaFamiliar.conocenenlafamilialasprac ? 'is-invalid' : ''}`} name='conocenenlafamilialasprac' value={fichaFamiliar.conocenenlafamilialasprac} onChange={handleChange}>
                  <option selected></option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </select>
                {!fichaFamiliar.conocenenlafamilialasprac && <div className="invalid-feedback">Campo obligatorio</div>}
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <div>
                  <label class="form-label">¿Qué prácticas de reducción de generación de residuos en la fuente realiza?</label>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="quepracticasdereduccionde"
                        value="Se evita el uso de productos de un solo uso como botellas, vajillas y cubiertos desechables, entre otros"
                        checked={fichaFamiliar.quepracticasdereduccionde.includes('Se evita el uso de productos de un solo uso como botellas, vajillas y cubiertos desechables, entre otros')}
                        onChange={handleChange}
                      />
                      Se evita el uso de productos de un solo uso como botellas, vajillas y cubiertos desechables, entre otros
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="quepracticasdereduccionde"
                        value="Se reutilizan materiales que hayan cumplido su primer ciclo, como el papel y el cartón"
                        checked={fichaFamiliar.quepracticasdereduccionde.includes('Se reutilizan materiales que hayan cumplido su primer ciclo, como el papel y el cartón')}
                        onChange={handleChange}
                      />
                      Se reutilizan materiales que hayan cumplido su primer ciclo, como el papel y el cartón
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="quepracticasdereduccionde"
                        value="Eliminación o reutilización de aparatos electricos a través de las empresas especializadas, como computadores, cartuchos de impresoras, enttre otros"
                        checked={fichaFamiliar.quepracticasdereduccionde.includes('Eliminación o reutilización de aparatos electricos a través de las empresas especializadas, como computadores, cartuchos de impresoras, enttre otros')}
                        onChange={handleChange}
                      />
                      Eliminación o reutilización de aparatos electricos a través de las empresas especializadas, como computadores, cartuchos de impresoras, enttre otros
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="quepracticasdereduccionde"
                        value="Se evita el uso de ambientadores o artificiales o aerosoles"
                        checked={fichaFamiliar.quepracticasdereduccionde.includes('Se evita el uso de ambientadores o artificiales o aerosoles')}
                        onChange={handleChange}
                      />
                      Se evita el uso de ambientadores o artificiales o aerosoles
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="quepracticasdereduccionde"
                        value="Se compra a grnael usando los propios envases o maletas para empacar"
                        checked={fichaFamiliar.quepracticasdereduccionde.includes('Se compra a grnael usando los propios envases o maletas para empacar')}
                        onChange={handleChange}
                      />
                      Se compra a grnael usando los propios envases o maletas para empacar
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="quepracticasdereduccionde"
                        value="Ninguna"
                        checked={fichaFamiliar.quepracticasdereduccionde.includes('Ninguna')}
                        onChange={handleChange}
                      />
                      Ninguna
                    </label>
                  </div>
                  <div class="input-group">
                    <div class="input-group-text">
                        <input
                          type="checkbox"
                          class="form-check-input mt-0"
                          name="quepracticasdereduccionde"
                          value={otrosquepracticasdereduccionde}
                          checked={fichaFamiliar.quepracticasdereduccionde.includes(otrosquepracticasdereduccionde)}
                          onChange={handleChange}
                          //onChange={(e) => fichaFamiliar.quepracticasdereduccionde(e.target.checked)}
                        />
                      </div>
                      <input type="text" class="form-control" aria-label="Text input with checkbox" 
                        placeholder="Digite otras opciones de ser necesario"
                        value={otrosquepracticasdereduccionde}
                        name="otrosquepracticasdereduccionde"
                        //onChange={handleInputChange}
                        //onBlur={handleInputBlur}
                        onChange={(event) => handleInputChange(event, setOtrosQuepracticasdereduccionde)}
                        onBlur={() => handleInputBlur(otrosquepracticasdereduccionde, 'quepracticasdereduccionde')}
                        />
                  </div>
                  {!fichaFamiliar.quepracticasdereduccionde.length && <div className="text-danger">Campo obligatorio</div>}
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <div>
                  <label class="form-label">¿Qué prácticas de aprovechamiento de residuos realiza (rural)?</label>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="quepracticasdeaprovechami"
                        value="Compostaje"
                        checked={fichaFamiliar.quepracticasdeaprovechami.includes('Compostaje')}
                        onChange={handleChange}
                      />
                      Compostaje
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="quepracticasdeaprovechami"
                        value="Lombricultivo"
                        checked={fichaFamiliar.quepracticasdeaprovechami.includes('Lombricultivo')}
                        onChange={handleChange}
                      />
                      Lombricultivo
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="quepracticasdeaprovechami"
                        value="Biocombustible"
                        checked={fichaFamiliar.quepracticasdeaprovechami.includes('Biocombustible')}
                        onChange={handleChange}
                      />
                      Biocombustible
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="quepracticasdeaprovechami"
                        value="Biofertilizante"
                        checked={fichaFamiliar.quepracticasdeaprovechami.includes('Biofertilizante')}
                        onChange={handleChange}
                      />
                      Biofertilizante
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="quepracticasdeaprovechami"
                        value="Ninguna"
                        checked={fichaFamiliar.quepracticasdeaprovechami.includes('Ninguna')}
                        onChange={handleChange}
                      />
                      Ninguna
                    </label>
                  </div>
                  <div class="input-group">
                    <div class="input-group-text">
                        <input
                          type="checkbox"
                          class="form-check-input mt-0"
                          name="quepracticasdeaprovechami"
                          value={otrosquepracticasdeaprovechami}
                          checked={fichaFamiliar.quepracticasdeaprovechami.includes(otrosquepracticasdeaprovechami)}
                          onChange={handleChange}
                          //onChange={(e) => fichaFamiliar.quepracticasdeaprovechami(e.target.checked)}
                        />
                      </div>
                      <input type="text" class="form-control" aria-label="Text input with checkbox" 
                        placeholder="Digite otras opciones de ser necesario"
                        value={otrosquepracticasdeaprovechami}
                        name="otrosquepracticasdeaprovechami"
                        //onChange={handleInputChange}
                        //onBlur={handleInputBlur}
                        onChange={(event) => handleInputChange(event, setOtrosQuepracticasdeaprovechami)}
                        onBlur={() => handleInputBlur(otrosquepracticasdeaprovechami, 'quepracticasdeaprovechami')}
                        />
                  </div>
                  {!fichaFamiliar.quepracticasdeaprovechami.length && <div className="text-danger">Campo obligatorio</div>}
                </div>
              </div> 
            </div>
            <div class="col-12 col-md-6 col-lg-4">                 
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">¿Qué prácticas de separación en la fuente de residuos realiza (rural)?</label>
                <select className={`form-select ${!fichaFamiliar.quepracticasdeseparacione ? 'is-invalid' : ''}`} name='quepracticasdeseparacione' value={fichaFamiliar.quepracticasdeseparacione} onChange={handleChange}>
                  <option selected></option>
                  <option value="Separación de resiudos aprovechables (papel, cartón, vidrio, plástico, tetrapack, metal) ">Separación de resiudos aprovechables (papel, cartón, vidrio, plástico, tetrapack, metal) </option>
                  <option value="Separación de resiudos no aprovechables ( Icopor, pañales, toallas higiénicas, cerámicas, papel carbón, entre otros) ">Separación de resiudos no aprovechables ( Icopor, pañales, toallas higiénicas, cerámicas, papel carbón, entre otros) </option>
                  <option value="Separación de residuos orgánicos (residuos de alimentos, restos vegetales de la poda y jardinería, restos de la carpintería, entre otros) ">Separación de residuos orgánicos (residuos de alimentos, restos vegetales de la poda y jardinería, restos de la carpintería, entre otros) </option>
                  <option value="Ninguna">Ninguna</option>
                </select>
                {!fichaFamiliar.quepracticasdeseparacione && <div className="invalid-feedback">Campo obligatorio</div>}
              </div> 
            </div>
            <div class="col-12 col-md-6 col-lg-4">                 
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">Se realizan prácticas adecuadas de reducción de generación de residuos en la fuente</label>
                <select className={`form-select ${!fichaFamiliar.serealizanpracticasadecuaap ? 'is-invalid' : ''}`} name='serealizanpracticasadecuaap' value={fichaFamiliar.serealizanpracticasadecuaap} onChange={handleChange}>
                  <option selected></option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </select>
                {!fichaFamiliar.serealizanpracticasadecuaap && <div className="invalid-feedback">Campo obligatorio</div>}
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">Se realizan prácticas adecuadas de aprovechamiento de residuos</label>
                <select className={`form-select ${!fichaFamiliar.serealizanpracticasadecuase ? 'is-invalid' : ''}`} name='serealizanpracticasadecuase' value={fichaFamiliar.serealizanpracticasadecuase} onChange={handleChange}>
                  <option selected></option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </select>
                {!fichaFamiliar.serealizanpracticasadecuase && <div className="invalid-feedback">Campo obligatorio</div>}
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">Se realiza el proceso de separación de los residuos en la fuente</label>
                <select className={`form-select ${!fichaFamiliar.serealizaelprocesodesepar ? 'is-invalid' : ''}`} name='serealizaelprocesodesepar' value={fichaFamiliar.serealizaelprocesodesepar} onChange={handleChange}>
                  <option selected></option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </select>
                {!fichaFamiliar.serealizaelprocesodesepar && <div className="invalid-feedback">Campo obligatorio</div>}
              </div>  
            </div>
          </div>
        </div>
        <div class="m-2 mt-4 border border-primary-subtle"><h4 class="text"> 2.6.	HIGIENE</h4>            
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <div>
                  <label class="form-label">¿Cómo se realiza la limpieza de pisos en la vivienda? (señale una sola opción, la más predominante)</label>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="comoserealizalalimpiezadepi"
                        value="Barrido"
                        checked={fichaFamiliar.comoserealizalalimpiezadepi.includes('Barrido')}
                        onChange={handleChange}
                      />
                      Barrido
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="comoserealizalalimpiezadepi"
                        value="Trapeado"
                        checked={fichaFamiliar.comoserealizalalimpiezadepi.includes('Trapeado')}
                        onChange={handleChange}
                      />
                      Trapeado
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="comoserealizalalimpiezadepi"
                        value="Barrido y trapeado"
                        checked={fichaFamiliar.comoserealizalalimpiezadepi.includes('Barrido y trapeado')}
                        onChange={handleChange}
                      />
                      Barrido y trapeado
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="comoserealizalalimpiezadepi"
                        value="Barrido, trapeado y desinfección"
                        checked={fichaFamiliar.comoserealizalalimpiezadepi.includes('Barrido, trapeado y desinfección')}
                        onChange={handleChange}
                      />
                      Barrido, trapeado y desinfección
                    </label>
                  </div>
                  <div class="input-group">
                    <div class="input-group-text">
                        <input
                          type="checkbox"
                          class="form-check-input mt-0"
                          name="comoserealizalalimpiezadepi"
                          value={otroscomoserealizalalimpiezadepi}
                          checked={fichaFamiliar.comoserealizalalimpiezadepi.includes(otroscomoserealizalalimpiezadepi)}
                          onChange={handleChange}
                          //onChange={(e) => fichaFamiliar.comoserealizalalimpiezadepi(e.target.checked)}
                        />
                      </div>
                      <input type="text" class="form-control" aria-label="Text input with checkbox" 
                        placeholder="Digite otras opciones de ser necesario"
                        value={otroscomoserealizalalimpiezadepi}
                        name="otroscomoserealizalalimpiezadepi"
                        //onChange={handleInputChange}
                        //onBlur={handleInputBlur}
                        onChange={(event) => handleInputChange(event, setOtrosComoserealizalalimpiezadepi)}
                        onBlur={() => handleInputBlur(otroscomoserealizalalimpiezadepi, 'comoserealizalalimpiezadepi')}
                        />
                  </div>
                  {!fichaFamiliar.comoserealizalalimpiezadepi.length && <div className="text-danger">Campo obligatorio</div>}
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <div>
                  <label class="form-label">¿Cómo se realiza la limpieza de paredes en la vivienda? (señale una sola opción, la más predominante)</label>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="comoserealizalalimpiezadepa"
                        value="Barrido"
                        checked={fichaFamiliar.comoserealizalalimpiezadepa.includes('Barrido')}
                        onChange={handleChange}
                      />
                      Barrido
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="comoserealizalalimpiezadepa"
                        value="Trapeado"
                        checked={fichaFamiliar.comoserealizalalimpiezadepa.includes('Trapeado')}
                        onChange={handleChange}
                      />
                      Trapeado
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="comoserealizalalimpiezadepa"
                        value="Barrido y trapeado"
                        checked={fichaFamiliar.comoserealizalalimpiezadepa.includes('Barrido y trapeado')}
                        onChange={handleChange}
                      />
                      Barrido y trapeado
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="comoserealizalalimpiezadepa"
                        value="Barrido, trapeado y desinfección"
                        checked={fichaFamiliar.comoserealizalalimpiezadepa.includes('Barrido, trapeado y desinfección')}
                        onChange={handleChange}
                      />
                      Barrido, trapeado y desinfección
                    </label>
                  </div>
                  <div class="input-group">
                    <div class="input-group-text">
                        <input
                          type="checkbox"
                          class="form-check-input mt-0"
                          name="comoserealizalalimpiezadepa"
                          value={otroscomoserealizalalimpiezadepa}
                          checked={fichaFamiliar.comoserealizalalimpiezadepa.includes(otroscomoserealizalalimpiezadepa)}
                          onChange={handleChange}
                          //onChange={(e) => fichaFamiliar.comoserealizalalimpiezadepa(e.target.checked)}
                        />
                      </div>
                      <input type="text" class="form-control" aria-label="Text input with checkbox" 
                        placeholder="Digite otras opciones de ser necesario"
                        value={otroscomoserealizalalimpiezadepa}
                        name="otroscomoserealizalalimpiezadepa"
                        //onChange={handleInputChange}
                        //onBlur={handleInputBlur}
                        onChange={(event) => handleInputChange(event, setOtrosComoserealizalalimpiezadepa)}
                        onBlur={() => handleInputBlur(otroscomoserealizalalimpiezadepa, 'comoserealizalalimpiezadepa')}
                        />
                  </div>
                  {!fichaFamiliar.comoserealizalalimpiezadepa.length && <div className="text-danger">Campo obligatorio</div>}
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <div>
                  <label class="form-label">¿Cómo se realiza la limpieza de superficies en la vivienda? (señale una sola opción, la más predominante)</label>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="comoserealizalalimpiezadesu"
                        value="Retiro de polvo"
                        checked={fichaFamiliar.comoserealizalalimpiezadesu.includes('Retiro de polvo')}
                        onChange={handleChange}
                      />
                      Retiro de polvo
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="comoserealizalalimpiezadesu"
                        value="Retiro de polvo y limpieza con agua y jabón"
                        checked={fichaFamiliar.comoserealizalalimpiezadesu.includes('Retiro de polvo y limpieza con agua y jabón')}
                        onChange={handleChange}
                      />
                      Retiro de polvo y limpieza con agua y jabón
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="comoserealizalalimpiezadesu"
                        value="Retiro de polvo, limpieza con agua y jabón y desinfección"
                        checked={fichaFamiliar.comoserealizalalimpiezadesu.includes('Retiro de polvo, limpieza con agua y jabón y desinfección')}
                        onChange={handleChange}
                      />
                      Retiro de polvo, limpieza con agua y jabón y desinfección
                    </label>
                  </div>
                  <div class="input-group">
                    <div class="input-group-text">
                        <input
                          type="checkbox"
                          class="form-check-input mt-0"
                          name="comoserealizalalimpiezadesu"
                          value={otroscomoserealizalalimpiezadesu}
                          checked={fichaFamiliar.comoserealizalalimpiezadesu.includes(otroscomoserealizalalimpiezadesu)}
                          onChange={handleChange}
                          //onChange={(e) => fichaFamiliar.comoserealizalalimpiezadesu(e.target.checked)}
                        />
                      </div>
                      <input type="text" class="form-control" aria-label="Text input with checkbox" 
                        placeholder="Digite otras opciones de ser necesario"
                        value={otroscomoserealizalalimpiezadesu}
                        name="otroscomoserealizalalimpiezadesu"
                        //onChange={handleInputChange}
                        //onBlur={handleInputBlur}
                        onChange={(event) => handleInputChange(event, setOtrosComoserealizalalimpiezadesu)}
                        onBlur={() => handleInputBlur(otroscomoserealizalalimpiezadesu, 'comoserealizalalimpiezadesu')}
                        />
                  </div>
                  {!fichaFamiliar.comoserealizalalimpiezadesu.length && <div className="text-danger">Campo obligatorio</div>}
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">¿Los miembros de la familia se lavan las manos con agua y jabón?</label>
                <select className={`form-select ${!fichaFamiliar.losmiembrosdelafamiliasel ? 'is-invalid' : ''}`} name='losmiembrosdelafamiliasel' value={fichaFamiliar.losmiembrosdelafamiliasel} onChange={handleChange}>
                  <option selected></option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </select>
                {!fichaFamiliar.losmiembrosdelafamiliasel && <div className="invalid-feedback">Campo obligatorio</div>}
              </div>
            </div>
            <div class="col-12">
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">¿Se comparten implementos de higiene personal entre los miembros de la familia?</label>
                <div class="row align-items-start">
                  <div class="col-md-3">
                    <div>
                      <label class="form-label">[Pisos]</label>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehpi"
                            value="Todos los días"
                            checked={fichaFamiliar.secompartenimplementosdehpi.includes('Todos los días')}
                            onChange={handleChange}
                          />
                          Todos los días
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehpi"
                            value="Una vez a la semana"
                            checked={fichaFamiliar.secompartenimplementosdehpi.includes('Una vez a la semana')}
                            onChange={handleChange}
                          />
                        Una vez a la semana
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehpi"
                            value="Una vez cada 15 días"
                            checked={fichaFamiliar.secompartenimplementosdehpi.includes('Una vez cada 15 días')}
                            onChange={handleChange}
                          />
                        Una vez cada 15 días
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehpi"
                            value="Una vez al mes"
                            checked={fichaFamiliar.secompartenimplementosdehpi.includes('Una vez al mes')}
                            onChange={handleChange}
                          />
                        Una vez al mes
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehpi"
                            value="Cada 6 meses"
                            checked={fichaFamiliar.secompartenimplementosdehpi.includes('Cada 6 meses')}
                            onChange={handleChange}
                          />
                        Cada 6 meses
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehpi"
                            value="Cada Año"
                            checked={fichaFamiliar.secompartenimplementosdehpi.includes('Cada Año')}
                            onChange={handleChange}
                          />
                        Cada Año
                        </label>
                      </div>
                      {/* {!fichaFamiliar.secompartenimplementosdehpi.length && <div className="text-danger">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div>
                      <label class="form-label">[Paredes]</label>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehpa"
                            value="Todos los días"
                            checked={fichaFamiliar.secompartenimplementosdehpa.includes('Todos los días')}
                            onChange={handleChange}
                          />
                          Todos los días
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehpa"
                            value="Una vez a la semana"
                            checked={fichaFamiliar.secompartenimplementosdehpa.includes('Una vez a la semana')}
                            onChange={handleChange}
                          />
                        Una vez a la semana
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehpa"
                            value="Una vez cada 15 días"
                            checked={fichaFamiliar.secompartenimplementosdehpa.includes('Una vez cada 15 días')}
                            onChange={handleChange}
                          />
                        Una vez cada 15 días
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehpa"
                            value="Una vez al mes"
                            checked={fichaFamiliar.secompartenimplementosdehpa.includes('Una vez al mes')}
                            onChange={handleChange}
                          />
                        Una vez al mes
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehpa"
                            value="Cada 6 meses"
                            checked={fichaFamiliar.secompartenimplementosdehpa.includes('Cada 6 meses')}
                            onChange={handleChange}
                          />
                        Cada 6 meses
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehpa"
                            value="Cada Año"
                            checked={fichaFamiliar.secompartenimplementosdehpa.includes('Cada Año')}
                            onChange={handleChange}
                          />
                        Cada Año
                        </label>
                      </div>
                      {/* {!fichaFamiliar.secompartenimplementosdehpa.length && <div className="text-danger">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div>
                      <label class="form-label">[Techos Muebles]</label>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehte"
                            value="Todos los días"
                            checked={fichaFamiliar.secompartenimplementosdehte.includes('Todos los días')}
                            onChange={handleChange}
                          />
                          Todos los días
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehte"
                            value="Una vez a la semana"
                            checked={fichaFamiliar.secompartenimplementosdehte.includes('Una vez a la semana')}
                            onChange={handleChange}
                          />
                        Una vez a la semana
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehte"
                            value="Una vez cada 15 días"
                            checked={fichaFamiliar.secompartenimplementosdehte.includes('Una vez cada 15 días')}
                            onChange={handleChange}
                          />
                        Una vez cada 15 días
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehte"
                            value="Una vez al mes"
                            checked={fichaFamiliar.secompartenimplementosdehte.includes('Una vez al mes')}
                            onChange={handleChange}
                          />
                        Una vez al mes
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehte"
                            value="Cada 6 meses"
                            checked={fichaFamiliar.secompartenimplementosdehte.includes('Cada 6 meses')}
                            onChange={handleChange}
                          />
                        Cada 6 meses
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehte"
                            value="Cada Año"
                            checked={fichaFamiliar.secompartenimplementosdehte.includes('Cada Año')}
                            onChange={handleChange}
                          />
                        Cada Año
                        </label>
                      </div>
                      {/* {!fichaFamiliar.secompartenimplementosdehte.length && <div className="text-danger">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div>
                      <label class="form-label">[Tapetes]</label>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehta"
                            value="Todos los días"
                            checked={fichaFamiliar.secompartenimplementosdehta.includes('Todos los días')}
                            onChange={handleChange}
                          />
                          Todos los días
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehta"
                            value="Una vez a la semana"
                            checked={fichaFamiliar.secompartenimplementosdehta.includes('Una vez a la semana')}
                            onChange={handleChange}
                          />
                        Una vez a la semana
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehta"
                            value="Una vez cada 15 días"
                            checked={fichaFamiliar.secompartenimplementosdehta.includes('Una vez cada 15 días')}
                            onChange={handleChange}
                          />
                        Una vez cada 15 días
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehta"
                            value="Una vez al mes"
                            checked={fichaFamiliar.secompartenimplementosdehta.includes('Una vez al mes')}
                            onChange={handleChange}
                          />
                        Una vez al mes
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehta"
                            value="Cada 6 meses"
                            checked={fichaFamiliar.secompartenimplementosdehta.includes('Cada 6 meses')}
                            onChange={handleChange}
                          />
                        Cada 6 meses
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehta"
                            value="Cada Año"
                            checked={fichaFamiliar.secompartenimplementosdehta.includes('Cada Año')}
                            onChange={handleChange}
                          />
                        Cada Año
                        </label>
                      </div>
                      {/* {!fichaFamiliar.secompartenimplementosdehta.length && <div className="text-danger">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div>
                      <label class="form-label">[Cortinas]</label>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehco"
                            value="Todos los días"
                            checked={fichaFamiliar.secompartenimplementosdehco.includes('Todos los días')}
                            onChange={handleChange}
                          />
                          Todos los días
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehco"
                            value="Una vez a la semana"
                            checked={fichaFamiliar.secompartenimplementosdehco.includes('Una vez a la semana')}
                            onChange={handleChange}
                          />
                        Una vez a la semana
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehco"
                            value="Una vez cada 15 días"
                            checked={fichaFamiliar.secompartenimplementosdehco.includes('Una vez cada 15 días')}
                            onChange={handleChange}
                          />
                        Una vez cada 15 días
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehco"
                            value="Una vez al mes"
                            checked={fichaFamiliar.secompartenimplementosdehco.includes('Una vez al mes')}
                            onChange={handleChange}
                          />
                        Una vez al mes
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehco"
                            value="Cada 6 meses"
                            checked={fichaFamiliar.secompartenimplementosdehco.includes('Cada 6 meses')}
                            onChange={handleChange}
                          />
                        Cada 6 meses
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehco"
                            value="Cada Año"
                            checked={fichaFamiliar.secompartenimplementosdehco.includes('Cada Año')}
                            onChange={handleChange}
                          />
                        Cada Año
                        </label>
                      </div>
                      {/* {!fichaFamiliar.secompartenimplementosdehco.length && <div className="text-danger">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div>
                      <label class="form-label">[Sabanas]</label>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehsa"
                            value="Todos los días"
                            checked={fichaFamiliar.secompartenimplementosdehsa.includes('Todos los días')}
                            onChange={handleChange}
                          />
                          Todos los días
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehsa"
                            value="Una vez a la semana"
                            checked={fichaFamiliar.secompartenimplementosdehsa.includes('Una vez a la semana')}
                            onChange={handleChange}
                          />
                        Una vez a la semana
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehsa"
                            value="Una vez cada 15 días"
                            checked={fichaFamiliar.secompartenimplementosdehsa.includes('Una vez cada 15 días')}
                            onChange={handleChange}
                          />
                        Una vez cada 15 días
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehsa"
                            value="Una vez al mes"
                            checked={fichaFamiliar.secompartenimplementosdehsa.includes('Una vez al mes')}
                            onChange={handleChange}
                          />
                        Una vez al mes
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehsa"
                            value="Cada 6 meses"
                            checked={fichaFamiliar.secompartenimplementosdehsa.includes('Cada 6 meses')}
                            onChange={handleChange}
                          />
                        Cada 6 meses
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehsa"
                            value="Cada Año"
                            checked={fichaFamiliar.secompartenimplementosdehsa.includes('Cada Año')}
                            onChange={handleChange}
                          />
                        Cada Año
                        </label>
                      </div>
                      {/* {!fichaFamiliar.secompartenimplementosdehsa.length && <div className="text-danger">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div>
                      <label class="form-label">[Cobija]</label>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehcob"
                            value="Todos los días"
                            checked={fichaFamiliar.secompartenimplementosdehcob.includes('Todos los días')}
                            onChange={handleChange}
                          />
                          Todos los días
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehcob"
                            value="Una vez a la semana"
                            checked={fichaFamiliar.secompartenimplementosdehcob.includes('Una vez a la semana')}
                            onChange={handleChange}
                          />
                        Una vez a la semana
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehcob"
                            value="Una vez cada 15 días"
                            checked={fichaFamiliar.secompartenimplementosdehcob.includes('Una vez cada 15 días')}
                            onChange={handleChange}
                          />
                        Una vez cada 15 días
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehcob"
                            value="Una vez al mes"
                            checked={fichaFamiliar.secompartenimplementosdehcob.includes('Una vez al mes')}
                            onChange={handleChange}
                          />
                        Una vez al mes
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehcob"
                            value="Cada 6 meses"
                            checked={fichaFamiliar.secompartenimplementosdehcob.includes('Cada 6 meses')}
                            onChange={handleChange}
                          />
                        Cada 6 meses
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehcob"
                            value="Cada Año"
                            checked={fichaFamiliar.secompartenimplementosdehcob.includes('Cada Año')}
                            onChange={handleChange}
                          />
                        Cada Año
                        </label>
                      </div>
                      {/* {!fichaFamiliar.secompartenimplementosdehcob.length && <div className="text-danger">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div>
                      <label class="form-label">[Baño]</label>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehba"
                            value="Todos los días"
                            checked={fichaFamiliar.secompartenimplementosdehba.includes('Todos los días')}
                            onChange={handleChange}
                          />
                          Todos los días
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehba"
                            value="Una vez a la semana"
                            checked={fichaFamiliar.secompartenimplementosdehba.includes('Una vez a la semana')}
                            onChange={handleChange}
                          />
                        Una vez a la semana
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehba"
                            value="Una vez cada 15 días"
                            checked={fichaFamiliar.secompartenimplementosdehba.includes('Una vez cada 15 días')}
                            onChange={handleChange}
                          />
                        Una vez cada 15 días
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehba"
                            value="Una vez al mes"
                            checked={fichaFamiliar.secompartenimplementosdehba.includes('Una vez al mes')}
                            onChange={handleChange}
                          />
                        Una vez al mes
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehba"
                            value="Cada 6 meses"
                            checked={fichaFamiliar.secompartenimplementosdehba.includes('Cada 6 meses')}
                            onChange={handleChange}
                          />
                        Cada 6 meses
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehba"
                            value="Cada Año"
                            checked={fichaFamiliar.secompartenimplementosdehba.includes('Cada Año')}
                            onChange={handleChange}
                          />
                        Cada Año
                        </label>
                      </div>
                      {/* {!fichaFamiliar.secompartenimplementosdehba.length && <div className="text-danger">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div>
                      <label class="form-label">[Cocina]</label>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehcoc"
                            value="Todos los días"
                            checked={fichaFamiliar.secompartenimplementosdehcoc.includes('Todos los días')}
                            onChange={handleChange}
                          />
                          Todos los días
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehcoc"
                            value="Una vez a la semana"
                            checked={fichaFamiliar.secompartenimplementosdehcoc.includes('Una vez a la semana')}
                            onChange={handleChange}
                          />
                        Una vez a la semana
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehcoc"
                            value="Una vez cada 15 días"
                            checked={fichaFamiliar.secompartenimplementosdehcoc.includes('Una vez cada 15 días')}
                            onChange={handleChange}
                          />
                        Una vez cada 15 días
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehcoc"
                            value="Una vez al mes"
                            checked={fichaFamiliar.secompartenimplementosdehcoc.includes('Una vez al mes')}
                            onChange={handleChange}
                          />
                        Una vez al mes
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehcoc"
                            value="Cada 6 meses"
                            checked={fichaFamiliar.secompartenimplementosdehcoc.includes('Cada 6 meses')}
                            onChange={handleChange}
                          />
                        Cada 6 meses
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehcoc"
                            value="Cada Año"
                            checked={fichaFamiliar.secompartenimplementosdehcoc.includes('Cada Año')}
                            onChange={handleChange}
                          />
                        Cada Año
                        </label>
                      </div>
                      {/* {!fichaFamiliar.secompartenimplementosdehcoc.length && <div className="text-danger">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div>
                      <label class="form-label">[Otras Superficie]</label>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehot"
                            value="Todos los días"
                            checked={fichaFamiliar.secompartenimplementosdehot.includes('Todos los días')}
                            onChange={handleChange}
                          />
                          Todos los días
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehot"
                            value="Una vez a la semana"
                            checked={fichaFamiliar.secompartenimplementosdehot.includes('Una vez a la semana')}
                            onChange={handleChange}
                          />
                        Una vez a la semana
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehot"
                            value="Una vez cada 15 días"
                            checked={fichaFamiliar.secompartenimplementosdehot.includes('Una vez cada 15 días')}
                            onChange={handleChange}
                          />
                        Una vez cada 15 días
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehot"
                            value="Una vez al mes"
                            checked={fichaFamiliar.secompartenimplementosdehot.includes('Una vez al mes')}
                            onChange={handleChange}
                          />
                        Una vez al mes
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehot"
                            value="Cada 6 meses"
                            checked={fichaFamiliar.secompartenimplementosdehot.includes('Cada 6 meses')}
                            onChange={handleChange}
                          />
                        Cada 6 meses
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            name="secompartenimplementosdehot"
                            value="Cada Año"
                            checked={fichaFamiliar.secompartenimplementosdehot.includes('Cada Año')}
                            onChange={handleChange}
                          />
                        Cada Año
                        </label>
                      </div>
                      {/* {!fichaFamiliar.secompartenimplementosdehot.length && <div className="text-danger">Campo obligatorio</div>} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <div>
                  <label class="form-label">En que situaciones se practica el lavado de manos:</label>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="enquesituacionessepractic"
                        value="Después de entrar al baño"
                        checked={fichaFamiliar.enquesituacionessepractic.includes('Después de entrar al baño')}
                        onChange={handleChange}
                      />
                      Después de entrar al baño
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="enquesituacionessepractic"
                        value="Después de cambiar pañales"
                        checked={fichaFamiliar.enquesituacionessepractic.includes('Después de cambiar pañales')}
                        onChange={handleChange}
                      />
                      Después de cambiar pañales
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="enquesituacionessepractic"
                        value="Antes de preparar y consumir alimentos"
                        checked={fichaFamiliar.enquesituacionessepractic.includes('Antes de preparar y consumir alimentos')}
                        onChange={handleChange}
                      />
                      Antes de preparar y consumir alimentos
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="enquesituacionessepractic"
                        value="Después de tener contacto con animales"
                        checked={fichaFamiliar.enquesituacionessepractic.includes('Después de tener contacto con animales')}
                        onChange={handleChange}
                      />
                      Después de tener contacto con animales
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="enquesituacionessepractic"
                        value="Después de retirar secreciones nasales"
                        checked={fichaFamiliar.enquesituacionessepractic.includes('Después de retirar secreciones nasales')}
                        onChange={handleChange}
                      />
                      Después de retirar secreciones nasales
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="enquesituacionessepractic"
                        value="Después de llegar de la calle"
                        checked={fichaFamiliar.enquesituacionessepractic.includes('Después de llegar de la calle')}
                        onChange={handleChange}
                      />
                      Después de llegar de la calle
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="enquesituacionessepractic"
                        value="Después de manipular sustancias químicas"
                        checked={fichaFamiliar.enquesituacionessepractic.includes('Después de manipular sustancias químicas')}
                        onChange={handleChange}
                      />
                      Después de manipular sustancias químicas
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="enquesituacionessepractic"
                        value="Todas"
                        checked={fichaFamiliar.enquesituacionessepractic.includes('Todas')}
                        onChange={handleChange}
                      />
                      Todas
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="enquesituacionessepractic"
                        value="Ninguna de las anteriores"
                        checked={fichaFamiliar.enquesituacionessepractic.includes('Ninguna de las anteriores')}
                        onChange={handleChange}
                      />
                      Ninguna de las anteriores
                    </label>
                  </div>
                  {!fichaFamiliar.enquesituacionessepractic.length && <div className="text-danger">Campo obligatorio</div>}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="m-2 mt-4 border border-primary-subtle"><h4 class="text"> 2.7.AIRE INTRADOMICILIARIO</h4>
          <div class="row">
            <div class="col-12 col-md-6 col-lg-4"> 
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <div>
                  <label class="form-label">¿Cuál fuente de energía o combustible que se usa para cocinar? (puede señalar varias opciones)</label>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="cualfuentedeenergiaocombu"
                        value="Electricidad"
                        checked={fichaFamiliar.cualfuentedeenergiaocombu.includes('Electricidad')}
                        onChange={handleChange}
                      />
                      Electricidad
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="cualfuentedeenergiaocombu"
                        value="Gas natural"
                        checked={fichaFamiliar.cualfuentedeenergiaocombu.includes('Gas natural')}
                        onChange={handleChange}
                      />
                      Gas natural
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="cualfuentedeenergiaocombu"
                        value="Gas Liquado del petroleo (gas propano)"
                        checked={fichaFamiliar.cualfuentedeenergiaocombu.includes('Gas Liquado del petroleo (gas propano)')}
                        onChange={handleChange}
                      />
                      Gas Liquado del petroleo (gas propano)
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="cualfuentedeenergiaocombu"
                        value="Leña, madera o carbón de leña"
                        checked={fichaFamiliar.cualfuentedeenergiaocombu.includes('Leña, madera o carbón de leña')}
                        onChange={handleChange}
                      />
                      Leña, madera o carbón de leña
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="cualfuentedeenergiaocombu"
                        value="Petróleo, gasolina, kerosén, alcohol"
                        checked={fichaFamiliar.cualfuentedeenergiaocombu.includes('Petróleo, gasolina, kerosén, alcohol')}
                        onChange={handleChange}
                      />
                      Petróleo, gasolina, kerosén, alcohol
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="cualfuentedeenergiaocombu"
                        value="Carbón mineral"
                        checked={fichaFamiliar.cualfuentedeenergiaocombu.includes('Carbón mineral')}
                        onChange={handleChange}
                      />
                      Carbón mineral
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="cualfuentedeenergiaocombu"
                        value="Materiales de desecho"
                        checked={fichaFamiliar.cualfuentedeenergiaocombu.includes('Materiales de desecho')}
                        onChange={handleChange}
                      />
                      Materiales de desecho
                    </label>
                  </div>
                  <div class="input-group">
                    <div class="input-group-text">
                        <input
                          type="checkbox"
                          class="form-check-input mt-0"
                          name="cualfuentedeenergiaocombu"
                          value={otroscualfuentedeenergiaocombu}
                          checked={fichaFamiliar.cualfuentedeenergiaocombu.includes(otroscualfuentedeenergiaocombu)}
                          onChange={handleChange}
                          //onChange={(e) => fichaFamiliar.cualfuentedeenergiaocombu(e.target.checked)}
                        />
                      </div>
                      <input type="text" class="form-control" aria-label="Text input with checkbox" 
                        placeholder="Digite otras opciones de ser necesario"
                        value={otroscualfuentedeenergiaocombu}
                        name="otroscualfuentedeenergiaocombu"
                        //onChange={handleInputChange}
                        //onBlur={handleInputBlur}
                        onChange={(event) => handleInputChange(event, setOtrosCualfuentedeenergiaocombu)}
                        onBlur={() => handleInputBlur(otroscualfuentedeenergiaocombu, 'cualfuentedeenergiaocombu')}
                        />
                  </div>
                  {!fichaFamiliar.cualfuentedeenergiaocombu.length && <div className="text-danger">Campo obligatorio</div>}
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">¿Es frecuente que haya humo dentro de la vivienda?</label>
                <select className={`form-select ${!fichaFamiliar.esfrecuentequehayahumoden ? 'is-invalid' : ''}`} name='esfrecuentequehayahumoden' value={fichaFamiliar.esfrecuentequehayahumoden} onChange={handleChange}>
                  <option selected></option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </select>
                {!fichaFamiliar.esfrecuentequehayahumoden && <div className="invalid-feedback">Campo obligatorio</div>}
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">         
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <div>
                  <label class="form-label">Señale las fuentes frecuentes de humo dentro de la vivienda</label>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="senalelasfuentesfrecuente"
                        value="Cigarrillo o tabaco"
                        checked={fichaFamiliar.senalelasfuentesfrecuente.includes('Cigarrillo o tabaco')}
                        onChange={handleChange}
                      />
                      Cigarrillo o tabaco
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="senalelasfuentesfrecuente"
                        value="Leña"
                        checked={fichaFamiliar.senalelasfuentesfrecuente.includes('Leña')}
                        onChange={handleChange}
                      />
                      Leña
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="senalelasfuentesfrecuente"
                        value="Carbón"
                        checked={fichaFamiliar.senalelasfuentesfrecuente.includes('Carbón')}
                        onChange={handleChange}
                      />
                      Carbón
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="senalelasfuentesfrecuente"
                        value="Quema de basura"
                        checked={fichaFamiliar.senalelasfuentesfrecuente.includes('Quema de basura')}
                        onChange={handleChange}
                      />
                      Quema de basura
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="senalelasfuentesfrecuente"
                        value="No Aplica"
                        checked={fichaFamiliar.senalelasfuentesfrecuente.includes('No Aplica')}
                        onChange={handleChange}
                      />
                      No Aplica
                    </label>
                  </div>
                  <div class="input-group">
                    <div class="input-group-text">
                        <input
                          type="checkbox"
                          class="form-check-input mt-0"
                          name="senalelasfuentesfrecuente"
                          value={otrossenalelasfuentesfrecuente}
                          checked={fichaFamiliar.senalelasfuentesfrecuente.includes(otrossenalelasfuentesfrecuente)}
                          onChange={handleChange}
                          //onChange={(e) => fichaFamiliar.senalelasfuentesfrecuente(e.target.checked)}
                        />
                      </div>
                      <input type="text" class="form-control" aria-label="Text input with checkbox" 
                        placeholder="Digite otras opciones de ser necesario"
                        value={otrossenalelasfuentesfrecuente}
                        name="otrossenalelasfuentesfrecuente"
                        //onChange={handleInputChange}
                        //onBlur={handleInputBlur}
                        onChange={(event) => handleInputChange(event, setOtrosSenalelasfuentesfrecuente)}
                        onBlur={() => handleInputBlur(otrossenalelasfuentesfrecuente, 'senalelasfuentesfrecuente')}
                        />
                  </div>
                  {!fichaFamiliar.senalelasfuentesfrecuente.length && <div className="text-danger">Campo obligatorio</div>}
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <div>
                  <label class="form-label">¿Cuáles de las siguientes prácticas o hábitos relacionados con la calidad del aire intradomiciliario, se identifican en la vivienda?</label>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="cualesdelassiguientesprac"
                        value="En la vivienda se cocina con carbón o leña"
                        checked={fichaFamiliar.cualesdelassiguientesprac.includes('En la vivienda se cocina con carbón o leña')}
                        onChange={handleChange}
                      />
                      En la vivienda se cocina con carbón o leña
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="cualesdelassiguientesprac"
                        value="Hay uno o más fumadores activos en la vivienda y habitualmente consumen a lo largo del día uno o más cigarrillos."
                        checked={fichaFamiliar.cualesdelassiguientesprac.includes('Hay uno o más fumadores activos en la vivienda y habitualmente consumen a lo largo del día uno o más cigarrillos.')}
                        onChange={handleChange}
                      />
                      Hay uno o más fumadores activos en la vivienda y habitualmente consumen a lo largo del día uno o más cigarrillos.
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="cualesdelassiguientesprac"
                        value="Existen otras prácticas como enceder el vehículo dentro de la vivienda en un área donde no hay buena ventilación."
                        checked={fichaFamiliar.cualesdelassiguientesprac.includes('Existen otras prácticas como enceder el vehículo dentro de la vivienda en un área donde no hay buena ventilación.')}
                        onChange={handleChange}
                      />
                      Existen otras prácticas como enceder el vehículo dentro de la vivienda en un área donde no hay buena ventilación.
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="cualesdelassiguientesprac"
                        value="Quema de basura afuera de la vivienda y el humo ingresa a la vivienda"
                        checked={fichaFamiliar.cualesdelassiguientesprac.includes('Quema de basura afuera de la vivienda y el humo ingresa a la vivienda')}
                        onChange={handleChange}
                      />
                      Quema de basura afuera de la vivienda y el humo ingresa a la vivienda
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="cualesdelassiguientesprac"
                        value="Barrido de pisos con levantamiento de polvillo que se queda suspendido al interior de la vivienda"
                        checked={fichaFamiliar.cualesdelassiguientesprac.includes('Barrido de pisos con levantamiento de polvillo que se queda suspendido al interior de la vivienda')}
                        onChange={handleChange}
                      />
                      Barrido de pisos con levantamiento de polvillo que se queda suspendido al interior de la vivienda
                    </label>
                  </div>
                  {!fichaFamiliar.cualesdelassiguientesprac.length && <div className="text-danger">Campo obligatorio</div>}
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <div>
                  <label class="form-label">¿Que tipo de estufa se emplea en la vivienda?</label>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="quetipodeestufaseempleaen"
                        value="Electrica, de gas,  petróleo, gasolina, kerosén, alcohol con conexiones en buen estado, sin fugas, buen estado de combustión, no hay riesgo eléctrico"
                        checked={fichaFamiliar.quetipodeestufaseempleaen.includes('Electrica, de gas,  petróleo, gasolina, kerosén, alcohol con conexiones en buen estado, sin fugas, buen estado de combustión, no hay riesgo eléctrico')}
                        onChange={handleChange}
                      />
                      Electrica, de gas,  petróleo, gasolina, kerosén, alcohol con conexiones en buen estado, sin fugas, buen estado de combustión, no hay riesgo eléctrico
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="quetipodeestufaseempleaen"
                        value="Electrica, de gas,  petróleo, gasolina, kerosén, alcohol con conexiones en mal estado,  reparaciones defectuosas, mal estado de combustión"
                        checked={fichaFamiliar.quetipodeestufaseempleaen.includes('Electrica, de gas,  petróleo, gasolina, kerosén, alcohol con conexiones en mal estado,  reparaciones defectuosas, mal estado de combustión')}
                        onChange={handleChange}
                      />
                      Electrica, de gas,  petróleo, gasolina, kerosén, alcohol con conexiones en mal estado,  reparaciones defectuosas, mal estado de combustión
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="quetipodeestufaseempleaen"
                        value="De leña, madera, carbón de leña o carbón mineral mejorada, con sistema de extracción de humo y gases (Chimenea) y está ubicada fuera de la vivienda,s o está ubica al interior de la vivienda, y se encuentra en buen estado"
                        checked={fichaFamiliar.quetipodeestufaseempleaen.includes('De leña, madera, carbón de leña o carbón mineral mejorada, con sistema de extracción de humo y gases (Chimenea) y está ubicada fuera de la vivienda,s o está ubica al interior de la vivienda, y se encuentra en buen estado')}
                        onChange={handleChange}
                      />
                      De leña, madera, carbón de leña o carbón mineral mejorada, con sistema de extracción de humo y gases (Chimenea) y está ubicada fuera de la vivienda,s o está ubica al interior de la vivienda, y se encuentra en buen estado
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="quetipodeestufaseempleaen"
                        value="De leña, madera, carbón de leña o carbón mineral ubicada dentro de la vivienda, con señales (manchas) de permitir el ingreso de humo en la vivienda"
                        checked={fichaFamiliar.quetipodeestufaseempleaen.includes('De leña, madera, carbón de leña o carbón mineral ubicada dentro de la vivienda, con señales (manchas) de permitir el ingreso de humo en la vivienda')}
                        onChange={handleChange}
                      />
                      De leña, madera, carbón de leña o carbón mineral ubicada dentro de la vivienda, con señales (manchas) de permitir el ingreso de humo en la vivienda
                    </label>
                  </div>
                  {!fichaFamiliar.quetipodeestufaseempleaen.length && <div className="text-danger">Campo obligatorio</div>}
                </div>
              </div>  
            </div>
            <div class="col-12 col-md-6 col-lg-4">       
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <div>
                  <label class="form-label">Que medidas se implementan cuando un miembro de la familia padece una enfermedad 6.6 respiratoria, para prevenir el contagio de otras personas?</label>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="quemedidasseimplementancu"
                        value="Uso de tapabocas"
                        checked={fichaFamiliar.quemedidasseimplementancu.includes('Uso de tapabocas')}
                        onChange={handleChange}
                      />
                      Uso de tapabocas
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="quemedidasseimplementancu"
                        value="Lavado frecuente de manos"
                        checked={fichaFamiliar.quemedidasseimplementancu.includes('Lavado frecuente de manos')}
                        onChange={handleChange}
                      />
                      Lavado frecuente de manos
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="quemedidasseimplementancu"
                        value="Cubrir rostro al toser y estornudar"
                        checked={fichaFamiliar.quemedidasseimplementancu.includes('Cubrir rostro al toser y estornudar')}
                        onChange={handleChange}
                      />
                      Cubrir rostro al toser y estornudar
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="quemedidasseimplementancu"
                        value="Ninguno"
                        checked={fichaFamiliar.quemedidasseimplementancu.includes('Ninguno')}
                        onChange={handleChange}
                      />
                      Ninguno
                    </label>
                  </div>
                  <div class="input-group">
                    <div class="input-group-text">
                        <input
                          type="checkbox"
                          class="form-check-input mt-0"
                          name="quemedidasseimplementancu"
                          value={otrosquemedidasseimplementancu}
                          checked={fichaFamiliar.quemedidasseimplementancu.includes(otrosquemedidasseimplementancu)}
                          onChange={handleChange}
                          //onChange={(e) => fichaFamiliar.quemedidasseimplementancu(e.target.checked)}
                        />
                      </div>
                      <input type="text" class="form-control" aria-label="Text input with checkbox" 
                        placeholder="Digite otras opciones de ser necesario"
                        value={otrosquemedidasseimplementancu}
                        name="otrosquemedidasseimplementancu"
                        //onChange={handleInputChange}
                        //onBlur={handleInputBlur}
                        onChange={(event) => handleInputChange(event, setOtrosQuemedidasseimplementancu)}
                        onBlur={() => handleInputBlur(otrosquemedidasseimplementancu, 'quemedidasseimplementancu')}
                        />
                  </div>
                  {!fichaFamiliar.quemedidasseimplementancu.length && <div className="text-danger">Campo obligatorio</div>}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="m-2 mt-4 border border-primary-subtle"><h4 class="text"> 2.8.	ZOONOSIS</h4>
          <div class="row">
            <div class="col-12">       
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">La familia convive con animales de compañía y/o producción dentro de la vivienda o su entorno inmediato</label>
                <select className={`form-select ${!fichaFamiliar.lafamiliaconviveconanimal ? 'is-invalid' : ''}`} name='lafamiliaconviveconanimal' value={fichaFamiliar.lafamiliaconviveconanimal} onChange={handleChange}>
                  <option selected></option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </select>
                {!fichaFamiliar.lafamiliaconviveconanimal && <div className="invalid-feedback">Campo obligatorio</div>}
              </div>
            </div>
            <div class="col-12"> 
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">Señale los animales que conviven con la familia dentro de la vivienda o en su entorno inmediato, e indique cuantos son:</label>
                <div class="row">
                  <div class="col-md-3">
                    <label class="form-label">Perros</label>
                    <input
                      type="number"
                      name='senalelosanimalesqueconviper'
                      placeholder='Perros'
                      className={`form-control`}
                      value={fichaFamiliar.senalelosanimalesqueconviper}
                      onChange={handleChange}
                    />
                  </div>
                  <div class="col-md-3">
                    <label class="form-label">Gatos</label>
                    <input
                      type="number"
                      name='senalelosanimalesqueconviga'
                      placeholder='Gatos'
                      className={`form-control`}
                      value={fichaFamiliar.senalelosanimalesqueconviga}
                      onChange={handleChange}
                    />
                  </div>
                  <div class="col-md-3">
                    <label class="form-label">Porcinos</label>
                    <input
                      type="number"
                      name='senalelosanimalesqueconvipo'
                      placeholder='Porcinos'
                      className={`form-control`}
                      value={fichaFamiliar.senalelosanimalesqueconvipo}
                      onChange={handleChange}
                    />
                  </div>
                  <div class="col-md-3">
                    <label class="form-label">Bóvidos: Búfalos, vacas, toros</label>
                    <input
                      type="number"
                      name='senalelosanimalesqueconvibo'
                      placeholder='Bóvidos: Búfalos, vacas, toros'
                      className={`form-control`}
                      value={fichaFamiliar.senalelosanimalesqueconvibo}
                      onChange={handleChange}
                    />
                  </div>
                  <div class="col-md-3">
                    <label class="form-label">Equidos: Asnos, mulas, caballos, burros</label>
                    <input
                      type="number"
                      name='senalelosanimalesqueconvieq'
                      placeholder='Equidos: Asnos, mulas, caballos, burros'
                      className={`form-control`}
                      value={fichaFamiliar.senalelosanimalesqueconvieq}
                      onChange={handleChange}
                    />
                  </div>
                  <div class="col-md-3">
                    <label class="form-label">Ovinos / caprino</label>
                    <input
                      type="number"
                      name='senalelosanimalesqueconviov'
                      placeholder='Ovinos / caprino'
                      className={`form-control`}
                      value={fichaFamiliar.senalelosanimalesqueconviov}
                      onChange={handleChange}
                    />
                  </div>
                  <div class="col-md-3">
                    <label class="form-label">Aves de producción</label>
                    <input
                      type="number"
                      name='senalelosanimalesqueconviap'
                      placeholder='Aves de producción'
                      className={`form-control`}
                      value={fichaFamiliar.senalelosanimalesqueconviap}
                      onChange={handleChange}
                    />
                  </div>
                  <div class="col-md-3">
                    <label class="form-label">Aves ornamentales: canarios, pericos australianos, cacatuas</label>
                    <input
                      type="number"
                      name='senalelosanimalesqueconviao'
                      placeholder='Aves ornamentales: canarios, pericos australianos, cacatuas'
                      className={`form-control`}
                      value={fichaFamiliar.senalelosanimalesqueconviao}
                      onChange={handleChange}
                    />
                  </div>
                  <div class="col-md-3">
                    <label class="form-label">Peces ornamentales, hamster</label>
                    <input
                      type="number"
                      name='senalelosanimalesqueconvipe'
                      placeholder='Peces ornamentales, hamster'
                      className={`form-control`}
                      value={fichaFamiliar.senalelosanimalesqueconvipe}
                      onChange={handleChange}
                    />
                  </div>
                  <div class="col-md-3">
                    <label class="form-label">Cobayos, conejos</label>
                    <input
                      type="number"
                      name='senalelosanimalesqueconvico'
                      placeholder='Cobayos, conejos'
                      className={`form-control`}
                      value={fichaFamiliar.senalelosanimalesqueconvico}
                      onChange={handleChange}
                    />
                  </div>
                  <div class="col-md-3">
                    <label class="form-label">Animales silvestres: serpientes, loros, tortugas, osos hormigueros, perezosos, águilas, buhos, primates, palomas, entre otros</label>
                    <input
                      type="number"
                      name='senalelosanimalesqueconvian'
                      placeholder='Animales silvestres: serpientes, loros, tortugas, osos hormigueros, perezosos, águilas, buhos, primates, palomas, entre otros'
                      className={`form-control`}
                      value={fichaFamiliar.senalelosanimalesqueconvian}
                      onChange={handleChange}
                    />
                  </div>
                  <div class="col-md-3">
                    <label class="form-label">Otros</label>
                    <input
                      type="number"
                      name='senalelosanimalesqueconviot'
                      placeholder='Otros'
                      className={`form-control`}
                      value={fichaFamiliar.senalelosanimalesqueconviot}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {/* {!fichaFamiliar.numeroficha && <div className="invalid-feedback">Campo obligatorio</div>} */}
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <div>
                  <label class="form-label">Cual es la finalidad de tener estos animales?</label>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="cualeslafinalidaddetenere"
                        value="compañía"
                        checked={fichaFamiliar.cualeslafinalidaddetenere.includes('compañía')}
                        onChange={handleChange}
                      />
                      compañía
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="cualeslafinalidaddetenere"
                        value="Producción"
                        checked={fichaFamiliar.cualeslafinalidaddetenere.includes('Producción')}
                        onChange={handleChange}
                      />
                      Producción
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="cualeslafinalidaddetenere"
                        value="Autoconsumo"
                        checked={fichaFamiliar.cualeslafinalidaddetenere.includes('Autoconsumo')}
                        onChange={handleChange}
                      />
                      Autoconsumo
                    </label>
                  </div>
                  {/* {!fichaFamiliar.cualeslafinalidaddetenere.length && <div className="text-danger">Campo obligatorio</div>} */}
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <div>
                  <label class="form-label">Los animales domésticos están:</label>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="losanimalesdomesticosesta"
                        value="Totalmente confinados"
                        checked={fichaFamiliar.losanimalesdomesticosesta.includes('Totalmente confinados')}
                        onChange={handleChange}
                      />
                      Totalmente confinados
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="losanimalesdomesticosesta"
                        value="Parcialmente confinados"
                        checked={fichaFamiliar.losanimalesdomesticosesta.includes('Parcialmente confinados')}
                        onChange={handleChange}
                      />
                      Parcialmente confinados
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="losanimalesdomesticosesta"
                        value="Libres"
                        checked={fichaFamiliar.losanimalesdomesticosesta.includes('Libres')}
                        onChange={handleChange}
                      />
                      Libres
                    </label>
                  </div>
                  {/* {!fichaFamiliar.losanimalesdomesticosesta.length && <div className="text-danger">Campo obligatorio</div>} */}
                </div>
              </div> 
            </div>
            <div class="col-12 col-md-6 col-lg-4">     
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">Se desparasita a los animales domésticos?</label>
                <select className={`form-select`} name='sedesparasitaalosanimales' value={fichaFamiliar.sedesparasitaalosanimales} onChange={handleChange}>
                  <option selected></option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </select>
                {/* {!fichaFamiliar.sedesparasitaalosanimales && <div className="invalid-feedback">Campo obligatorio</div>} */}
              </div>  
            </div>
            <div class="col-12 col-md-6 col-lg-4">    
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">Las instalaciones para atención y manejo de los animales de producción que habitan en la  vivienda (patios, solares, establos, pecebreras),</label>
                <select className={`form-select`} name='lasinstalacionesparaatenc' value={fichaFamiliar.lasinstalacionesparaatenc} onChange={handleChange}>
                  <option selected></option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </select>
                {/* {!fichaFamiliar.lasinstalacionesparaatenc && <div className="invalid-feedback">Campo obligatorio</div>} */}
              </div>   
            </div>
            <div class="col-12 col-md-6 col-lg-4">   
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">Los animales de compañía (perros y gatos) son vacunados contra la rabia una vez al año</label>
                <select className={`form-select`} name='losanimalesdecompaniaperr' value={fichaFamiliar.losanimalesdecompaniaperr} onChange={handleChange}>
                  <option selected></option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </select>
                {/* {!fichaFamiliar.losanimalesdecompaniaperr && <div className="invalid-feedback">Campo obligatorio</div>} */}
              </div>    
            </div>
            <div class="col-12 col-md-6 col-lg-4">  
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">Las excretas de los animales de compañía se recogen y disponen adecuadamente?</label>
                <select className={`form-select`} name='lasexcretasdelosanimalesd' value={fichaFamiliar.lasexcretasdelosanimalesd} onChange={handleChange}>
                  <option selected></option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </select>
                {/* {!fichaFamiliar.lasexcretasdelosanimalesd && <div className="invalid-feedback">Campo obligatorio</div>} */}
              </div>
            </div>
          </div>
        </div>
        <div class="m-2 mt-4 border border-primary-subtle"><h4 class="text"> 2.9.	CONTROL DE VECTORES</h4>
          <div class="row">
            <div class="col-12 col-md-6"> 
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">Se evidencia o la familia informa la presencia vectores transmisores de enfermedades en la vivienda o en su entorno inmediato?</label>
                <select className={`form-select`} name='seevidenciaolafamiliainfo' value={fichaFamiliar.seevidenciaolafamiliainfo} onChange={handleChange}>
                  <option selected></option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </select>
                {/* {!fichaFamiliar.seevidenciaolafamiliainfo && <div className="invalid-feedback">Campo obligatorio</div>} */}
              </div>
            </div>
            <div class="col-12 col-md-6"> 
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <div>
                  <label class="form-label">¿Cuáles de los siguientes vectores transmisores de enfermedades se evidencian en la vivienda o en su entorno inmediato?</label>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="cualesdelossiguientesvect"
                        value="Zancudos"
                        checked={fichaFamiliar.cualesdelossiguientesvect.includes('Zancudos')}
                        onChange={handleChange}
                      />
                      Zancudos
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="cualesdelossiguientesvect"
                        value="Cucarachas"
                        checked={fichaFamiliar.cualesdelossiguientesvect.includes('Cucarachas')}
                        onChange={handleChange}
                      />
                      Cucarachas
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="cualesdelossiguientesvect"
                        value="Moscas"
                        checked={fichaFamiliar.cualesdelossiguientesvect.includes('Moscas')}
                        onChange={handleChange}
                      />
                      Moscas
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="cualesdelossiguientesvect"
                        value="Mosquitos"
                        checked={fichaFamiliar.cualesdelossiguientesvect.includes('Mosquitos')}
                        onChange={handleChange}
                      />
                      Mosquitos
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="cualesdelossiguientesvect"
                        value="Pulgas"
                        checked={fichaFamiliar.cualesdelossiguientesvect.includes('Pulgas')}
                        onChange={handleChange}
                      />
                      Pulgas
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="cualesdelossiguientesvect"
                        value="Piojos"
                        checked={fichaFamiliar.cualesdelossiguientesvect.includes('Piojos')}
                        onChange={handleChange}
                      />
                      Piojos
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="cualesdelossiguientesvect"
                        value="Garrapatas"
                        checked={fichaFamiliar.cualesdelossiguientesvect.includes('Garrapatas')}
                        onChange={handleChange}
                      />
                      Garrapatas
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="cualesdelossiguientesvect"
                        value="Roedores"
                        checked={fichaFamiliar.cualesdelossiguientesvect.includes('Roedores')}
                        onChange={handleChange}
                      />
                      Roedores
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="cualesdelossiguientesvect"
                        value="Chinches"
                        checked={fichaFamiliar.cualesdelossiguientesvect.includes('Chinches')}
                        onChange={handleChange}
                      />
                      Chinches
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="cualesdelossiguientesvect"
                        value="Triatominos (Pito)"
                        checked={fichaFamiliar.cualesdelossiguientesvect.includes('Triatominos (Pito)')}
                        onChange={handleChange}
                      />
                      Triatominos (Pito)
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="cualesdelossiguientesvect"
                        value="Ninguno"
                        checked={fichaFamiliar.cualesdelossiguientesvect.includes('Ninguno')}
                        onChange={handleChange}
                      />
                      Ninguno
                    </label>
                  </div>
                  <div class="input-group">
                    <div class="input-group-text">
                        <input
                          type="checkbox"
                          class="form-check-input mt-0"
                          name="cualesdelossiguientesvect"
                          value={otroscualesdelossiguientesvect}
                          checked={fichaFamiliar.cualesdelossiguientesvect.includes(otroscualesdelossiguientesvect)}
                          onChange={handleChange}
                          //onChange={(e) => fichaFamiliar.cualesdelossiguientesvect(e.target.checked)}
                        />
                      </div>
                      <input type="text" class="form-control" aria-label="Text input with checkbox" 
                        placeholder="Digite otras opciones de ser necesario"
                        value={otroscualesdelossiguientesvect}
                        name="otroscualesdelossiguientesvect"
                        //onChange={handleInputChange}
                        //onBlur={handleInputBlur}
                        onChange={(event) => handleInputChange(event, setOtrosCualesdelossiguientesvect)}
                        onBlur={() => handleInputBlur(otroscualesdelossiguientesvect, 'cualesdelossiguientesvect')}
                        />
                  </div>
                  {/* {!fichaFamiliar.cualesdelossiguientesvect.length && <div className="text-danger">Campo obligatorio</div>} */}
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6"> 
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <div>
                  <label class="form-label">¿Se observa cerca de la vivienda o dentro de ella alguno(s) de los siguientes criaderos o reservorios que pueden favorecer la presencia de vectores transmisores de enfermedades?</label>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="seobservacercadelaviviend"
                        value="Agua estancada o de agua lluvias cerca de la vivienda o dentro de ella que puede favorecer la presencia de vectores"
                        checked={fichaFamiliar.seobservacercadelaviviend.includes('Agua estancada o de agua lluvias cerca de la vivienda o dentro de ella que puede favorecer la presencia de vectores')}
                        onChange={handleChange}
                      />
                      Agua estancada o de agua lluvias cerca de la vivienda o dentro de ella que puede favorecer la presencia de vectores
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="seobservacercadelaviviend"
                        value="El perodomicilio, jardín o espacios de la vivienda como patio o azotea tienen el pasto sin podar, matorrales o con acumulación de hojarasca, maleza,  se observa materia fecal de los animales de compañía, hay desagües destapados, canaletas obstruidas con hojas secas, hay agua almacenada en recipientes sin tapa (baldes, tanques de agua, albercas, lavadero), hay acumulación de elementos inservibles como llantas, y bebederos y comederos de los animales de compañía sucios"
                        checked={fichaFamiliar.seobservacercadelaviviend.includes('El perodomicilio, jardín o espacios de la vivienda como patio o azotea tienen el pasto sin podar, matorrales o con acumulación de hojarasca, maleza,  se observa materia fecal de los animales de compañía, hay desagües destapados, canaletas obstruidas con hojas secas, hay agua almacenada en recipientes sin tapa (baldes, tanques de agua, albercas, lavadero), hay acumulación de elementos inservibles como llantas, y bebederos y comederos de los animales de compañía sucios')}
                        onChange={handleChange}
                      />
                      El perodomicilio, jardín o espacios de la vivienda como patio o azotea tienen el pasto sin podar, matorrales o con acumulación de hojarasca, maleza,  se observa materia fecal de los animales de compañía, hay desagües destapados, canaletas obstruidas con hojas secas, hay agua almacenada en recipientes sin tapa (baldes, tanques de agua, albercas, lavadero), hay acumulación de elementos inservibles como llantas, y bebederos y comederos de los animales de compañía sucios
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="seobservacercadelaviviend"
                        value="Dentro de la vivienda se encuentran desagües destapados, paredes con grietas, se almacena agua en recipientes sin tapa, hay acumulación de residuos generales como bolsas de plástico, latas vacías, botellas, etc.,  bebederos y comederos de los animales de compañía sucios, floreros y materas con platos debajo de ellas con agua estancada"
                        checked={fichaFamiliar.seobservacercadelaviviend.includes('Dentro de la vivienda se encuentran desagües destapados, paredes con grietas, se almacena agua en recipientes sin tapa, hay acumulación de residuos generales como bolsas de plástico, latas vacías, botellas, etc.,  bebederos y comederos de los animales de compañía sucios, floreros y materas con platos debajo de ellas con agua estancada')}
                        onChange={handleChange}
                      />
                      Dentro de la vivienda se encuentran desagües destapados, paredes con grietas, se almacena agua en recipientes sin tapa, hay acumulación de residuos generales como bolsas de plástico, latas vacías, botellas, etc.,  bebederos y comederos de los animales de compañía sucios, floreros y materas con platos debajo de ellas con agua estancada
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="seobservacercadelaviviend"
                        value="Se encuentran los gallineros o porquerizas cerca de los dormitorios "
                        checked={fichaFamiliar.seobservacercadelaviviend.includes('Se encuentran los gallineros o porquerizas cerca de los dormitorios ')}
                        onChange={handleChange}
                      />
                      Se encuentran los gallineros o porquerizas cerca de los dormitorios 
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="seobservacercadelaviviend"
                        value="No se observan condiciones o prácticas que favorezcan la presencia de vectores"
                        checked={fichaFamiliar.seobservacercadelaviviend.includes('No se observan condiciones o prácticas que favorezcan la presencia de vectores')}
                        onChange={handleChange}
                      />
                      No se observan condiciones o prácticas que favorezcan la presencia de vectores
                    </label>
                  </div>
                  {/* {!fichaFamiliar.seobservacercadelaviviend.length && <div className="text-danger">Campo obligatorio</div>} */}
                </div>
              </div> 
            </div>
            <div class="col-12 col-md-6">         
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <div>
                  <label class="form-label">¿Que medidas se ponen en práctica para control de vectores transmisores de enfermedades?</label>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="quemedidasseponenenpracti"
                        value="Manejo higiénico de los residuos sólidos al interior de la vivienda"
                        checked={fichaFamiliar.quemedidasseponenenpracti.includes('Manejo higiénico de los residuos sólidos al interior de la vivienda')}
                        onChange={handleChange}
                      />
                      Manejo higiénico de los residuos sólidos al interior de la vivienda
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="quemedidasseponenenpracti"
                        value="Evitar el uso de fuentes de luz blanca o brillante en ambientes oscuros dentro de la vivienda"
                        checked={fichaFamiliar.quemedidasseponenenpracti.includes('Evitar el uso de fuentes de luz blanca o brillante en ambientes oscuros dentro de la vivienda')}
                        onChange={handleChange}
                      />
                      Evitar el uso de fuentes de luz blanca o brillante en ambientes oscuros dentro de la vivienda
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="quemedidasseponenenpracti"
                        value="Manejo higiénico de los residuos sólidos en el entorno de la vivienda"
                        checked={fichaFamiliar.quemedidasseponenenpracti.includes('Manejo higiénico de los residuos sólidos en el entorno de la vivienda')}
                        onChange={handleChange}
                      />
                      Manejo higiénico de los residuos sólidos en el entorno de la vivienda
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="quemedidasseponenenpracti"
                        value="Recolección de inservibles"
                        checked={fichaFamiliar.quemedidasseponenenpracti.includes('Recolección de inservibles')}
                        onChange={handleChange}
                      />
                      Recolección de inservibles
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="quemedidasseponenenpracti"
                        value="Uso de toldillos"
                        checked={fichaFamiliar.quemedidasseponenenpracti.includes('Uso de toldillos')}
                        onChange={handleChange}
                      />
                      Uso de toldillos
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="quemedidasseponenenpracti"
                        value="Uso de angeos y trampas caseras"
                        checked={fichaFamiliar.quemedidasseponenenpracti.includes('Uso de angeos y trampas caseras')}
                        onChange={handleChange}
                      />
                      Uso de angeos y trampas caseras
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="quemedidasseponenenpracti"
                        value="Ordenamiento e higiene"
                        checked={fichaFamiliar.quemedidasseponenenpracti.includes('Ordenamiento e higiene')}
                        onChange={handleChange}
                      />
                      Ordenamiento e higiene
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="quemedidasseponenenpracti"
                        value="Limpieza de malezas"
                        checked={fichaFamiliar.quemedidasseponenenpracti.includes('Limpieza de malezas')}
                        onChange={handleChange}
                      />
                      Limpieza de malezas
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="quemedidasseponenenpracti"
                        value="Drenaje de zonas encharcadas"
                        checked={fichaFamiliar.quemedidasseponenenpracti.includes('Drenaje de zonas encharcadas')}
                        onChange={handleChange}
                      />
                      Drenaje de zonas encharcadas
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="quemedidasseponenenpracti"
                        value="Protección de depósitos de agua de consumo mientras no estén en uso"
                        checked={fichaFamiliar.quemedidasseponenenpracti.includes('Protección de depósitos de agua de consumo mientras no estén en uso')}
                        onChange={handleChange}
                      />
                      Protección de depósitos de agua de consumo mientras no estén en uso
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="quemedidasseponenenpracti"
                        value="Retiro de recipientes que usualmente contienen o acumulan agua"
                        checked={fichaFamiliar.quemedidasseponenenpracti.includes('Retiro de recipientes que usualmente contienen o acumulan agua')}
                        onChange={handleChange}
                      />
                      Retiro de recipientes que usualmente contienen o acumulan agua
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="quemedidasseponenenpracti"
                        value="Reemplazo de techos en paja por techo de zinc"
                        checked={fichaFamiliar.quemedidasseponenenpracti.includes('Reemplazo de techos en paja por techo de zinc')}
                        onChange={handleChange}
                      />
                      Reemplazo de techos en paja por techo de zinc
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="quemedidasseponenenpracti"
                        value="Reparaciones para eliminar sitios húmedos o pocos iluminados"
                        checked={fichaFamiliar.quemedidasseponenenpracti.includes('Reparaciones para eliminar sitios húmedos o pocos iluminados')}
                        onChange={handleChange}
                      />
                      Reparaciones para eliminar sitios húmedos o pocos iluminados
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="quemedidasseponenenpracti"
                        value="Uso de pantalones y camisas manga larga de colores claro"
                        checked={fichaFamiliar.quemedidasseponenenpracti.includes('Uso de pantalones y camisas manga larga de colores claro')}
                        onChange={handleChange}
                      />
                      Uso de pantalones y camisas manga larga de colores claro
                    </label>
                  </div>
                  <div class="input-group">
                    <div class="input-group-text">
                        <input
                          type="checkbox"
                          class="form-check-input mt-0"
                          name="quemedidasseponenenpracti"
                          value={otrosquemedidasseponenenpracti}
                          checked={fichaFamiliar.quemedidasseponenenpracti.includes(otrosquemedidasseponenenpracti)}
                          onChange={handleChange}
                          //onChange={(e) => fichaFamiliar.quemedidasseponenenpracti(e.target.checked)}
                        />
                      </div>
                      <input type="text" class="form-control" aria-label="Text input with checkbox" 
                        placeholder="Digite otras opciones de ser necesario"
                        value={otrosquemedidasseponenenpracti}
                        name="otrosquemedidasseponenenpracti"
                        //onChange={handleInputChange}
                        //onBlur={handleInputBlur}
                        onChange={(event) => handleInputChange(event, setOtrosQuemedidasseponenenpracti)}
                        onBlur={() => handleInputBlur(otrosquemedidasseponenenpracti, 'quemedidasseponenenpracti')}
                        />
                  </div>
                  {/* {!fichaFamiliar.quemedidasseponenenpracti.length && <div className="text-danger">Campo obligatorio</div>} */}
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6"> 
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">¿Se observan animales ponzoñosos o venenosos al interior de la vivienda o en su entorno inmediato?</label>
                <select className={`form-select`} name='seobservananimalesponzono' value={fichaFamiliar.seobservananimalesponzono} onChange={handleChange}>
                  <option selected></option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </select>
                {/* {!fichaFamiliar.seobservananimalesponzono && <div className="invalid-feedback">Campo obligatorio</div>} */}
              </div>
            </div>
            <div class="col-12 col-md-6"> 
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <div>
                  <label class="form-label">¿Se observan alguno(s) de los siguientes animales ponzoñosos o venenosos al interior de la vivienda o en su entorno inmediato?</label>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="seobservanalgunosdelossig"
                        value="Arañas"
                        checked={fichaFamiliar.seobservanalgunosdelossig.includes('Arañas')}
                        onChange={handleChange}
                      />
                      Arañas
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="seobservanalgunosdelossig"
                        value="Escorpiones o Alacranes"
                        checked={fichaFamiliar.seobservanalgunosdelossig.includes('Escorpiones o Alacranes')}
                        onChange={handleChange}
                      />
                      Escorpiones o Alacranes
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="seobservanalgunosdelossig"
                        value="Serpientes o víboras"
                        checked={fichaFamiliar.seobservanalgunosdelossig.includes('Serpientes o víboras')}
                        onChange={handleChange}
                      />
                      Serpientes o víboras
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="seobservanalgunosdelossig"
                        value="Abejas"
                        checked={fichaFamiliar.seobservanalgunosdelossig.includes('Abejas')}
                        onChange={handleChange}
                      />
                      Abejas
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="seobservanalgunosdelossig"
                        value="Avispas"
                        checked={fichaFamiliar.seobservanalgunosdelossig.includes('Avispas')}
                        onChange={handleChange}
                      />
                      Avispas
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="seobservanalgunosdelossig"
                        value="Orugas"
                        checked={fichaFamiliar.seobservanalgunosdelossig.includes('Orugas')}
                        onChange={handleChange}
                      />
                      Orugas
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="seobservanalgunosdelossig"
                        value="Ninguno"
                        checked={fichaFamiliar.seobservanalgunosdelossig.includes('Ninguno')}
                        onChange={handleChange}
                      />
                      Ninguno
                    </label>
                  </div>
                  <div class="input-group">
                    <div class="input-group-text">
                        <input
                          type="checkbox"
                          class="form-check-input mt-0"
                          name="seobservanalgunosdelossig"
                          value={otrosseobservanalgunosdelossig}
                          checked={fichaFamiliar.seobservanalgunosdelossig.includes(otrosseobservanalgunosdelossig)}
                          onChange={handleChange}
                          //onChange={(e) => fichaFamiliar.seobservanalgunosdelossig(e.target.checked)}
                        />
                      </div>
                      <input type="text" class="form-control" aria-label="Text input with checkbox" 
                        placeholder="Digite otras opciones de ser necesario"
                        value={otrosseobservanalgunosdelossig}
                        name="otrosseobservanalgunosdelossig"
                        //onChange={handleInputChange}
                        //onBlur={handleInputBlur}
                        onChange={(event) => handleInputChange(event, setOtrosSeobservanalgunosdelossig)}
                        onBlur={() => handleInputBlur(otrosseobservanalgunosdelossig, 'seobservanalgunosdelossig')}
                        />
                  </div>
                  {/* {!fichaFamiliar.seobservanalgunosdelossig.length && <div className="text-danger">Campo obligatorio</div>} */}
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6"> 
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <div>
                  <label class="form-label">¿Que medidas se ponen en práctica para reducir el riesgo por ataque de animales venenosos, ponzoñosos y mosquitos?</label>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="quemedidasseponenenpract"
                        value="Sacudir la ropa y los zapatos antes de colocarselos"
                        checked={fichaFamiliar.quemedidasseponenenpract.includes('Sacudir la ropa y los zapatos antes de colocarselos')}
                        onChange={handleChange}
                      />
                      Sacudir la ropa y los zapatos antes de colocarselos
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="quemedidasseponenenpract"
                        value="No meter las manos en huecos de los árboles"
                        checked={fichaFamiliar.quemedidasseponenenpract.includes('No meter las manos en huecos de los árboles')}
                        onChange={handleChange}
                      />
                      No meter las manos en huecos de los árboles
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="quemedidasseponenenpract"
                        value="Tener precaución al levantar piedras o rocas para no entrar en contacto con animales venenosos o ponzoñosos"
                        checked={fichaFamiliar.quemedidasseponenenpract.includes('Tener precaución al levantar piedras o rocas para no entrar en contacto con animales venenosos o ponzoñosos')}
                        onChange={handleChange}
                      />
                      Tener precaución al levantar piedras o rocas para no entrar en contacto con animales venenosos o ponzoñosos
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="quemedidasseponenenpract"
                        value="Orden e higiene en las viviendas y el peridomicilio"
                        checked={fichaFamiliar.quemedidasseponenenpract.includes('Orden e higiene en las viviendas y el peridomicilio')}
                        onChange={handleChange}
                      />
                      Orden e higiene en las viviendas y el peridomicilio
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="quemedidasseponenenpract"
                        value="No caminar junto al corte del monte en los caminos de herradura"
                        checked={fichaFamiliar.quemedidasseponenenpract.includes('No caminar junto al corte del monte en los caminos de herradura')}
                        onChange={handleChange}
                      />
                      No caminar junto al corte del monte en los caminos de herradura
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="quemedidasseponenenpract"
                        value="No caminar descalzo en el campo, usar botas de caña alta"
                        checked={fichaFamiliar.quemedidasseponenenpract.includes('No caminar descalzo en el campo, usar botas de caña alta')}
                        onChange={handleChange}
                      />
                      No caminar descalzo en el campo, usar botas de caña alta
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="quemedidasseponenenpract"
                        value="Evitar que los niños y niñas jueguen en zonas boscosas"
                        checked={fichaFamiliar.quemedidasseponenenpract.includes('Evitar que los niños y niñas jueguen en zonas boscosas')}
                        onChange={handleChange}
                      />
                      Evitar que los niños y niñas jueguen en zonas boscosas
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="quemedidasseponenenpract"
                        value="Transitar si es posible en compañía de perros"
                        checked={fichaFamiliar.quemedidasseponenenpract.includes('Transitar si es posible en compañía de perros')}
                        onChange={handleChange}
                      />
                      Transitar si es posible en compañía de perros
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="quemedidasseponenenpract"
                        value="Evitar deambular de noche"
                        checked={fichaFamiliar.quemedidasseponenenpract.includes('Evitar deambular de noche')}
                        onChange={handleChange}
                      />
                      Evitar deambular de noche
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="quemedidasseponenenpract"
                        value="No molestar panales de abejas o avispas"
                        checked={fichaFamiliar.quemedidasseponenenpract.includes('No molestar panales de abejas o avispas')}
                        onChange={handleChange}
                      />
                      No molestar panales de abejas o avispas
                    </label>
                  </div>
                  <div class="input-group">
                    <div class="input-group-text">
                        <input
                          type="checkbox"
                          class="form-check-input mt-0"
                          name="quemedidasseponenenpract"
                          value={otrosquemedidasseponenenpract}
                          checked={fichaFamiliar.quemedidasseponenenpract.includes(otrosquemedidasseponenenpract)}
                          onChange={handleChange}
                          //onChange={(e) => fichaFamiliar.quemedidasseponenenpract(e.target.checked)}
                        />
                      </div>
                      <input type="text" class="form-control" aria-label="Text input with checkbox" 
                        placeholder="Digite otras opciones de ser necesario"
                        value={otrosquemedidasseponenenpract}
                        name="otrosquemedidasseponenenpract"
                        //onChange={handleInputChange}
                        //onBlur={handleInputBlur}
                        onChange={(event) => handleInputChange(event, setOtrosQuemedidasseponenenpract)}
                        onBlur={() => handleInputBlur(otrosquemedidasseponenenpract, 'quemedidasseponenenpract')}
                        />
                  </div>
                  {/* {!fichaFamiliar.quemedidasseponenenpract.length && <div className="text-danger">Campo obligatorio</div>} */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="m-2 mt-4 border border-primary-subtle"><h4 class="text"> 2.10.	MANEJO DE SUSTANCIAS QUIMICAS Y RESIDUOS PELIGROSOS.</h4>
          <div class="row">
            <div class="col-12 col-md-6 col-lg-4">      
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">¿Se revisan las etiquetas y se siguen las indicaciones proporcionadas por el fabricante, para el manejo, almacenamiento y precauciones de seguridad de los productos químicos en la vivienda?</label>
                <select className={`form-select`} name='serevisanlasetiquetasyses' value={fichaFamiliar.serevisanlasetiquetasyses} onChange={handleChange}>
                  <option selected></option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </select>
                {/* {!fichaFamiliar.serevisanlasetiquetasyses && <div className="invalid-feedback">Campo obligatorio</div>} */}
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">¿Los productos de limpieza, aseo personal, medicamentos, cosméticos y plaguicidas se almacenan en sus recipientes originales y en un lugar específico?</label>
                <select className={`form-select`} name='losproductosdelimpiezaase' value={fichaFamiliar.losproductosdelimpiezaase} onChange={handleChange}>
                  <option selected></option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </select>
                {/* {!fichaFamiliar.losproductosdelimpiezaase && <div className="invalid-feedback">Campo obligatorio</div>} */}
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">El lugar donde se almacenan los productos químicos para el uso en el hogar se encuentra ventilado, cerrado y separado de comida, alimentos para animales o medicamentos y fuera del alcance de los niños, niñas y las macotas?</label>
                <select className={`form-select`} name='ellugardondesealmacenanlo' value={fichaFamiliar.ellugardondesealmacenanlo} onChange={handleChange}>
                  <option selected></option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </select>
                {/* {!fichaFamiliar.ellugardondesealmacenanlo && <div className="invalid-feedback">Campo obligatorio</div>} */}
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">¿Cuándo se utilizan productos de limpieza, se mantiene el área ventilada y se usan elementos de protección personal durante su aplicación?</label>
                <select className={`form-select`} name='cuandoseutilizanproductos' value={fichaFamiliar.cuandoseutilizanproductos} onChange={handleChange}>
                  <option selected></option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </select>
                {/* {!fichaFamiliar.cuandoseutilizanproductos && <div className="invalid-feedback">Campo obligatorio</div>} */}
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <div>
                  <label class="form-label">Dónde se adquieren los productos químicos para la vivienda</label>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="dondeseadquierenlosproduc"
                        value="Tiendas de barrio"
                        checked={fichaFamiliar.dondeseadquierenlosproduc.includes('Tiendas de barrio')}
                        onChange={handleChange}
                      />
                      Tiendas de barrio
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="dondeseadquierenlosproduc"
                        value="Almacenes de cadena"
                        checked={fichaFamiliar.dondeseadquierenlosproduc.includes('Almacenes de cadena')}
                        onChange={handleChange}
                      />
                      Almacenes de cadena
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="dondeseadquierenlosproduc"
                        value="Establecimientos especificos de venta de productos químicos"
                        checked={fichaFamiliar.dondeseadquierenlosproduc.includes('Establecimientos especificos de venta de productos químicos')}
                        onChange={handleChange}
                      />
                      Establecimientos especificos de venta de productos químicos
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="dondeseadquierenlosproduc"
                        value="Establecimientos de venta a granel"
                        checked={fichaFamiliar.dondeseadquierenlosproduc.includes('Establecimientos de venta a granel')}
                        onChange={handleChange}
                      />
                      Establecimientos de venta a granel
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="dondeseadquierenlosproduc"
                        value="Via WEB"
                        checked={fichaFamiliar.dondeseadquierenlosproduc.includes('Via WEB')}
                        onChange={handleChange}
                      />
                      Via WEB
                    </label>
                  </div>
                  {/* {!fichaFamiliar.dondeseadquierenlosproduc.length && <div className="text-danger">Campo obligatorio</div>} */}
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <div>
                  <label class="form-label">Cómo se realiza la disposicion final de los residuos como envases de productos de aseo</label>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="comoserealizaladisposicioen"
                        value="Se separan de los residuos ordinarios en la vivienda para posterior aprovechamiento"
                        checked={fichaFamiliar.comoserealizaladisposicioen.includes('Se separan de los residuos ordinarios en la vivienda para posterior aprovechamiento')}
                        onChange={handleChange}
                      />
                      Se separan de los residuos ordinarios en la vivienda para posterior aprovechamiento
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="comoserealizaladisposicioen"
                        value="En conjunto con los residuos ordinarios de la vivienda"
                        checked={fichaFamiliar.comoserealizaladisposicioen.includes('En conjunto con los residuos ordinarios de la vivienda')}
                        onChange={handleChange}
                      />
                      En conjunto con los residuos ordinarios de la vivienda
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="comoserealizaladisposicioen"
                        value="Quema a campo abierto"
                        checked={fichaFamiliar.comoserealizaladisposicioen.includes('Quema a campo abierto')}
                        onChange={handleChange}
                      />
                      Quema a campo abierto
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="comoserealizaladisposicioen"
                        value="Disposición en fuentes de agua cercana"
                        checked={fichaFamiliar.comoserealizaladisposicioen.includes('Disposición en fuentes de agua cercana')}
                        onChange={handleChange}
                      />
                      Disposición en fuentes de agua cercana
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="comoserealizaladisposicioen"
                        value="Disposición a campo abierto"
                        checked={fichaFamiliar.comoserealizaladisposicioen.includes('Disposición a campo abierto')}
                        onChange={handleChange}
                      />
                      Disposición a campo abierto
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="comoserealizaladisposicioen"
                        value="Enterramiento"
                        checked={fichaFamiliar.comoserealizaladisposicioen.includes('Enterramiento')}
                        onChange={handleChange}
                      />
                      Enterramiento
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="comoserealizaladisposicioen"
                        value="Se utilizan para contener bebidad, alimentos y agua y para otros usos en el hogar"
                        checked={fichaFamiliar.comoserealizaladisposicioen.includes('Se utilizan para contener bebidad, alimentos y agua y para otros usos en el hogar')}
                        onChange={handleChange}
                      />
                      Se utilizan para contener bebidad, alimentos y agua y para otros usos en el hogar
                    </label>
                  </div>
                  <div class="input-group">
                    <div class="input-group-text">
                        <input
                          type="checkbox"
                          class="form-check-input mt-0"
                          name="comoserealizaladisposicioen"
                          value={otroscomoserealizaladisposicioen}
                          checked={fichaFamiliar.comoserealizaladisposicioen.includes(otroscomoserealizaladisposicioen)}
                          onChange={handleChange}
                          //onChange={(e) => fichaFamiliar.comoserealizaladisposicioen(e.target.checked)}
                        />
                      </div>
                      <input type="text" class="form-control" aria-label="Text input with checkbox" 
                        placeholder="Digite otras opciones de ser necesario"
                        value={otroscomoserealizaladisposicioen}
                        name="otroscomoserealizaladisposicioen"
                        //onChange={handleInputChange}
                        //onBlur={handleInputBlur}
                        onChange={(event) => handleInputChange(event, setOtrosComoserealizaladisposicioen)}
                        onBlur={() => handleInputBlur(otroscomoserealizaladisposicioen, 'comoserealizaladisposicioen')}
                        />
                  </div>
                  {/* {!fichaFamiliar.comoserealizaladisposicioen.length && <div className="text-danger">Campo obligatorio</div>} */}
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <div>
                  <label class="form-label">¿Cómo se realiza la disposicion final de los residuos peligrosos como medicamentos vencidos, plaguicidas, RAEES, pilas, aceites usados, bombillos, baterías para autos, entre otros?</label>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="comoserealizaladisposiciopel"
                        value="Recolectados por empresas especializadas"
                        checked={fichaFamiliar.comoserealizaladisposiciopel.includes('Recolectados por empresas especializadas')}
                        onChange={handleChange}
                      />
                      Recolectados por empresas especializadas
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="comoserealizaladisposiciopel"
                        value="Sitios autorizados para su recolección"
                        checked={fichaFamiliar.comoserealizaladisposiciopel.includes('Sitios autorizados para su recolección')}
                        onChange={handleChange}
                      />
                      Sitios autorizados para su recolección
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="comoserealizaladisposiciopel"
                        value="Centros de acopio"
                        checked={fichaFamiliar.comoserealizaladisposiciopel.includes('Centros de acopio')}
                        onChange={handleChange}
                      />
                      Centros de acopio
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="comoserealizaladisposiciopel"
                        value="Enterramiento"
                        checked={fichaFamiliar.comoserealizaladisposiciopel.includes('Enterramiento')}
                        onChange={handleChange}
                      />
                      Enterramiento
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="comoserealizaladisposiciopel"
                        value="Quema a campo abierto"
                        checked={fichaFamiliar.comoserealizaladisposiciopel.includes('Quema a campo abierto')}
                        onChange={handleChange}
                      />
                      Quema a campo abierto
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="comoserealizaladisposiciopel"
                        value="Disposición en fuentes de agua cercana"
                        checked={fichaFamiliar.comoserealizaladisposiciopel.includes('Disposición en fuentes de agua cercana')}
                        onChange={handleChange}
                      />
                      Disposición en fuentes de agua cercana
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="comoserealizaladisposiciopel"
                        value="Disposición a campo abierto"
                        checked={fichaFamiliar.comoserealizaladisposiciopel.includes('Disposición a campo abierto')}
                        onChange={handleChange}
                      />
                      Disposición a campo abierto
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="comoserealizaladisposiciopel"
                        value="En conjunto con los residuos ordinarios de la vivienda"
                        checked={fichaFamiliar.comoserealizaladisposiciopel.includes('En conjunto con los residuos ordinarios de la vivienda')}
                        onChange={handleChange}
                      />
                      En conjunto con los residuos ordinarios de la vivienda
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="comoserealizaladisposiciopel"
                        value="Se utilizan para contener bebidad, alimentos y agua y para otros usos en el hogar"
                        checked={fichaFamiliar.comoserealizaladisposiciopel.includes('Se utilizan para contener bebidad, alimentos y agua y para otros usos en el hogar')}
                        onChange={handleChange}
                      />
                      Se utilizan para contener bebidad, alimentos y agua y para otros usos en el hogar
                    </label>
                  </div>
                  <div class="input-group">
                    <div class="input-group-text">
                        <input
                          type="checkbox"
                          class="form-check-input mt-0"
                          name="comoserealizaladisposiciopel"
                          value={otroscomoserealizaladisposiciopel}
                          checked={fichaFamiliar.comoserealizaladisposiciopel.includes(otroscomoserealizaladisposiciopel)}
                          onChange={handleChange}
                          //onChange={(e) => fichaFamiliar.comoserealizaladisposiciopel(e.target.checked)}
                        />
                      </div>
                      <input type="text" class="form-control" aria-label="Text input with checkbox" 
                        placeholder="Digite otras opciones de ser necesario"
                        value={otroscomoserealizaladisposiciopel}
                        name="otroscomoserealizaladisposiciopel"
                        //onChange={handleInputChange}
                        //onBlur={handleInputBlur}
                        onChange={(event) => handleInputChange(event, setOtrosComoserealizaladisposiciopel)}
                        onBlur={() => handleInputBlur(otroscomoserealizaladisposiciopel, 'comoserealizaladisposiciopel')}
                        />
                  </div>
                  {/* {!fichaFamiliar.comoserealizaladisposiciopel.length && <div className="text-danger">Campo obligatorio</div>} */}
                </div>
              </div> 
            </div>
          </div>   
        </div>
        <div class="mb-5"><h4 class="text">MIEMBROS FAMILIA</h4>
          {/* Botón para agregar un nuevo grupo de campos */}
          <button type="button" class="btn btn-warning" onClick={agregarGrupoPersonas}>
            Agregar miembros familia
          </button>
          <div class="accordion" id="accordionParents">
            {/* Mostrar los grupos de campos agregados */}
            {grupospersonas.map((grupo) => (
              <div key={grupo.persona} class="accordion-item border border-primary">
                <h2 class="accordion-header">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse-${grupo.persona}`} aria-expanded="true" aria-controls={`collapse-${grupo.persona}`}>
                    Persona: {grupo.persona}
                  </button>
                </h2>
                <div id={`collapse-${grupo.persona}`} class="accordion-collapse collapse" data-bs-parent="#accordionParents">
                  <div class="accordion-body">
                    {/* Agregar aquí los campos específicos de este grupo */}
                    <div class="mb-4"><h4 class="text-center"> 3.	DATOS BASICOS</h4>
                      <div class="row">
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">Nombres y Apellidos:</label>
                            <input
                              type="text"
                              name='nombresyapellidos'
                              className={`form-control`}
                              value={grupo.nombresyapellidos}
                              onChange={(e) => handleChangeCampo(grupo.persona, 'nombresyapellidos', e.target.value)}
                            />
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">Tipo de Identidad</label>
                            <select className={`form-select`} name='tipodeidentidad' value={grupo.tipodeidentidad} onChange={(e) => handleChangeCampo(grupo.persona, 'tipodeidentidad', e.target.value)}>
                              <option selected></option>
                              <option value="RC">RC</option>
                              <option value="TI">TI</option>
                              <option value="CC">CC</option>
                              <option value="ASI">ASI</option>
                              <option value="MSI">MSI</option>
                              <option value="CE">CE</option>
                            </select>
                            {/* {!grupo.tipodeidentidad && <div className="invalid-feedback">Campo obligatorio</div>} */}
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">N° de documento de Identidad:</label>
                            <input
                              type="number"
                              name='ndedocumentodeidentidad'
                              className={`form-control`}
                              value={grupo.ndedocumentodeidentidad}
                              onChange={(e) => handleChangeCampo(grupo.persona, 'ndedocumentodeidentidad', e.target.value)}
                            />
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">Fecha de Nacimiento</label>
                            <input
                              type="date"
                              name='fechadenacimiento'
                              className={`form-control`}
                              value={grupo.fechadenacimiento}
                              onChange={(e) => handleChangeCampo(grupo.persona, 'fechadenacimiento', e.target.value)}
                            />
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">Sexo</label>
                            <select className={`form-select`} name='sexo' value={grupo.sexo} onChange={(e) => handleChangeCampo(grupo.persona, 'sexo', e.target.value)}>
                              <option selected></option>
                              <option value="F">F</option>
                              <option value="M">M</option>
                            </select>
                            {/* {!grupo.sexo && <div className="invalid-feedback">Campo obligatorio</div>} */}
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">Etnia</label>
                            <select className={`form-select`} name='etnia' value={grupo.etnia} onChange={(e) => handleChangeCampo(grupo.persona, 'etnia', e.target.value)}>
                              <option selected></option>
                              <option value="Afrocolombiano">Afrocolombiano</option>
                              <option value="mulato, negro">mulato, negro</option>
                              <option value="Palenquero">Palenquero</option>
                              <option value="Indígena, pueblo">Indígena, pueblo</option>
                              <option value="Raizal">Raizal</option>
                              <option value="ROM">ROM</option>
                              <option value="Ninguno">Ninguno</option>
                            </select>
                            {/* {!grupo.etnia && <div className="invalid-feedback">Campo obligatorio</div>} */}
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">Condición especial (SM)</label>
                            <select className={`form-select`} name='condicionespecialsm' value={grupo.condicionespecialsm} onChange={(e) => handleChangeCampo(grupo.persona, 'condicionespecialsm', e.target.value)}>
                              <option selected></option>
                              <option value="Victima">Victima</option>
                              <option value="Habitante de calle">Habitante de calle</option>
                              <option value="LGTBI">LGTBI</option>
                              <option value="No aplica ">No aplica </option>
                            </select>
                            {/* {!grupo.condicionespecialsm && <div className="invalid-feedback">Campo obligatorio</div>} */}
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">Discapacidad (S/M)</label>
                            <select className={`form-select`} name='discapacidadsm' value={grupo.discapacidadsm} onChange={(e) => handleChangeCampo(grupo.persona, 'discapacidadsm', e.target.value)}>
                              <option selected></option>
                              <option value="Cognitiva">Cognitiva</option>
                              <option value="Motora">Motora</option>
                              <option value="Sensorial">Sensorial</option>
                              <option value="Ninguna ">Ninguna </option>
                            </select>
                            {/* {!grupo.discapacidadsm && <div className="invalid-feedback">Campo obligatorio</div>} */}
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">Estado civil</label>
                            <select className={`form-select`} name='estadocivil' value={grupo.estadocivil} onChange={(e) => handleChangeCampo(grupo.persona, 'estadocivil', e.target.value)}>
                              <option selected></option>
                              <option value="Unión libre">Unión libre</option>
                              <option value="hijastro(a)">Casado(a)</option>
                              <option value="Viudo(a)">Viudo(a)</option>
                              <option value="Separado(a)">Separado(a)</option>
                              <option value="Soltero(a)">Soltero(a)</option>
                              <option value="Compañero permanente">Compañero permanente</option>
                              <option value="No aplica">No aplica</option>
                            </select>
                            {/* {!grupo.estadocivil && <div className="invalid-feedback">Campo obligatorio</div>} */}
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">Parentesco con el jefe de hogar</label>
                            <select className={`form-select`} name='parentescoconeljefedehoga' value={grupo.parentescoconeljefedehoga} onChange={(e) => handleChangeCampo(grupo.persona, 'parentescoconeljefedehoga', e.target.value)}>
                              <option selected></option>
                              <option value="Hijo(a)">Hijo(a)</option>
                              <option value="hijastro(a)">hijastro(a)</option>
                              <option value="Nieto(a)">Nieto(a)</option>
                              <option value="padre o madre">padre o madre</option>
                              <option value="Hermano(a)">Hermano(a)</option>
                              <option value="Yerno –Nuera">Yerno –Nuera</option>
                              <option value="Abuelos">Abuelos</option>
                              <option value="Suegros">Suegros</option>
                              <option value="No parientes ">No parientes </option>
                            </select>
                            {/* {!grupo.parentescoconeljefedehoga && <div className="invalid-feedback">Campo obligatorio</div>} */}
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">Reg. Salud</label>
                            <select className={`form-select`} name='regsalud' value={grupo.regsalud} onChange={(e) => handleChangeCampo(grupo.persona, 'regsalud', e.target.value)}>
                              <option selected></option>
                              <option value="S">S</option>
                              <option value="C">C</option>
                              <option value="E">E</option>
                              <option value="PNA">PNA</option>
                            </select>
                            {/* {!grupo.regsalud && <div className="invalid-feedback">Campo obligatorio</div>} */}
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">Nombre de la EPS</label>
                            <select className={`form-select`} name='nombredelaeps' value={grupo.nombredelaeps} onChange={(e) => handleChangeCampo(grupo.persona, 'nombredelaeps', e.target.value)}>
                              <option selected></option>
                              <option value="Asmet Salud">Asmet Salud</option>
                              <option value="Nueva EPS">Nueva EPS</option>
                              <option value="Sanitas EPS">Sanitas EPS</option>
                              <option value="FAMAC">FAMAC</option>
                              <option value="Sanidad Ejercito">Sanidad Ejercito</option>
                              <option value="Sanidad Policia.">Sanidad Policia.</option>
                              <option value="Otro">Otro</option>
                            </select>
                            {/* {!grupo.nombredelaeps && <div className="invalid-feedback">Campo obligatorio</div>} */}
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">Ocupación principal</label>
                            <select className={`form-select`} name='ocupacionprincipal' value={grupo.ocupacionprincipal} onChange={(e) => handleChangeCampo(grupo.persona, 'ocupacionprincipal', e.target.value)}>
                              <option selected></option>
                              <option value="Desempleado (desde los 12 a)">Desempleado (desde los 12 a)</option>
                              <option value="Trabajador (desde los 12 a)">Trabajador (desde los 12 a)</option>
                              <option value="Ama de casa (desde los 12 a)">Ama de casa (desde los 12 a)</option>
                              <option value="Pensionado/jubilado (desde los 18 a)">Pensionado/jubilado (desde los 18 a)</option>
                              <option value="Estudiante (desde de los 7 a)">Estudiante (desde de los 7 a)</option>
                              <option value="Ninguna (Entre los 7 y 11 años)">Ninguna (Entre los 7 y 11 años)</option>
                            </select>
                            {/* {!grupo.ocupacionprincipal && <div className="invalid-feedback">Campo obligatorio</div>} */}
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">Depende económicamente de un familiar</label>
                            <select className={`form-select`} name='dependeeconomicamentedeun' value={grupo.dependeeconomicamentedeun} onChange={(e) => handleChangeCampo(grupo.persona, 'dependeeconomicamentedeun', e.target.value)}>
                              <option selected></option>
                              <option value="Si">Si</option>
                              <option value="No">No</option>
                              <option value="SD">SD</option>
                            </select>
                            {/* {!grupo.dependeeconomicamentedeun && <div className="invalid-feedback">Campo obligatorio</div>} */}
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">Escolaridad</label>
                            <select className={`form-select`} name='escolaridad' value={grupo.escolaridad} onChange={(e) => handleChangeCampo(grupo.persona, 'escolaridad', e.target.value)}>
                              <option selected></option>
                              <option value="Ningún estudio">Ningún estudio</option>
                              <option value="Primaria">Primaria</option>
                              <option value="Secundaria">Secundaria</option>
                              <option value="Técnica">Técnica</option>
                              <option value="Tecnológico">Tecnológico</option>
                              <option value="Universitario">Universitario</option>
                              <option value="Postgrado">Postgrado</option>
                              <option value="Abandono los estudios de primaria o bachiller (De 7 a 21 años)">Abandono los estudios de primaria o bachiller (De 7 a 21 años)</option>
                            </select>
                            {/* {!grupo.escolaridad && <div className="invalid-feedback">Campo obligatorio</div>} */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mb-4"><h4 class="text-center"> 3.1.	ANTECEDENTES MÉDICOS</h4>
                      <div class="row">
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <div>
                              <label class="form-label">Antecedente Personal (SM)</label>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`antecedentepersonalsm-${grupo.persona}`}
                                    value="1. Abuso sexual en la infancia (a partir 14 a)"
                                    checked={grupo.antecedentepersonalsm.includes('1. Abuso sexual en la infancia (a partir 14 a)')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'antecedentepersonalsm', '1. Abuso sexual en la infancia (a partir 14 a)', true)}
                                  />
                                  1. Abuso sexual en la infancia (a partir 14 a)
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`antecedentepersonalsm-${grupo.persona}`}
                                    value="2. Cáncer"
                                    checked={grupo.antecedentepersonalsm.includes('2. Cáncer')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'antecedentepersonalsm', '2. Cáncer', true)}
                                  />
                                  2. Cáncer
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`antecedentepersonalsm-${grupo.persona}`}
                                    value="3. Complicación de la madre durante la gestación, parto o puerperio (0 a 4 a)"
                                    checked={grupo.antecedentepersonalsm.includes('3. Complicación de la madre durante la gestación, parto o puerperio (0 a 4 a)')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'antecedentepersonalsm', '3. Complicación de la madre durante la gestación, parto o puerperio (0 a 4 a)', true)}
                                  />
                                  3. Complicación de la madre durante la gestación, parto o puerperio (0 a 4 a)
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`antecedentepersonalsm-${grupo.persona}`}
                                    value="4. Depresión – ansiedad (a partir 13 a)"
                                    checked={grupo.antecedentepersonalsm.includes('4. Depresión – ansiedad (a partir 13 a)')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'antecedentepersonalsm', '4. Depresión – ansiedad (a partir 13 a)', true)}
                                  />
                                  4. Depresión – ansiedad (a partir 13 a)
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`antecedentepersonalsm-${grupo.persona}`}
                                    value="5. Diabetes (a partir 6 a)"
                                    checked={grupo.antecedentepersonalsm.includes('5. Diabetes (a partir 6 a)')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'antecedentepersonalsm', '5. Diabetes (a partir 6 a)', true)}
                                  />
                                  5. Diabetes (a partir 6 a)
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`antecedentepersonalsm-${grupo.persona}`}
                                    value="6. Diabetes Gestacional (a partir 12 a)"
                                    checked={grupo.antecedentepersonalsm.includes('6. Diabetes Gestacional (a partir 12 a)')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'antecedentepersonalsm', '6. Diabetes Gestacional (a partir 12 a)', true)}
                                  />
                                  6. Diabetes Gestacional (a partir 12 a)
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`antecedentepersonalsm-${grupo.persona}`}
                                    value="7. Dificultades escolares (a partir 15 a)"
                                    checked={grupo.antecedentepersonalsm.includes('7. Dificultades escolares (a partir 15 a)')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'antecedentepersonalsm', '7. Dificultades escolares (a partir 15 a)', true)}
                                  />
                                  7. Dificultades escolares (a partir 15 a)
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`antecedentepersonalsm-${grupo.persona}`}
                                    value="8. Enfermedad cardiovascular (a partir 31 a)"
                                    checked={grupo.antecedentepersonalsm.includes('8. Enfermedad cardiovascular (a partir 31 a)')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'antecedentepersonalsm', '8. Enfermedad cardiovascular (a partir 31 a)', true)}
                                  />
                                  8. Enfermedad cardiovascular (a partir 31 a)
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`antecedentepersonalsm-${grupo.persona}`}
                                    value="9. ERC"
                                    checked={grupo.antecedentepersonalsm.includes('9. ERC')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'antecedentepersonalsm', '9. ERC', true)}
                                  />
                                  9. ERC
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`antecedentepersonalsm-${grupo.persona}`}
                                    value="10. EPOC (a partir 31 a)"
                                    checked={grupo.antecedentepersonalsm.includes('10. EPOC (a partir 31 a)')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'antecedentepersonalsm', '10. EPOC (a partir 31 a)', true)}
                                  />
                                  10. EPOC (a partir 31 a)
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`antecedentepersonalsm-${grupo.persona}`}
                                    value="11. Esquizofrenia"
                                    checked={grupo.antecedentepersonalsm.includes('11. Esquizofrenia')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'antecedentepersonalsm', '11. Esquizofrenia', true)}
                                  />
                                  11. Esquizofrenia
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`antecedentepersonalsm-${grupo.persona}`}
                                    value="12. Exposición a la violencia"
                                    checked={grupo.antecedentepersonalsm.includes('12. Exposición a la violencia')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'antecedentepersonalsm', '12. Exposición a la violencia', true)}
                                  />
                                  12. Exposición a la violencia
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`antecedentepersonalsm-${grupo.persona}`}
                                    value="13. Helicobacter pylori (a partir 31 a)"
                                    checked={grupo.antecedentepersonalsm.includes('13. Helicobacter pylori (a partir 31 a)')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'antecedentepersonalsm', '13. Helicobacter pylori (a partir 31 a)', true)}
                                  />
                                  13. Helicobacter pylori (a partir 31 a)
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`antecedentepersonalsm-${grupo.persona}`}
                                    value="14. HTA"
                                    checked={grupo.antecedentepersonalsm.includes('14. HTA')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'antecedentepersonalsm', '14. HTA', true)}
                                  />
                                  14. HTA
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`antecedentepersonalsm-${grupo.persona}`}
                                    value="15. Intento de suicidio (a partir 11 a)"
                                    checked={grupo.antecedentepersonalsm.includes('15. Intento de suicidio (a partir 11 a)')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'antecedentepersonalsm', '15. Intento de suicidio (a partir 11 a)', true)}
                                  />
                                  15. Intento de suicidio (a partir 11 a)
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`antecedentepersonalsm-${grupo.persona}`}
                                    value="16. No lactancia materna (0 a 5 a)"
                                    checked={grupo.antecedentepersonalsm.includes('16. No lactancia materna (0 a 5 a)')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'antecedentepersonalsm', '16. No lactancia materna (0 a 5 a)', true)}
                                  />
                                  16. No lactancia materna (0 a 5 a)
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`antecedentepersonalsm-${grupo.persona}`}
                                    value="17. No lactancia materna actual (6 a 23 m)"
                                    checked={grupo.antecedentepersonalsm.includes('17. No lactancia materna actual (6 a 23 m)')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'antecedentepersonalsm', '17. No lactancia materna actual (6 a 23 m)', true)}
                                  />
                                  17. No lactancia materna actual (6 a 23 m)
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`antecedentepersonalsm-${grupo.persona}`}
                                    value="18. No lactancia materna exclusiva hasta los 6 meses"
                                    checked={grupo.antecedentepersonalsm.includes('18. No lactancia materna exclusiva hasta los 6 meses')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'antecedentepersonalsm', '18. No lactancia materna exclusiva hasta los 6 meses', true)}
                                  />
                                  18. No lactancia materna exclusiva hasta los 6 meses
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`antecedentepersonalsm-${grupo.persona}`}
                                    value="19. Retraso desarrollo psicomotor en el primer año de vida (a partir 15 a)"
                                    checked={grupo.antecedentepersonalsm.includes('19. Retraso desarrollo psicomotor en el primer año de vida (a partir 15 a)')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'antecedentepersonalsm', '19. Retraso desarrollo psicomotor en el primer año de vida (a partir 15 a)', true)}
                                  />
                                  19. Retraso desarrollo psicomotor en el primer año de vida (a partir 15 a)
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`antecedentepersonalsm-${grupo.persona}`}
                                    value="20. TB"
                                    checked={grupo.antecedentepersonalsm.includes('20. TB')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'antecedentepersonalsm', '20. TB', true)}
                                  />
                                  20. TB
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`antecedentepersonalsm-${grupo.persona}`}
                                    value="21. Trastorno afectivo bipolar (a partir 9 a)"
                                    checked={grupo.antecedentepersonalsm.includes('21. Trastorno afectivo bipolar (a partir 9 a)')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'antecedentepersonalsm', '21. Trastorno afectivo bipolar (a partir 9 a)', true)}
                                  />
                                  21. Trastorno afectivo bipolar (a partir 9 a)
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`antecedentepersonalsm-${grupo.persona}`}
                                    value="22. Trastornos mentales en la infancia y adolescencia (a partir 18 a)"
                                    checked={grupo.antecedentepersonalsm.includes('22. Trastornos mentales en la infancia y adolescencia (a partir 18 a)')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'antecedentepersonalsm', '22. Trastornos mentales en la infancia y adolescencia (a partir 18 a)', true)}
                                  />
                                  22. Trastornos mentales en la infancia y adolescencia (a partir 18 a)
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`antecedentepersonalsm-${grupo.persona}`}
                                    value="23. VIH"
                                    checked={grupo.antecedentepersonalsm.includes('23. VIH')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'antecedentepersonalsm', '23. VIH', true)}
                                  />
                                  23. VIH
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`antecedentepersonalsm-${grupo.persona}`}
                                    value="24. VPH (a partir 12 a)"
                                    checked={grupo.antecedentepersonalsm.includes('24. VPH (a partir 12 a)')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'antecedentepersonalsm', '24. VPH (a partir 12 a)', true)}
                                  />
                                  24. VPH (a partir 12 a)
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`antecedentepersonalsm-${grupo.persona}`}
                                    value="25. Niveles de azúcar altos"
                                    checked={grupo.antecedentepersonalsm.includes('25. Niveles de azúcar altos')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'antecedentepersonalsm', '25. Niveles de azúcar altos', true)}
                                  />
                                  25. Niveles de azúcar altos
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`antecedentepersonalsm-${grupo.persona}`}
                                    value="26. Ninguna"
                                    checked={grupo.antecedentepersonalsm.includes('26. Ninguna')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'antecedentepersonalsm', '26. Ninguna', true)}
                                  />
                                  26. Ninguna
                                </label>
                              </div>
                              {/* {!grupo.antecedentepersonalsm.length && <div className="text-danger">Campo obligatorio</div>} */}
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <div>
                              <label class="form-label">Enfermedad actual (SM)</label>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`enfermedadactualsm-${grupo.persona}`}
                                    value="1. Bajo peso al nacer (o a 11 m)"
                                    checked={grupo.enfermedadactualsm.includes('1. Bajo peso al nacer (o a 11 m)')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'enfermedadactualsm', '1. Bajo peso al nacer (o a 11 m)', true)}
                                  />
                                  1. Bajo peso al nacer (o a 11 m)
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`enfermedadactualsm-${grupo.persona}`}
                                    value="2. Cáncer de colon-recto (a partir 31 a)"
                                    checked={grupo.enfermedadactualsm.includes('2. Cáncer de colon-recto (a partir 31 a)')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'enfermedadactualsm', '2. Cáncer de colon-recto (a partir 31 a)', true)}
                                  />
                                  2. Cáncer de colon-recto (a partir 31 a)
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`enfermedadactualsm-${grupo.persona}`}
                                    value="3. Cáncer de cuello uterino-cérvix (a partir 21 a)"
                                    checked={grupo.enfermedadactualsm.includes('3. Cáncer de cuello uterino-cérvix (a partir 21 a)')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'enfermedadactualsm', '3. Cáncer de cuello uterino-cérvix (a partir 21 a)', true)}
                                  />
                                  3. Cáncer de cuello uterino-cérvix (a partir 21 a)
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`enfermedadactualsm-${grupo.persona}`}
                                    value="4. Cáncer de estómago (a partir 31 a)"
                                    checked={grupo.enfermedadactualsm.includes('4. Cáncer de estómago (a partir 31 a)')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'enfermedadactualsm', '4. Cáncer de estómago (a partir 31 a)', true)}
                                  />
                                  4. Cáncer de estómago (a partir 31 a)
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`enfermedadactualsm-${grupo.persona}`}
                                    value="5. Cáncer de mama (a partir 21 a)"
                                    checked={grupo.enfermedadactualsm.includes('5. Cáncer de mama (a partir 21 a)')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'enfermedadactualsm', '5. Cáncer de mama (a partir 21 a)', true)}
                                  />
                                  5. Cáncer de mama (a partir 21 a)
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`enfermedadactualsm-${grupo.persona}`}
                                    value="6. Cáncer de próstata (a partir 40 a)"
                                    checked={grupo.enfermedadactualsm.includes('6. Cáncer de próstata (a partir 40 a)')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'enfermedadactualsm', '6. Cáncer de próstata (a partir 40 a)', true)}
                                  />
                                  6. Cáncer de próstata (a partir 40 a)
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`enfermedadactualsm-${grupo.persona}`}
                                    value="7. Cáncer de pulmón (a partir 50 a)"
                                    checked={grupo.enfermedadactualsm.includes('7. Cáncer de pulmón (a partir 50 a)')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'enfermedadactualsm', '7. Cáncer de pulmón (a partir 50 a)', true)}
                                  />
                                  7. Cáncer de pulmón (a partir 50 a)
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`enfermedadactualsm-${grupo.persona}`}
                                    value="8. Caries (6m a 5 a)"
                                    checked={grupo.enfermedadactualsm.includes('8. Caries (6m a 5 a)')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'enfermedadactualsm', '8. Caries (6m a 5 a)', true)}
                                  />
                                  8. Caries (6m a 5 a)
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`enfermedadactualsm-${grupo.persona}`}
                                    value="9. Depresión – ansiedad (a partir 13 a)"
                                    checked={grupo.enfermedadactualsm.includes('9. Depresión – ansiedad (a partir 13 a)')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'enfermedadactualsm', '9. Depresión – ansiedad (a partir 13 a)', true)}
                                  />
                                  9. Depresión – ansiedad (a partir 13 a)
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`enfermedadactualsm-${grupo.persona}`}
                                    value="10. Desnutrición"
                                    checked={grupo.enfermedadactualsm.includes('10. Desnutrición')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'enfermedadactualsm', '10. Desnutrición', true)}
                                  />
                                  10. Desnutrición
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`enfermedadactualsm-${grupo.persona}`}
                                    value="11. Diabetes Gestacional (a partir 12 a)"
                                    checked={grupo.enfermedadactualsm.includes('11. Diabetes Gestacional (a partir 12 a)')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'enfermedadactualsm', '11. Diabetes Gestacional (a partir 12 a)', true)}
                                  />
                                  11. Diabetes Gestacional (a partir 12 a)
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`enfermedadactualsm-${grupo.persona}`}
                                    value="12. Diarrea permanente"
                                    checked={grupo.enfermedadactualsm.includes('12. Diarrea permanente')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'enfermedadactualsm', '12. Diarrea permanente', true)}
                                  />
                                  12. Diarrea permanente
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`enfermedadactualsm-${grupo.persona}`}
                                    value="13. Dislipidemia (a partir 31 a)"
                                    checked={grupo.enfermedadactualsm.includes('13. Dislipidemia (a partir 31 a)')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'enfermedadactualsm', '13. Dislipidemia (a partir 31 a)', true)}
                                  />
                                  13. Dislipidemia (a partir 31 a)
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`enfermedadactualsm-${grupo.persona}`}
                                    value="14. Enfermedad periodontal"
                                    checked={grupo.enfermedadactualsm.includes('14. Enfermedad periodontal')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'enfermedadactualsm', '14. Enfermedad periodontal', true)}
                                  />
                                  14. Enfermedad periodontal
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`enfermedadactualsm-${grupo.persona}`}
                                    value="15. Helicobacter pylori (a partir 31 a)"
                                    checked={grupo.enfermedadactualsm.includes('15. Helicobacter pylori (a partir 31 a)')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'enfermedadactualsm', '15. Helicobacter pylori (a partir 31 a)', true)}
                                  />
                                  15. Helicobacter pylori (a partir 31 a)
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`enfermedadactualsm-${grupo.persona}`}
                                    value="16. Hipotiroidismo congénito"
                                    checked={grupo.enfermedadactualsm.includes('16. Hipotiroidismo congénito')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'enfermedadactualsm', '16. Hipotiroidismo congénito', true)}
                                  />
                                  16. Hipotiroidismo congénito
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`enfermedadactualsm-${grupo.persona}`}
                                    value="17. ITS"
                                    checked={grupo.enfermedadactualsm.includes('17. ITS')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'enfermedadactualsm', '17. ITS', true)}
                                  />
                                  17. ITS
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`enfermedadactualsm-${grupo.persona}`}
                                    value="18. Leucemia y linfoma en niños (0 a 18 a)"
                                    checked={grupo.enfermedadactualsm.includes('18. Leucemia y linfoma en niños (0 a 18 a)')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'enfermedadactualsm', '18. Leucemia y linfoma en niños (0 a 18 a)', true)}
                                  />
                                  18. Leucemia y linfoma en niños (0 a 18 a)
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`enfermedadactualsm-${grupo.persona}`}
                                    value="19. Neumonía (0 a 4 años)"
                                    checked={grupo.enfermedadactualsm.includes('19. Neumonía (0 a 4 años)')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'enfermedadactualsm', '19. Neumonía (0 a 4 años)', true)}
                                  />
                                  19. Neumonía (0 a 4 años)
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`enfermedadactualsm-${grupo.persona}`}
                                    value="20. TB"
                                    checked={grupo.enfermedadactualsm.includes('20. TB')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'enfermedadactualsm', '20. TB', true)}
                                  />
                                  20. TB
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`enfermedadactualsm-${grupo.persona}`}
                                    value="21. Trastornos mentales en la infancia y adolescencia (9 a 17 a)"
                                    checked={grupo.enfermedadactualsm.includes('21. Trastornos mentales en la infancia y adolescencia (9 a 17 a)')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'enfermedadactualsm', '21. Trastornos mentales en la infancia y adolescencia (9 a 17 a)', true)}
                                  />
                                  21. Trastornos mentales en la infancia y adolescencia (9 a 17 a)
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`enfermedadactualsm-${grupo.persona}`}
                                    value="22. VPH (a partir 12 a)"
                                    checked={grupo.enfermedadactualsm.includes('22. VPH (a partir 12 a)')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'enfermedadactualsm', '22. VPH (a partir 12 a)', true)}
                                  />
                                  22. VPH (a partir 12 a)
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`enfermedadactualsm-${grupo.persona}`}
                                    value="23. Infección urinaria recurrente (a partir 9 a)"
                                    checked={grupo.enfermedadactualsm.includes('23. Infección urinaria recurrente (a partir 9 a)')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'enfermedadactualsm', '23. Infección urinaria recurrente (a partir 9 a)', true)}
                                  />
                                  23. Infección urinaria recurrente (a partir 9 a)
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`enfermedadactualsm-${grupo.persona}`}
                                    value="24. Ninguna"
                                    checked={grupo.enfermedadactualsm.includes('24. Ninguna')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'enfermedadactualsm', '24. Ninguna', true)}
                                  />
                                  24. Ninguna
                                </label>
                              </div>
                              {/* {!grupo.enfermedadactualsm.length && <div className="text-danger">Campo obligatorio</div>} */}
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <div>
                              <label class="form-label">Antecedente Familiar primer y segundo grado (SM)</label>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`antecedentefamiliarprimer-${grupo.persona}`}
                                    value="1.Cáncer de colon-recto (a partir de 19 a)"
                                    checked={grupo.antecedentefamiliarprimer.includes('1.Cáncer de colon-recto (a partir de 19 a)')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'antecedentefamiliarprimer', '1.Cáncer de colon-recto (a partir de 19 a)', true)}
                                  />
                                  1.Cáncer de colon-recto (a partir de 19 a)
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`antecedentefamiliarprimer-${grupo.persona}`}
                                    value="2. Cáncer de cuello uterino-cérvix (a partir 19 a)"
                                    checked={grupo.antecedentefamiliarprimer.includes('2. Cáncer de cuello uterino-cérvix (a partir 19 a)')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'antecedentefamiliarprimer', '2. Cáncer de cuello uterino-cérvix (a partir 19 a)', true)}
                                  />
                                  2. Cáncer de cuello uterino-cérvix (a partir 19 a)
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`antecedentefamiliarprimer-${grupo.persona}`}
                                    value="3. Cáncer de estómago. (a partir 19 a)"
                                    checked={grupo.antecedentefamiliarprimer.includes('3. Cáncer de estómago. (a partir 19 a)')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'antecedentefamiliarprimer', '3. Cáncer de estómago. (a partir 19 a)', true)}
                                  />
                                  3. Cáncer de estómago. (a partir 19 a)
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`antecedentefamiliarprimer-${grupo.persona}`}
                                    value="4. Cáncer de mama (a partir 19 a)"
                                    checked={grupo.antecedentefamiliarprimer.includes('4. Cáncer de mama (a partir 19 a)')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'antecedentefamiliarprimer', '4. Cáncer de mama (a partir 19 a)', true)}
                                  />
                                  4. Cáncer de mama (a partir 19 a)
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`antecedentefamiliarprimer-${grupo.persona}`}
                                    value="5. Cáncer de próstata (a partir 19 a)"
                                    checked={grupo.antecedentefamiliarprimer.includes('5. Cáncer de próstata (a partir 19 a)')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'antecedentefamiliarprimer', '5. Cáncer de próstata (a partir 19 a)', true)}
                                  />
                                  5. Cáncer de próstata (a partir 19 a)
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`antecedentefamiliarprimer-${grupo.persona}`}
                                    value="6. Cáncer de pulmón (a partir 19 a)"
                                    checked={grupo.antecedentefamiliarprimer.includes('6. Cáncer de pulmón (a partir 19 a)')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'antecedentefamiliarprimer', '6. Cáncer de pulmón (a partir 19 a)', true)}
                                  />
                                  6. Cáncer de pulmón (a partir 19 a)
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`antecedentefamiliarprimer-${grupo.persona}`}
                                    value="7. Diabetes (a partir de los 6 a)"
                                    checked={grupo.antecedentefamiliarprimer.includes('7. Diabetes (a partir de los 6 a)')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'antecedentefamiliarprimer', '7. Diabetes (a partir de los 6 a)', true)}
                                  />
                                  7. Diabetes (a partir de los 6 a)
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`antecedentefamiliarprimer-${grupo.persona}`}
                                    value="8. Enfermedad cardiovascular (a partir 18 a)"
                                    checked={grupo.antecedentefamiliarprimer.includes('8. Enfermedad cardiovascular (a partir 18 a)')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'antecedentefamiliarprimer', '8. Enfermedad cardiovascular (a partir 18 a)', true)}
                                  />
                                  8. Enfermedad cardiovascular (a partir 18 a)
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`antecedentefamiliarprimer-${grupo.persona}`}
                                    value="9. Esquizofrenia (a partir 9 a)"
                                    checked={grupo.antecedentefamiliarprimer.includes('9. Esquizofrenia (a partir 9 a)')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'antecedentefamiliarprimer', '9. Esquizofrenia (a partir 9 a)', true)}
                                  />
                                  9. Esquizofrenia (a partir 9 a)
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`antecedentefamiliarprimer-${grupo.persona}`}
                                    value="10. Leucemia y linfoma en niños (0 a 17 a)"
                                    checked={grupo.antecedentefamiliarprimer.includes('10. Leucemia y linfoma en niños (0 a 17 a)')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'antecedentefamiliarprimer', '10. Leucemia y linfoma en niños (0 a 17 a)', true)}
                                  />
                                  10. Leucemia y linfoma en niños (0 a 17 a)
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`antecedentefamiliarprimer-${grupo.persona}`}
                                    value="11. Trastorno afectivo bipolar (a partir 9 a)"
                                    checked={grupo.antecedentefamiliarprimer.includes('11. Trastorno afectivo bipolar (a partir 9 a)')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'antecedentefamiliarprimer', '11. Trastorno afectivo bipolar (a partir 9 a)', true)}
                                  />
                                  11. Trastorno afectivo bipolar (a partir 9 a)
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`antecedentefamiliarprimer-${grupo.persona}`}
                                    value="12. Ninguna"
                                    checked={grupo.antecedentefamiliarprimer.includes('12. Ninguna')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'antecedentefamiliarprimer', '12. Ninguna', true)}
                                  />
                                  12. Ninguna
                                </label>
                              </div>
                              {/* {!grupo.antecedentefamiliarprimer.length && <div className="text-danger">Campo obligatorio</div>} */}
                            </div>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <div>
                              <label class="form-label">Síntomas recientes sin causa aparente (SM)</label>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`sintomasrecientessincausa-${grupo.persona}`}
                                    value="1. Adinamia-debilidad"
                                    checked={grupo.sintomasrecientessincausa.includes('1. Adinamia-debilidad')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'sintomasrecientessincausa', '1. Adinamia-debilidad', true)}
                                  />
                                  1. Adinamia-debilidad
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`sintomasrecientessincausa-${grupo.persona}`}
                                    value="2. Bronquitis o sibilancias"
                                    checked={grupo.sintomasrecientessincausa.includes('2. Bronquitis o sibilancias')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'sintomasrecientessincausa', '2. Bronquitis o sibilancias', true)}
                                  />
                                  2. Bronquitis o sibilancias
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`sintomasrecientessincausa-${grupo.persona}`}
                                    value="3. Candidiasis"
                                    checked={grupo.sintomasrecientessincausa.includes('3. Candidiasis')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'sintomasrecientessincausa', '3. Candidiasis', true)}
                                  />
                                  3. Candidiasis
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`sintomasrecientessincausa-${grupo.persona}`}
                                    value="4. Cefaleas"
                                    checked={grupo.sintomasrecientessincausa.includes('4. Cefaleas')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'sintomasrecientessincausa', '4. Cefaleas', true)}
                                  />
                                  4. Cefaleas
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`sintomasrecientessincausa-${grupo.persona}`}
                                    value="5. Cicatrización lenta"
                                    checked={grupo.sintomasrecientessincausa.includes('5. Cicatrización lenta')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'sintomasrecientessincausa', '5. Cicatrización lenta', true)}
                                  />
                                  5. Cicatrización lenta
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`sintomasrecientessincausa-${grupo.persona}`}
                                    value="6. Diarrea permanente"
                                    checked={grupo.sintomasrecientessincausa.includes('6. Diarrea permanente')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'sintomasrecientessincausa', '6. Diarrea permanente', true)}
                                  />
                                  6. Diarrea permanente
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`sintomasrecientessincausa-${grupo.persona}`}
                                    value="7. Dificultad para orinar (M)"
                                    checked={grupo.sintomasrecientessincausa.includes('7. Dificultad para orinar (M)')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'sintomasrecientessincausa', '7. Dificultad para orinar (M)', true)}
                                  />
                                  7. Dificultad para orinar (M)
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`sintomasrecientessincausa-${grupo.persona}`}
                                    value="8. Dificultad para respirar"
                                    checked={grupo.sintomasrecientessincausa.includes('8. Dificultad para respirar')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'sintomasrecientessincausa', '8. Dificultad para respirar', true)}
                                  />
                                  8. Dificultad para respirar
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`sintomasrecientessincausa-${grupo.persona}`}
                                    value="9. Disminución y alteración en la materia fecal"
                                    checked={grupo.sintomasrecientessincausa.includes('9. Disminución y alteración en la materia fecal')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'sintomasrecientessincausa', '9. Disminución y alteración en la materia fecal', true)}
                                  />
                                  9. Disminución y alteración en la materia fecal
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`sintomasrecientessincausa-${grupo.persona}`}
                                    value="10. Dolor abdominal"
                                    checked={grupo.sintomasrecientessincausa.includes('10. Dolor abdominal')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'sintomasrecientessincausa', '10. Dolor abdominal', true)}
                                  />
                                  10. Dolor abdominal
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`sintomasrecientessincausa-${grupo.persona}`}
                                    value="11. Dolor al eyacular u orinar (M)"
                                    checked={grupo.sintomasrecientessincausa.includes('11. Dolor al eyacular u orinar (M)')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'sintomasrecientessincausa', '11. Dolor al eyacular u orinar (M)', true)}
                                  />
                                  11. Dolor al eyacular u orinar (M)
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`sintomasrecientessincausa-${grupo.persona}`}
                                    value="12. Dolor constante en el pecho"
                                    checked={grupo.sintomasrecientessincausa.includes('12. Dolor constante en el pecho')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'sintomasrecientessincausa', '12. Dolor constante en el pecho', true)}
                                  />
                                  12. Dolor constante en el pecho
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`sintomasrecientessincausa-${grupo.persona}`}
                                    value="13. Dolor durante las relaciones sexuales (F)"
                                    checked={grupo.sintomasrecientessincausa.includes('13. Dolor durante las relaciones sexuales (F)')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'sintomasrecientessincausa', '13. Dolor durante las relaciones sexuales (F)', true)}
                                  />
                                  13. Dolor durante las relaciones sexuales (F)
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`sintomasrecientessincausa-${grupo.persona}`}
                                    value="14. Dolor torácico al respirar y/o toser"
                                    checked={grupo.sintomasrecientessincausa.includes('14. Dolor torácico al respirar y/o toser')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'sintomasrecientessincausa', '14. Dolor torácico al respirar y/o toser', true)}
                                  />
                                  14. Dolor torácico al respirar y/o toser
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`sintomasrecientessincausa-${grupo.persona}`}
                                    value="15. Dolores óseos"
                                    checked={grupo.sintomasrecientessincausa.includes('15. Dolores óseos')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'sintomasrecientessincausa', '15. Dolores óseos', true)}
                                  />
                                  15. Dolores óseos
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`sintomasrecientessincausa-${grupo.persona}`}
                                    value="16. Endurecimiento o hinchazón de una parte de las mamas"
                                    checked={grupo.sintomasrecientessincausa.includes('16. Endurecimiento o hinchazón de una parte de las mamas')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'sintomasrecientessincausa', '16. Endurecimiento o hinchazón de una parte de las mamas', true)}
                                  />
                                  16. Endurecimiento o hinchazón de una parte de las mamas
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`sintomasrecientessincausa-${grupo.persona}`}
                                    value="17. Enrojecimiento o descamación en el pezón o las mamas"
                                    checked={grupo.sintomasrecientessincausa.includes('17. Enrojecimiento o descamación en el pezón o las mamas')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'sintomasrecientessincausa', '17. Enrojecimiento o descamación en el pezón o las mamas', true)}
                                  />
                                  17. Enrojecimiento o descamación en el pezón o las mamas
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`sintomasrecientessincausa-${grupo.persona}`}
                                    value="18. Erupción macular de piel"
                                    checked={grupo.sintomasrecientessincausa.includes('18. Erupción macular de piel')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'sintomasrecientessincausa', '18. Erupción macular de piel', true)}
                                  />
                                  18. Erupción macular de piel
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`sintomasrecientessincausa-${grupo.persona}`}
                                    value="19. Esputos sanguinolentos"
                                    checked={grupo.sintomasrecientessincausa.includes('19. Esputos sanguinolentos')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'sintomasrecientessincausa', '19. Esputos sanguinolentos', true)}
                                  />
                                  19. Esputos sanguinolentos
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`sintomasrecientessincausa-${grupo.persona}`}
                                    value="20. Exacerbación de la tos previamente existente generalmente sin moco"
                                    checked={grupo.sintomasrecientessincausa.includes('20. Exacerbación de la tos previamente existente generalmente sin moco')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'sintomasrecientessincausa', '20. Exacerbación de la tos previamente existente generalmente sin moco', true)}
                                  />
                                  20. Exacerbación de la tos previamente existente generalmente sin moco
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`sintomasrecientessincausa-${grupo.persona}`}
                                    value="21. Sangre en la orina (M)"
                                    checked={grupo.sintomasrecientessincausa.includes('21. Sangre en la orina (M)')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'sintomasrecientessincausa', '21. Sangre en la orina (M)', true)}
                                  />
                                  21. Sangre en la orina (M)
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`sintomasrecientessincausa-${grupo.persona}`}
                                    value="22. Secreción vaginal inusual (F)"
                                    checked={grupo.sintomasrecientessincausa.includes('22. Secreción vaginal inusual (F)')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'sintomasrecientessincausa', '22. Secreción vaginal inusual (F)', true)}
                                  />
                                  22. Secreción vaginal inusual (F)
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`sintomasrecientessincausa-${grupo.persona}`}
                                    value="23. Sed excesiva"
                                    checked={grupo.sintomasrecientessincausa.includes('23. Sed excesiva')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'sintomasrecientessincausa', '23. Sed excesiva', true)}
                                  />
                                  23. Sed excesiva
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`sintomasrecientessincausa-${grupo.persona}`}
                                    value="24. Sensación de que el intestino no se vacía por completo"
                                    checked={grupo.sintomasrecientessincausa.includes('24. Sensación de que el intestino no se vacía por completo')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'sintomasrecientessincausa', '24. Sensación de que el intestino no se vacía por completo', true)}
                                  />
                                  24. Sensación de que el intestino no se vacía por completo
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`sintomasrecientessincausa-${grupo.persona}`}
                                    value="25. Sudoración"
                                    checked={grupo.sintomasrecientessincausa.includes('25. Sudoración')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'sintomasrecientessincausa', '25. Sudoración', true)}
                                  />
                                  25. Sudoración
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`sintomasrecientessincausa-${grupo.persona}`}
                                    value="26. Tos con expectoración mucosa mayor a 15 días"
                                    checked={grupo.sintomasrecientessincausa.includes('26. Tos con expectoración mucosa mayor a 15 días')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'sintomasrecientessincausa', '26. Tos con expectoración mucosa mayor a 15 días', true)}
                                  />
                                  26. Tos con expectoración mucosa mayor a 15 días
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`sintomasrecientessincausa-${grupo.persona}`}
                                    value="27. Faringitis"
                                    checked={grupo.sintomasrecientessincausa.includes('27. Faringitis')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'sintomasrecientessincausa', '27. Faringitis', true)}
                                  />
                                  27. Faringitis
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`sintomasrecientessincausa-${grupo.persona}`}
                                    value="28. Fiebre persistente"
                                    checked={grupo.sintomasrecientessincausa.includes('28. Fiebre persistente')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'sintomasrecientessincausa', '28. Fiebre persistente', true)}
                                  />
                                  28. Fiebre persistente
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`sintomasrecientessincausa-${grupo.persona}`}
                                    value="29. Fosfenos"
                                    checked={grupo.sintomasrecientessincausa.includes('29. Fosfenos')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'sintomasrecientessincausa', '29. Fosfenos', true)}
                                  />
                                  29. Fosfenos
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`sintomasrecientessincausa-${grupo.persona}`}
                                    value="30. Gastritis crónica"
                                    checked={grupo.sintomasrecientessincausa.includes('30. Gastritis crónica')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'sintomasrecientessincausa', '30. Gastritis crónica', true)}
                                  />
                                  30. Gastritis crónica
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`sintomasrecientessincausa-${grupo.persona}`}
                                    value="31. Hambre excesiva"
                                    checked={grupo.sintomasrecientessincausa.includes('31. Hambre excesiva')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'sintomasrecientessincausa', '31. Hambre excesiva', true)}
                                  />
                                  31. Hambre excesiva
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`sintomasrecientessincausa-${grupo.persona}`}
                                    value="32. Herpes"
                                    checked={grupo.sintomasrecientessincausa.includes('32. Herpes')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'sintomasrecientessincausa', '32. Herpes', true)}
                                  />
                                  32. Herpes
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`sintomasrecientessincausa-${grupo.persona}`}
                                    value="33. Hundimiento del pezón (F)"
                                    checked={grupo.sintomasrecientessincausa.includes('33. Hundimiento del pezón (F)')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'sintomasrecientessincausa', '33. Hundimiento del pezón (F)', true)}
                                  />
                                  33. Hundimiento del pezón (F)
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`sintomasrecientessincausa-${grupo.persona}`}
                                    value="34. Infección respiratoria recurrente que no cede (bronquitis-neumonía)"
                                    checked={grupo.sintomasrecientessincausa.includes('34. Infección respiratoria recurrente que no cede (bronquitis-neumonía)')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'sintomasrecientessincausa', '34. Infección respiratoria recurrente que no cede (bronquitis-neumonía)', true)}
                                  />
                                  34. Infección respiratoria recurrente que no cede (bronquitis-neumonía)
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`sintomasrecientessincausa-${grupo.persona}`}
                                    value="35. Inflamación de los ganglios"
                                    checked={grupo.sintomasrecientessincausa.includes('35. Inflamación de los ganglios')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'sintomasrecientessincausa', '35. Inflamación de los ganglios', true)}
                                  />
                                  35. Inflamación de los ganglios
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`sintomasrecientessincausa-${grupo.persona}`}
                                    value="36. Mialgias"
                                    checked={grupo.sintomasrecientessincausa.includes('36. Mialgias')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'sintomasrecientessincausa', '36. Mialgias', true)}
                                  />
                                  36. Mialgias
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`sintomasrecientessincausa-${grupo.persona}`}
                                    value="37. Orina con frecuencia"
                                    checked={grupo.sintomasrecientessincausa.includes('37. Orina con frecuencia')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'sintomasrecientessincausa', '37. Orina con frecuencia', true)}
                                  />
                                  37. Orina con frecuencia
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`sintomasrecientessincausa-${grupo.persona}`}
                                    value="38. Pérdida de apetito"
                                    checked={grupo.sintomasrecientessincausa.includes('38. Pérdida de apetito')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'sintomasrecientessincausa', '38. Pérdida de apetito', true)}
                                  />
                                  38. Pérdida de apetito
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`sintomasrecientessincausa-${grupo.persona}`}
                                    value="39. Perdida de la sensibilidad en los pies"
                                    checked={grupo.sintomasrecientessincausa.includes('39. Perdida de la sensibilidad en los pies')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'sintomasrecientessincausa', '39. Perdida de la sensibilidad en los pies', true)}
                                  />
                                  39. Perdida de la sensibilidad en los pies
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`sintomasrecientessincausa-${grupo.persona}`}
                                    value="40. Pérdida de peso"
                                    checked={grupo.sintomasrecientessincausa.includes('40. Pérdida de peso')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'sintomasrecientessincausa', '40. Pérdida de peso', true)}
                                  />
                                  40. Pérdida de peso
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`sintomasrecientessincausa-${grupo.persona}`}
                                    value="41. Presencia de masas o bultos en los senos o en las axilas"
                                    checked={grupo.sintomasrecientessincausa.includes('41. Presencia de masas o bultos en los senos o en las axilas')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'sintomasrecientessincausa', '41. Presencia de masas o bultos en los senos o en las axilas', true)}
                                  />
                                  41. Presencia de masas o bultos en los senos o en las axilas
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`sintomasrecientessincausa-${grupo.persona}`}
                                    value="42. Producción de esputo"
                                    checked={grupo.sintomasrecientessincausa.includes('42. Producción de esputo')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'sintomasrecientessincausa', '42. Producción de esputo', true)}
                                  />
                                  42. Producción de esputo
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`sintomasrecientessincausa-${grupo.persona}`}
                                    value="43. Salida de secreción por el pezón (F)"
                                    checked={grupo.sintomasrecientessincausa.includes('43. Salida de secreción por el pezón (F)')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'sintomasrecientessincausa', '43. Salida de secreción por el pezón (F)', true)}
                                  />
                                  43. Salida de secreción por el pezón (F)
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`sintomasrecientessincausa-${grupo.persona}`}
                                    value="44. Sangrado vaginal anormal (F)"
                                    checked={grupo.sintomasrecientessincausa.includes('44. Sangrado vaginal anormal (F)')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'sintomasrecientessincausa', '44. Sangrado vaginal anormal (F)', true)}
                                  />
                                  44. Sangrado vaginal anormal (F)
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`sintomasrecientessincausa-${grupo.persona}`}
                                    value="45. Sangrados en mucosas y/o pie"
                                    checked={grupo.sintomasrecientessincausa.includes('45. Sangrados en mucosas y/o pie')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'sintomasrecientessincausa', '45. Sangrados en mucosas y/o pie', true)}
                                  />
                                  45. Sangrados en mucosas y/o pie
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`sintomasrecientessincausa-${grupo.persona}`}
                                    value="46. Sangre en el semen (M)"
                                    checked={grupo.sintomasrecientessincausa.includes('46. Sangre en el semen (M)')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'sintomasrecientessincausa', '46. Sangre en el semen (M)', true)}
                                  />
                                  46. Sangre en el semen (M)
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`sintomasrecientessincausa-${grupo.persona}`}
                                    value="47. Sangre en la materia fecal"
                                    checked={grupo.sintomasrecientessincausa.includes('47. Sangre en la materia fecal')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'sintomasrecientessincausa', '47. Sangre en la materia fecal', true)}
                                  />
                                  47. Sangre en la materia fecal
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`sintomasrecientessincausa-${grupo.persona}`}
                                    value="48. Tos crónica"
                                    checked={grupo.sintomasrecientessincausa.includes('48. Tos crónica')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'sintomasrecientessincausa', '48. Tos crónica', true)}
                                  />
                                  48. Tos crónica
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`sintomasrecientessincausa-${grupo.persona}`}
                                    value="49. Tos persistente mayor a 21 días (0 a 5 a)"
                                    checked={grupo.sintomasrecientessincausa.includes('49. Tos persistente mayor a 21 días (0 a 5 a)')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'sintomasrecientessincausa', '49. Tos persistente mayor a 21 días (0 a 5 a)', true)}
                                  />
                                  49. Tos persistente mayor a 21 días (0 a 5 a)
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`sintomasrecientessincausa-${grupo.persona}`}
                                    value="50. Tinnitus"
                                    checked={grupo.sintomasrecientessincausa.includes('50. Tinnitus')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'sintomasrecientessincausa', '50. Tinnitus', true)}
                                  />
                                  50. Tinnitus
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`sintomasrecientessincausa-${grupo.persona}`}
                                    value="51. Hemorragia conjuntival"
                                    checked={grupo.sintomasrecientessincausa.includes('51. Hemorragia conjuntival')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'sintomasrecientessincausa', '51. Hemorragia conjuntival', true)}
                                  />
                                  51. Hemorragia conjuntival
                                </label>
                              </div>
                              <div class="form-check">
                                <label class="form-check-label">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    name={`sintomasrecientessincausa-${grupo.persona}`}
                                    value="52. Ninguno de los anteriores"
                                    checked={grupo.sintomasrecientessincausa.includes('52. Ninguno de los anteriores')}
                                    onChange={() => handleChangeCampo(grupo.persona, 'sintomasrecientessincausa', '52. Ninguno de los anteriores', true)}
                                  />
                                  52. Ninguno de los anteriores
                                </label>
                              </div>
                              {/* {!grupo.sintomasrecientessincausa.length && <div className="text-danger">Campo obligatorio</div>} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mb-4"><h4 class="text-center"> 4.	PRACTICAS DE SALUD DE SALUD ORAL</h4>
                      <div class="row">
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">Cepillado diario mínimo 3 veces al día</label>
                            <select className={`form-select`} name='cepilladodiariominimovec' value={grupo.cepilladodiariominimovec} onChange={(e) => handleChangeCampo(grupo.persona, 'cepilladodiariominimovec', e.target.value)}>
                              <option selected></option>
                              <option value="SI">SI</option>
                              <option value="NO">NO</option>
                              <option value="SD">SD</option>
                            </select>
                            {/* {!grupo.cepilladodiariominimovec && <div className="invalid-feedback">Campo obligatorio</div>} */}
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">¿Utiliza otro elemento para el higiene de salud oral?</label>
                            <select className={`form-select`} name='utilizaotroelementoparael' value={grupo.utilizaotroelementoparael} onChange={(e) => handleChangeCampo(grupo.persona, 'utilizaotroelementoparael', e.target.value)}>
                              <option selected></option>
                              <option value="SI">SI</option>
                              <option value="NO">NO</option>
                              <option value="SD">SD</option>
                            </select>
                            {/* {!grupo.utilizaotroelementoparael && <div className="invalid-feedback">Campo obligatorio</div>} */}
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">¿la última vez que visitó el odontólogo?</label>
                            <select className={`form-select`} name='laultimavezquevisitoelodo' value={grupo.laultimavezquevisitoelodo} onChange={(e) => handleChangeCampo(grupo.persona, 'laultimavezquevisitoelodo', e.target.value)}>
                              <option selected></option>
                              <option value="Menos de 6 meses">Menos de 6 meses</option>
                              <option value="Entre 6 y 12 meses">Entre 6 y 12 meses</option>
                              <option value="Entre 1 y 2 años">Entre 1 y 2 años</option>
                              <option value="Más de 2 años">Más de 2 años</option>
                              <option value="Nunca lo han visitado">Nunca lo han visitado</option>
                            </select>
                            {/* {!grupo.laultimavezquevisitoelodo && <div className="invalid-feedback">Campo obligatorio</div>} */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mb-4"><h4 class="text-center"> 5.	SEXUALIDAD, DERECHOS SEXUALES Y REPRODUCTIVOS</h4>
                      <div class="row">
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">Fecha de la última menstruación</label>
                            <input
                              type="date"
                              name='fechadelaultimamenstruaci'
                              className={`form-control`}
                              value={grupo.fechadelaultimamenstruaci}
                              onChange={(e) => handleChangeCampo(grupo.persona, 'fechadelaultimamenstruaci', e.target.value)}
                            />
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">Su última menstruación fue después de los 50 años (F)</label>
                            <select className={`form-select`} name='suultimamenstruacionfuede' value={grupo.suultimamenstruacionfuede} onChange={(e) => handleChangeCampo(grupo.persona, 'suultimamenstruacionfuede', e.target.value)}>
                              <option selected></option>
                              <option value="SI">SI</option>
                              <option value="NO">NO</option>
                              <option value="SD">SD</option>
                              <option value="NA">NA</option>
                            </select>
                            {/* {!grupo.suultimamenstruacionfuede && <div className="invalid-feedback">Campo obligatorio</div>} */}
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">Actualmente tiene relaciones sexuales (SM)</label>
                            <select className={`form-select`} name='actualmentetienerelacione' value={grupo.actualmentetienerelacione} onChange={(e) => handleChangeCampo(grupo.persona, 'actualmentetienerelacione', e.target.value)}>
                              <option selected></option>
                              <option value="SI">SI</option>
                              <option value="NO">NO</option>
                              <option value="SD">SD</option>
                              <option value="NA">NA</option>
                            </select>
                            {/* {!grupo.actualmentetienerelacione && <div className="invalid-feedback">Campo obligatorio</div>} */}
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">Práctica sexual riesgosa (SM) a partir 12 a</label>
                            <select className={`form-select`} name='practicasexualriesgosasma' value={grupo.practicasexualriesgosasma} onChange={(e) => handleChangeCampo(grupo.persona, 'practicasexualriesgosasma', e.target.value)}>
                              <option selected></option>
                              <option value="Trabajo sexual">Trabajo sexual</option>
                              <option value="Relaciones sexuales hombre con hombre (M)">Relaciones sexuales hombre con hombre (M)</option>
                              <option value="Relaciones sexuales sin condón">Relaciones sexuales sin condón</option>
                              <option value="Ninguna">Ninguna</option>
                              <option value="NA">NA</option>
                            </select>
                            {/* {!grupo.practicasexualriesgosasma && <div className="invalid-feedback">Campo obligatorio</div>} */}
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">PQue método de planificación utiliza como pareja (SM) de 12 a 49 a</label>
                            <select className={`form-select`} name='quemetododeplanificacionu' value={grupo.quemetododeplanificacionu} onChange={(e) => handleChangeCampo(grupo.persona, 'quemetododeplanificacionu', e.target.value)}>
                              <option selected></option>
                              <option value="Barrera">Barrera</option>
                              <option value="Definitivo">Definitivo</option>
                              <option value="Hormonal">Hormonal</option>
                              <option value="Mecánico">Mecánico</option>
                              <option value="Natural">Natural</option>
                              <option value="Ninguna">Ninguna</option>
                              <option value="NA">NA</option>
                            </select>
                            {/* {!grupo.quemetododeplanificacionu && <div className="invalid-feedback">Campo obligatorio</div>} */}
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">Es constante con el método de planificación (12 a 49 a)</label>
                            <select className={`form-select`} name='esconstanteconelmetododep' value={grupo.esconstanteconelmetododep} onChange={(e) => handleChangeCampo(grupo.persona, 'esconstanteconelmetododep', e.target.value)}>
                              <option selected></option>
                              <option value="SI">SI</option>
                              <option value="NO">NO</option>
                              <option value="SD">SD</option>
                              <option value="NA">NA</option>
                            </select>
                            {/* {!grupo.esconstanteconelmetododep && <div className="invalid-feedback">Campo obligatorio</div>} */}
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">Ha utilizado por más de 10 años anticonceptivos orales (F) (a partir 22 a)</label>
                            <select className={`form-select`} name='hautilizadopormasdediezan' value={grupo.hautilizadopormasdediezan} onChange={(e) => handleChangeCampo(grupo.persona, 'hautilizadopormasdediezan', e.target.value)}>
                              <option selected></option>
                              <option value="SI">SI</option>
                              <option value="NO">NO</option>
                              <option value="SD">SD</option>
                              <option value="NA">NA</option>
                            </select>
                            {/* {!grupo.hautilizadopormasdediezan && <div className="invalid-feedback">Campo obligatorio</div>} */}
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">¿Quién facilita el método el acceso al método planificación?</label>
                            <select className={`form-select`} name='quienfacilitaelmetodoelac' value={grupo.quienfacilitaelmetodoelac} onChange={(e) => handleChangeCampo(grupo.persona, 'quienfacilitaelmetodoelac', e.target.value)}>
                              <option selected></option>
                              <option value="IPS">IPS</option>
                              <option value="EPS">EPS</option>
                              <option value="Adquirido por cuenta propia">Adquirido por cuenta propia</option>
                              <option value="NA">NA</option>
                            </select>
                            {/* {!grupo.quienfacilitaelmetodoelac && <div className="invalid-feedback">Campo obligatorio</div>} */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mb-4"><h4 class="text-center"> 5.	SEXUALIDAD, DERECHOS SEXUALES Y REPRODUCTIVOS (MATERNIDAD)</h4>
                      <div class="row">
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">N° Partos</label>
                            <input
                              type="number"
                              name='npartos'
                              className={`form-control`}
                              value={grupo.npartos}
                              onChange={(e) => handleChangeCampo(grupo.persona, 'npartos', e.target.value)}
                            />
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">N° cesáreas</label>
                            <input
                              type="number"
                              name='ncesareas'
                              className={`form-control`}
                              value={grupo.ncesareas}
                              onChange={(e) => handleChangeCampo(grupo.persona, 'ncesareas', e.target.value)}
                            />
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">N° abortos</label>
                            <input
                              type="number"
                              name='nabortos'
                              className={`form-control`}
                              value={grupo.nabortos}
                              onChange={(e) => handleChangeCampo(grupo.persona, 'nabortos', e.target.value)}
                            />
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">N° nacidos vivos</label>
                            <input
                              type="number"
                              name='nnacidosvivos'
                              className={`form-control`}
                              value={grupo.nnacidosvivos}
                              onChange={(e) => handleChangeCampo(grupo.persona, 'nnacidosvivos', e.target.value)}
                            />
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">N° mortinatos</label>
                            <input
                              type="number"
                              name='nmortinatos'
                              className={`form-control`}
                              value={grupo.nmortinatos}
                              onChange={(e) => handleChangeCampo(grupo.persona, 'nmortinatos', e.target.value)}
                            />
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">Edad al momento de nacer el primer hijo</label>
                            <select className={`form-select`} name='edadalmomentodenacerelpri' value={grupo.edadalmomentodenacerelpri} onChange={(e) => handleChangeCampo(grupo.persona, 'edadalmomentodenacerelpri', e.target.value)}>
                              <option selected></option>
                              <option value="Menores de 14 años">Menores de 14 años</option>
                              <option value="Entre 14 y 17 años">Entre 14 y 17 años</option>
                              <option value="Entre  18 y 29 años">Entre  18 y 29 años</option>
                              <option value="Entre  30 y 39 años">Entre  30 y 39 años</option>
                              <option value="Mayor de 40 años">Mayor de 40 años</option>
                              <option value="NA">NA</option>
                            </select>
                            {/* {!grupo.edadalmomentodenacerelpri && <div className="invalid-feedback">Campo obligatorio</div>} */}
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">En sus embarazos consumió alcohol o cigarrillo de forma frecuente</label>
                            <select className={`form-select`} name='ensusembarazosconsumioalc' value={grupo.ensusembarazosconsumioalc} onChange={(e) => handleChangeCampo(grupo.persona, 'ensusembarazosconsumioalc', e.target.value)}>
                              <option selected></option>
                              <option value="SI">SI</option>
                              <option value="NO">NO</option>
                              <option value="NA">NA</option>
                            </select>
                            {/* {!grupo.ensusembarazosconsumioalc && <div className="invalid-feedback">Campo obligatorio</div>} */}
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">Embarazo planeado</label>
                            <select className={`form-select`} name='embarazoplaneado' value={grupo.embarazoplaneado} onChange={(e) => handleChangeCampo(grupo.persona, 'embarazoplaneado', e.target.value)}>
                              <option selected></option>
                              <option value="SI">SI</option>
                              <option value="NO">NO</option>
                              <option value="NA">NA</option>
                            </select>
                            {/* {!grupo.embarazoplaneado && <div className="invalid-feedback">Campo obligatorio</div>} */}
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">Clasificación del riesgo obstétrico</label>
                            <select className={`form-select`} name='clasificaciondelriesgoobs' value={grupo.clasificaciondelriesgoobs} onChange={(e) => handleChangeCampo(grupo.persona, 'clasificaciondelriesgoobs', e.target.value)}>
                              <option selected></option>
                              <option value="Biologico ">Biologico </option>
                              <option value="Psicologico">Psicologico</option>
                              <option value="Sociocultutral ">Sociocultutral </option>
                            </select>
                            {/* {!grupo.clasificaciondelriesgoobs && <div className="invalid-feedback">Campo obligatorio</div>} */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mb-4"><h4 class="text-center"> 6.	RECIEN NACIDO</h4>
                      <div class="row">
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">¿Sabe el Motivo del riesgo?</label>
                            <input
                              type="text"
                              name='sabeelmotivodelriesgo'
                              className={`form-control`}
                              value={grupo.sabeelmotivodelriesgo}
                              onChange={(e) => handleChangeCampo(grupo.persona, 'sabeelmotivodelriesgo', e.target.value)}
                            />
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">Alimenta con leche materna exclusiva? (hasta 6 m)</label>
                            <select className={`form-select`} name='alimentaconlechematernaex' value={grupo.alimentaconlechematernaex} onChange={(e) => handleChangeCampo(grupo.persona, 'alimentaconlechematernaex', e.target.value)}>
                              <option selected></option>
                              <option value="SI ">SI </option>
                              <option value="NO">NO</option>
                              <option value="SD">SD</option>
                            </select>
                            {/* {!grupo.alimentaconlechematernaex && <div className="invalid-feedback">Campo obligatorio</div>} */}
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">Alimentación complementaria (hasta 2 años)</label>
                            <select className={`form-select`} name='alimentacioncomplementari' value={grupo.alimentacioncomplementari} onChange={(e) => handleChangeCampo(grupo.persona, 'alimentacioncomplementari', e.target.value)}>
                              <option selected></option>
                              <option value="SI ">SI </option>
                              <option value="NO">NO</option>
                              <option value="SD">SD</option>
                            </select>
                            {/* {!grupo.alimentacioncomplementari && <div className="invalid-feedback">Campo obligatorio</div>} */}
                          </div> 
                        </div>
                      </div> 
                    </div>
                    <div class="mb-4"><h4 class="text-center"> 7.	VIDA SALUDABLE Y CONDICIONES NO TRANSMISIBLES</h4>
                      <div class="row">
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">¿Última vez que asistió a citología y/o VPH? Mujeres 25 - 29 años - citología) -Mujeres persona de 30 años-VPH (resultado negativo con frecuencia 5 años)</label>
                            <select className={`form-select`} name='ultimavezqueasistioacitol' value={grupo.ultimavezqueasistioacitol} onChange={(e) => handleChangeCampo(grupo.persona, 'ultimavezqueasistioacitol', e.target.value)}>
                              <option selected></option>
                              <option value="Menor a 3 años">Menor a 3 años</option>
                              <option value="Más de 3 años">Más de 3 años</option>
                              <option value="Nunca">Nunca</option>
                              <option value="NA">NA</option>
                            </select>
                            {/* {!grupo.ultimavezqueasistioacitol && <div className="invalid-feedback">Campo obligatorio</div>} */}
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">¿Última vez le realizaron prueba de Antígeno prostático y/o tacto rectal? (persona de 50 años)</label>
                            <select className={`form-select`} name='ultimavezlerealizaronprue' value={grupo.ultimavezlerealizaronprue} onChange={(e) => handleChangeCampo(grupo.persona, 'ultimavezlerealizaronprue', e.target.value)}>
                              <option selected></option>
                              <option value="Menor a 5 años">Menor a 5 años</option>
                              <option value="Más de 5 años">Más de 5 años</option>
                              <option value="Nunca">Nunca</option>
                              <option value="NA">NA</option>
                            </select>
                            {/* {!grupo.ultimavezlerealizaronprue && <div className="invalid-feedback">Campo obligatorio</div>} */}
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">¿última vez que le realizaron examen clínico de mama? (menores 50 años)</label>
                            <select className={`form-select`} name='ultimavezquelerealizarone' value={grupo.ultimavezquelerealizarone} onChange={(e) => handleChangeCampo(grupo.persona, 'ultimavezquelerealizarone', e.target.value)}>
                              <option selected></option>
                              <option value="Menor a 1 año">Menor a 1 año</option>
                              <option value="Más de 1 año">Más de 1 año</option>
                              <option value="Nunca">Nunca</option>
                              <option value="NA">NA</option>
                            </select>
                            {/* {!grupo.ultimavezquelerealizarone && <div className="invalid-feedback">Campo obligatorio</div>} */}
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">¿última vez que le realizaron mamografía (persona de 50 años)</label>
                            <select className={`form-select`} name='ultimavezquelerealizaronm' value={grupo.ultimavezquelerealizaronm} onChange={(e) => handleChangeCampo(grupo.persona, 'ultimavezquelerealizaronm', e.target.value)}>
                              <option selected></option>
                              <option value="Menor a 2 años">Menor a 2 años</option>
                              <option value="Más de 2 años">Más de 2 años</option>
                              <option value="Nunca">Nunca</option>
                              <option value="NA">NA</option>
                            </select>
                            {/* {!grupo.ultimavezquelerealizaronm && <div className="invalid-feedback">Campo obligatorio</div>} */}
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">Práctica de actividad física en minutos</label>
                            <select className={`form-select`} name='practicadeactividadfisica' value={grupo.practicadeactividadfisica} onChange={(e) => handleChangeCampo(grupo.persona, 'practicadeactividadfisica', e.target.value)}>
                              <option selected></option>
                              <option value="Mayor a 60 min diarios">Mayor a 60 min diarios</option>
                              <option value="Menor a 60 min diarios">Menor a 60 min diarios</option>
                              <option value="Mayor a 30 min diarios">Mayor a 30 min diarios</option>
                              <option value="Menor a 30 min diarios">Menor a 30 min diarios</option>
                              <option value="No Practica Ninguna actividad fisica">No Practica Ninguna actividad fisica</option>
                            </select>
                            {/* {!grupo.practicadeactividadfisica && <div className="invalid-feedback">Campo obligatorio</div>} */}
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">Hábitos de alimentación</label>
                            <select className={`form-select`} name='habitosdealimentacion' value={grupo.habitosdealimentacion} onChange={(e) => handleChangeCampo(grupo.persona, 'habitosdealimentacion', e.target.value)}>
                              <option selected></option>
                              <option value="Alto consumo de sal">Alto consumo de sal</option>
                              <option value="No consume mínimo 2 frutas diarias">No consume mínimo 2 frutas diarias</option>
                              <option value="No consume mínimo 2 verduras diarias">No consume mínimo 2 verduras diarias</option>
                              <option value="Consumo alto de grasas">Consumo alto de grasas</option>
                              <option value="Alto consumo de azúcar">Alto consumo de azúcar</option>
                              <option value="Alto consumo de empaquetados, salsas, dulces, jugos en caja, embutidos">Alto consumo de empaquetados, salsas, dulces, jugos en caja, embutidos</option>
                              <option value="Adecuados">Adecuados</option>
                              <option value="NA">NA</option>
                            </select>
                            {/* {!grupo.habitosdealimentacion && <div className="invalid-feedback">Campo obligatorio</div>} */}
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">Exposición frecuente a humo (SM)</label>
                            <select className={`form-select`} name='exposicionfrecuenteahumos' value={grupo.exposicionfrecuenteahumos} onChange={(e) => handleChangeCampo(grupo.persona, 'exposicionfrecuenteahumos', e.target.value)}>
                              <option selected></option>
                              <option value="Cigarrillo">Cigarrillo</option>
                              <option value="Leña">Leña</option>
                              <option value="carbón">carbón</option>
                              <option value="NA">NA</option>
                            </select>
                            {/* {!grupo.exposicionfrecuenteahumos && <div className="invalid-feedback">Campo obligatorio</div>} */}
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">¿Se desparasitado en los ultimos 6 meses? *A partir de los 6 meses de edad.</label>
                            <select className={`form-select`} name='sedesparasitadoenlosultim' value={grupo.sedesparasitadoenlosultim} onChange={(e) => handleChangeCampo(grupo.persona, 'sedesparasitadoenlosultim', e.target.value)}>
                              <option selected></option>
                              <option value="SI">SI</option>
                              <option value="NO">NO</option>
                              <option value="NA">NA</option>
                            </select>
                            {/* {!grupo.sedesparasitadoenlosultim && <div className="invalid-feedback">Campo obligatorio</div>} */}
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">¿última vez que le practicaron tamizaje para cáncer colorrectal? (prueba de sangre oculta)</label>
                            <select className={`form-select`} name='ultimavezquelepracticaron' value={grupo.ultimavezquelepracticaron} onChange={(e) => handleChangeCampo(grupo.persona, 'ultimavezquelepracticaron', e.target.value)}>
                              <option selected></option>
                              <option value="hace dos años">hace dos años</option>
                              <option value="Más de 3 años">Más de 3 años</option>
                              <option value="Nunca">Nunca</option>
                            </select>
                            {/* {!grupo.ultimavezquelepracticaron && <div className="invalid-feedback">Campo obligatorio</div>} */}
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">Peso (Kg)</label>
                            <input
                              type="number"
                              name='pesokg'
                              className={`form-control`}
                              value={grupo.pesokg}
                              onChange={(e) => handleChangeCampo(grupo.persona, 'pesokg', e.target.value)}
                            />
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">Talla (Cm)</label>
                            <input
                              type="number"
                              name='tallacm'
                              className={`form-control`}
                              value={grupo.tallacm}
                              onChange={(e) => handleChangeCampo(grupo.persona, 'tallacm', e.target.value)}
                            />
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">Clasificación Antrometrica</label>
                            <input
                              type="text"
                              name='clasificacionantrometrica'
                              className={`form-control`}
                              value={grupo.clasificacionantrometrica}
                              onChange={(e) => handleChangeCampo(grupo.persona, 'clasificacionantrometrica', e.target.value)}
                            />
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">Circunferencia de la Cintura (cm)</label>
                            <input
                              type="number"
                              name='circunferenciadelacintura'
                              className={`form-control`}
                              value={grupo.circunferenciadelacintura}
                              onChange={(e) => handleChangeCampo(grupo.persona, 'circunferenciadelacintura', e.target.value)}
                            />
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">Perimetro del brazo (Niños y Niñas de 6 a 59 meses)</label>
                            <input
                              type="number"
                              name='perimetrodelbrazoninosyni'
                              className={`form-control`}
                              value={grupo.perimetrodelbrazoninosyni}
                              onChange={(e) => handleChangeCampo(grupo.persona, 'perimetrodelbrazoninosyni', e.target.value)}
                            />
                          </div>
                        </div>
                      </div> 
                    </div>    
                    <div class="mb-4"><h4 class="text-center"> 8.	SALUD MENTAL</h4>
                      <div class="row">
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">¿Actualmente existe alguna crisis familiar o personal en la cual usted no se siente en las capacidades para enfrentar</label>
                            <select className={`form-select`} name='actualmenteexistealgunacr' value={grupo.actualmenteexistealgunacr} onChange={(e) => handleChangeCampo(grupo.persona, 'actualmenteexistealgunacr', e.target.value)}>
                              <option selected></option>
                              <option value="SI">SI</option>
                              <option value="NO">NO</option>
                              <option value="NA">NA</option>
                            </select>
                            {/* {!grupo.actualmenteexistealgunacr && <div className="invalid-feedback">Campo obligatorio</div>} */}
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">¿Se despierta varias horas antes de lo habitual y no puede volver a dormir?</label>
                            <select className={`form-select`} name='sedespiertavariashorasant' value={grupo.sedespiertavariashorasant} onChange={(e) => handleChangeCampo(grupo.persona, 'sedespiertavariashorasant', e.target.value)}>
                              <option selected></option>
                              <option value="SI">SI</option>
                              <option value="NO">NO</option>
                              <option value="NA">NA</option>
                            </select>
                            {/* {!grupo.sedespiertavariashorasant && <div className="invalid-feedback">Campo obligatorio</div>} */}
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">¿A veces siente en que no tiene sentido vivir?</label>
                            <select className={`form-select`} name='avecessienteenquenotienes' value={grupo.avecessienteenquenotienes} onChange={(e) => handleChangeCampo(grupo.persona, 'avecessienteenquenotienes', e.target.value)}>
                              <option selected></option>
                              <option value="SI">SI</option>
                              <option value="NO">NO</option>
                              <option value="NA">NA</option>
                            </select>
                            {/* {!grupo.avecessienteenquenotienes && <div className="invalid-feedback">Campo obligatorio</div>} */}
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">¿considera que la muerte puede ser  la solución al /los problema(s) que usted está presentando?</label>
                            <select className={`form-select`} name='consideraquelamuertepuede' value={grupo.consideraquelamuertepuede} onChange={(e) => handleChangeCampo(grupo.persona, 'consideraquelamuertepuede', e.target.value)}>
                              <option selected></option>
                              <option value="SI">SI</option>
                              <option value="NO">NO</option>
                              <option value="NA">NA</option>
                            </select>
                            {/* {!grupo.consideraquelamuertepuede && <div className="invalid-feedback">Campo obligatorio</div>} */}
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">¿Estar cargo del cuidado de alguna persona con dependencia funcional (discapacidad – vejez) le genera malestar psicológico o físico ?</label>
                            <select className={`form-select`} name='estarcargodelcuidadodealg' value={grupo.estarcargodelcuidadodealg} onChange={(e) => handleChangeCampo(grupo.persona, 'estarcargodelcuidadodealg', e.target.value)}>
                              <option selected></option>
                              <option value="SI">SI</option>
                              <option value="NO">NO</option>
                              <option value="NA">NA</option>
                            </select>
                            {/* {!grupo.estarcargodelcuidadodealg && <div className="invalid-feedback">Campo obligatorio</div>} */}
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">¿experimenta una perdida/muerte que sobrepasa su capacidad de afrontamiento? </label>
                            <select className={`form-select`} name='experimentaunaperdidamuer' value={grupo.experimentaunaperdidamuer} onChange={(e) => handleChangeCampo(grupo.persona, 'experimentaunaperdidamuer', e.target.value)}>
                              <option selected></option>
                              <option value="SI">SI</option>
                              <option value="NO">NO</option>
                              <option value="NA">NA</option>
                            </select>
                            {/* {!grupo.experimentaunaperdidamuer && <div className="invalid-feedback">Campo obligatorio</div>} */}
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">¿Considera que los comportamientos que su familia emplea para solucionar los problemas son inadecuados, causan sufrimiento? </label>
                            <select className={`form-select`} name='consideraqueloscomportami' value={grupo.consideraqueloscomportami} onChange={(e) => handleChangeCampo(grupo.persona, 'consideraqueloscomportami', e.target.value)}>
                              <option selected></option>
                              <option value="SI">SI</option>
                              <option value="NO">NO</option>
                              <option value="NA">NA</option>
                            </select>
                            {/* {!grupo.consideraqueloscomportami && <div className="invalid-feedback">Campo obligatorio</div>} */}
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">¿Considera anormales  sus hábitos alimenticios o los de algún integrante de su hogar’</label>
                            <select className={`form-select`} name='consideraanormalessushabi' value={grupo.consideraanormalessushabi} onChange={(e) => handleChangeCampo(grupo.persona, 'consideraanormalessushabi', e.target.value)}>
                              <option selected></option>
                              <option value="SI">SI</option>
                              <option value="NO">NO</option>
                              <option value="NA">NA</option>
                            </select>
                            {/* {!grupo.consideraanormalessushabi && <div className="invalid-feedback">Campo obligatorio</div>} */}
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">¿Identifica en su hogar comportamientos que considere extraños, que afectan la cotidianidad?</label>
                            <select className={`form-select`} name='identificaensuhogarcompor' value={grupo.identificaensuhogarcompor} onChange={(e) => handleChangeCampo(grupo.persona, 'identificaensuhogarcompor', e.target.value)}>
                              <option selected></option>
                              <option value="SI">SI</option>
                              <option value="NO">NO</option>
                              <option value="NA">NA</option>
                            </select>
                            {/* {!grupo.identificaensuhogarcompor && <div className="invalid-feedback">Campo obligatorio</div>} */}
                          </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                          <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                            <label class="form-label">Consumo de Sustancias Psicoactiva</label>
                            <select className={`form-select`} name='consumodesustanciaspsicoa' value={grupo.consumodesustanciaspsicoa} onChange={(e) => handleChangeCampo(grupo.persona, 'consumodesustanciaspsicoa', e.target.value)}>
                              <option selected></option>
                              <option value="Alcohol (<9 a 17 a)">Alcohol (Menor 9 a 17 a)</option>
                              <option value="Abuso de alcohol (a partir 18 a)">Abuso de alcohol (a partir 18 a)</option>
                              <option value="Cigarrillo">Cigarrillo</option>
                              <option value="Otras SPA">Otras SPA</option>
                              <option value="Riesgo o uso de SPA por vía inyectable">Riesgo o uso de SPA por vía inyectable</option>
                              <option value="Ninguna">Ninguna</option>
                              <option value="NA">NA</option>
                            </select>
                            {/* {!grupo.consumodesustanciaspsicoa && <div className="invalid-feedback">Campo obligatorio</div>} */}
                          </div>
                        </div>
                      </div> 
                    </div>
                    <div class="mb-5"><h4 class="text-center">SEGUIMIENTO VACUNACIÓN</h4>
                      <div class="mb-5">
                        <h5 class="text">Niños menores de 6 años</h5>
                        <div class="accordion" id={`accordionSixYeards-1`}>
                          {/* Mostrar los grupos de campos agregados */}
                          <div class="accordion-item border border-primary">
                            <h2 class="accordion-header">
                              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapsesix-1`} aria-expanded="true" aria-controls={`collapsesix-1`}>
                                Desplegar para diligenciar datos
                              </button>
                            </h2>
                            <div id={`collapsesix-1`} class="accordion-collapse collapse" data-bs-parent={`#accordionSixYeard-1`}>
                              <div class="accordion-body">
                                {/* Agregar aquí los campos específicos de este grupo */}
                                <div class="mb-4"><h5 class="text-center">Por favor al diligenciar registre la fecha  de aplicación de los biológicos trazadores de los niños y niñas menores de 6 años</h5>
                                  <div class="row">
                                    <div class="col-12 col-md-6 col-lg-4">
                                      <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                        <label class="form-label">DOSIS RN (BCG)</label>
                                        <input
                                          type="date"
                                          name='seisdosisrnbcg'
                                          className={`form-control`}
                                          value={grupo.seisdosisrnbcg}
                                          onChange={(e) => handleChangeCampo(grupo.persona, 'seisdosisrnbcg', e.target.value)}
                                        />
                                      </div>
                                    </div>
                                    <div class="col-12 col-md-6 col-lg-4">
                                      <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                        <label class="form-label">3RAS DOSIS (PENTA)</label>
                                        <input
                                          type="date"
                                          name='seistercerasdosispenta'
                                          className={`form-control`}
                                          value={grupo.seistercerasdosispenta}
                                          onChange={(e) => handleChangeCampo(grupo.persona, 'seistercerasdosispenta', e.target.value)}
                                        />
                                      </div>
                                    </div>
                                    <div class="col-12 col-md-6 col-lg-4">
                                      <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                        <label class="form-label">DOSIS AÑO (Triple Viral)</label>
                                        <input
                                          type="date"
                                          name='seisdosisanotripleviral'
                                          className={`form-control`}
                                          value={grupo.seisdosisanotripleviral}
                                          onChange={(e) => handleChangeCampo(grupo.persona, 'seisdosisanotripleviral', e.target.value)}
                                        />
                                      </div>
                                    </div>
                                    <div class="col-12 col-md-6 col-lg-4">
                                      <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                        <label class="form-label">18 MESES (DPT Ó PENTA)</label>
                                        <input
                                          type="date"
                                          name='seisdiesiochomesesdptopenta'
                                          className={`form-control`}
                                          value={grupo.seisdiesiochomesesdptopenta}
                                          onChange={(e) => handleChangeCampo(grupo.persona, 'seisdiesiochomesesdptopenta', e.target.value)}
                                        />
                                      </div>
                                    </div>
                                    <div class="col-12 col-md-6 col-lg-4">
                                      <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                        <label class="form-label">DOSIS 5 AÑOS (2 RF DPT)</label>
                                        <input
                                          type="date"
                                          name='seisdosiscincoaniossegundorfdpt'
                                          className={`form-control`}
                                          value={grupo.seisdosiscincoaniossegundorfdpt}
                                          onChange={(e) => handleChangeCampo(grupo.persona, 'seisdosiscincoaniossegundorfdpt', e.target.value)}
                                        />
                                      </div>
                                    </div>
                                    <div class="col-12 col-md-6 col-lg-4">
                                      <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                        <label class="form-label">DOSIS DE INFLUENZA ANUAL</label>
                                        <input
                                          type="date"
                                          name='seisdosisdeinfluenzaanual'
                                          className={`form-control`}
                                          value={grupo.seisdosisdeinfluenzaanual}
                                          onChange={(e) => handleChangeCampo(grupo.persona, 'seisdosisdeinfluenzaanual', e.target.value)}
                                        />
                                      </div>
                                    </div>
                                    <div class="col-12 col-md-6 col-lg-4">
                                      <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                        <label class="form-label">ESQUEMA COMPLETO (1.SI - 2.NO)</label>
                                        <select className={`form-select`} name='seisesquemacompleto' value={grupo.seisesquemacompleto} onChange={(e) => handleChangeCampo(grupo.persona, 'seisesquemacompleto', e.target.value)}>
                                          <option selected></option>
                                          <option value="SI">SI</option>
                                          <option value="NO">NO</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div class="col-12 col-md-6 col-lg-4">
                                      <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                        <label class="form-label">1.VACUNADO / 2.CANALIZADO</label>
                                        <select className={`form-select`} name='seisvacunadocanalizado' value={grupo.seisvacunadocanalizado} onChange={(e) => handleChangeCampo(grupo.persona, 'seisvacunadocanalizado', e.target.value)}>
                                          <option selected></option>
                                          <option value="VACUNADO">VACUNADO</option>
                                          <option value="CANALIZADO">CANALIZADO</option>
                                        </select>
                                      </div>
                                    </div>
                                  </div> 
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="mb-5">
                        <h5 class="text">Dosis adicional de SR niños de 2 A 11 años</h5>
                        <div class="accordion" id="accordionTwoEleven"> 
                            <div class="accordion-item border border-primary">
                              <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapsetwoeleven-1`} aria-expanded="true" aria-controls={`collapsetwoeleven-1`}>
                                  Desplegar para diligenciar datos
                                </button>
                              </h2>
                              <div id={`collapsetwoeleven-1`} class="accordion-collapse collapse" data-bs-parent="#accordionTwoEleven">
                                <div class="accordion-body">
                                  {/* Agregar aquí los campos específicos de este grupo */}
                                  <div class="mb-4"><h5 class="text-center">Por favor al diligenciar registre la fecha  de aplicación de la dosis adicional de SR en niños de a 2 a 11 años</h5>
                                    <div class="row">
                                      <div class="col-12 col-md-6 col-lg-4">
                                        <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                          <label class="form-label">DOSIS ADICIONAL SR</label>
                                          <input
                                            type="date"
                                            name='dosdosisadicionalsr'
                                            className={`form-control`}
                                            value={grupo.dosdosisadicionalsr}
                                            onChange={(e) => handleChangeCampo(grupo.persona, 'dosdosisadicionalsr', e.target.value)}
                                          />
                                        </div>
                                      </div>
                                      <div class="col-12 col-md-6 col-lg-4">
                                        <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                          <label class="form-label">ESQUEMA COMPLETO (1.SI - 2.NO)</label>
                                          <select className={`form-select`} name='dosesquemacompleto' value={grupo.dosesquemacompleto} onChange={(e) => handleChangeCampo(grupo.persona, 'dosesquemacompleto', e.target.value)}>
                                            <option selected></option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                          </select>
                                        </div>
                                      </div>
                                      <div class="col-12 col-md-6 col-lg-4">
                                        <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                          <label class="form-label">1.VACUNADO / 2.CANALIZADO</label>
                                          <select className={`form-select`} name='dosvacunadocanalizado' value={grupo.dosvacunadocanalizado} onChange={(e) => handleChangeCampo(grupo.persona, 'dosvacunadocanalizado', e.target.value)}>
                                            <option selected></option>
                                            <option value="VACUNADO">VACUNADO</option>
                                            <option value="CANALIZADO">CANALIZADO</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div> 
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                      </div>
                      <div class="mb-5">
                        <h5 class="text">COVID-19 persona de 3 años</h5>
                        <div class="accordion" id="accordionCovidTres">
                            <div class="accordion-item border border-primary">
                              <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapsecovidtres-1`} aria-expanded="true" aria-controls={`collapsecovidtres-1`}>
                                  Desplegar para diligenciar datos
                                </button>
                              </h2>
                              <div id={`collapsecovidtres-1`} class="accordion-collapse collapse" data-bs-parent="#accordionCovidTres">
                                <div class="accordion-body">
                                  {/* Agregar aquí los campos específicos de este grupo */}
                                  <div class="mb-4"><h5 class="text-center">Por favor al diligenciar registre la fecha  de aplicación de las dosis de vacuna contra el COVID-19</h5>
                                    <div class="row">
                                      <div class="col-12 col-md-6 col-lg-4">
                                        <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                          <label class="form-label">1 DOSIS</label>
                                          <input
                                            type="date"
                                            name='covidprimeradosis'
                                            className={`form-control`}
                                            value={grupo.covidprimeradosis}
                                            onChange={(e) => handleChangeCampo(grupo.persona, 'covidprimeradosis', e.target.value)}
                                          />
                                        </div>
                                      </div>
                                      <div class="col-12 col-md-6 col-lg-4">
                                        <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                          <label class="form-label">DOSUS ÚNICA</label>
                                          <input
                                            type="date"
                                            name='coviddosusunica'
                                            className={`form-control`}
                                            value={grupo.coviddosusunica}
                                            onChange={(e) => handleChangeCampo(grupo.persona, 'coviddosusunica', e.target.value)}
                                          />
                                        </div>
                                      </div>
                                      <div class="col-12 col-md-6 col-lg-4">
                                        <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                          <label class="form-label">SEGUNDA DODIS</label>
                                          <input
                                            type="date"
                                            name='covidsegundadodis'
                                            className={`form-control`}
                                            value={grupo.covidsegundadodis}
                                            onChange={(e) => handleChangeCampo(grupo.persona, 'covidsegundadodis', e.target.value)}
                                          />
                                        </div>
                                      </div>
                                      <div class="col-12 col-md-6 col-lg-4">
                                        <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                          <label class="form-label">PRIMER REFUERZO</label>
                                          <input
                                            type="date"
                                            name='covidprimerrefuerzo'
                                            className={`form-control`}
                                            value={grupo.covidprimerrefuerzo}
                                            onChange={(e) => handleChangeCampo(grupo.persona, 'covidprimerrefuerzo', e.target.value)}
                                          />
                                        </div>
                                      </div>
                                      <div class="col-12 col-md-6 col-lg-4">
                                        <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                          <label class="form-label">SEGUNDO REFUERZO</label>
                                          <input
                                            type="date"
                                            name='covidsegundorefuerzo'
                                            className={`form-control`}
                                            value={grupo.covidsegundorefuerzo}
                                            onChange={(e) => handleChangeCampo(grupo.persona, 'covidsegundorefuerzo', e.target.value)}
                                          />
                                        </div>
                                      </div>
                                      <div class="col-12 col-md-6 col-lg-4">
                                        <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                          <label class="form-label">ESQUEMA COMPLETO (1.SI - 2.NO)</label>
                                          <select className={`form-select`} name='covidesquemacompleto' value={grupo.covidesquemacompleto} onChange={(e) => handleChangeCampo(grupo.persona, 'covidesquemacompleto', e.target.value)}>
                                            <option selected></option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                          </select>
                                        </div>
                                      </div>
                                      <div class="col-12 col-md-6 col-lg-4">
                                        <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                          <label class="form-label">1.VACUNADO / 2.CANALIZADO</label>
                                          <select className={`form-select`} name='covidvacunadocanalizado' value={grupo.covidvacunadocanalizado} onChange={(e) => handleChangeCampo(grupo.persona, 'covidvacunadocanalizado', e.target.value)}>
                                            <option selected></option>
                                            <option value="VACUNADO">VACUNADO</option>
                                            <option value="CANALIZADO">CANALIZADO</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div> 
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                      </div>
                      <div class="mb-5">
                        <h5 class="text">Mujeres entre 9 a 17 años (en persona de 18 años con la primera dosis se le garantiza la segunda dosis de VPH)</h5>
                        <div class="accordion" id="accordionMujeresNueve">
                            <div class="accordion-item border border-primary">
                              <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapsemujeresnueve-1`} aria-expanded="true" aria-controls={`collapsemujeresnueve-1`}>
                                  Desplegar para diligenciar datos
                                </button>
                              </h2>
                              <div id={`collapsemujeresnueve-1`} class="accordion-collapse collapse" data-bs-parent="#accordionMujeresNueve">
                                <div class="accordion-body">
                                  {/* Agregar aquí los campos específicos de este grupo */}
                                  <div class="mb-4"><h5 class="text-center">Por favor al diligenciar registre el año de aplicación de las dosis, de acuerdo a los biológicos trazadores que aquí se indican. </h5>
                                    <div class="row">
                                      <div class="col-12 col-md-6 col-lg-4">
                                        <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                          <label class="form-label">EPS</label>
                                          <select className={`form-select`} name='mujereseps' value={grupo.mujereseps} onChange={(e) => handleChangeCampo(grupo.persona, 'mujereseps', e.target.value)}>
                                            <option selected></option>
                                            <option value="Asmet Salud">Asmet Salud</option>
                                            <option value="Nueva EPS">Nueva EPS</option>
                                            <option value="Sanitas EPS">Sanitas EPS</option>
                                            <option value="FAMAC">FAMAC</option>
                                            <option value="Sanidad Ejercito">Sanidad Ejercito</option>
                                            <option value="Sanidad Policia.">Sanidad Policia.</option>
                                            <option value="Otro">Otro</option>
                                          </select>
                                          {/* {!grupo.eps && <div className="invalid-feedback">Campo obligatorio</div>} */}
                                        </div>
                                      </div>
                                      <div class="col-12 col-md-6 col-lg-4">
                                        <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                          <label class="form-label">1RA DOSIS</label>
                                          <input
                                            type="date"
                                            name='mujeresprimeraradosis'
                                            className={`form-control`}
                                            value={grupo.mujeresprimeraradosis}
                                            onChange={(e) => handleChangeCampo(grupo.persona, 'mujeresprimeraradosis', e.target.value)}
                                          />
                                        </div>
                                      </div>
                                      <div class="col-12 col-md-6 col-lg-4">
                                        <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                          <label class="form-label">2DA DOSIS</label>
                                          <input
                                            type="date"
                                            name='mujeressegundadadosis'
                                            className={`form-control`}
                                            value={grupo.mujeressegundadadosis}
                                            onChange={(e) => handleChangeCampo(grupo.persona, 'mujeressegundadadosis', e.target.value)}
                                          />
                                        </div>
                                      </div>
                                      <div class="col-12 col-md-6 col-lg-4">
                                        <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                          <label class="form-label">ESQUEMA COMPLETO (1.SI - 2.NO)</label>
                                          <select className={`form-select`} name='mujeresesquemacompleto' value={grupo.mujeresesquemacompleto} onChange={(e) => handleChangeCampo(grupo.persona, 'mujeresesquemacompleto', e.target.value)}>
                                            <option selected></option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                          </select>
                                        </div>
                                      </div>
                                      <div class="col-12 col-md-6 col-lg-4">
                                        <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                          <label class="form-label">1.VACUNADO / 2.CANALIZADO</label>
                                          <select className={`form-select`} name='mujeresvacunadocanalizado' value={grupo.mujeresvacunadocanalizado} onChange={(e) => handleChangeCampo(grupo.persona, 'mujeresvacunadocanalizado', e.target.value)}>
                                            <option selected></option>
                                            <option value="VACUNADO">VACUNADO</option>
                                            <option value="CANALIZADO">CANALIZADO</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div> 
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                      </div>
                      <div class="mb-5">
                        <h5 class="text">Mujeres gestantes</h5>
                        <div class="accordion" id="accordionGestantes">
                            <div class="accordion-item border border-primary">
                              <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapsegestantes-1`} aria-expanded="true" aria-controls={`collapsegestantes-1`}>
                                  Desplegar para diligenciar datos
                                </button>
                              </h2>
                              <div id={`collapsegestantes-1`} class="accordion-collapse collapse" data-bs-parent="#accordionGestantes">
                                <div class="accordion-body">
                                  {/* Agregar aquí los campos específicos de este grupo */}
                                  <div class="mb-4"><h5 class="text-center">Por favor al diligenciar registre el año de aplicación de las dosis, de acuerdo a los biológicos trazadores que aquí se indican.</h5>
                                    <div class="row">
                                      <div class="col-12 col-md-6 col-lg-4">
                                        <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                          <label class="form-label">SEMANAS DE GESTACIÓN</label>
                                          <input
                                            type="number"
                                            name='gestsemanasdegestacion'
                                            className={`form-control`}
                                            value={grupo.gestsemanasdegestacion}
                                            onChange={(e) => handleChangeCampo(grupo.persona, 'gestsemanasdegestacion', e.target.value)}
                                          />
                                        </div>
                                      </div>
                                      <div class="col-12 col-md-6 col-lg-4">
                                        <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                          <label class="form-label">CONTROL PRENATAL</label>
                                          <input
                                            type="date"
                                            name='gestcontrolprenatal'
                                            className={`form-control`}
                                            value={grupo.gestcontrolprenatal}
                                            onChange={(e) => handleChangeCampo(grupo.persona, 'gestcontrolprenatal', e.target.value)}
                                          />
                                        </div>
                                      </div>
                                      <div class="col-12 col-md-6 col-lg-4">
                                        <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                          <label class="form-label">Tdap Celular (sem 20)rural</label>
                                          <input
                                            type="date"
                                            name='gesttdapcelularsemveinte'
                                            className={`form-control`}
                                            value={grupo.gesttdapcelularsemveinte}
                                            onChange={(e) => handleChangeCampo(grupo.persona, 'gesttdapcelularsemveinte', e.target.value)}
                                          />
                                        </div>
                                      </div>
                                      <div class="col-12 col-md-6 col-lg-4">
                                        <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                          <label class="form-label">TOXOIDE TETÁNICO ( sem 14)</label>
                                          <input
                                            type="date"
                                            name='gesttoxoidetetanicosem'
                                            className={`form-control`}
                                            value={grupo.gesttoxoidetetanicosem}
                                            onChange={(e) => handleChangeCampo(grupo.persona, 'gesttoxoidetetanicosem', e.target.value)}
                                          />
                                        </div>
                                      </div>
                                      <div class="col-12 col-md-6 col-lg-4">
                                        <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                          <label class="form-label">INFLUENZA (sem 12)</label>
                                          <input
                                            type="date"
                                            name='gestinfluenzasemdoce'
                                            className={`form-control`}
                                            value={grupo.gestinfluenzasemdoce}
                                            onChange={(e) => handleChangeCampo(grupo.persona, 'gestinfluenzasemdoce', e.target.value)}
                                          />
                                        </div>
                                      </div>
                                      <div class="col-12 col-md-6 col-lg-4">
                                        <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                          <label class="form-label">COVID (sem12)</label>
                                          <input
                                            type="date"
                                            name='gestcovidsemdoce'
                                            className={`form-control`}
                                            value={grupo.gestcovidsemdoce}
                                            onChange={(e) => handleChangeCampo(grupo.persona, 'gestcovidsemdoce', e.target.value)}
                                          />
                                        </div>
                                      </div>
                                      <div class="col-12 col-md-6 col-lg-4">
                                        <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                          <label class="form-label">ESQUEMA COMPLETO (1.SI - 2.NO)</label>
                                          <select className={`form-select`} name='gestesquemacompleto' value={grupo.gestesquemacompleto} onChange={(e) => handleChangeCampo(grupo.persona, 'gestesquemacompleto', e.target.value)}>
                                            <option selected></option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                          </select>
                                        </div>
                                      </div>
                                      <div class="col-12 col-md-6 col-lg-4">
                                        <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                          <label class="form-label">1.VACUNADO / 2.CANALIZADO</label>
                                          <select className={`form-select`} name='gestvacunadocanalizado' value={grupo.gestvacunadocanalizado} onChange={(e) => handleChangeCampo(grupo.persona, 'gestvacunadocanalizado', e.target.value)}>
                                            <option selected></option>
                                            <option value="VACUNADO">VACUNADO</option>
                                            <option value="CANALIZADO">CANALIZADO</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div> 
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                      </div>
                      <div class="mb-5">
                        <h5 class="text">Adultos persona y pacientes crónicos</h5>
                        <div class="accordion" id="accordionMayores">
                            <div class="accordion-item border border-primary">
                              <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapsemayores-1`} aria-expanded="true" aria-controls={`collapsemayores-1`}>
                                  Desplegar para diligenciar datos
                                </button>
                              </h2>
                              <div id={`collapsemayores-1`} class="accordion-collapse collapse" data-bs-parent="#accordionMayores">
                                <div class="accordion-body">
                                  {/* Agregar aquí los campos específicos de este grupo */}
                                  <div class="mb-4"><h5 class="text-center">Por favor al diligenciar registre el año de aplicación de las dosis, de acuerdo a los biológicos trazadores que aquí se indican.</h5>
                                    <div class="row">
                                      <div class="col-12 col-md-6 col-lg-4">
                                        <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                          <label class="form-label">DOSIS ANUAL DE INFLUENZA</label>
                                          <input
                                            type="date"
                                            name='mayordosisanualdeinfluenza'
                                            className={`form-control`}
                                            value={grupo.mayordosisanualdeinfluenza}
                                            onChange={(e) => handleChangeCampo(grupo.persona, 'mayordosisanualdeinfluenza', e.target.value)}
                                          />
                                        </div>
                                      </div>
                                      <div class="col-12 col-md-6 col-lg-4">
                                        <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                          <label class="form-label">DOSIS COVID</label>
                                          <input
                                            type="date"
                                            name='mayordosiscovid'
                                            className={`form-control`}
                                            value={grupo.mayordosiscovid}
                                            onChange={(e) => handleChangeCampo(grupo.persona, 'mayordosiscovid', e.target.value)}
                                          />
                                        </div>
                                      </div>
                                      <div class="col-12 col-md-6 col-lg-4">
                                        <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                          <label class="form-label">ENFERMEDAD CRÓNICA</label>
                                          <input
                                            type="text"
                                            name='mayorenfermedadcronica'
                                            className={`form-control`}
                                            value={grupo.mayorenfermedadcronica}
                                            onChange={(e) => handleChangeCampo(grupo.persona, 'mayorenfermedadcronica', e.target.value)}
                                          />
                                        </div>
                                      </div>
                                      <div class="col-12 col-md-6 col-lg-4">
                                        <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                          <label class="form-label">CONTROL ENFERMEDAD</label>
                                          <input
                                            type="text"
                                            name='mayorcontrolenfermedad'
                                            className={`form-control`}
                                            value={grupo.mayorcontrolenfermedad}
                                            onChange={(e) => handleChangeCampo(grupo.persona, 'mayorcontrolenfermedad', e.target.value)}
                                          />
                                        </div>
                                      </div>
                                      <div class="col-12 col-md-6 col-lg-4">
                                        <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                          <label class="form-label">ESQUEMA COMPLETO (1.SI - 2.NO)</label>
                                          <select className={`form-select`} name='mayoresquemacompleto' value={grupo.mayoresquemacompleto} onChange={(e) => handleChangeCampo(grupo.persona, 'mayoresquemacompleto', e.target.value)}>
                                            <option selected></option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                          </select>
                                        </div>
                                      </div>
                                      <div class="col-12 col-md-6 col-lg-4">
                                        <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                          <label class="form-label">1.VACUNADO / 2.CANALIZADO</label>
                                          <select className={`form-select`} name='mayorvacunadocanalizado' value={grupo.mayorvacunadocanalizado} onChange={(e) => handleChangeCampo(grupo.persona, 'mayorvacunadocanalizado', e.target.value)}>
                                            <option selected></option>
                                            <option value="VACUNADO">VACUNADO</option>
                                            <option value="CANALIZADO">CANALIZADO</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div> 
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                      </div>
                      <div class="mb-5">
                        <h5 class="text">Fiebre amarilla persona DE 1 a 59 años</h5>
                        <div class="accordion" id="accordionAmarilla">
                            <div class="accordion-item border border-primary">
                              <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapseamarilla-1`} aria-expanded="true" aria-controls={`collapseamarilla-1`}>
                                  Desplegar para diligenciar datos
                                </button>
                              </h2>
                              <div id={`collapseamarilla-1`} class="accordion-collapse collapse" data-bs-parent="#accordionAmarilla">
                                <div class="accordion-body">
                                  {/* Agregar aquí los campos específicos de este grupo */}
                                  <div class="mb-4"><h5 class="text-center">Por favor al diligenciar registre el año de aplicación de las dosis, de acuerdo a los biológicos trazadores que aquí se indican.</h5>
                                    <div class="row">
                                      <div class="col-12 col-md-6 col-lg-4">
                                        <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                          <label class="form-label">DOSIS FA</label>
                                          <input
                                            type="date"
                                            name='amaridosisfa'
                                            className={`form-control`}
                                            value={grupo.amaridosisfa}
                                            onChange={(e) => handleChangeCampo(grupo.persona, 'amaridosisfa', e.target.value)}
                                          />
                                        </div>
                                      </div>
                                      <div class="col-12 col-md-6 col-lg-4">
                                        <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                          <label class="form-label">ESQUEMA COMPLETO (1.SI - 2.NO)</label>
                                          <select className={`form-select`} name='amariesquemacompleto' value={grupo.amariesquemacompleto} onChange={(e) => handleChangeCampo(grupo.persona, 'amariesquemacompleto', e.target.value)}>
                                            <option selected></option>
                                            <option value="SI">SI</option>
                                            <option value="NO">NO</option>
                                          </select>
                                        </div>
                                      </div>
                                      <div class="col-12 col-md-6 col-lg-4">
                                        <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                          <label class="form-label">1.VACUNADO / 2.CANALIZADO</label>
                                          <select className={`form-select`} name='amarivacunadocanalizado' value={grupo.amarivacunadocanalizado} onChange={(e) => handleChangeCampo(grupo.persona, 'amarivacunadocanalizado', e.target.value)}>
                                            <option selected></option>
                                            <option value="VACUNADO">VACUNADO</option>
                                            <option value="CANALIZADO">CANALIZADO</option>
                                          </select>
                                        </div>                                        
                                      </div>
                                    </div> 
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                      </div>
                    </div>
                    {/* Botón para eliminar este grupo de campos */}
                    <button type="button" class="btn btn-danger" onClick={() => {
                      eliminarGrupoPersonas(grupo.persona);
                    }}>
                      Eliminar miembro familia
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div class="m-2 mt-4 border border-primary-subtle"><h4 class="text">Observaciones Coordinador Equipo Inicial de Atención</h4>
          <div class="row">
            <div class="col-12 col-md-6">  
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">Analisis de la caracterización familiar:</label>
                <input
                  type="textarea"
                  name='analisisdelacaracterizaci'
                  className={`form-control`}
                  value={fichaFamiliar.analisisdelacaracterizaci}
                  onChange={handleChange}
                />
                {/* {!fichaFamiliar.analisisdelacaracterizaci && <div className="invalid-feedback">Campo obligatorio</div>} */}
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">Principales factores de riesgo identificado en el hogar caracterizado.</label>
                <input
                  type="textarea"
                  name='principalesfactoresderies'
                  className={`form-control`}
                  value={fichaFamiliar.principalesfactoresderies}
                  onChange={handleChange}
                />
                {/* {!fichaFamiliar.principalesfactoresderies && <div className="invalid-feedback">Campo obligatorio</div>} */}
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">Integrante(s) de la familia que requieren intervenciones especificas</label>
                <input
                  type="textarea"
                  name='integrantesdelafamiliaque'
                  className={`form-control`}
                  value={fichaFamiliar.integrantesdelafamiliaque}
                  onChange={handleChange}
                />
                {/* {!fichaFamiliar.integrantesdelafamiliaque && <div className="invalid-feedback">Campo obligatorio</div>} */}
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">¿Cuáles son los factores de riesgo identificado?</label>
                <input
                  type="textarea"
                  name='cualessonlosfactoresderie'
                  className={`form-control`}
                  value={fichaFamiliar.cualessonlosfactoresderie}
                  onChange={handleChange}
                />
                {/* {!fichaFamiliar.cualessonlosfactoresderie && <div className="invalid-feedback">Campo obligatorio</div>} */}
              </div>
            </div>
            <div class="col-12">
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">Resultados por Componentes:</label>
                <div class="row">
                  <div class="col-md-3">
                    <label class="form-label">Condiciones de habitad  familiar y social</label>
                    <input
                      type="number"
                      name='resultadosporcompocoh'
                      placeholder='Condiciones de habitad  familiar y social'
                      className={`form-control`}
                      value={fichaFamiliar.resultadosporcompocoh}
                      onChange={handleChange}
                    />
                  </div>
                  <div class="col-md-3">
                    <label class="form-label">Saniamiento Basico</label>
                    <input
                      type="number"
                      name='resultadosporcomposan'
                      placeholder='Saniamiento Basico'
                      className={`form-control`}
                      value={fichaFamiliar.resultadosporcomposan}
                      onChange={handleChange}
                    />
                  </div>
                  <div class="col-md-3">
                    <label class="form-label">Aire Intradocimiciliario</label>
                    <input
                      type="number"
                      name='resultadosporcompoair'
                      placeholder='Aire Intradocimiciliario'
                      className={`form-control`}
                      value={fichaFamiliar.resultadosporcompoair}
                      onChange={handleChange}
                    />
                  </div>
                  <div class="col-md-3">
                    <label class="form-label">Zoonosis</label>
                    <input
                      type="number"
                      name='resultadosporcompozoo'
                      placeholder='Zoonosis'
                      className={`form-control`}
                      value={fichaFamiliar.resultadosporcompozoo}
                      onChange={handleChange}
                    />
                  </div>
                  <div class="col-md-3">
                    <label class="form-label">Control de Vectores</label>
                    <input
                      type="number"
                      name='resultadosporcompocon'
                      placeholder='Control de Vectores'
                      className={`form-control`}
                      value={fichaFamiliar.resultadosporcompocon}
                      onChange={handleChange}
                    />
                  </div>
                  <div class="col-md-3">
                    <label class="form-label">Manejo de sustancias Quimicas  y Eliminación de residuales</label>
                    <input
                      type="number"
                      name='resultadosporcompoman'
                      placeholder='Manejo de sustancias Quimicas  y Eliminación de residuales'
                      className={`form-control`}
                      value={fichaFamiliar.resultadosporcompoman}
                      onChange={handleChange}
                    />
                  </div>
                  <div class="col-md-3">
                    <label class="form-label">Valoración Individual</label>
                    <input
                      type="number"
                      name='resultadosporcompoval'
                      placeholder='Valoración Individual'
                      className={`form-control`}
                      value={fichaFamiliar.resultadosporcompoval}
                      onChange={handleChange}
                    />
                  </div>
                  <div class="col-md-3">
                    <label class="form-label">Antecedentes Medicos</label>
                    <input
                      type="number"
                      name='resultadosporcompoant'
                      placeholder='Antecedentes Medicos'
                      className={`form-control`}
                      value={fichaFamiliar.resultadosporcompoant}
                      onChange={handleChange}
                    />
                  </div>
                  <div class="col-md-3">
                    <label class="form-label">Sexualidad, Derechos Sexuales y Reproductivos</label>
                    <input
                      type="number"
                      name='resultadosporcomposex'
                      placeholder='Sexualidad, Derechos Sexuales y Reproductivos'
                      className={`form-control`}
                      value={fichaFamiliar.resultadosporcomposex}
                      onChange={handleChange}
                    />
                  </div>
                  <div class="col-md-3">
                    <label class="form-label">Recien Nacido</label>
                    <input
                      type="number"
                      name='resultadosporcomporec'
                      placeholder='Recien Nacido'
                      className={`form-control`}
                      value={fichaFamiliar.resultadosporcomporec}
                      onChange={handleChange}
                    />
                  </div>
                  <div class="col-md-3">
                    <label class="form-label">Vida saludable y condiciones No Transmisibles</label>
                    <input
                      type="number"
                      name='resultadosporcompovid'
                      placeholder='Vida saludable y condiciones No Transmisibles'
                      className={`form-control`}
                      value={fichaFamiliar.resultadosporcompovid}
                      onChange={handleChange}
                    />
                  </div>
                  <div class="col-md-3">
                    <label class="form-label">Salud Mental</label>
                    <input
                      type="number"
                      name='resultadosporcomposal'
                      placeholder='Salud Mental'
                      className={`form-control`}
                      value={fichaFamiliar.resultadosporcomposal}
                      onChange={handleChange}
                    />
                  </div>
                  <div class="col-md-3">
                    <label class="form-label">Programa Ampliado de Inmunizaciones</label>
                    <input
                      type="number"
                      name='resultadosporcompopro'
                      placeholder='Programa Ampliado de Inmunizaciones'
                      className={`form-control`}
                      value={fichaFamiliar.resultadosporcompopro}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {/* {!fichaFamiliar.numeroficha && <div className="invalid-feedback">Campo obligatorio</div>} */}
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">Clasificación del Riesgo - Alto</label>
                <input
                  type="text"
                  name='clasificaciondelriesgoalt'
                  className={`form-control`}
                  value={fichaFamiliar.clasificaciondelriesgoalt}
                  onChange={handleChange}
                />
                {/* {!fichaFamiliar.clasificaciondelriesgoalt && <div className="invalid-feedback">Campo obligatorio</div>} */}
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">Clasificación del Riesgo - Medio</label>
                <input
                  type="text"
                  name='clasificaciondelriesgomed'
                  className={`form-control`}
                  value={fichaFamiliar.clasificaciondelriesgomed}
                  onChange={handleChange}
                />
                {/* {!fichaFamiliar.clasificaciondelriesgomed && <div className="invalid-feedback">Campo obligatorio</div>} */}
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">Clasificación del Riesgo - Bajo</label>
                <input
                  type="text"
                  name='clasificaciondelriesgobaj'
                  className={`form-control`}
                  value={fichaFamiliar.clasificaciondelriesgobaj}
                  onChange={handleChange}
                />
                {/* {!fichaFamiliar.clasificaciondelriesgobaj && <div className="invalid-feedback">Campo obligatorio</div>} */}
              </div> 
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">¿Requiere priorizar valoración del riesgo antes de generar plan de cuidado?</label>
                <select className={`form-select`} name='requierepriorizarvaloraci' value={fichaFamiliar.requierepriorizarvaloraci} onChange={handleChange}>
                  <option selected></option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </select>
                {/* {!fichaFamiliar.requierepriorizarvaloraci && <div className="invalid-feedback">Campo obligatorio</div>} */}
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">Justifique la respuesta anterior</label>
                <input
                  type="text"
                  name='justifiquelarespuestaante'
                  className={`form-control`}
                  value={fichaFamiliar.justifiquelarespuestaante}
                  onChange={handleChange}
                />
                {/* {!fichaFamiliar.justifiquelarespuestaante && <div className="invalid-feedback">Campo obligatorio</div>} */}
              </div> 
            </div>
          </div> 
        </div>
        <div class="m-2 mt-4 border border-primary-subtle"><h4 class="text">Datos del encuestador</h4>
          <div class="row">
            <div class="col-12 col-md-6 col-lg-4">   
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">Nombre y Apellido del Responsable del diligenciamiento</label>
                <input
                  type="text"
                  name='nombreyapellidodelrespons'
                  className={`form-control ${!fichaFamiliar.nombreyapellidodelrespons ? 'is-invalid' : ''}`}
                  value={fichaFamiliar.nombreyapellidodelrespons}
                  onChange={handleChange}
                />
                {!fichaFamiliar.nombreyapellidodelrespons && <div className="invalid-feedback">Campo obligatorio</div>}
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">Rol en el Equipo Inicial de Atención</label>
                <input
                  type="text"
                  name='rolenelequipoinicialdeate'
                  className={`form-control ${!fichaFamiliar.rolenelequipoinicialdeate ? 'is-invalid' : ''}`}
                  value={fichaFamiliar.rolenelequipoinicialdeate}
                  onChange={handleChange}
                />
                {!fichaFamiliar.rolenelequipoinicialdeate && <div className="invalid-feedback">Campo obligatorio</div>}
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
              <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                <label class="form-label">Teléfono/Celular</label>
                <input
                  type="number"
                  name='telefonocelularresdil'
                  className={`form-control ${!fichaFamiliar.telefonocelularresdil ? 'is-invalid' : ''}`}
                  value={fichaFamiliar.telefonocelularresdil}
                  onChange={handleChange}
                />
                {!fichaFamiliar.telefonocelularresdil && <div className="invalid-feedback">Campo obligatorio</div>}
              </div>
            </div>
          </div> 
        </div>
        <button type="submit" className="btn btn-primary">Guardar Ficha</button>
      </form>
      
    </div>
  );
};

export default Caracterizacion;