import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyBeoyQt3IBjoFAuHN5J85DdTM4NcoVlfZ0",
    authDomain: "fireaps-b3007.firebaseapp.com",
    projectId: "fireaps-b3007",
    storageBucket: "fireaps-b3007.appspot.com",
    messagingSenderId: "783078639978",
    appId: "1:783078639978:web:16200d457f00e19b79bd13"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { app, auth, signInWithEmailAndPassword };
