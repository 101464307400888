import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

function EditarPersona() {
  const navigate = useNavigate();
  const { _id, ndedocumentodeidentidad } = useParams();
  const [persona, setPersona] = useState(null); // Inicializado como nulo para manejar la carga inicial
  const [edicion, setEdicion] = useState(false);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/persona?ndedocumentodeidentidad=${ndedocumentodeidentidad}`)
      .then((response) => {
        const resultado = response.data[0];

        // Verificamos si hay algún resultado y si tiene el campo 'grupospersonas'
        if (resultado && resultado.grupospersonas) {
          // Establecemos la persona con el primer elemento de 'grupospersonas'
          setPersona(resultado.grupospersonas[0]);
        } else {
          console.warn('No se encontró la persona o no tiene el campo "grupospersonas".');
        }
      })
      .catch((error) => {
        console.error('Error al cargar persona:', error);
      });
    }, [ndedocumentodeidentidad]);
    
    const handleGuardar = () => {
      axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/persona/${ndedocumentodeidentidad}`, {
        grupospersonas: [persona],  // Enviamos la información del miembro de la familia como un array
      })
        .then((response) => {
          setPersona(response.data.grupospersonas[0]);  // Actualizamos la persona con la respuesta del servidor
          setEdicion(false);
          alert('Datos editados');
          navigate('/fichas');
        })
        .catch((error) => {
          console.error('Error al guardar persona:', error);
        });
    };
    
    const handleEliminar = () => {
      if (window.confirm('¿Estás seguro de que quieres eliminar esta persona?')) {
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/persona/${_id}/${ndedocumentodeidentidad}`)
          .then(() => {
            alert('Persona eliminada');
            navigate('/fichas');
          })
          .catch((error) => {
            console.error('Error al eliminar persona:', error);
          });
      }
    };

    console.log('_id:', _id);    
    console.log('ndedocumentodeidentidad:', ndedocumentodeidentidad);
    
    const handleInputChange = (event) => {
      const { name, value, type, checked } = event.target;
    
      setPersona((prevCampos) => {
        if (type === 'checkbox') {
          const newArray = checked
            ? [...prevCampos[name], value]
            : prevCampos[name].filter((item) => item !== value);
    
          return {
            ...prevCampos,
            [name]: newArray,
          };
        }
    
        return {
          ...prevCampos,
          [name]: value,
        };
      });
    }; 

  return (
    <div class="container">
      {persona ? (
        edicion ? (
          <div>
            <h5>Editar Persona: {persona.ndedocumentodeidentidad} - {persona.nombresyapellidos} - {persona.fechadenacimiento ? persona.fechadenacimiento.split('T')[0] : ''}</h5>
            <form>
              <div class="mb-4"><h4 class="text-center"> 3.	DATOS BASICOS</h4>
                <div class="row">
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Nombres y Apellidos:</label>
                      <input
                        type="text"
                        name='nombresyapellidos'
                        className={`form-control`}
                        value={persona.nombresyapellidos}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Tipo de Identidad</label>
                      <select className={`form-select`} name='tipodeidentidad' value={persona.tipodeidentidad} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="RC">RC</option>
                        <option value="TI">TI</option>
                        <option value="CC">CC</option>
                        <option value="ASI">ASI</option>
                        <option value="MSI">MSI</option>
                        <option value="CE">CE</option>
                      </select>
                      {/* {!persona.tipodeidentidad && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">N° de documento de Identidad:</label>
                      <input
                        type="number"
                        name='ndedocumentodeidentidad'
                        className={`form-control`}
                        value={persona.ndedocumentodeidentidad}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Fecha de Nacimiento</label>
                      <input
                        type="date"
                        name='fechadenacimiento'
                        className={`form-control`}
                        value={persona.fechadenacimiento ? persona.fechadenacimiento.split('T')[0] : ''}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Sexo</label>
                      <select className={`form-select`} name='sexo' value={persona.sexo} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="F">F</option>
                        <option value="M">M</option>
                      </select>
                      {/* {!persona.sexo && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Etnia</label>
                      <select className={`form-select`} name='etnia' value={persona.etnia} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Afrocolombiano">Afrocolombiano</option>
                        <option value="mulato, negro">mulato, negro</option>
                        <option value="Palenquero">Palenquero</option>
                        <option value="Indígena, pueblo">Indígena, pueblo</option>
                        <option value="Raizal">Raizal</option>
                        <option value="ROM">ROM</option>
                        <option value="Ninguno">Ninguno</option>
                      </select>
                      {/* {!persona.etnia && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Condición especial (SM)</label>
                      <select className={`form-select`} name='condicionespecialsm' value={persona.condicionespecialsm} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Victima">Victima</option>
                        <option value="Habitante de calle">Habitante de calle</option>
                        <option value="LGTBI">LGTBI</option>
                        <option value="No aplica ">No aplica </option>
                      </select>
                      {/* {!persona.condicionespecialsm && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Discapacidad (S/M)</label>
                      <select className={`form-select`} name='discapacidadsm' value={persona.discapacidadsm} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Cognitiva">Cognitiva</option>
                        <option value="Motora">Motora</option>
                        <option value="Sensorial">Sensorial</option>
                        <option value="Ninguna ">Ninguna </option>
                      </select>
                      {/* {!persona.discapacidadsm && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Estado civil</label>
                      <select className={`form-select`} name='estadocivil' value={persona.estadocivil} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Unión libre">Unión libre</option>
                        <option value="hijastro(a)">Casado(a)</option>
                        <option value="Viudo(a)">Viudo(a)</option>
                        <option value="Separado(a)">Separado(a)</option>
                        <option value="Soltero(a)">Soltero(a)</option>
                        <option value="Compañero permanente">Compañero permanente</option>
                        <option value="No aplica">No aplica</option>
                      </select>
                      {/* {!persona.estadocivil && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Parentesco con el jefe de hogar</label>
                      <select className={`form-select`} name='parentescoconeljefedehoga' value={persona.parentescoconeljefedehoga} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Hijo(a)">Hijo(a)</option>
                        <option value="hijastro(a)">hijastro(a)</option>
                        <option value="Nieto(a)">Nieto(a)</option>
                        <option value="padre o madre">padre o madre</option>
                        <option value="Hermano(a)">Hermano(a)</option>
                        <option value="Yerno –Nuera">Yerno –Nuera</option>
                        <option value="Abuelos">Abuelos</option>
                        <option value="Suegros">Suegros</option>
                        <option value="No parientes ">No parientes </option>
                      </select>
                      {/* {!persona.parentescoconeljefedehoga && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Reg. Salud</label>
                      <select className={`form-select`} name='regsalud' value={persona.regsalud} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="S">S</option>
                        <option value="C">C</option>
                        <option value="E">E</option>
                        <option value="PNA">PNA</option>
                      </select>
                      {/* {!persona.regsalud && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Nombre de la EPS</label>
                      <select className={`form-select`} name='nombredelaeps' value={persona.nombredelaeps} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Asmet Salud">Asmet Salud</option>
                        <option value="Nueva EPS">Nueva EPS</option>
                        <option value="Sanitas EPS">Sanitas EPS</option>
                        <option value="FAMAC">FAMAC</option>
                        <option value="Sanidad Ejercito">Sanidad Ejercito</option>
                        <option value="Sanidad Policia.">Sanidad Policia.</option>
                        <option value="Otro">Otro</option>
                      </select>
                      {/* {!persona.nombredelaeps && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Ocupación principal</label>
                      <select className={`form-select`} name='ocupacionprincipal' value={persona.ocupacionprincipal} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Desempleado (desde los 12 a)">Desempleado (desde los 12 a)</option>
                        <option value="Trabajador (desde los 12 a)">Trabajador (desde los 12 a)</option>
                        <option value="Ama de casa (desde los 12 a)">Ama de casa (desde los 12 a)</option>
                        <option value="Pensionado/jubilado (desde los 18 a)">Pensionado/jubilado (desde los 18 a)</option>
                        <option value="Estudiante (desde de los 7 a)">Estudiante (desde de los 7 a)</option>
                        <option value="Ninguna (Entre los 7 y 11 años)">Ninguna (Entre los 7 y 11 años)</option>
                      </select>
                      {/* {!persona.ocupacionprincipal && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Depende económicamente de un familiar</label>
                      <select className={`form-select`} name='dependeeconomicamentedeun' value={persona.dependeeconomicamentedeun} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Si">Si</option>
                        <option value="No">No</option>
                        <option value="SD">SD</option>
                      </select>
                      {/* {!persona.dependeeconomicamentedeun && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Escolaridad</label>
                      <select className={`form-select`} name='escolaridad' value={persona.escolaridad} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Ningún estudio">Ningún estudio</option>
                        <option value="Primaria">Primaria</option>
                        <option value="Secundaria">Secundaria</option>
                        <option value="Técnica">Técnica</option>
                        <option value="Tecnológico">Tecnológico</option>
                        <option value="Universitario">Universitario</option>
                        <option value="Postgrado">Postgrado</option>
                        <option value="Abandono los estudios de primaria o bachiller (De 7 a 21 años)">Abandono los estudios de primaria o bachiller (De 7 a 21 años)</option>
                      </select>
                      {/* {!persona.escolaridad && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-4"><h4 class="text-center"> 3.1.	ANTECEDENTES MÉDICOS</h4>
                <div class="row">
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <div>
                        <label class="form-label">Antecedente Personal (SM)</label>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="antecedentepersonalsm"
                              value="1. Abuso sexual en la infancia (a partir 14 a)"
                              checked={persona.antecedentepersonalsm.includes('1. Abuso sexual en la infancia (a partir 14 a)')}
                              onChange={handleInputChange}
                            />
                            1. Abuso sexual en la infancia (a partir 14 a)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="antecedentepersonalsm"
                              value="2. Cáncer"
                              checked={persona.antecedentepersonalsm.includes('2. Cáncer')}
                              onChange={handleInputChange}
                            />
                            2. Cáncer
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="antecedentepersonalsm"
                              value="3. Complicación de la madre durante la gestación, parto o puerperio (0 a 4 a)"
                              checked={persona.antecedentepersonalsm.includes('3. Complicación de la madre durante la gestación, parto o puerperio (0 a 4 a)')}
                              onChange={handleInputChange}
                            />
                            3. Complicación de la madre durante la gestación, parto o puerperio (0 a 4 a)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="antecedentepersonalsm"
                              value="4. Depresión – ansiedad (a partir 13 a)"
                              checked={persona.antecedentepersonalsm.includes('4. Depresión – ansiedad (a partir 13 a)')}
                              onChange={handleInputChange}
                            />
                            4. Depresión – ansiedad (a partir 13 a)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="antecedentepersonalsm"
                              value="5. Diabetes (a partir 6 a)"
                              checked={persona.antecedentepersonalsm.includes('5. Diabetes (a partir 6 a)')}
                              onChange={handleInputChange}
                            />
                            5. Diabetes (a partir 6 a)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="antecedentepersonalsm"
                              value="6. Diabetes Gestacional (a partir 12 a)"
                              checked={persona.antecedentepersonalsm.includes('6. Diabetes Gestacional (a partir 12 a)')}
                              onChange={handleInputChange}
                            />
                            6. Diabetes Gestacional (a partir 12 a)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="antecedentepersonalsm"
                              value="7. Dificultades escolares (a partir 15 a)"
                              checked={persona.antecedentepersonalsm.includes('7. Dificultades escolares (a partir 15 a)')}
                              onChange={handleInputChange}
                            />
                            7. Dificultades escolares (a partir 15 a)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="antecedentepersonalsm"
                              value="8. Enfermedad cardiovascular (a partir 31 a)"
                              checked={persona.antecedentepersonalsm.includes('8. Enfermedad cardiovascular (a partir 31 a)')}
                              onChange={handleInputChange}
                            />
                            8. Enfermedad cardiovascular (a partir 31 a)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="antecedentepersonalsm"
                              value="9. ERC"
                              checked={persona.antecedentepersonalsm.includes('9. ERC')}
                              onChange={handleInputChange}
                            />
                            9. ERC
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="antecedentepersonalsm"
                              value="10. EPOC (a partir 31 a)"
                              checked={persona.antecedentepersonalsm.includes('10. EPOC (a partir 31 a)')}
                              onChange={handleInputChange}
                            />
                            10. EPOC (a partir 31 a)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="antecedentepersonalsm"
                              value="11. Esquizofrenia"
                              checked={persona.antecedentepersonalsm.includes('11. Esquizofrenia')}
                              onChange={handleInputChange}
                            />
                            11. Esquizofrenia
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="antecedentepersonalsm"
                              value="12. Exposición a la violencia"
                              checked={persona.antecedentepersonalsm.includes('12. Exposición a la violencia')}
                              onChange={handleInputChange}
                            />
                            12. Exposición a la violencia
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="antecedentepersonalsm"
                              value="13. Helicobacter pylori (a partir 31 a)"
                              checked={persona.antecedentepersonalsm.includes('13. Helicobacter pylori (a partir 31 a)')}
                              onChange={handleInputChange}
                            />
                            13. Helicobacter pylori (a partir 31 a)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="antecedentepersonalsm"
                              value="14. HTA"
                              checked={persona.antecedentepersonalsm.includes('14. HTA')}
                              onChange={handleInputChange}
                            />
                            14. HTA
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="antecedentepersonalsm"
                              value="15. Intento de suicidio (a partir 11 a)"
                              checked={persona.antecedentepersonalsm.includes('15. Intento de suicidio (a partir 11 a)')}
                              onChange={handleInputChange}
                            />
                            15. Intento de suicidio (a partir 11 a)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="antecedentepersonalsm"
                              value="16. No lactancia materna (0 a 5 a)"
                              checked={persona.antecedentepersonalsm.includes('16. No lactancia materna (0 a 5 a)')}
                              onChange={handleInputChange}
                            />
                            16. No lactancia materna (0 a 5 a)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="antecedentepersonalsm"
                              value="17. No lactancia materna actual (6 a 23 m)"
                              checked={persona.antecedentepersonalsm.includes('17. No lactancia materna actual (6 a 23 m)')}
                              onChange={handleInputChange}
                            />
                            17. No lactancia materna actual (6 a 23 m)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="antecedentepersonalsm"
                              value="18. No lactancia materna exclusiva hasta los 6 meses"
                              checked={persona.antecedentepersonalsm.includes('18. No lactancia materna exclusiva hasta los 6 meses')}
                              onChange={handleInputChange}
                            />
                            18. No lactancia materna exclusiva hasta los 6 meses
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="antecedentepersonalsm"
                              value="19. Retraso desarrollo psicomotor en el primer año de vida (a partir 15 a)"
                              checked={persona.antecedentepersonalsm.includes('19. Retraso desarrollo psicomotor en el primer año de vida (a partir 15 a)')}
                              onChange={handleInputChange}
                            />
                            19. Retraso desarrollo psicomotor en el primer año de vida (a partir 15 a)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="antecedentepersonalsm"
                              value="20. TB"
                              checked={persona.antecedentepersonalsm.includes('20. TB')}
                              onChange={handleInputChange}
                            />
                            20. TB
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="antecedentepersonalsm"
                              value="21. Trastorno afectivo bipolar (a partir 9 a)"
                              checked={persona.antecedentepersonalsm.includes('21. Trastorno afectivo bipolar (a partir 9 a)')}
                              onChange={handleInputChange}
                            />
                            21. Trastorno afectivo bipolar (a partir 9 a)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="antecedentepersonalsm"
                              value="22. Trastornos mentales en la infancia y adolescencia (a partir 18 a)"
                              checked={persona.antecedentepersonalsm.includes('22. Trastornos mentales en la infancia y adolescencia (a partir 18 a)')}
                              onChange={handleInputChange}
                            />
                            22. Trastornos mentales en la infancia y adolescencia (a partir 18 a)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="antecedentepersonalsm"
                              value="23. VIH"
                              checked={persona.antecedentepersonalsm.includes('23. VIH')}
                              onChange={handleInputChange}
                            />
                            23. VIH
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="antecedentepersonalsm"
                              value="24. VPH (a partir 12 a)"
                              checked={persona.antecedentepersonalsm.includes('24. VPH (a partir 12 a)')}
                              onChange={handleInputChange}
                            />
                            24. VPH (a partir 12 a)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="antecedentepersonalsm"
                              value="25. Niveles de azúcar altos"
                              checked={persona.antecedentepersonalsm.includes('25. Niveles de azúcar altos')}
                              onChange={handleInputChange}
                            />
                            25. Niveles de azúcar altos
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="antecedentepersonalsm"
                              value="26. Ninguna"
                              checked={persona.antecedentepersonalsm.includes('26. Ninguna')}
                              onChange={handleInputChange}
                            />
                            26. Ninguna
                          </label>
                        </div>
                        {/* {!persona.antecedentepersonalsm.length && <div className="text-danger">Campo obligatorio</div>} */}
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <div>
                        <label class="form-label">Enfermedad actual (SM)</label>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="enfermedadactualsm"
                              value="1. Bajo peso al nacer (o a 11 m)"
                              checked={persona.enfermedadactualsm.includes('1. Bajo peso al nacer (o a 11 m)')}
                              onChange={handleInputChange}
                            />
                            1. Bajo peso al nacer (o a 11 m)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="enfermedadactualsm"
                              value="2. Cáncer de colon-recto (a partir 31 a)"
                              checked={persona.enfermedadactualsm.includes('2. Cáncer de colon-recto (a partir 31 a)')}
                              onChange={handleInputChange}
                            />
                            2. Cáncer de colon-recto (a partir 31 a)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="enfermedadactualsm"
                              value="3. Cáncer de cuello uterino-cérvix (a partir 21 a)"
                              checked={persona.enfermedadactualsm.includes('3. Cáncer de cuello uterino-cérvix (a partir 21 a)')}
                              onChange={handleInputChange}
                            />
                            3. Cáncer de cuello uterino-cérvix (a partir 21 a)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="enfermedadactualsm"
                              value="4. Cáncer de estómago (a partir 31 a)"
                              checked={persona.enfermedadactualsm.includes('4. Cáncer de estómago (a partir 31 a)')}
                              onChange={handleInputChange}
                            />
                            4. Cáncer de estómago (a partir 31 a)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="enfermedadactualsm"
                              value="5. Cáncer de mama (a partir 21 a)"
                              checked={persona.enfermedadactualsm.includes('5. Cáncer de mama (a partir 21 a)')}
                              onChange={handleInputChange}
                            />
                            5. Cáncer de mama (a partir 21 a)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="enfermedadactualsm"
                              value="6. Cáncer de próstata (a partir 40 a)"
                              checked={persona.enfermedadactualsm.includes('6. Cáncer de próstata (a partir 40 a)')}
                              onChange={handleInputChange}
                            />
                            6. Cáncer de próstata (a partir 40 a)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="enfermedadactualsm"
                              value="7. Cáncer de pulmón (a partir 50 a)"
                              checked={persona.enfermedadactualsm.includes('7. Cáncer de pulmón (a partir 50 a)')}
                              onChange={handleInputChange}
                            />
                            7. Cáncer de pulmón (a partir 50 a)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="enfermedadactualsm"
                              value="8. Caries (6m a 5 a)"
                              checked={persona.enfermedadactualsm.includes('8. Caries (6m a 5 a)')}
                              onChange={handleInputChange}
                            />
                            8. Caries (6m a 5 a)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="enfermedadactualsm"
                              value="9. Depresión – ansiedad (a partir 13 a)"
                              checked={persona.enfermedadactualsm.includes('9. Depresión – ansiedad (a partir 13 a)')}
                              onChange={handleInputChange}
                            />
                            9. Depresión – ansiedad (a partir 13 a)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="enfermedadactualsm"
                              value="10. Desnutrición"
                              checked={persona.enfermedadactualsm.includes('10. Desnutrición')}
                              onChange={handleInputChange}
                            />
                            10. Desnutrición
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="enfermedadactualsm"
                              value="11. Diabetes Gestacional (a partir 12 a)"
                              checked={persona.enfermedadactualsm.includes('11. Diabetes Gestacional (a partir 12 a)')}
                              onChange={handleInputChange}
                            />
                            11. Diabetes Gestacional (a partir 12 a)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="enfermedadactualsm"
                              value="12. Diarrea permanente"
                              checked={persona.enfermedadactualsm.includes('12. Diarrea permanente')}
                              onChange={handleInputChange}
                            />
                            12. Diarrea permanente
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="enfermedadactualsm"
                              value="13. Dislipidemia (a partir 31 a)"
                              checked={persona.enfermedadactualsm.includes('13. Dislipidemia (a partir 31 a)')}
                              onChange={handleInputChange}
                            />
                            13. Dislipidemia (a partir 31 a)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="enfermedadactualsm"
                              value="14. Enfermedad periodontal"
                              checked={persona.enfermedadactualsm.includes('14. Enfermedad periodontal')}
                              onChange={handleInputChange}
                            />
                            14. Enfermedad periodontal
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="enfermedadactualsm"
                              value="15. Helicobacter pylori (a partir 31 a)"
                              checked={persona.enfermedadactualsm.includes('15. Helicobacter pylori (a partir 31 a)')}
                              onChange={handleInputChange}
                            />
                            15. Helicobacter pylori (a partir 31 a)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="enfermedadactualsm"
                              value="16. Hipotiroidismo congénito"
                              checked={persona.enfermedadactualsm.includes('16. Hipotiroidismo congénito')}
                              onChange={handleInputChange}
                            />
                            16. Hipotiroidismo congénito
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="enfermedadactualsm"
                              value="17. ITS"
                              checked={persona.enfermedadactualsm.includes('17. ITS')}
                              onChange={handleInputChange}
                            />
                            17. ITS
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="enfermedadactualsm"
                              value="18. Leucemia y linfoma en niños (0 a 18 a)"
                              checked={persona.enfermedadactualsm.includes('18. Leucemia y linfoma en niños (0 a 18 a)')}
                              onChange={handleInputChange}
                            />
                            18. Leucemia y linfoma en niños (0 a 18 a)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="enfermedadactualsm"
                              value="19. Neumonía (0 a 4 años)"
                              checked={persona.enfermedadactualsm.includes('19. Neumonía (0 a 4 años)')}
                              onChange={handleInputChange}
                            />
                            19. Neumonía (0 a 4 años)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="enfermedadactualsm"
                              value="20. TB"
                              checked={persona.enfermedadactualsm.includes('20. TB')}
                              onChange={handleInputChange}
                            />
                            20. TB
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="enfermedadactualsm"
                              value="21. Trastornos mentales en la infancia y adolescencia (9 a 17 a)"
                              checked={persona.enfermedadactualsm.includes('21. Trastornos mentales en la infancia y adolescencia (9 a 17 a)')}
                              onChange={handleInputChange}
                            />
                            21. Trastornos mentales en la infancia y adolescencia (9 a 17 a)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="enfermedadactualsm"
                              value="22. VPH (a partir 12 a)"
                              checked={persona.enfermedadactualsm.includes('22. VPH (a partir 12 a)')}
                              onChange={handleInputChange}
                            />
                            22. VPH (a partir 12 a)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="enfermedadactualsm"
                              value="23. Infección urinaria recurrente (a partir 9 a)"
                              checked={persona.enfermedadactualsm.includes('23. Infección urinaria recurrente (a partir 9 a)')}
                              onChange={handleInputChange}
                            />
                            23. Infección urinaria recurrente (a partir 9 a)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="enfermedadactualsm"
                              value="24. Ninguna"
                              checked={persona.enfermedadactualsm.includes('24. Ninguna')}
                              onChange={handleInputChange}
                            />
                            24. Ninguna
                          </label>
                        </div>
                        {/* {!persona.enfermedadactualsm.length && <div className="text-danger">Campo obligatorio</div>} */}
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <div>
                        <label class="form-label">Antecedente Familiar primer y segundo grado (SM)</label>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="antecedentefamiliarprimer"
                              value="1.Cáncer de colon-recto (a partir de 19 a)"
                              checked={persona.antecedentefamiliarprimer.includes('1.Cáncer de colon-recto (a partir de 19 a)')}
                              onChange={handleInputChange}
                            />
                            1.Cáncer de colon-recto (a partir de 19 a)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="antecedentefamiliarprimer"
                              value="2. Cáncer de cuello uterino-cérvix (a partir 19 a)"
                              checked={persona.antecedentefamiliarprimer.includes('2. Cáncer de cuello uterino-cérvix (a partir 19 a)')}
                              onChange={handleInputChange}
                            />
                            2. Cáncer de cuello uterino-cérvix (a partir 19 a)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="antecedentefamiliarprimer"
                              value="3. Cáncer de estómago. (a partir 19 a)"
                              checked={persona.antecedentefamiliarprimer.includes('3. Cáncer de estómago. (a partir 19 a)')}
                              onChange={handleInputChange}
                            />
                            3. Cáncer de estómago. (a partir 19 a)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="antecedentefamiliarprimer"
                              value="4. Cáncer de mama (a partir 19 a)"
                              checked={persona.antecedentefamiliarprimer.includes('4. Cáncer de mama (a partir 19 a)')}
                              onChange={handleInputChange}
                            />
                            4. Cáncer de mama (a partir 19 a)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="antecedentefamiliarprimer"
                              value="5. Cáncer de próstata (a partir 19 a)"
                              checked={persona.antecedentefamiliarprimer.includes('5. Cáncer de próstata (a partir 19 a)')}
                              onChange={handleInputChange}
                            />
                            5. Cáncer de próstata (a partir 19 a)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="antecedentefamiliarprimer"
                              value="6. Cáncer de pulmón (a partir 19 a)"
                              checked={persona.antecedentefamiliarprimer.includes('6. Cáncer de pulmón (a partir 19 a)')}
                              onChange={handleInputChange}
                            />
                            6. Cáncer de pulmón (a partir 19 a)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="antecedentefamiliarprimer"
                              value="7. Diabetes (a partir de los 6 a)"
                              checked={persona.antecedentefamiliarprimer.includes('7. Diabetes (a partir de los 6 a)')}
                              onChange={handleInputChange}
                            />
                            7. Diabetes (a partir de los 6 a)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="antecedentefamiliarprimer"
                              value="8. Enfermedad cardiovascular (a partir 18 a)"
                              checked={persona.antecedentefamiliarprimer.includes('8. Enfermedad cardiovascular (a partir 18 a)')}
                              onChange={handleInputChange}
                            />
                            8. Enfermedad cardiovascular (a partir 18 a)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="antecedentefamiliarprimer"
                              value="9. Esquizofrenia (a partir 9 a)"
                              checked={persona.antecedentefamiliarprimer.includes('9. Esquizofrenia (a partir 9 a)')}
                              onChange={handleInputChange}
                            />
                            9. Esquizofrenia (a partir 9 a)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="antecedentefamiliarprimer"
                              value="10. Leucemia y linfoma en niños (0 a 17 a)"
                              checked={persona.antecedentefamiliarprimer.includes('10. Leucemia y linfoma en niños (0 a 17 a)')}
                              onChange={handleInputChange}
                            />
                            10. Leucemia y linfoma en niños (0 a 17 a)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="antecedentefamiliarprimer"
                              value="11. Trastorno afectivo bipolar (a partir 9 a)"
                              checked={persona.antecedentefamiliarprimer.includes('11. Trastorno afectivo bipolar (a partir 9 a)')}
                              onChange={handleInputChange}
                            />
                            11. Trastorno afectivo bipolar (a partir 9 a)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="antecedentefamiliarprimer"
                              value="12. Ninguna"
                              checked={persona.antecedentefamiliarprimer.includes('12. Ninguna')}
                              onChange={handleInputChange}
                            />
                            12. Ninguna
                          </label>
                        </div>
                        {/* {!persona.antecedentefamiliarprimer.length && <div className="text-danger">Campo obligatorio</div>} */}
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <div>
                        <label class="form-label">Síntomas recientes sin causa aparente (SM)</label>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="sintomasrecientessincausa"
                              value="1. Adinamia-debilidad"
                              checked={persona.sintomasrecientessincausa.includes('1. Adinamia-debilidad')}
                              onChange={handleInputChange}
                            />
                            1. Adinamia-debilidad
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="sintomasrecientessincausa"
                              value="2. Bronquitis o sibilancias"
                              checked={persona.sintomasrecientessincausa.includes('2. Bronquitis o sibilancias')}
                              onChange={handleInputChange}
                            />
                            2. Bronquitis o sibilancias
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="sintomasrecientessincausa"
                              value="3. Candidiasis"
                              checked={persona.sintomasrecientessincausa.includes('3. Candidiasis')}
                              onChange={handleInputChange}
                            />
                            3. Candidiasis
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="sintomasrecientessincausa"
                              value="4. Cefaleas"
                              checked={persona.sintomasrecientessincausa.includes('4. Cefaleas')}
                              onChange={handleInputChange}
                            />
                            4. Cefaleas
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="sintomasrecientessincausa"
                              value="5. Cicatrización lenta"
                              checked={persona.sintomasrecientessincausa.includes('5. Cicatrización lenta')}
                              onChange={handleInputChange}
                            />
                            5. Cicatrización lenta
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="sintomasrecientessincausa"
                              value="6. Diarrea permanente"
                              checked={persona.sintomasrecientessincausa.includes('6. Diarrea permanente')}
                              onChange={handleInputChange}
                            />
                            6. Diarrea permanente
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="sintomasrecientessincausa"
                              value="7. Dificultad para orinar (M)"
                              checked={persona.sintomasrecientessincausa.includes('7. Dificultad para orinar (M)')}
                              onChange={handleInputChange}
                            />
                            7. Dificultad para orinar (M)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="sintomasrecientessincausa"
                              value="8. Dificultad para respirar"
                              checked={persona.sintomasrecientessincausa.includes('8. Dificultad para respirar')}
                              onChange={handleInputChange}
                            />
                            8. Dificultad para respirar
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="sintomasrecientessincausa"
                              value="9. Disminución y alteración en la materia fecal"
                              checked={persona.sintomasrecientessincausa.includes('9. Disminución y alteración en la materia fecal')}
                              onChange={handleInputChange}
                            />
                            9. Disminución y alteración en la materia fecal
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="sintomasrecientessincausa"
                              value="10. Dolor abdominal"
                              checked={persona.sintomasrecientessincausa.includes('10. Dolor abdominal')}
                              onChange={handleInputChange}
                            />
                            10. Dolor abdominal
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="sintomasrecientessincausa"
                              value="11. Dolor al eyacular u orinar (M)"
                              checked={persona.sintomasrecientessincausa.includes('11. Dolor al eyacular u orinar (M)')}
                              onChange={handleInputChange}
                            />
                            11. Dolor al eyacular u orinar (M)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="sintomasrecientessincausa"
                              value="12. Dolor constante en el pecho"
                              checked={persona.sintomasrecientessincausa.includes('12. Dolor constante en el pecho')}
                              onChange={handleInputChange}
                            />
                            12. Dolor constante en el pecho
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="sintomasrecientessincausa"
                              value="13. Dolor durante las relaciones sexuales (F)"
                              checked={persona.sintomasrecientessincausa.includes('13. Dolor durante las relaciones sexuales (F)')}
                              onChange={handleInputChange}
                            />
                            13. Dolor durante las relaciones sexuales (F)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="sintomasrecientessincausa"
                              value="14. Dolor torácico al respirar y/o toser"
                              checked={persona.sintomasrecientessincausa.includes('14. Dolor torácico al respirar y/o toser')}
                              onChange={handleInputChange}
                            />
                            14. Dolor torácico al respirar y/o toser
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="sintomasrecientessincausa"
                              value="15. Dolores óseos"
                              checked={persona.sintomasrecientessincausa.includes('15. Dolores óseos')}
                              onChange={handleInputChange}
                            />
                            15. Dolores óseos
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="sintomasrecientessincausa"
                              value="16. Endurecimiento o hinchazón de una parte de las mamas"
                              checked={persona.sintomasrecientessincausa.includes('16. Endurecimiento o hinchazón de una parte de las mamas')}
                              onChange={handleInputChange}
                            />
                            16. Endurecimiento o hinchazón de una parte de las mamas
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="sintomasrecientessincausa"
                              value="17. Enrojecimiento o descamación en el pezón o las mamas"
                              checked={persona.sintomasrecientessincausa.includes('17. Enrojecimiento o descamación en el pezón o las mamas')}
                              onChange={handleInputChange}
                            />
                            17. Enrojecimiento o descamación en el pezón o las mamas
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="sintomasrecientessincausa"
                              value="18. Erupción macular de piel"
                              checked={persona.sintomasrecientessincausa.includes('18. Erupción macular de piel')}
                              onChange={handleInputChange}
                            />
                            18. Erupción macular de piel
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="sintomasrecientessincausa"
                              value="19. Esputos sanguinolentos"
                              checked={persona.sintomasrecientessincausa.includes('19. Esputos sanguinolentos')}
                              onChange={handleInputChange}
                            />
                            19. Esputos sanguinolentos
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="sintomasrecientessincausa"
                              value="20. Exacerbación de la tos previamente existente generalmente sin moco"
                              checked={persona.sintomasrecientessincausa.includes('20. Exacerbación de la tos previamente existente generalmente sin moco')}
                              onChange={handleInputChange}
                            />
                            20. Exacerbación de la tos previamente existente generalmente sin moco
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="sintomasrecientessincausa"
                              value="21. Sangre en la orina (M)"
                              checked={persona.sintomasrecientessincausa.includes('21. Sangre en la orina (M)')}
                              onChange={handleInputChange}
                            />
                            21. Sangre en la orina (M)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="sintomasrecientessincausa"
                              value="22. Secreción vaginal inusual (F)"
                              checked={persona.sintomasrecientessincausa.includes('22. Secreción vaginal inusual (F)')}
                              onChange={handleInputChange}
                            />
                            22. Secreción vaginal inusual (F)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="sintomasrecientessincausa"
                              value="23. Sed excesiva"
                              checked={persona.sintomasrecientessincausa.includes('23. Sed excesiva')}
                              onChange={handleInputChange}
                            />
                            23. Sed excesiva
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="sintomasrecientessincausa"
                              value="24. Sensación de que el intestino no se vacía por completo"
                              checked={persona.sintomasrecientessincausa.includes('24. Sensación de que el intestino no se vacía por completo')}
                              onChange={handleInputChange}
                            />
                            24. Sensación de que el intestino no se vacía por completo
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="sintomasrecientessincausa"
                              value="25. Sudoración"
                              checked={persona.sintomasrecientessincausa.includes('25. Sudoración')}
                              onChange={handleInputChange}
                            />
                            25. Sudoración
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="sintomasrecientessincausa"
                              value="26. Tos con expectoración mucosa mayor a 15 días"
                              checked={persona.sintomasrecientessincausa.includes('26. Tos con expectoración mucosa mayor a 15 días')}
                              onChange={handleInputChange}
                            />
                            26. Tos con expectoración mucosa mayor a 15 días
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="sintomasrecientessincausa"
                              value="27. Faringitis"
                              checked={persona.sintomasrecientessincausa.includes('27. Faringitis')}
                              onChange={handleInputChange}
                            />
                            27. Faringitis
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="sintomasrecientessincausa"
                              value="28. Fiebre persistente"
                              checked={persona.sintomasrecientessincausa.includes('28. Fiebre persistente')}
                              onChange={handleInputChange}
                            />
                            28. Fiebre persistente
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="sintomasrecientessincausa"
                              value="29. Fosfenos"
                              checked={persona.sintomasrecientessincausa.includes('29. Fosfenos')}
                              onChange={handleInputChange}
                            />
                            29. Fosfenos
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="sintomasrecientessincausa"
                              value="30. Gastritis crónica"
                              checked={persona.sintomasrecientessincausa.includes('30. Gastritis crónica')}
                              onChange={handleInputChange}
                            />
                            30. Gastritis crónica
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="sintomasrecientessincausa"
                              value="31. Hambre excesiva"
                              checked={persona.sintomasrecientessincausa.includes('31. Hambre excesiva')}
                              onChange={handleInputChange}
                            />
                            31. Hambre excesiva
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="sintomasrecientessincausa"
                              value="32. Herpes"
                              checked={persona.sintomasrecientessincausa.includes('32. Herpes')}
                              onChange={handleInputChange}
                            />
                            32. Herpes
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="sintomasrecientessincausa"
                              value="33. Hundimiento del pezón (F)"
                              checked={persona.sintomasrecientessincausa.includes('33. Hundimiento del pezón (F)')}
                              onChange={handleInputChange}
                            />
                            33. Hundimiento del pezón (F)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="sintomasrecientessincausa"
                              value="34. Infección respiratoria recurrente que no cede (bronquitis-neumonía)"
                              checked={persona.sintomasrecientessincausa.includes('34. Infección respiratoria recurrente que no cede (bronquitis-neumonía)')}
                              onChange={handleInputChange}
                            />
                            34. Infección respiratoria recurrente que no cede (bronquitis-neumonía)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="sintomasrecientessincausa"
                              value="35. Inflamación de los ganglios"
                              checked={persona.sintomasrecientessincausa.includes('35. Inflamación de los ganglios')}
                              onChange={handleInputChange}
                            />
                            35. Inflamación de los ganglios
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="sintomasrecientessincausa"
                              value="36. Mialgias"
                              checked={persona.sintomasrecientessincausa.includes('36. Mialgias')}
                              onChange={handleInputChange}
                            />
                            36. Mialgias
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="sintomasrecientessincausa"
                              value="37. Orina con frecuencia"
                              checked={persona.sintomasrecientessincausa.includes('37. Orina con frecuencia')}
                              onChange={handleInputChange}
                            />
                            37. Orina con frecuencia
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="sintomasrecientessincausa"
                              value="38. Pérdida de apetito"
                              checked={persona.sintomasrecientessincausa.includes('38. Pérdida de apetito')}
                              onChange={handleInputChange}
                            />
                            38. Pérdida de apetito
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="sintomasrecientessincausa"
                              value="39. Perdida de la sensibilidad en los pies"
                              checked={persona.sintomasrecientessincausa.includes('39. Perdida de la sensibilidad en los pies')}
                              onChange={handleInputChange}
                            />
                            39. Perdida de la sensibilidad en los pies
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="sintomasrecientessincausa"
                              value="40. Pérdida de peso"
                              checked={persona.sintomasrecientessincausa.includes('40. Pérdida de peso')}
                              onChange={handleInputChange}
                            />
                            40. Pérdida de peso
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="sintomasrecientessincausa"
                              value="41. Presencia de masas o bultos en los senos o en las axilas"
                              checked={persona.sintomasrecientessincausa.includes('41. Presencia de masas o bultos en los senos o en las axilas')}
                              onChange={handleInputChange}
                            />
                            41. Presencia de masas o bultos en los senos o en las axilas
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="sintomasrecientessincausa"
                              value="42. Producción de esputo"
                              checked={persona.sintomasrecientessincausa.includes('42. Producción de esputo')}
                              onChange={handleInputChange}
                            />
                            42. Producción de esputo
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="sintomasrecientessincausa"
                              value="43. Salida de secreción por el pezón (F)"
                              checked={persona.sintomasrecientessincausa.includes('43. Salida de secreción por el pezón (F)')}
                              onChange={handleInputChange}
                            />
                            43. Salida de secreción por el pezón (F)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="sintomasrecientessincausa"
                              value="44. Sangrado vaginal anormal (F)"
                              checked={persona.sintomasrecientessincausa.includes('44. Sangrado vaginal anormal (F)')}
                              onChange={handleInputChange}
                            />
                            44. Sangrado vaginal anormal (F)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="sintomasrecientessincausa"
                              value="45. Sangrados en mucosas y/o pie"
                              checked={persona.sintomasrecientessincausa.includes('45. Sangrados en mucosas y/o pie')}
                              onChange={handleInputChange}
                            />
                            45. Sangrados en mucosas y/o pie
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="sintomasrecientessincausa"
                              value="46. Sangre en el semen (M)"
                              checked={persona.sintomasrecientessincausa.includes('46. Sangre en el semen (M)')}
                              onChange={handleInputChange}
                            />
                            46. Sangre en el semen (M)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="sintomasrecientessincausa"
                              value="47. Sangre en la materia fecal"
                              checked={persona.sintomasrecientessincausa.includes('47. Sangre en la materia fecal')}
                              onChange={handleInputChange}
                            />
                            47. Sangre en la materia fecal
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="sintomasrecientessincausa"
                              value="48. Tos crónica"
                              checked={persona.sintomasrecientessincausa.includes('48. Tos crónica')}
                              onChange={handleInputChange}
                            />
                            48. Tos crónica
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="sintomasrecientessincausa"
                              value="49. Tos persistente mayor a 21 días (0 a 5 a)"
                              checked={persona.sintomasrecientessincausa.includes('49. Tos persistente mayor a 21 días (0 a 5 a)')}
                              onChange={handleInputChange}
                            />
                            49. Tos persistente mayor a 21 días (0 a 5 a)
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="sintomasrecientessincausa"
                              value="50. Tinnitus"
                              checked={persona.sintomasrecientessincausa.includes('50. Tinnitus')}
                              onChange={handleInputChange}
                            />
                            50. Tinnitus
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="sintomasrecientessincausa"
                              value="51. Hemorragia conjuntival"
                              checked={persona.sintomasrecientessincausa.includes('51. Hemorragia conjuntival')}
                              onChange={handleInputChange}
                            />
                            51. Hemorragia conjuntival
                          </label>
                        </div>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input
                              type="checkbox"
                              class="form-check-input"
                              name="sintomasrecientessincausa"
                              value="52. Ninguno de los anteriores"
                              checked={persona.sintomasrecientessincausa.includes('52. Ninguno de los anteriores')}
                              onChange={handleInputChange}
                            />
                            52. Ninguno de los anteriores
                          </label>
                        </div>
                        {/* {!persona.sintomasrecientessincausa.length && <div className="text-danger">Campo obligatorio</div>} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-4"><h4 class="text-center"> 4.	PRACTICAS DE SALUD DE SALUD ORAL</h4>
                <div class="row">
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Cepillado diario mínimo 3 veces al día</label>
                      <select className={`form-select`} name='cepilladodiariominimovec' value={persona.cepilladodiariominimovec} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="SI">SI</option>
                        <option value="NO">NO</option>
                        <option value="SD">SD</option>
                      </select>
                      {/* {!persona.cepilladodiariominimovec && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">¿Utiliza otro elemento para el higiene de salud oral?</label>
                      <select className={`form-select`} name='utilizaotroelementoparael' value={persona.utilizaotroelementoparael} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="SI">SI</option>
                        <option value="NO">NO</option>
                        <option value="SD">SD</option>
                      </select>
                      {/* {!persona.utilizaotroelementoparael && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">¿la última vez que visitó el odontólogo?</label>
                      <select className={`form-select`} name='laultimavezquevisitoelodo' value={persona.laultimavezquevisitoelodo} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Menos de 6 meses">Menos de 6 meses</option>
                        <option value="Entre 6 y 12 meses">Entre 6 y 12 meses</option>
                        <option value="Entre 1 y 2 años">Entre 1 y 2 años</option>
                        <option value="Más de 2 años">Más de 2 años</option>
                        <option value="Nunca lo han visitado">Nunca lo han visitado</option>
                      </select>
                      {/* {!persona.laultimavezquevisitoelodo && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-4"><h4 class="text-center"> 5.	SEXUALIDAD, DERECHOS SEXUALES Y REPRODUCTIVOS</h4>
                <div class="row">
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Fecha de la última menstruación</label>
                      <input
                        type="date"
                        name='fechadelaultimamenstruaci'
                        className={`form-control`}
                        value={persona.fechadelaultimamenstruaci ? persona.fechadelaultimamenstruaci.split('T')[0] : ''}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Su última menstruación fue después de los 50 años (F)</label>
                      <select className={`form-select`} name='suultimamenstruacionfuede' value={persona.suultimamenstruacionfuede} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="SI">SI</option>
                        <option value="NO">NO</option>
                        <option value="SD">SD</option>
                        <option value="NA">NA</option>
                      </select>
                      {/* {!persona.suultimamenstruacionfuede && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Actualmente tiene relaciones sexuales (SM)</label>
                      <select className={`form-select`} name='actualmentetienerelacione' value={persona.actualmentetienerelacione} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="SI">SI</option>
                        <option value="NO">NO</option>
                        <option value="SD">SD</option>
                        <option value="NA">NA</option>
                      </select>
                      {/* {!persona.actualmentetienerelacione && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Práctica sexual riesgosa (SM) a partir 12 a</label>
                      <select className={`form-select`} name='practicasexualriesgosasma' value={persona.practicasexualriesgosasma} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Trabajo sexual">Trabajo sexual</option>
                        <option value="Relaciones sexuales hombre con hombre (M)">Relaciones sexuales hombre con hombre (M)</option>
                        <option value="Relaciones sexuales sin condón">Relaciones sexuales sin condón</option>
                        <option value="Ninguna">Ninguna</option>
                        <option value="NA">NA</option>
                      </select>
                      {/* {!persona.practicasexualriesgosasma && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">PQue método de planificación utiliza como pareja (SM) de 12 a 49 a</label>
                      <select className={`form-select`} name='quemetododeplanificacionu' value={persona.quemetododeplanificacionu} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Barrera">Barrera</option>
                        <option value="Definitivo">Definitivo</option>
                        <option value="Hormonal">Hormonal</option>
                        <option value="Mecánico">Mecánico</option>
                        <option value="Natural">Natural</option>
                        <option value="Ninguna">Ninguna</option>
                        <option value="NA">NA</option>
                      </select>
                      {/* {!persona.quemetododeplanificacionu && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Es constante con el método de planificación (12 a 49 a)</label>
                      <select className={`form-select`} name='esconstanteconelmetododep' value={persona.esconstanteconelmetododep} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="SI">SI</option>
                        <option value="NO">NO</option>
                        <option value="SD">SD</option>
                        <option value="NA">NA</option>
                      </select>
                      {/* {!persona.esconstanteconelmetododep && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Ha utilizado por más de 10 años anticonceptivos orales (F) (a partir 22 a)</label>
                      <select className={`form-select`} name='hautilizadopormasdediezan' value={persona.hautilizadopormasdediezan} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="SI">SI</option>
                        <option value="NO">NO</option>
                        <option value="SD">SD</option>
                        <option value="NA">NA</option>
                      </select>
                      {/* {!persona.hautilizadopormasdediezan && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">¿Quién facilita el método el acceso al método planificación?</label>
                      <select className={`form-select`} name='quienfacilitaelmetodoelac' value={persona.quienfacilitaelmetodoelac} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="IPS">IPS</option>
                        <option value="EPS">EPS</option>
                        <option value="Adquirido por cuenta propia">Adquirido por cuenta propia</option>
                        <option value="NA">NA</option>
                      </select>
                      {/* {!persona.quienfacilitaelmetodoelac && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-4"><h4 class="text-center"> 5.	SEXUALIDAD, DERECHOS SEXUALES Y REPRODUCTIVOS (MATERNIDAD)</h4>
                <div class="row">
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">N° Partos</label>
                      <input
                        type="number"
                        name='npartos'
                        className={`form-control`}
                        value={persona.npartos}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">N° cesáreas</label>
                      <input
                        type="number"
                        name='ncesareas'
                        className={`form-control`}
                        value={persona.ncesareas}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">N° abortos</label>
                      <input
                        type="number"
                        name='nabortos'
                        className={`form-control`}
                        value={persona.nabortos}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">N° nacidos vivos</label>
                      <input
                        type="number"
                        name='nnacidosvivos'
                        className={`form-control`}
                        value={persona.nnacidosvivos}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">N° mortinatos</label>
                      <input
                        type="number"
                        name='nmortinatos'
                        className={`form-control`}
                        value={persona.nmortinatos}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Edad al momento de nacer el primer hijo</label>
                      <select className={`form-select`} name='edadalmomentodenacerelpri' value={persona.edadalmomentodenacerelpri} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Menores de 14 años">Menores de 14 años</option>
                        <option value="Entre 14 y 17 años">Entre 14 y 17 años</option>
                        <option value="Entre  18 y 29 años">Entre  18 y 29 años</option>
                        <option value="Entre  30 y 39 años">Entre  30 y 39 años</option>
                        <option value="Mayor de 40 años">Mayor de 40 años</option>
                        <option value="NA">NA</option>
                      </select>
                      {/* {!persona.edadalmomentodenacerelpri && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">En sus embarazos consumió alcohol o cigarrillo de forma frecuente</label>
                      <select className={`form-select`} name='ensusembarazosconsumioalc' value={persona.ensusembarazosconsumioalc} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="SI">SI</option>
                        <option value="NO">NO</option>
                        <option value="NA">NA</option>
                      </select>
                      {/* {!persona.ensusembarazosconsumioalc && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Embarazo planeado</label>
                      <select className={`form-select`} name='embarazoplaneado' value={persona.embarazoplaneado} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="SI">SI</option>
                        <option value="NO">NO</option>
                        <option value="NA">NA</option>
                      </select>
                      {/* {!persona.embarazoplaneado && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Clasificación del riesgo obstétrico</label>
                      <select className={`form-select`} name='clasificaciondelriesgoobs' value={persona.clasificaciondelriesgoobs} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Biologico ">Biologico </option>
                        <option value="Psicologico">Psicologico</option>
                        <option value="Sociocultutral ">Sociocultutral </option>
                      </select>
                      {/* {!persona.clasificaciondelriesgoobs && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-4"><h4 class="text-center"> 6.	RECIEN NACIDO</h4>
                <div class="row">
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">¿Sabe el Motivo del riesgo?</label>
                      <input
                        type="text"
                        name='sabeelmotivodelriesgo'
                        className={`form-control`}
                        value={persona.sabeelmotivodelriesgo}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Alimenta con leche materna exclusiva? (hasta 6 m)</label>
                      <select className={`form-select`} name='alimentaconlechematernaex' value={persona.alimentaconlechematernaex} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="SI ">SI </option>
                        <option value="NO">NO</option>
                        <option value="SD">SD</option>
                      </select>
                      {/* {!persona.alimentaconlechematernaex && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Alimentación complementaria (hasta 2 años)</label>
                      <select className={`form-select`} name='alimentacioncomplementari' value={persona.alimentacioncomplementari} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="SI ">SI </option>
                        <option value="NO">NO</option>
                        <option value="SD">SD</option>
                      </select>
                      {/* {!persona.alimentacioncomplementari && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div> 
                  </div>
                </div> 
              </div>
              <div class="mb-4"><h4 class="text-center"> 7.	VIDA SALUDABLE Y CONDICIONES NO TRANSMISIBLES</h4>
                <div class="row">
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">¿Última vez que asistió a citología y/o VPH? Mujeres 25 - 29 años - citología) -Mujeres persona de 30 años-VPH (resultado negativo con frecuencia 5 años)</label>
                      <select className={`form-select`} name='ultimavezqueasistioacitol' value={persona.ultimavezqueasistioacitol} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Menor a 3 años">Menor a 3 años</option>
                        <option value="Más de 3 años">Más de 3 años</option>
                        <option value="Nunca">Nunca</option>
                        <option value="NA">NA</option>
                      </select>
                      {/* {!persona.ultimavezqueasistioacitol && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">¿Última vez le realizaron prueba de Antígeno prostático y/o tacto rectal? (persona de 50 años)</label>
                      <select className={`form-select`} name='ultimavezlerealizaronprue' value={persona.ultimavezlerealizaronprue} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Menor a 5 años">Menor a 5 años</option>
                        <option value="Más de 5 años">Más de 5 años</option>
                        <option value="Nunca">Nunca</option>
                        <option value="NA">NA</option>
                      </select>
                      {/* {!persona.ultimavezlerealizaronprue && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">¿última vez que le realizaron examen clínico de mama? (menores 50 años)</label>
                      <select className={`form-select`} name='ultimavezquelerealizarone' value={persona.ultimavezquelerealizarone} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Menor a 1 año">Menor a 1 año</option>
                        <option value="Más de 1 año">Más de 1 año</option>
                        <option value="Nunca">Nunca</option>
                        <option value="NA">NA</option>
                      </select>
                      {/* {!persona.ultimavezquelerealizarone && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">¿última vez que le realizaron mamografía (persona de 50 años)</label>
                      <select className={`form-select`} name='ultimavezquelerealizaronm' value={persona.ultimavezquelerealizaronm} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Menor a 2 años">Menor a 2 años</option>
                        <option value="Más de 2 años">Más de 2 años</option>
                        <option value="Nunca">Nunca</option>
                        <option value="NA">NA</option>
                      </select>
                      {/* {!persona.ultimavezquelerealizaronm && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Práctica de actividad física en minutos</label>
                      <select className={`form-select`} name='practicadeactividadfisica' value={persona.practicadeactividadfisica} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Mayor a 60 min diarios">Mayor a 60 min diarios</option>
                        <option value="Menor a 60 min diarios">Menor a 60 min diarios</option>
                        <option value="Mayor a 30 min diarios">Mayor a 30 min diarios</option>
                        <option value="Menor a 30 min diarios">Menor a 30 min diarios</option>
                        <option value="No Practica Ninguna actividad fisica">No Practica Ninguna actividad fisica</option>
                      </select>
                      {/* {!persona.practicadeactividadfisica && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Hábitos de alimentación</label>
                      <select className={`form-select`} name='habitosdealimentacion' value={persona.habitosdealimentacion} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Alto consumo de sal">Alto consumo de sal</option>
                        <option value="No consume mínimo 2 frutas diarias">No consume mínimo 2 frutas diarias</option>
                        <option value="No consume mínimo 2 verduras diarias">No consume mínimo 2 verduras diarias</option>
                        <option value="Consumo alto de grasas">Consumo alto de grasas</option>
                        <option value="Alto consumo de azúcar">Alto consumo de azúcar</option>
                        <option value="Alto consumo de empaquetados, salsas, dulces, jugos en caja, embutidos">Alto consumo de empaquetados, salsas, dulces, jugos en caja, embutidos</option>
                        <option value="Adecuados">Adecuados</option>
                        <option value="NA">NA</option>
                      </select>
                      {/* {!persona.habitosdealimentacion && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Exposición frecuente a humo (SM)</label>
                      <select className={`form-select`} name='exposicionfrecuenteahumos' value={persona.exposicionfrecuenteahumos} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Cigarrillo">Cigarrillo</option>
                        <option value="Leña">Leña</option>
                        <option value="carbón">carbón</option>
                        <option value="NA">NA</option>
                      </select>
                      {/* {!persona.exposicionfrecuenteahumos && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">¿Se desparasitado en los ultimos 6 meses? *A partir de los 6 meses de edad.</label>
                      <select className={`form-select`} name='sedesparasitadoenlosultim' value={persona.sedesparasitadoenlosultim} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="SI">SI</option>
                        <option value="NO">NO</option>
                        <option value="NA">NA</option>
                      </select>
                      {/* {!persona.sedesparasitadoenlosultim && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">¿última vez que le practicaron tamizaje para cáncer colorrectal? (prueba de sangre oculta)</label>
                      <select className={`form-select`} name='ultimavezquelepracticaron' value={persona.ultimavezquelepracticaron} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="hace dos años">hace dos años</option>
                        <option value="Más de 3 años">Más de 3 años</option>
                        <option value="Nunca">Nunca</option>
                      </select>
                      {/* {!persona.ultimavezquelepracticaron && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Peso (Kg)</label>
                      <input
                        type="number"
                        name='pesokg'
                        className={`form-control`}
                        value={persona.pesokg}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Talla (Cm)</label>
                      <input
                        type="number"
                        name='tallacm'
                        className={`form-control`}
                        value={persona.tallacm}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Clasificación Antrometrica</label>
                      <input
                        type="text"
                        name='clasificacionantrometrica'
                        className={`form-control`}
                        value={persona.clasificacionantrometrica}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Circunferencia de la Cintura (cm)</label>
                      <input
                        type="number"
                        name='circunferenciadelacintura'
                        className={`form-control`}
                        value={persona.circunferenciadelacintura}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Perimetro del brazo (Niños y Niñas de 6 a 59 meses)</label>
                      <input
                        type="number"
                        name='perimetrodelbrazoninosyni'
                        className={`form-control`}
                        value={persona.perimetrodelbrazoninosyni}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div> 
              </div>    
              <div class="mb-4"><h4 class="text-center"> 8.	SALUD MENTAL</h4>
                <div class="row">
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">¿Actualmente existe alguna crisis familiar o personal en la cual usted no se siente en las capacidades para enfrentar</label>
                      <select className={`form-select`} name='actualmenteexistealgunacr' value={persona.actualmenteexistealgunacr} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="SI">SI</option>
                        <option value="NO">NO</option>
                        <option value="NA">NA</option>
                      </select>
                      {/* {!persona.actualmenteexistealgunacr && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">¿Se despierta varias horas antes de lo habitual y no puede volver a dormir?</label>
                      <select className={`form-select`} name='sedespiertavariashorasant' value={persona.sedespiertavariashorasant} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="SI">SI</option>
                        <option value="NO">NO</option>
                        <option value="NA">NA</option>
                      </select>
                      {/* {!persona.sedespiertavariashorasant && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">¿A veces siente en que no tiene sentido vivir?</label>
                      <select className={`form-select`} name='avecessienteenquenotienes' value={persona.avecessienteenquenotienes} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="SI">SI</option>
                        <option value="NO">NO</option>
                        <option value="NA">NA</option>
                      </select>
                      {/* {!persona.avecessienteenquenotienes && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">¿considera que la muerte puede ser  la solución al /los problema(s) que usted está presentando?</label>
                      <select className={`form-select`} name='consideraquelamuertepuede' value={persona.consideraquelamuertepuede} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="SI">SI</option>
                        <option value="NO">NO</option>
                        <option value="NA">NA</option>
                      </select>
                      {/* {!persona.consideraquelamuertepuede && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">¿Estar cargo del cuidado de alguna persona con dependencia funcional (discapacidad – vejez) le genera malestar psicológico o físico ?</label>
                      <select className={`form-select`} name='estarcargodelcuidadodealg' value={persona.estarcargodelcuidadodealg} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="SI">SI</option>
                        <option value="NO">NO</option>
                        <option value="NA">NA</option>
                      </select>
                      {/* {!persona.estarcargodelcuidadodealg && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">¿experimenta una perdida/muerte que sobrepasa su capacidad de afrontamiento? </label>
                      <select className={`form-select`} name='experimentaunaperdidamuer' value={persona.experimentaunaperdidamuer} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="SI">SI</option>
                        <option value="NO">NO</option>
                        <option value="NA">NA</option>
                      </select>
                      {/* {!persona.experimentaunaperdidamuer && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">¿Considera que los comportamientos que su familia emplea para solucionar los problemas son inadecuados, causan sufrimiento? </label>
                      <select className={`form-select`} name='consideraqueloscomportami' value={persona.consideraqueloscomportami} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="SI">SI</option>
                        <option value="NO">NO</option>
                        <option value="NA">NA</option>
                      </select>
                      {/* {!persona.consideraqueloscomportami && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">¿Considera anormales  sus hábitos alimenticios o los de algún integrante de su hogar’</label>
                      <select className={`form-select`} name='consideraanormalessushabi' value={persona.consideraanormalessushabi} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="SI">SI</option>
                        <option value="NO">NO</option>
                        <option value="NA">NA</option>
                      </select>
                      {/* {!persona.consideraanormalessushabi && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">¿Identifica en su hogar comportamientos que considere extraños, que afectan la cotidianidad?</label>
                      <select className={`form-select`} name='identificaensuhogarcompor' value={persona.identificaensuhogarcompor} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="SI">SI</option>
                        <option value="NO">NO</option>
                        <option value="NA">NA</option>
                      </select>
                      {/* {!persona.identificaensuhogarcompor && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                      <label class="form-label">Consumo de Sustancias Psicoactiva</label>
                      <select className={`form-select`} name='consumodesustanciaspsicoa' value={persona.consumodesustanciaspsicoa} onChange={handleInputChange}>
                        <option selected></option>
                        <option value="Alcohol (<9 a 17 a)">Alcohol (Menor 9 a 17 a)</option>
                        <option value="Abuso de alcohol (a partir 18 a)">Abuso de alcohol (a partir 18 a)</option>
                        <option value="Cigarrillo">Cigarrillo</option>
                        <option value="Otras SPA">Otras SPA</option>
                        <option value="Riesgo o uso de SPA por vía inyectable">Riesgo o uso de SPA por vía inyectable</option>
                        <option value="Ninguna">Ninguna</option>
                        <option value="NA">NA</option>
                      </select>
                      {/* {!persona.consumodesustanciaspsicoa && <div className="invalid-feedback">Campo obligatorio</div>} */}
                    </div>
                  </div>
                </div> 
              </div>
              <div class="mb-5"><h4 class="text-center">SEGUIMIENTO VACUNACIÓN</h4>
                <div class="mb-5">
                  <h5 class="text">Niños menores de 6 años</h5>
                  <div class="accordion" id={`accordionSixYeards-1`}>
                    {/* Mostrar los grupos de campos agregados */}
                    <div class="accordion-item border border-primary">
                      <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapsesix-1`} aria-expanded="true" aria-controls={`collapsesix-1`}>
                          Desplegar para diligenciar datos
                        </button>
                      </h2>
                      <div id={`collapsesix-1`} class="accordion-collapse collapse" data-bs-parent={`#accordionSixYeard-1`}>
                        <div class="accordion-body">
                          {/* Agregar aquí los campos específicos de este grupo */}
                          <div class="mb-4"><h5 class="text-center">Por favor al diligenciar registre la fecha  de aplicación de los biológicos trazadores de los niños y niñas menores de 6 años</h5>
                            <div class="row">
                              <div class="col-12 col-md-6 col-lg-4">
                                <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                  <label class="form-label">DOSIS RN (BCG)</label>
                                  <input
                                    type="date"
                                    name='seisdosisrnbcg'
                                    className={`form-control`}
                                    value={persona.seisdosisrnbcg ? persona.seisdosisrnbcg.split('T')[0] : ''}
                                    onChange={handleInputChange}
                                  />
                                </div>
                              </div>
                              <div class="col-12 col-md-6 col-lg-4">
                                <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                  <label class="form-label">3RAS DOSIS (PENTA)</label>
                                  <input
                                    type="date"
                                    name='seistercerasdosispenta'
                                    className={`form-control`}
                                    value={persona.seistercerasdosispenta ? persona.seistercerasdosispenta.split('T')[0] : ''}
                                    onChange={handleInputChange}
                                  />
                                </div>
                              </div>
                              <div class="col-12 col-md-6 col-lg-4">
                                <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                  <label class="form-label">DOSIS AÑO (Triple Viral)</label>
                                  <input
                                    type="date"
                                    name='seisdosisanotripleviral'
                                    className={`form-control`}
                                    value={persona.seisdosisanotripleviral ? persona.seisdosisanotripleviral.split('T')[0] : ''}
                                    onChange={handleInputChange}
                                  />
                                </div>
                              </div>
                              <div class="col-12 col-md-6 col-lg-4">
                                <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                  <label class="form-label">18 MESES (DPT Ó PENTA)</label>
                                  <input
                                    type="date"
                                    name='seisdiesiochomesesdptopenta'
                                    className={`form-control`}
                                    value={persona.seisdiesiochomesesdptopenta ? persona.seisdiesiochomesesdptopenta.split('T')[0] : ''}
                                    onChange={handleInputChange}
                                  />
                                </div>
                              </div>
                              <div class="col-12 col-md-6 col-lg-4">
                                <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                  <label class="form-label">DOSIS 5 AÑOS (2 RF DPT)</label>
                                  <input
                                    type="date"
                                    name='seisdosiscincoaniossegundorfdpt'
                                    className={`form-control`}
                                    value={persona.seisdosiscincoaniossegundorfdpt ? persona.seisdosiscincoaniossegundorfdpt.split('T')[0] : ''}
                                    onChange={handleInputChange}
                                  />
                                </div>
                              </div>
                              <div class="col-12 col-md-6 col-lg-4">
                                <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                  <label class="form-label">DOSIS DE INFLUENZA ANUAL</label>
                                  <input
                                    type="date"
                                    name='seisdosisdeinfluenzaanual'
                                    className={`form-control`}
                                    value={persona.seisdosisdeinfluenzaanual ? persona.seisdosisdeinfluenzaanual.split('T')[0] : ''}
                                    onChange={handleInputChange}
                                  />
                                </div>
                              </div>
                              <div class="col-12 col-md-6 col-lg-4">
                                <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                  <label class="form-label">ESQUEMA COMPLETO (1.SI - 2.NO)</label>
                                  <select className={`form-select`} name='seisesquemacompleto' value={persona.seisesquemacompleto} onChange={handleInputChange}>
                                    <option selected></option>
                                    <option value="SI">SI</option>
                                    <option value="NO">NO</option>
                                  </select>
                                </div>
                              </div>
                              <div class="col-12 col-md-6 col-lg-4">
                                <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                  <label class="form-label">1.VACUNADO / 2.CANALIZADO</label>
                                  <select className={`form-select`} name='seisvacunadocanalizado' value={persona.seisvacunadocanalizado} onChange={handleInputChange}>
                                    <option selected></option>
                                    <option value="VACUNADO">VACUNADO</option>
                                    <option value="CANALIZADO">CANALIZADO</option>
                                  </select>
                                </div>
                              </div>
                            </div> 
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mb-5">
                  <h5 class="text">Dosis adicional de SR niños de 2 A 11 años</h5>
                  <div class="accordion" id="accordionTwoEleven"> 
                      <div class="accordion-item border border-primary">
                        <h2 class="accordion-header">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapsetwoeleven-1`} aria-expanded="true" aria-controls={`collapsetwoeleven-1`}>
                            Desplegar para diligenciar datos
                          </button>
                        </h2>
                        <div id={`collapsetwoeleven-1`} class="accordion-collapse collapse" data-bs-parent="#accordionTwoEleven">
                          <div class="accordion-body">
                            {/* Agregar aquí los campos específicos de este grupo */}
                            <div class="mb-4"><h5 class="text-center">Por favor al diligenciar registre la fecha  de aplicación de la dosis adicional de SR en niños de a 2 a 11 años</h5>
                              <div class="row">
                                <div class="col-12 col-md-6 col-lg-4">
                                  <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">DOSIS ADICIONAL SR</label>
                                    <input
                                      type="date"
                                      name='dosdosisadicionalsr'
                                      className={`form-control`}
                                      value={persona.dosdosisadicionalsr ? persona.dosdosisadicionalsr.split('T')[0] : ''}
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                  <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">ESQUEMA COMPLETO (1.SI - 2.NO)</label>
                                    <select className={`form-select`} name='dosesquemacompleto' value={persona.dosesquemacompleto} onChange={handleInputChange}>
                                      <option selected></option>
                                      <option value="SI">SI</option>
                                      <option value="NO">NO</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                  <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">1.VACUNADO / 2.CANALIZADO</label>
                                    <select className={`form-select`} name='dosvacunadocanalizado' value={persona.dosvacunadocanalizado} onChange={handleInputChange}>
                                      <option selected></option>
                                      <option value="VACUNADO">VACUNADO</option>
                                      <option value="CANALIZADO">CANALIZADO</option>
                                    </select>
                                  </div>
                                </div>
                              </div> 
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
                <div class="mb-5">
                  <h5 class="text">COVID-19 persona de 3 años</h5>
                  <div class="accordion" id="accordionCovidTres">
                      <div class="accordion-item border border-primary">
                        <h2 class="accordion-header">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapsecovidtres-1`} aria-expanded="true" aria-controls={`collapsecovidtres-1`}>
                            Desplegar para diligenciar datos
                          </button>
                        </h2>
                        <div id={`collapsecovidtres-1`} class="accordion-collapse collapse" data-bs-parent="#accordionCovidTres">
                          <div class="accordion-body">
                            {/* Agregar aquí los campos específicos de este grupo */}
                            <div class="mb-4"><h5 class="text-center">Por favor al diligenciar registre la fecha  de aplicación de las dosis de vacuna contra el COVID-19</h5>
                              <div class="row">
                                <div class="col-12 col-md-6 col-lg-4">
                                  <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">1 DOSIS</label>
                                    <input
                                      type="date"
                                      name='covidprimeradosis'
                                      className={`form-control`}
                                      value={persona.covidprimeradosis ? persona.covidprimeradosis.split('T')[0] : ''}
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                  <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">DOSUS ÚNICA</label>
                                    <input
                                      type="date"
                                      name='coviddosusunica'
                                      className={`form-control`}
                                      value={persona.coviddosusunica ? persona.coviddosusunica.split('T')[0] : ''}
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                  <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">SEGUNDA DODIS</label>
                                    <input
                                      type="date"
                                      name='covidsegundadodis'
                                      className={`form-control`}
                                      value={persona.covidsegundadodis ? persona.covidsegundadodis.split('T')[0] : ''}
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                  <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">PRIMER REFUERZO</label>
                                    <input
                                      type="date"
                                      name='covidprimerrefuerzo'
                                      className={`form-control`}
                                      value={persona.covidprimerrefuerzo ? persona.covidprimerrefuerzo.split('T')[0] : ''}
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                  <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">SEGUNDO REFUERZO</label>
                                    <input
                                      type="date"
                                      name='covidsegundorefuerzo'
                                      className={`form-control`}
                                      value={persona.covidsegundorefuerzo ? persona.covidsegundorefuerzo.split('T')[0] : ''}
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                  <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">ESQUEMA COMPLETO (1.SI - 2.NO)</label>
                                    <select className={`form-select`} name='covidesquemacompleto' value={persona.covidesquemacompleto} onChange={handleInputChange}>
                                      <option selected></option>
                                      <option value="SI">SI</option>
                                      <option value="NO">NO</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                  <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">1.VACUNADO / 2.CANALIZADO</label>
                                    <select className={`form-select`} name='covidvacunadocanalizado' value={persona.covidvacunadocanalizado} onChange={handleInputChange}>
                                      <option selected></option>
                                      <option value="VACUNADO">VACUNADO</option>
                                      <option value="CANALIZADO">CANALIZADO</option>
                                    </select>
                                  </div>
                                </div>
                              </div> 
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
                <div class="mb-5">
                  <h5 class="text">Mujeres entre 9 a 17 años (en persona de 18 años con la primera dosis se le garantiza la segunda dosis de VPH)</h5>
                  <div class="accordion" id="accordionMujeresNueve">
                      <div class="accordion-item border border-primary">
                        <h2 class="accordion-header">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapsemujeresnueve-1`} aria-expanded="true" aria-controls={`collapsemujeresnueve-1`}>
                            Desplegar para diligenciar datos
                          </button>
                        </h2>
                        <div id={`collapsemujeresnueve-1`} class="accordion-collapse collapse" data-bs-parent="#accordionMujeresNueve">
                          <div class="accordion-body">
                            {/* Agregar aquí los campos específicos de este grupo */}
                            <div class="mb-4"><h5 class="text-center">Por favor al diligenciar registre el año de aplicación de las dosis, de acuerdo a los biológicos trazadores que aquí se indican. </h5>
                              <div class="row">
                                <div class="col-12 col-md-6 col-lg-4">
                                  <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">EPS</label>
                                    <select className={`form-select`} name='mujereseps' value={persona.mujereseps} onChange={handleInputChange}>
                                      <option selected></option>
                                      <option value="Asmet Salud">Asmet Salud</option>
                                      <option value="Nueva EPS">Nueva EPS</option>
                                      <option value="Sanitas EPS">Sanitas EPS</option>
                                      <option value="FAMAC">FAMAC</option>
                                      <option value="Sanidad Ejercito">Sanidad Ejercito</option>
                                      <option value="Sanidad Policia.">Sanidad Policia.</option>
                                      <option value="Otro">Otro</option>
                                    </select>
                                    {/* {!persona.eps && <div className="invalid-feedback">Campo obligatorio</div>} */}
                                  </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                  <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">1RA DOSIS</label>
                                    <input
                                      type="date"
                                      name='mujeresprimeraradosis'
                                      className={`form-control`}
                                      value={persona.mujeresprimeraradosis ? persona.mujeresprimeraradosis.split('T')[0] : ''}
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                  <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">2DA DOSIS</label>
                                    <input
                                      type="date"
                                      name='mujeressegundadadosis'
                                      className={`form-control`}
                                      value={persona.mujeressegundadadosis ? persona.mujeressegundadadosis.split('T')[0] : ''}
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                  <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">ESQUEMA COMPLETO (1.SI - 2.NO)</label>
                                    <select className={`form-select`} name='mujeresesquemacompleto' value={persona.mujeresesquemacompleto} onChange={handleInputChange}>
                                      <option selected></option>
                                      <option value="SI">SI</option>
                                      <option value="NO">NO</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                  <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">1.VACUNADO / 2.CANALIZADO</label>
                                    <select className={`form-select`} name='mujeresvacunadocanalizado' value={persona.mujeresvacunadocanalizado} onChange={handleInputChange}>
                                      <option selected></option>
                                      <option value="VACUNADO">VACUNADO</option>
                                      <option value="CANALIZADO">CANALIZADO</option>
                                    </select>
                                  </div>
                                </div>
                              </div> 
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
                <div class="mb-5">
                  <h5 class="text">Mujeres gestantes</h5>
                  <div class="accordion" id="accordionGestantes">
                      <div class="accordion-item border border-primary">
                        <h2 class="accordion-header">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapsegestantes-1`} aria-expanded="true" aria-controls={`collapsegestantes-1`}>
                            Desplegar para diligenciar datos
                          </button>
                        </h2>
                        <div id={`collapsegestantes-1`} class="accordion-collapse collapse" data-bs-parent="#accordionGestantes">
                          <div class="accordion-body">
                            {/* Agregar aquí los campos específicos de este grupo */}
                            <div class="mb-4"><h5 class="text-center">Por favor al diligenciar registre el año de aplicación de las dosis, de acuerdo a los biológicos trazadores que aquí se indican.</h5>
                              <div class="row">
                                <div class="col-12 col-md-6 col-lg-4">
                                  <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">SEMANAS DE GESTACIÓN</label>
                                    <input
                                      type="number"
                                      name='gestsemanasdegestacion'
                                      className={`form-control`}
                                      value={persona.gestsemanasdegestacion}
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                  <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">CONTROL PRENATAL</label>
                                    <input
                                      type="date"
                                      name='gestcontrolprenatal'
                                      className={`form-control`}
                                      value={persona.gestcontrolprenatal ? persona.gestcontrolprenatal.split('T')[0] : ''}
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                  <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">Tdap Celular (sem 20)rural</label>
                                    <input
                                      type="date"
                                      name='gesttdapcelularsemveinte'
                                      className={`form-control`}
                                      value={persona.gesttdapcelularsemveinte ? persona.gesttdapcelularsemveinte.split('T')[0] : ''}
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                  <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">TOXOIDE TETÁNICO ( sem 14)</label>
                                    <input
                                      type="date"
                                      name='gesttoxoidetetanicosem'
                                      className={`form-control`}
                                      value={persona.gesttoxoidetetanicosem ? persona.gesttoxoidetetanicosem.split('T')[0] : ''}
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                  <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">INFLUENZA (sem 12)</label>
                                    <input
                                      type="date"
                                      name='gestinfluenzasemdoce'
                                      className={`form-control`}
                                      value={persona.gestinfluenzasemdoce ? persona.gestinfluenzasemdoce.split('T')[0] : ''}
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                  <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">COVID (sem12)</label>
                                    <input
                                      type="date"
                                      name='gestcovidsemdoce'
                                      className={`form-control`}
                                      value={persona.gestcovidsemdoce ? persona.gestcovidsemdoce.split('T')[0] : ''}
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                  <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">ESQUEMA COMPLETO (1.SI - 2.NO)</label>
                                    <select className={`form-select`} name='gestesquemacompleto' value={persona.gestesquemacompleto} onChange={handleInputChange}>
                                      <option selected></option>
                                      <option value="SI">SI</option>
                                      <option value="NO">NO</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                  <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">1.VACUNADO / 2.CANALIZADO</label>
                                    <select className={`form-select`} name='gestvacunadocanalizado' value={persona.gestvacunadocanalizado} onChange={handleInputChange}>
                                      <option selected></option>
                                      <option value="VACUNADO">VACUNADO</option>
                                      <option value="CANALIZADO">CANALIZADO</option>
                                    </select>
                                  </div>
                                </div>
                              </div> 
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
                <div class="mb-5">
                  <h5 class="text">Adultos persona y pacientes crónicos</h5>
                  <div class="accordion" id="accordionMayores">
                      <div class="accordion-item border border-primary">
                        <h2 class="accordion-header">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapsemayores-1`} aria-expanded="true" aria-controls={`collapsemayores-1`}>
                            Desplegar para diligenciar datos
                          </button>
                        </h2>
                        <div id={`collapsemayores-1`} class="accordion-collapse collapse" data-bs-parent="#accordionMayores">
                          <div class="accordion-body">
                            {/* Agregar aquí los campos específicos de este grupo */}
                            <div class="mb-4"><h5 class="text-center">Por favor al diligenciar registre el año de aplicación de las dosis, de acuerdo a los biológicos trazadores que aquí se indican.</h5>
                              <div class="row">
                                <div class="col-12 col-md-6 col-lg-4">
                                  <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">DOSIS ANUAL DE INFLUENZA</label>
                                    <input
                                      type="date"
                                      name='mayordosisanualdeinfluenza'
                                      className={`form-control`}
                                      value={persona.mayordosisanualdeinfluenza ? persona.mayordosisanualdeinfluenza.split('T')[0] : ''}
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                  <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">DOSIS COVID</label>
                                    <input
                                      type="date"
                                      name='mayordosiscovid'
                                      className={`form-control`}
                                      value={persona.mayordosiscovid ? persona.mayordosiscovid.split('T')[0] : ''}
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                  <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">ENFERMEDAD CRÓNICA</label>
                                    <input
                                      type="text"
                                      name='mayorenfermedadcronica'
                                      className={`form-control`}
                                      value={persona.mayorenfermedadcronica}
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                  <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">CONTROL ENFERMEDAD</label>
                                    <input
                                      type="text"
                                      name='mayorcontrolenfermedad'
                                      className={`form-control`}
                                      value={persona.mayorcontrolenfermedad}
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                  <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">ESQUEMA COMPLETO (1.SI - 2.NO)</label>
                                    <select className={`form-select`} name='mayoresquemacompleto' value={persona.mayoresquemacompleto} onChange={handleInputChange}>
                                      <option selected></option>
                                      <option value="SI">SI</option>
                                      <option value="NO">NO</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                  <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">1.VACUNADO / 2.CANALIZADO</label>
                                    <select className={`form-select`} name='mayorvacunadocanalizado' value={persona.mayorvacunadocanalizado} onChange={handleInputChange}>
                                      <option selected></option>
                                      <option value="VACUNADO">VACUNADO</option>
                                      <option value="CANALIZADO">CANALIZADO</option>
                                    </select>
                                  </div>
                                </div>
                              </div> 
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
                <div class="mb-5">
                  <h5 class="text">Fiebre amarilla persona DE 1 a 59 años</h5>
                  <div class="accordion" id="accordionAmarilla">
                      <div class="accordion-item border border-primary">
                        <h2 class="accordion-header">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapseamarilla-1`} aria-expanded="true" aria-controls={`collapseamarilla-1`}>
                            Desplegar para diligenciar datos
                          </button>
                        </h2>
                        <div id={`collapseamarilla-1`} class="accordion-collapse collapse" data-bs-parent="#accordionAmarilla">
                          <div class="accordion-body">
                            {/* Agregar aquí los campos específicos de este grupo */}
                            <div class="mb-4"><h5 class="text-center">Por favor al diligenciar registre el año de aplicación de las dosis, de acuerdo a los biológicos trazadores que aquí se indican.</h5>
                              <div class="row">
                                <div class="col-12 col-md-6 col-lg-4">
                                  <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">DOSIS FA</label>
                                    <input
                                      type="date"
                                      name='amaridosisfa'
                                      className={`form-control`}
                                      value={persona.amaridosisfa ? persona.amaridosisfa.split('T')[0] : ''}
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                  <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">ESQUEMA COMPLETO (1.SI - 2.NO)</label>
                                    <select className={`form-select`} name='amariesquemacompleto' value={persona.amariesquemacompleto} onChange={handleInputChange}>
                                      <option selected></option>
                                      <option value="SI">SI</option>
                                      <option value="NO">NO</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-12 col-md-6 col-lg-4">
                                  <div class="m-2 border border-primary-subtle rounded bg-primary bg-opacity-10">
                                    <label class="form-label">1.VACUNADO / 2.CANALIZADO</label>
                                    <select className={`form-select`} name='amarivacunadocanalizado' value={persona.amarivacunadocanalizado} onChange={handleInputChange}>
                                      <option selected></option>
                                      <option value="VACUNADO">VACUNADO</option>
                                      <option value="CANALIZADO">CANALIZADO</option>
                                    </select>
                                  </div>                                        
                                </div>
                              </div> 
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
              </div>

            </form>
            <button type="submit" className="btn btn-primary" onClick={handleGuardar}>Guardar</button>
          </div>
        ) : (
          <div>
            <h4>Editar ficha documento: {ndedocumentodeidentidad}</h4>
            <button type="submit" className="btn btn-primary" onClick={() => setEdicion(true)}>Editar</button>
            <button type="button" className="btn btn-danger" onClick={handleEliminar}>Eliminar</button>
          </div>
        )
      ) : (
        <p>Datos editados, busque una nueva ficha..</p>
      )}
    </div>
  );
}

export default EditarPersona;
