import { openDB } from 'idb';

const DB_NAME = 'my-pwa-form-db';
const STORE_NAME = 'form-data';

const dbPromise = openDB(DB_NAME, 1, {
  upgrade(db) {
    if (!db.objectStoreNames.contains(STORE_NAME)) {
      db.createObjectStore(STORE_NAME, { keyPath: 'id', autoIncrement: true });
    }
  },
});

export const saveFichaFamiliar = async (fichaFamiliar) => {
  const db = await dbPromise;
  const tx = db.transaction(STORE_NAME, 'readwrite');
  const store = tx.objectStore(STORE_NAME);
  await store.put(fichaFamiliar);
};

export const getAllFichaFamiliar = async () => {
  const db = await dbPromise;
  const tx = db.transaction(STORE_NAME, 'readonly');
  const store = tx.objectStore(STORE_NAME);
  return store.getAll();
};

export const deleteFichaFamiliar = async (id) => {
  const db = await dbPromise;
  const tx = db.transaction(STORE_NAME, 'readwrite');
  const store = tx.objectStore(STORE_NAME);
  await store.delete(id);
  // Lógica para eliminar datos por ID de IndexedDB
};