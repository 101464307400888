import axios from 'axios';
import { getAllFichaFamiliar, deleteFichaFamiliar } from './indexedDB';

const API_URL = `${process.env.REACT_APP_API_BASE_URL}/api/formdata`;

export const syncWithMongoDB = async (fichaFamiliar) => {
  try {
    const response = await axios.post(API_URL, fichaFamiliar);
    return response.data;
  } catch (error) {
    console.error('Error syncing with MongoDB:', error);
    return null;
  }
};

export const syncLocalDataWithMongoDB = async () => {
  const localData = await getAllFichaFamiliar();
  
  for (const fichaFamiliar of localData) {
    try {
      const response = await syncWithMongoDB(fichaFamiliar);
      if (response) {
        // Los datos se sincronizaron con éxito, puedes eliminarlos de IndexedDB
        await deleteFichaFamiliar(fichaFamiliar.id);
      }
    } catch (error) {
      console.error('Error syncing data:', error);
    }
  }
};